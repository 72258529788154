<template>
    <div class="footer">
        <div class="footer__left">
            <a :href="$keys.urls.neocc" :title="$keys.titles.neocc" target="_blank">{{ $keys.titles.neocc }}</a>
            <a :href="$keys.urls.termsAndConditions" :title="$t.termsAndConditions" target="_blank">{{ $t.termsAndConditions }}</a>
            <a :href="$keys.urls.privacyPolicy" :title="$t.privacyPolicy" target="_blank">{{ $t.privacyPolicy }}</a>
            <a :href="'javascript:void(0);'"> {{ MilkyWayAttribution }}</a>
            <a :href="$keys.urls.ESAGaia" class="milkyway-copyright-link" :title="$keys.titles.ESAGaia" target="_blank">{{$keys.titles.ESAGaia}}</a>
            <a :href="$keys.urls.CreativeCommons" class="milkyway-copyright-link" :title="$keys.titles.CreativeCommons" target="_blank">{{$keys.titles.CreativeCommons}}</a>
        </div>
        <div class="footer__right">
            {{ currentYear }} {{ attributiontext }} {{ $t.version }}: {{ version }}
        </div>
    </div>
</template>

<script>
import Config from '@/constants/config'

export default {
    name: 'CommonFooter',
    data() {
        return {
            currentYear: (new Date()).getFullYear(),
            MilkyWayAttribution: Config. MilkyWayAttribution,
            attributiontext: Config.attributionText
        }
    },
    computed: {
        version() {
            return process.env.VUE_APP_VERSION;
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/common/footer';
</style>