import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import VisualisationService from '@/services/visualisation.service'

const SpinnerService = {
    getSpinnerShow() {
        return store.getters[CommonGetters.spinnerShow];
    },
    setSpinnerShow(value) {
        if (value === false) {
            SpinnerService.resetToken;
        }
        store.dispatch(CommonActions.setSpinnerShow, value);
    },
    show(withCancel) {
        if (withCancel) {
            SpinnerService.setRequestCancel(true)
        }
        SpinnerService.setSpinnerShow(true);
    },
    hide() {
        SpinnerService.setSpinnerShow(false);
    },
    getRequestCancel() {
        return store.getters[CommonGetters.requestCancel];
    },
    setRequestCancel(value) {
        store.dispatch(CommonActions.setRequestCancel, value);
    },
    getToken() {
        return VisualisationService.getCloseApproachesRequest();
    },
    resetToken() {
        SpinnerService.setRequestCancel(false);
        return VisualisationService.setCloseApproachesRequest(null);
    }
}

export default SpinnerService;