<template>
    <div class="focus-selector">
        <span class="focus-selector__label">{{ $t.focusOn }}</span>
        <Multiselect
            class="focus-selector__input"
            v-model="selectedObject"
            :searchable="true"
            :canClear="false"
            :canDeselect="false"
            :placeholder="'Please select'"
            :groups="true"
            :options="selectOptions"
            @select="onSelect($event)"
        >
            <template v-slot:grouplabel="{ group }">
                <span class="focus-selector__divider" :class="{ 'focus-selector__divider--visible': group.label }">{{
                    group.label
                }}</span>
            </template>
        </Multiselect>
    </div>
</template>

<script>
import SynodicCalculationService from "@/services/synodic-calculation.service";
import VisualisationService from "@/services/visualisation.service";
import Multiselect from "@vueform/multiselect";
import Config from "@/constants/config";
import ObjectsService from "@/services/objects.service";
import SyntheticObjectsService from "@/services/synthetic-objects.service";

export default {
    name: "FocusSelector",
    components: {
        Multiselect,
    },
    props: {
        tool: String,
    },
    data() {
        return {
            selectedObject: "",
            sunSelectOption: { options: [{ label: "Sun", value: "sun" }] },
            planetsSelectList: Config.planetsSelectList,
        };
    },
    computed: {
        focusedObject() {
            let focusedObject = null;
            if (this.tool === "sovt") {
                focusedObject = VisualisationService.getSovtFocusedObject();
            }
            if (this.tool === "ovt") {
                focusedObject = VisualisationService.getOvtFocusedObject();
            }
            return focusedObject;
        },

        settings() {
            return VisualisationService.getSettings();
        },

        sovtPlanetsEnabled() {
            return this.settings && this.settings.objectsSettings.synodicPlanets.value;
        },

        visibleSynodicObjectsList() {
            return SynodicCalculationService.getVisibleRealSynodicObjectsList();
        },

        visibleOvtObjectsList() {
            return VisualisationService.getIsChecked();
        },

        selectOptions() {
            const selectOptions = [this.sunSelectOption];
            selectOptions.push(this.getPlanetsList());
            this.tool === "sovt" &&
                this.visibleSynodicObjectsList.length &&
                selectOptions.push(this.getVisibleAsteroidsList());
            this.tool === "ovt" &&
                this.visibleOvtObjectsList.length &&
                selectOptions.push(this.getVisibleAsteroidsList());
            return selectOptions;
        },
    },
    methods: {
        onSelect(selectedObject) {
            if (this.tool === "sovt") {
                VisualisationService.setSovtFocusedObject(selectedObject);
            }
            if (this.tool === "ovt") {
                VisualisationService.setOvtFocusedObject(selectedObject);
            }
        },

        getPlanetsList() {
            if (this.tool === "sovt") {
                const planetsList = this.sovtPlanetsEnabled
                    ? this.planetsSelectList.map((planet) => ({
                          label: planet,
                          value: `${planet.toLowerCase()}${SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX}`,
                      }))
                    : [{ label: "Earth", value: SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME }];
                return { label: "planets", options: planetsList };
            }
            if (this.tool === "ovt") {
                const planetsList = this.planetsSelectList.map((planet) => ({
                    label: planet,
                    value: planet.toLowerCase(),
                }));
                return { label: "planets", options: planetsList };
            }
        },

        getVisibleAsteroidsList() {
            if (this.tool === "sovt" || this.tool === "ovt") {
                const objectsList = ObjectsService.getFinalAsteroidsList();
                const syntheticObjectsList = SyntheticObjectsService.getSyntheticDefaultObjectsList();
                const objects = objectsList.concat(syntheticObjectsList);
                if (!objects.length) {
                    return null;
                }
                const visibleObjects =
                    this.tool === "sovt" ? this.visibleSynodicObjectsList : this.visibleOvtObjectsList;
                const asteroidsList = visibleObjects.map((designator) => {
                    const object = objects.find((addedObject) => {
                        const isSynthetic = !!addedObject.objectName;
                        return isSynthetic
                            ? addedObject.designator === designator
                            : SynodicCalculationService.getDesignator(addedObject) === designator;
                    });
                    if (!object) {
                        return null;
                    }
                    const isSynthetic = !!object.objectName;
                    return {
                        label: object.objectName || ObjectsService.objectNumberAndName(object),
                        value: isSynthetic ? object.designator : SynodicCalculationService.getDesignator(object),
                    };
                });
                return { label: "asteroids", options: asteroidsList };
            }
        },
    },
    watch: {
        focusedObject(focusedObject) {
            this.tool === "sovt" && VisualisationService.handleFocusOnChange(focusedObject, "sovt");
            this.tool === "ovt" && VisualisationService.handleFocusOnChange(focusedObject, "ovt");
            this.selectedObject = focusedObject;
        },
    },
    mounted() {
        if (this.tool === "sovt") {
            this.selectedObject = VisualisationService.getSovtFocusedObject();
        }
        if (this.tool === "ovt") {
            this.selectedObject = VisualisationService.getOvtFocusedObject();
        }
    },
};
</script>

<style lang="scss">
@import "@/styles/common/focus-selector.scss";
</style>
