import Store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import MemoryService from '@/services/memory.service'
import PopupService from '@/services/popup.service'
import Keys from '@/constants/keys'
import isEqual from 'lodash/isEqual'
import ObservatoryService from '@/services/observatory.service'
import TimeFrameService from '@/services/timeframe.service'
import ObjectsService from '@/services/objects.service'
import CalculateService from '@/services/calculate.service'
import SyntheticObjectsService from '@/services/synthetic-objects.service'

export default {
    optFilteringAvailable() {
        const selectedObservatory = ObservatoryService.getSelectedObservatory();
        const observatoryReady = (selectedObservatory && selectedObservatory.longitude !=='' && !isNaN(selectedObservatory.longitude) && selectedObservatory.latitude !=='' && !isNaN(selectedObservatory.latitude));

        const timeFrame = TimeFrameService.getTimeFrame();
        const timeFrameReady = (timeFrame && timeFrame.converted.start && timeFrame.converted.end);

        const enableOptFiltering = Store.getters[CommonGetters.enableOptFiltering];

        const setupMemory = MemoryService.getSetupMemory();
        const currentSetup = MemoryService.getCurrentSetup();
        const setupChanged = !isEqual(setupMemory, currentSetup);

        if (observatoryReady && timeFrameReady) {
            if (!enableOptFiltering) {
                Store.dispatch(CommonActions.setEnableOptFiltering, true);
                MemoryService.saveSetupMemory();
                return true;
            } else if (enableOptFiltering && setupChanged) {
                PopupService.show({
                    component: 'PopupDialog',
                    type: 'warning',
                    message: Keys.t.setupConfigurationWasChanged,
                    actions: {
                        cancel: {
                            text: Keys.t.restorePreviousConfiguration,
                            emits: 'cancel-setup-change',
                        },
                        submit: {
                            text: Keys.t.refreshFiltering,
                            emits: 'confirm-setup-change',
                        }
                    }
                });
                return false; 
            } else {
                return true;
            }
        } else {
            if (!observatoryReady) {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'warning',
                    message: Keys.t.invalidObservatoryData
                });
            } else {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'warning',
                    message: Keys.t.invalidTimeFrameData
                });
            }
            Store.dispatch(CommonActions.setEnableOptFiltering, false);
            return false;
        }
    },

    optResultsAvailable(ignoreFilterChange = false) {
        const setupMemory = MemoryService.getSetupMemory();
        const currentSetup = MemoryService.getCurrentSetup();
        const setupChanged = !isEqual(setupMemory, currentSetup);

        const timestepMemory = MemoryService.getTimestepMemory();
        const currentTimestep = TimeFrameService.getTimestep();
        const timestepChanged = !isEqual(timestepMemory, currentTimestep);

        const enableOptResults = Store.getters[CommonGetters.enableOptResults];
        const selectedObjects = ObjectsService.getObjectsSelected().length || 0;

        if (setupChanged) {
            return false;
        } else if (timestepMemory) {
            if (ignoreFilterChange && ignoreFilterChange === 'true') {
                return true;
            } else if (timestepChanged) {
                PopupService.show({
                    component: 'PopupDialog',
                    type: 'warning',
                    message: Keys.t.filteringConfigurationWasChanged,
                    actions: {
                        cancel: {
                            text: Keys.t.goToResults,
                            emits: 'ignore-filtering-change',
                        },
                        submit: {
                            text: Keys.t.recalculateEphemerides,
                            emits: 'confirm-filtering-change',
                        }
                    }
                });
                return false;
            } else {
                return true;
            }
        } else {
            return enableOptResults && selectedObjects;
        }
    },

    scdVisualisationAvailable() {
        const skychartEnableVisualisation = Store.getters[CommonGetters.skychartEnableVisualisation];
        const isSyntheticObject = !!SyntheticObjectsService.getSyntheticDefaultObjectsList().length;

        if (CalculateService.dataPointsLimitExceeded()) {
            CalculateService.dataPointsExceededNotification();
            return false;
        } else {
            return skychartEnableVisualisation || isSyntheticObject;
        }
    }
}