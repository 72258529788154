<template>
    <div class="ovt__particle-size">
        <div class="ovt__particle-size-label">{{ $t.particleSize }}</div>
        <div class="ovt__particle-size-slider">
            <span class="units">
                {{ min }}
            </span>
            <Slider :lazy='false' :tooltips="false" :step="1" v-model="size" :min="min" :max="max" />
            <span class="units">
                {{ max }}
            </span>
        </div>
        
    </div>
</template>

<script>
import Slider from '@vueform/slider'
import SpacekitService from '@/services/spacekit.service'
import VisualisationService from '@/services/visualisation.service';

export default {
    name: 'OrbitParticlesSize',
    components: {
        Slider,
    },
    data() {
        return {
            min: 1,
            max: 20,
            size: 7,
        }
    },
    watch: {
        size(newVal) {
            SpacekitService.resizeParticles(newVal);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.size = VisualisationService.getSettings().particleSize
        });
    }
}
</script>

<style>
</style>