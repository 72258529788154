<template>
    <button class="primary" :class="{disabled: !objectsSelected}" @click="init()">
        {{ $t.calculateEphemerides }}
    </button>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import CalculateService from '@/services/calculate.service'

export default {
    name: 'CalculateEphemerides',
    computed: {
        objectsSelected() {
            return ObjectsService.getObjectsSelected().length;
        },
    },
    methods: {
        init() {
            CalculateService.initCalculations();
        }
    },
}
</script>

<style>

</style>