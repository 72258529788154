<template>
    <div class="longterm-plots__legend">
        <span v-for="(julianDate, index) of timestamps" :key="index" class="longterm-plots__legend--item" :class="{'first-in-day': isFirstInDay(index)}">
            <span class="longterm-plots__legend--date">{{ date(julianDate) }}</span>
        </span>
    </div>
</template>

<script>
import UtilsService from '@/services/utils.service'
import LongtermService from '@/services/longterm.service'

export default {
    name: 'ResultsLongTermLegend',
    data() {
        return {
            timestamps: [],
            object: {},
        }
    },
    computed: {
        longtermObjectName() {
            return LongtermService.getLongtermObjectName();
        },
        longtermEphemerides() {
            return LongtermService.getLongtermEphemerides();
        },
    },
    methods: {
        date(julianDate) {
            const timestamp = UtilsService.julianToTimestamp(julianDate);
            return UtilsService.momentUtcDate(timestamp, true);
        },
        isFirstInDay(index) {
            if (index) {
                const currentTimestamp = UtilsService.julianToTimestamp(this.timestamps[index]);
                const previousTimestamp = UtilsService.julianToTimestamp(this.timestamps[index - 1]);
                return !UtilsService.isTheSameDay(currentTimestamp, previousTimestamp);
            } else {
                return true;
            }
        },
        prepareTimestamps() {
            this.timestamps = UtilsService.reduceDensity(this.object.timestamps);
        }
    },
    watch: {
        longtermObjectName(newVal) {
            this.object = this.longtermEphemerides[newVal];
            this.prepareTimestamps();
        },
    },
    mounted() {
        this.object = this.longtermEphemerides[this.longtermObjectName];
        this.prepareTimestamps();
    }
}
</script>

<style>

</style>