<template>
    <div class="longterm-plots__bar" :class="`color-${plot}`">
        <span class="longterm-plots__bar--item" v-for="(value, index) of values" :key="index" :class="{observable: isObservableAll(index)}">
            <span class="longterm-plots__bar--value" :class="{active: isObservable(index)}" />
        </span>
    </div>
</template>

<script>
import UtilsService from '@/services/utils.service';

export default {
    name: 'ResultsLongTermBar',
    props: {
        plot: String,
        object: Object,
    },
    data() {
        return {
            values: []
        }
    },
    methods: {
        isObservableAll(index) {
            if (!this.object.plots[this.plot].enable) return false;
            return this.object.observableAll.indexOf(index) > -1;
        },
        isObservable(index) {
            const plot = this.object.plots[this.plot];
            if (!plot.enable) return false;
            return plot.observable.indexOf(index) > -1;
        },   
    },
    mounted() {
        if (this.object) {
            this.values = UtilsService.reduceDensity(this.object.plots[this.plot].values);
        }
    }
}
</script>

<style>

</style>