<template>
    <span class="select-handler">
        <i class="icon-square" @click="selectAll()" v-if="selectedNone()"></i>
        <i class="icon-minus-square" @click="selectAll()" v-if="selectedSome()"></i>
        <i class="icon-check-square" @click="selectNone()" v-if="selectedAll()"></i>
    </span>
</template>

<script>
import ObjectsService from '@/services/objects.service'

export default {
    name: 'SelectHandler',
    data() {
        return {
            objectsList: [],
        }
    },
    computed: {
        objectsSelected() {
            return ObjectsService.getObjectsSelected();
        },
        objects() {
            return ObjectsService.getObjects();
        },
    },
    methods: {
        selectAll() {
            ObjectsService.selectAll();
        },
        selectNone() {
            ObjectsService.selectNone();
        },
        selectedAll(){
            return this.objectsSelected.length && (this.objectsSelected.length === this.objectsList.length);
        },
        selectedSome(){
            return this.objectsSelected.length && (this.objectsSelected.length !== this.objectsList.length);
        },
        selectedNone(){
            return !this.objectsSelected.length;
        },
    },
    watch: {
        objects: {
            deep: true,
            handler(newVal) {
                this.objectsList = newVal.list;
            }
        },
    },
    mounted() {
        this.objectsList = this.objects.list;
    },
}
</script>

<style>

</style>