import axios from 'axios'
import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Keys from '@/constants/keys'
import Config from '@/constants/config'
import PopupService from '@/services/popup.service'
import UtilsService from '@/services/utils.service'

const emptyObservatory = {
    code: null,
    alt: 0,
    id: 0,
    latitude: 0,
    longitude: 0,
    name: '',
}

const geocentricObservatory = {
    code: '500',
    alt: 0,
    id: 471,
    latitude: 0,
    longitude: 0,
    name: 'Geocentric'
}

const ObservatoryService = {
    getObservatoryApiUrl() {
        return Config.api.restUrl + Config.api.findObservatoryByPhraseUrl;
    },

    getLoadingObservatoryList() {
        return store.getters[CommonGetters.loadingObservatoryList];
    },

    setLoadingObservatoryList(value) {
        store.dispatch(CommonActions.setLoadingObservatoryList, value);
    },

    getObservatoryList() {
        return store.getters[CommonGetters.observatoryList];
    },

    getSelectedObservatory() {
        return store.getters[CommonGetters.selectedObservatory];
    },

    getRecentlySelectedObservatory() {
        return store.getters[CommonGetters.recentlySelectedObservatory];
    },

    setSelectedObservatory(value) {
        value.latitude  = value.latitude*1;
        value.longitude  = value.longitude*1;
        store.dispatch(CommonActions.setSelectedObservatory, value);
    },

    isGeocentric() {
        return ObservatoryService.getSelectedObservatory().code === '500';
    },

    setGeocentricObservatory() {
        ObservatoryService.setSelectedObservatory(geocentricObservatory);
    },
    
    setEmptyObservatory() {
        ObservatoryService.setSelectedObservatory(emptyObservatory);
    },

    setRecentlySelectedObservatory() {
        ObservatoryService.setSelectedObservatory(ObservatoryService.getRecentlySelectedObservatory());
    },

    setUserDefinedObservatory(latitude = 0, longitude = 0, name = Keys.t.custom) {
        const userObservatorySettings = {latitude, longitude, name}
        const userDefinedObservatory = {... emptyObservatory, ... userObservatorySettings};
        ObservatoryService.setSelectedObservatory(userDefinedObservatory);
    },

    findObservatoryByPhrase(phrase = '') {
        if (!phrase || phrase === '') {
            store.dispatch(CommonActions.setObservatoryList, []);
            return;
        }
        ObservatoryService.setLoadingObservatoryList(true);
        const url = ObservatoryService.getObservatoryApiUrl() + '?query=' + phrase;
        axios(url)
            .then(response => {
                store.dispatch(CommonActions.setObservatoryList, response.data);
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                ObservatoryService.setLoadingObservatoryList(false);
            });
	},

    getObservatoryElevation() {
        return store.getters[CommonGetters.observatoryElevation];
    },

    setObservatoryElevation(value) {
        store.dispatch(CommonActions.setObservatoryElevation, value);
    },
}

export default ObservatoryService;