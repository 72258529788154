<template>
    <div class="slider__title">{{ $t[type] }}</div>
    <div class="slider__minmax degrees">{{ `${fovLabel} x`}}</div>
    <input class="slider__input" type="number" v-model="setting.value" :step="0.1" />
    <div class="slider__minmax degrees__title">{{$t.squareDeg}}</div>
    <div class="slider__minmax">{{ setting.min }}</div>
    <Slider :lazy='false' :tooltips="false" orientation="vertical" direction="ltr" v-model="setting.value" :step="0.1" :min="setting.min" :max="setting.max" />
    <div class="slider__minmax">{{ setting.max }}</div>
   <div class="slider__units">{{ $t.sliderDegrees }}</div>
</template>

<script>
import Slider from '@vueform/slider'
import SkychartService from '@/services/skychart.service';

export default {
    name: 'SkyChartSettingsSlider',
    components: {
        Slider,
    },
    props: {
        type: String,
        settings: Object,
    },
    data() {
        return {
            setting: this.settings[this.type],
            fovLabel: '',
        }
    },
    methods: {
        updateFovLabel() {
            const scd = window['scd'];
            const container = document.querySelector('#skychart');
            const width = container.clientWidth;
            const height = container.clientHeight;
            const aspect = width / height;
            this.fovLabel = (this.settings.fov.value * aspect).toFixed(1);
            const displaySettings = SkychartService.getSettings().displaySettings;

            if (scd && scd.scene){
                const scene  =  scd.scene;
                setTimeout(() => {
                    SkychartService.updateGrids(scene, displaySettings, "");
                }, 10);
            }
        }
    },
    watch: {
        setting: {
            deep: true,
            handler() {
                this.updateFovLabel();
            }
        }
    },
    mounted() {
        this.updateFovLabel();
        window.addEventListener('resize', this.updateFovLabel, false);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateFovLabel);
    }
}
</script>

<style>

</style>
