<template>
    <div
        v-if="arrowLegend && !onAir"
        class="fvt__legend arrow-legend"
        :class="{
            'with-config': configPanelOpen,
            'with-details': objectDetails && activeObject
        }">
        <div class="fvt__legend--label">{{ $t.arrowLegend }}</div>
        <div class="d-table">
            <div class="d-table-row">                
                <div class="d-table-cell"><span class="fvt_legend vector-legend sun" :style="sunColor"></span></div>                                
                <div class="d-table-cell">{{ $t.sun }} {{ $t.vector }}</div>
            </div>
            <div class="d-table-row">                
                <div class="d-table-cell"><span class="fvt_legend vector-legend moon" :style="moonColor"></span></div>                                
                <div class="d-table-cell">{{ $t.moon }} {{ $t.vector }}</div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell"><span class="fvt_legend vector-legend object" :style="objectColor"></span></div>                                
                <div class="d-table-cell">{{ $t.object }} {{ $t.vector }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'FlybyArrowLegend',
    computed: {
        settings() {
            return VisualisationService.getSettings();
        },
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        arrowLegend() {
            return this.settings && this.settings.objectsSettings.arrowLegend.value;
        },
        objectDetails() {
            return this.settings && this.settings.objectsSettings.objectDetails.value;
        },
        activeObject() {
            return VisualisationService.getOrbitActiveObject();
        },
        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        },              
        sunColor() {
            return {
            '--background-color-sun': this.settings.objectsSettings.sunOrbit.color,
            };
        },
        moonColor() {            
            return {
            '--background-color-moon': this.settings.objectsSettings.moonOrbit.color,
            };
        },
        objectColor() {            
            return {
            '--background-color-object': this.settings.objectsSettings.asteroidOrbit.color,
            };
        }    
    },    
}
</script>
