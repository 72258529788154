<template>    
    <div class="slider-panel">        
        <div v-if= "showLabel" class="slider-panel__label">{{$t[option]}}:</div>        
        <div class="slider-panel_wrapper">            
            <div v-if="stepDecimal">
                <Slider :lazy='false' :tooltips="true" :format="format" :step="settings.step" v-model="value" :min="min" :max="max" :merge="merge"/>
                <span v-if="units" class="slider-panel__units">{{ settings.units }}</span>
            </div>
            <div v-else>
                <Slider :lazy='false' :tooltips="true" :step="settings.step" v-model="value" :min="min" :max="max" :merge="merge"/>
                <span v-if="units" class="slider-panel__units">{{ settings.units }}</span>
            </div>                         
        </div>                         
        <span class="slider-panel__min">
                {{ min }}
        </span>
        <span class="slider-panel__max">
                {{ max }}
        </span>           
    </div>    
</template>

<script>
import Slider from '@vueform/slider'

export default {
    name: 'PanelSlider',
    components: {
        Slider,
    },
    props: {
        option: String,
        settings: Object,
        min: Number,
        max: Number,
        decimalFormat: Number,
        groupName: String,
        onChange: {
            type: Function
        },                     
    },
    data() {
        return {
            value: this.settings.value,             
            units: this.settings.units,
            format: {                  
                decimals: this.decimalFormat || 1,                                
            },            
            merge: this.settings.merge,  
        }                    
    },
    computed: {        
        stepDecimal(){                        
            return !Number.isInteger(this.settings.step);
        },               
        showLabel(){   
            return this.settings.label === undefined ? true: this.settings.label;            
        }
    },  
    methods: { 
    },
    watch: {
        value(newVal) {            
            if (this.onChange && !this.groupName) {               
                this.onChange(this.option,'value', newVal);
            } else if (this.onChange && this.groupName) {
                this.onChange(this.option, this.groupName, newVal);
            }          
        }       
    },
    mounted() {             
    }
}
</script>

<style>
</style>
