<template>
    <div class="scd">
        <SkychartObjectsTable />
        <SkychartDisplay
            v-if="settings && objects" 
            :settings="settings" 
            :objects="objects" 
            :traceDate="traceDate"
            :activeObjectName="activeObjectName"
            :withPanel="withPanel"
            @object-select="onObjectSelect($event)" 
            @fov-change="onFovChange($event)" 
            @print-request="onPrintRequest($event)"
            @view-legend="onViewLegend($event)">
            <SkychartObjectsDetails />
            <SkychartObjectLegend v-if="legend" :legend="legend" :withPanel="withPanel" />
            <SkychartTelescopeLegend v-if="viewLegend" :legend="viewLegend" :withPanel="withPanel" />
        </SkychartDisplay>
        <SkychartSettings @print-request="onPrintRequest($event)" />
    </div>
</template>

<script>
import SkychartObjectsTable from '@/components/scd/SkychartObjectsTable'
import SkychartObjectsDetails from '@/components/scd/SkychartObjectsDetails'
import SkychartDisplay from '@/components/scd/SkychartDisplay'
import SkychartSettings from '@/components/scd/SkychartSettings'
import SkychartObjectLegend from '@/components/scd/SkychartObjectLegend'
import SkychartService from '@/services/skychart.service'
import ObjectsService from '@/services/objects.service'
import UtilsService from '@/services/utils.service'
import TimeFrameService from '@/services/timeframe.service'
import SyntheticObjectsService from '@/services/synthetic-objects.service'
import SkychartTelescopeLegend from '@/components/scd/SkychartTelescopeLegend'

export default {
    name: 'SkyChart',
    data() {
        return {
            legend: null,
            viewLegend: null,
        }
    },
    components: {
        SkychartObjectsTable,
        SkychartObjectsDetails,
        SkychartDisplay,
        SkychartSettings,
        SkychartObjectLegend,
        SkychartTelescopeLegend
    },
    computed: {
        settings() {
            return SkychartService.getSettings();
        },
        objects() {
            const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
            const syntheticObjectsList = SyntheticObjectsService.getSyntheticDefaultObjectsList();
            return finalAsteroidsList.concat(syntheticObjectsList);
        },
        traceDate() {
            return SkychartService.getTraceDate();
        },
        activeObjectName() {
            return SkychartService.getActiveObjectName();
        },
        withPanel() {
            return SkychartService.getObjectsShow();
        },
    },
    methods: {
        onObjectSelect(object) {
            if (object) {
                this.legend = object.objectInfo;
                if (object.objectInfo[4]) {
                    SkychartService.setActiveObjectName(object.name);
                    SkychartService.setTraceDate(object.objectInfo[4]);
                }
            } else {
                this.legend = null;
            }
        },
        onFovChange(data) {
            this.settings.fov.value = data;
        },
        onPrintRequest(data) {
            this.settings.printRequest = data;
        },
        onViewLegend(legend) {
            this.viewLegend = legend;
        }
    },
    mounted() {
        const julianDateStart = UtilsService.dateToJulian(TimeFrameService.getTimeFrame().start + ' 00:00');
        SkychartService.setTraceDate(julianDateStart+'');
    }
}
</script>

<style lang="scss">
@import "@/styles/scd/skychart.scss";
</style>