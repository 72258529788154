<template>
    <Spinner />
    <Popup />
    <Header />
    <div class="content">
        <router-view/>
    </div>
    <Footer />
    <CookieNotification />
    <EventBus />
</template>

<script>
import Spinner from '@/components/common/Spinner'
import Popup from '@/components/common/Popup'
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import CookieNotification from '@/components/common/CookieNotification'
import EventBus from '@/components/common/EventBus'

export default {
    components: {
        Spinner,
        Popup,
        Header,
        Footer,
        CookieNotification,
        EventBus,
    },
}
</script>

<style lang="scss">
@import "@/styles/global.scss";
</style>
