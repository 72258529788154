<template>    
    <label v-if= "showLabel">{{$t[option]}}:</label>
    <VueDatePicker :model-value="date" :teleport="true" :min-date="min" :max-date="max" :enable-time-picker="false" format="dd/MM/yyyy" :clearable="false" :year-range="years_range" auto-apply @update:model-value="trailLeadYearsUpdate" prevent-min-max-navigation text-input/>        
</template>

<script>
import JulianDate from 'julian-date'
import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import UtilsService from '@/services/utils.service';
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'PanelDatePicker',
    components: {
        VueDatePicker,
    },
    props: {
        option: String,
        settings: Object,
        min: Date,
        max: Date,       
        onChange: {
            type: Function
        },                     
    },
    data() {
        return {
            date: '', // this.value,
            valueBack: 2 * 365.242,
            valueFront: 2 * 365.242
        }                    
    },
    created() {
       // this.date = this.value; // Set the date property after the component is created
  },
    computed: {                             
        showLabel(){   
            return this.settings.label === undefined ? true: this.settings.label;            
        },
        currentDate(){
            return VisualisationService.getCurrentDate();
        },
        dateFormat() {              
            if (this.date === undefined) {
                return;
            }
            const day = String(this.date.getDate()).padStart(2, '0');
            const month = String(this.date.getMonth() + 1).padStart(2, '0');
            const year = this.date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        value() {           
            let valueFromConfig = this.settings.value;            
            const isBack = this.settings.back;                
            if (typeof valueFromConfig === 'number') {                        
                valueFromConfig = this.convertToDate(valueFromConfig, isBack);             
            }                
            return valueFromConfig;              
        }, 
        years_range() {
            return [this.min.getFullYear(),this.max.getFullYear()];
        }            
    },  
    methods: {
        julianToDate(julian) {
            const j = new JulianDate().julian(julian);
            return j.getDate();
        },  
        fromDecimalYear(num) {
            let year = parseFloat(num);
            return this.julianToDate(year);
        },
        toDecimalYear(date) {
            let d = new Date(date);
            return UtilsService.dateToJulian(d);
        },
        convertToDate(value, isBack=false) {  
            let valueToDate = new Date(VisualisationService.getCurrentDate()); 
            if (isBack) {
                valueToDate = this.fromDecimalYear(this.toDecimalYear(valueToDate) - value);
            } else {
                valueToDate = this.fromDecimalYear(this.toDecimalYear(valueToDate) + value);
            }   
            valueToDate = UtilsService.clamp(valueToDate, this.min, this.max);                    
            return valueToDate;
        },            
        calculateYearDifference(start, end) { // Calculate the difference between two dates in years                        
            let difference = Math.abs(this.toDecimalYear(end) - this.toDecimalYear(start));
            return difference; 
        },       
        convertToNumber(value) {     
            const currentDate = new Date(VisualisationService.getCurrentDate());  
            if (this.settings.back) {    
                this.valueBack =  this.calculateYearDifference(value, currentDate);
                this.onChange(this.option,'value', this.valueBack/365.242)
            }  else {
                this.valueFront = this.calculateYearDifference(currentDate,value);   
                this.onChange(this.option,'value', this.valueFront/365.242)
            }   

        },
        trailLeadYearsUpdate(event) {
            this.date = event;
            this.convertToNumber(event);
        },                  
    },
    watch: {
        currentDate(newVal){ 
            let valueToDate;
            if (this.settings.back) {
                valueToDate = this.fromDecimalYear(this.toDecimalYear(newVal) - this.valueBack);
            } else {
                valueToDate = this.fromDecimalYear(this.toDecimalYear(newVal) + this.valueFront);
            }
            valueToDate = UtilsService.clamp(valueToDate, this.min, this.max);
            this.date = UtilsService.dateToStringTechnical(valueToDate, true);
        }
    },
    mounted() {      
        const currentDate = VisualisationService.getCurrentDate();  
        let valueToDate;
        if (this.settings.back) {
            const valueBack = this.settings.value * 365.242; 
            valueToDate = this.fromDecimalYear(this.toDecimalYear(currentDate) - valueBack);
            this.valueBack = valueBack;
        } else {
            const valueFront = this.settings.value * 365.242; 
            valueToDate = this.fromDecimalYear(this.toDecimalYear(currentDate) + valueFront);
            this.valueFront = valueFront;
        }
        valueToDate = UtilsService.clamp(valueToDate, this.min, this.max);
        this.date = UtilsService.dateToStringTechnical(valueToDate, true);
    }
}
</script>


<style>
</style>