import store from '@/store'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Config from '@/constants/config'
import PopupService from '@/services/popup.service'
import FilteringService from '@/services/filtering.service'
import ShorttermService from '@/services/shortterm.service'
import LongtermService from '@/services/longterm.service'
import UtilsService from '@/services/utils.service'

const EphemeridesService = {
    getEphemerides() {
        return store.getters[CommonGetters.ephemerides];
    },

    getEphemeridesFiltered() {
        return store.getters[CommonGetters.ephemeridesFiltered];
    },

    setEphemeridesFiltered(inputColumns = null) {
        const ephemeridesFiltered = cloneDeep(EphemeridesService.getEphemerides());
        const columns = inputColumns || [...EphemeridesService.getEphemeridesColumns()];
        const onlyObservable = EphemeridesService.ephemeridesOnlyObservable();
        const filters = FilteringService.getFilters();
        const map = FilteringService.getFiltersEphemeridesMap();
        
        const indexes = [];
        for (const column of columns) {
            indexes.push(ephemeridesFiltered.fields.indexOf(column));
        }

        if (indexes.length) {
            const units = [];
            for (const index of indexes) {
                units.push(ephemeridesFiltered.units[index])
            }
            
            for (const objectName in ephemeridesFiltered.objects) {
                const dataPoints = ephemeridesFiltered.objects[objectName];

                if (!inputColumns && onlyObservable && filters && Object.keys(filters).length) {
                    for (const filter in filters) {
                        const filterMin = parseFloat(filters[filter][filter+'Min']);
                        const filterMax = parseFloat(filters[filter][filter+'Max']);
                        const filterIndex = ephemeridesFiltered.fields.indexOf(map[filter]);
                        for (let index = dataPoints.length -1; index >= 0; index--) {
                            const dataPoint = dataPoints[index];
                            const value = parseFloat(dataPoint[filterIndex]);
                            const withinRange = value >= filterMin && value <= filterMax;
                            if (!withinRange) {
                                dataPoints.splice(index, 1);
                            }
                        }
                    }
                }

                if (inputColumns) {
                    dataPoints.splice(1);
                }

                for (const [index, dataPoint] of dataPoints.entries()) {
                    const newDataPoint = [];
                    for (const index of indexes) {
                        newDataPoint.push(dataPoint[index])
                    }
                    dataPoints[index] = newDataPoint;
                }
            }

            ephemeridesFiltered.fields = columns;
            ephemeridesFiltered.units = units;
        }
        if (inputColumns) {
            store.dispatch(CommonActions.setEphemeridesShortterm, ephemeridesFiltered);
        } else {
            store.dispatch(CommonActions.setEphemeridesFiltered, ephemeridesFiltered);
        }
    },

    setEphemerides(ephemerides) {
        store.dispatch(CommonActions.setEphemerides, ephemerides);
    },

    ephemeridesOnlyObservable() {
        return store.getters[CommonGetters.ephemeridesOnlyObservable];
    },

    setEphemeridesOnlyObservable(boolean) {
        store.dispatch(CommonActions.setEphemeridesOnlyObservable, boolean);
        EphemeridesService.setEphemeridesFiltered();
    },

    getEphemeridesActiveRow() {
        return store.getters[CommonGetters.ephemeridesActiveRow];
    },

    setEphemeridesActiveRow(objectName) {
        store.dispatch(CommonActions.setEphemeridesActiveRow, objectName);
    },

    getEphemeridesColumns() {
        return store.getters[CommonGetters.ephemeridesColumns];
    },

    setEphemeridesColumns(array) {
        store.dispatch(CommonActions.setEphemeridesColumns, array);
        EphemeridesService.setEphemeridesFiltered();
    },

    getEphemeridesDefaultColumns() {
        return (Config.ephemerides.defaultColumns).split(',');
    },

    getEphemeridesSunmoonColumns() {
        return (Config.ephemerides.sunmoonColumns).split(',');
    },

    setEphemeridesDefaultColumns() {
        const columns = [ ...EphemeridesService.getEphemeridesDefaultColumns() ];
        EphemeridesService.setEphemeridesColumns(columns);
    },

    setEphemeridesAllColumns() {
        const columns = [ ...EphemeridesService.getEphemerides().fields ];
        EphemeridesService.setEphemeridesColumns(columns);
    },

    getAvailableEphemeridesColumns() {
        return store.getters[CommonGetters.availableEphemeridesColumns];
    },

    setAvailableEphemeridesColumns(value) {
        store.dispatch(CommonActions.setAvailableEphemeridesColumns, value);
    },

    getFinalEphemeridesColumns() {
        return store.getters[CommonGetters.finalEphemeridesColumns];
    },

    setFinalEphemeridesColumns(value) {
        store.dispatch(CommonActions.setFinalEphemeridesColumns, value);
    },

    updateEphemeridesColumns(availableEphemeridesColumns = null, finalEphemeridesColumns = null) {
        const availableColumns = availableEphemeridesColumns || EphemeridesService.getAvailableEphemeridesColumns();
        const finalColumns = finalEphemeridesColumns || EphemeridesService.getFinalEphemeridesColumns();
        EphemeridesService.setAvailableEphemeridesColumns(availableColumns);
        EphemeridesService.setFinalEphemeridesColumns(finalColumns);
    },
    
    ephemeridesConfigAdd(availableSelected) {
        let finalEphemeridesColumns = [...EphemeridesService.getFinalEphemeridesColumns(), ...availableSelected];
        let availableEphemeridesColumns = [...EphemeridesService.getAvailableEphemeridesColumns()];
        for (const item of availableSelected) {
            availableEphemeridesColumns = availableEphemeridesColumns.filter(e => e !== item);
        }
        EphemeridesService.updateEphemeridesColumns(availableEphemeridesColumns, finalEphemeridesColumns);
        return [];
    },
    
    ephemeridesConfigAddAll() {
        const finalEphemeridesColumns = [...EphemeridesService.getFinalEphemeridesColumns()];
        for (const item of EphemeridesService.getAvailableEphemeridesColumns()) {
            finalEphemeridesColumns.push(item);
        }
        EphemeridesService.updateEphemeridesColumns([], finalEphemeridesColumns);
        return [];
    },
    
    ephemeridesConfigRemove(finalSelected) {
        let finalEphemeridesColumns = [...EphemeridesService.getFinalEphemeridesColumns()];
        let availableEphemeridesColumns = [...EphemeridesService.getAvailableEphemeridesColumns(), ...finalSelected];
        for (const item of finalSelected) {
            finalEphemeridesColumns = finalEphemeridesColumns.filter(e => e !== item);
        }
        EphemeridesService.updateEphemeridesColumns(availableEphemeridesColumns, finalEphemeridesColumns);        
        return [];
    },
    
    ephemeridesConfigRemoveAll() {
        const availableEphemeridesColumns = [...EphemeridesService.getAvailableEphemeridesColumns()];
        for (const item of EphemeridesService.getFinalEphemeridesColumns()) {
            availableEphemeridesColumns.push(item);
        }
        EphemeridesService.updateEphemeridesColumns(availableEphemeridesColumns, []);
        return [];
    },

    getLongTermPlots() {
        const configObj = {};
        const token = FilteringService.getToken();
        if (token && token !== '') {
            configObj.headers = {
                Token: token
            }
        }

        const url = LongtermService.longTermUrl();
        return axios(url, configObj)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
    },

    getShortTermPlots() {
        const configObj = {};
        const token = FilteringService.getToken();
        if (token && token !== '') {
            configObj.headers = {
                Token: token
            }
        }

        const url = ShorttermService.shortTermUrl();
        return axios(url, configObj)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
    },

    async getLongAndShortTermPlots() {
        const promises = [EphemeridesService.getLongTermPlots()];

        await Promise.all(promises).then(([longTermPlots]) => {
            EphemeridesService.mapEphemerides(longTermPlots);
        });
    },

    mapEphemerides(ephemeridesObject) {
        if (!ephemeridesObject || !ephemeridesObject.object || !ephemeridesObject.object.data || !ephemeridesObject.object.data.length || !ephemeridesObject.sunmoon) return;

        const ephemerides = EphemeridesService.getEphemerides();
        ephemerides.fields = ephemeridesObject.object.fields;
        ephemerides.units = ephemeridesObject.object.units;
        ephemerides.sunmoon = ephemeridesObject.sunmoon;
        ephemerides.objects = {};

        for (const object of ephemeridesObject.object.data) {
            ephemerides.objects[object.name] = object.data;
        }

        EphemeridesService.buildAdditionalColumns(ephemerides);
        EphemeridesService.setEphemerides(ephemerides);
    },

    buildAdditionalColumns(ephemerides) {
        if (ephemerides && ephemerides.sunmoon && ephemerides.sunmoon.fields && ephemerides.sunmoon.units) {
            const additionalColumns = EphemeridesService.getEphemeridesSunmoonColumns();
            for (const column of additionalColumns) {
                const index = ephemerides.sunmoon.fields.indexOf(column);
                if (index > -1) {
                    ephemerides.fields.push(column);
                    ephemerides.units.push(ephemerides.sunmoon.units[index]);
                    for (const name in ephemerides.objects) {
                        for (const [i, timestep] of ephemerides.objects[name].entries()) {
                            timestep.push(ephemerides.sunmoon.data[i][index]);
                        }
                    }
                }
            }
        }       
    }
}

export default EphemeridesService;