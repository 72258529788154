<template>
    <button class="link" @click="downloadContent()">
        <i class="icon-download" /><span>{{ $t.download }}</span>
    </button>
</template>

<script>
import Config from '@/constants/config'
import UtilsService from '@/services/utils.service'

export default {
    name: 'EphemeridesDownload',
    data() {
        return {
            fileContent: '',
            fileName: Config.ephemerides.downloadFileName,
        }
    },
    methods: {
        parseEphemeridesTable() {
            this.fileContent = '';
            const table = document.querySelector('.ephemerides-table');
            const tableRows = table.querySelectorAll('.d-table-row');

            for (const tableRow of tableRows) {
                const tableCells = tableRow.querySelectorAll('.d-table-cell');
                const row = [];
                for (const tableCell of tableCells) {
                    if (!tableCell.classList.contains('no-print')) {
                        if (tableCell.getAttribute('data-print')) {
                            row.push(tableCell.getAttribute('data-print'));
                        } else {
                            row.push(tableCell.innerText);
                        }
                    }
                }
                this.fileContent += row.join(',');
                this.fileContent += '\n';
            }
        },
        downloadContent() {
            this.parseEphemeridesTable();
            UtilsService.downloadAsciiFile(this.fileContent, this.fileName);
        }
    }
}
</script>

<style>

</style>