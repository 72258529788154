<template>
    <div class="input">
        <button class="primary search-button" :class="{disabled: isBtnDisabled}" @click="mode === 'user-defined' ? compute() : search()" :disabled="isBtnDisabled">{{ mode === 'user-defined' ? $t.compute : $t.search }}</button>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service';
import SyntheticObjectsService from '@/services/synthetic-objects.service';
import ObjectsService from '@/services/objects.service'

export default {
    name: 'FlybySearch',
    props: {
        mode: String,
        isBtnDisabled: Boolean,
    },
    methods: {
        search() {
            VisualisationService.searchCloseApproaches();
        },
        compute() {
            VisualisationService.setFvtFocusedObject('mainbody');
            VisualisationService.setSelectedCloseApproach(SyntheticObjectsService.getSyntheticFvtObjectsList()[0]);
        }
    },
    computed: {
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },
        autoload() {
            const query = this.$route.query;
            return query.start && query.end && query.object && query.mainbody && this.finalAsteroidsList.length === 1 && !this.isBtnDisabled;
        },
    },
    watch: {
        async autoload(newVal) {
            if (newVal) {
                await VisualisationService.searchCloseApproaches();
                const closeApproachList = VisualisationService.getCloseApproaches();                
                if (closeApproachList.length > 0) {
                    VisualisationService.setSelectedCloseApproach(closeApproachList[0]);                                              
                }
            }
        }
    }
}
</script>

<style>

</style>