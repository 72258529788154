<template>
    <div class="ovt__settings">
        <div class="ovt__settings--section buttons">
            <SettingsButton v-for="option of buttonOptions" :key="option" :type="option" :settings="settings" @change="onChange($event)" @export="onExport($event)" />
        </div>
        <div v-for="option of sliderOptions" :key="option" class="ovt__settings--section slider">
            <SettingsSlider v-if="settings" :type="option" :settings="settings" @change="onChange($event)" />
        </div>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'
import SettingsButton from '@/components/common/visualisation/SettingsButton'
import SettingsSlider from '@/components/common/visualisation/SettingsSlider'

export default {
    name: 'VisualisationSettings',
    components: {
        SettingsButton,
        SettingsSlider,
    },
    computed: {
        settings() {
            return VisualisationService.getSettings();
        },
        buttonOptions() {
            return VisualisationService.getSettingsOptions('button');
        },
        sliderOptions() {
            return VisualisationService.getSettingsOptions('slider');
        },
    },
    methods: {
        onChange(event) {

            VisualisationService.updateSetting(event);
        },
        onExport(event) {
            console.log(event);
        }
    }
}
</script>

<style>

</style>
