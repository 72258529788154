<template>
    <div class="sovt__action">
        <button :disabled="!isParametersProvided || !isNewDataProvided || !isMagnitudeValid" class="sovt__btn primary search-button"
            :class="{ disabled: !isParametersProvided || !isNewDataProvided || !isMagnitudeValid }" @click="calculate()">
            {{ $t.calculate }}
        </button>
    </div>
</template>

<script>
export default {
    name: "SynodicOrbitCalculate",
    props: {
        isParametersProvided: Boolean,
        isNewDataProvided: Boolean,
        isMagnitudeValid: Boolean,
    },
    methods: {
        calculate() {
            if (!this.isParametersProvided || !this.isNewDataProvided || !this.isMagnitudeValid) {
                return;
            }
            this.$emit("calculate");
        },
    },
};
</script>

<style lang="scss">
@import "@/styles/sovt/calculate.scss";
</style>
