import axios from 'axios'
import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Config from '@/constants/config'
import FilteringService from '@/services/filtering.service'
import TimeFrameService from '@/services/timeframe.service'
import ObservatoryService from '@/services/observatory.service'
import PopupService from '@/services/popup.service'
import ObjectsService from '@/services/objects.service'
import SpinnerService from '@/services/spinner.service'
import Keys from '@/constants/keys'
import UtilsService from '@/services/utils.service'
import EphemeridesService from '@/services/ephemerides.service'
import MemoryService from '@/services/memory.service'

const CalculateService = {
    getCalculateUrl() {
        return Config.api.restUrl + Config.api.calculate;
    },

    getCalculationResultUrl() {
        return Config.api.restUrl + Config.api.calculationResult;
    },

    getCancelCalculationtUrl() {
        return Config.api.restUrl + Config.api.cancelCalculation;
    },

    getDataPointsLimit() {
        return Config.calculation.dataPointsLimit;
    },

    getResultsDelay() {
        return Config.calculation.getResultsDelay;
    },

    initCalculations() {
        if (CalculateService.dataPointsLimitExceeded()) {
            CalculateService.dataPointsExceededNotification();
        } else {
            CalculateService.calculate();
        }
    },
    
    calculate() {
        const configObj = {};
        const paramsObj = {
            // step: 1, // constant for filtering
            // julianDateStart: '',
            // julianDateEnd: '',
            // observatoryCode: 'C16',
            // observatoryElevation: 2000,
            // observatoryLatitude: '',
            // observatoryLongitude: '',
            // names: '',
        };

        const step = TimeFrameService.getTimestep();
        paramsObj.step = step.converted;

        const julianDates = TimeFrameService.getJulianDates();
        paramsObj.julianDateStart = julianDates['julianDateStart'];
        paramsObj.julianDateEnd = julianDates['julianDateEnd'] + 0.9999;

        const utcDates = TimeFrameService.getUtcDates();
        paramsObj.utcStart = utcDates['utcStart'];
        paramsObj.utcEnd = utcDates['utcEnd'].replace('00:00', '23:59');

        const observatory = ObservatoryService.getSelectedObservatory();
        if (observatory.code) {
            paramsObj.observatoryCode = observatory.code;
        } else {
            paramsObj.observatoryElevation = ObservatoryService.getObservatoryElevation();
            paramsObj.observatoryLatitude = observatory.latitude;
            paramsObj.observatoryLongitude = observatory.longitude*1 < 0 ? observatory.longitude*1 + 360 : observatory.longitude;
        }

        const maxObjectLimit = FilteringService.getInitialLoadSize();

        let selectedObjects = ObjectsService.getObjectsSelected();

        if (selectedObjects.length > maxObjectLimit){
            selectedObjects = selectedObjects.slice(0, maxObjectLimit);
            ObjectsService.setObjectsSelected(selectedObjects);
        }
        const names = selectedObjects.join();
        paramsObj.names = names;

        const token = FilteringService.getToken();
        if (token && token !== '') {
            configObj.headers = {
                Token: token
            }
        }

        const url = CalculateService.getCalculateUrl() + UtilsService.parseParams(paramsObj);
        SpinnerService.show();
        return axios(url, configObj)
            .then(response => {
                const estimatedTime = response.data.estimatedTime || 0;
                if (estimatedTime && estimatedTime >= 2) {
                    CalculateService.setCalculationEstimatedTime(estimatedTime);
                    CalculateService.runProgressBar();
                    SpinnerService.hide();
                } else {
                    setTimeout(() => {
                        CalculateService.getCalculationResult();
                    }, (estimatedTime * 1000) + 100);
                }
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupDialog',
                    type: 'error',
                    message,
                    actions: {
                        cancel: {
                            text: Keys.t.cancel,
                        },
                        submit: {
                            text: Keys.t.retryCalculation,
                            emits: 'retry-calculation'
                        }
                    },
                });
                SpinnerService.hide();
            })
    },

    getCalculationProgress() {
        return store.getters[CommonGetters.calculationProgress];
    },

    setCalculationProgress(value) {
        store.dispatch(CommonActions.setCalculationProgress, value);
    },

    getCalculationEstimatedTime() {
        return store.getters[CommonGetters.calculationEstimatedTime];
    },

    setCalculationEstimatedTime(value) {
        store.dispatch(CommonActions.setCalculationEstimatedTime, value);
    },

    getCalculatedObjects() {
        return store.getters[CommonGetters.calculatedObjects];
    },

    setCalculatedObjects(value) {
        store.dispatch(CommonActions.setCalculatedObjects, value);
    },

    estimateProgress() {
        const estimatedTime = Math.ceil(CalculateService.getCalculationEstimatedTime());
        const currentProgress = parseFloat(CalculateService.getCalculationProgress());
        const partialProgress = parseFloat((1 / estimatedTime).toFixed(2));
        const progress = (currentProgress + partialProgress).toFixed(2);
        CalculateService.setCalculationProgress(progress);
    },

    getCalculationResult() {
        const requiredObjectsLength = ObjectsService.getObjectsSelected().length;
        const configObj = {};
        const token = FilteringService.getToken();
        const tokenCancelled = FilteringService.getTokenCancelled();
        const isCancelled = token === tokenCancelled;

        if (token && token !== '') {
            configObj.headers = {
                Token: token
            }
        }

        const url = CalculateService.getCalculationResultUrl();
        return axios(url, configObj)
            .then(async response => {
                if (!isCancelled) {
                    const calculatedObjectLength = response.data.object.data.length;
                    CalculateService.setCalculatedObjects(calculatedObjectLength);
                        if (calculatedObjectLength !== requiredObjectsLength) {
                            CalculateService.estimateProgress();
                            setTimeout(() => {
                                CalculateService.getCalculationResult();
                            }, CalculateService.getResultsDelay());
                        } else {
                            const objects = response.data.object.data || [];
                            if (objects.length) {
                                ObjectsService.updateObjectsFilteringValues(objects, true);
                            }
                            await EphemeridesService.getLongAndShortTermPlots();
                            CalculateService.setEnableOptResults(true);
                            SpinnerService.hide();
                        
                            PopupService.show({
                                component: 'PopupInfo',
                                type: 'success',
                                message: Keys.t.calculationsPerformedSuccessfully,
                                actions: {
                                    cancel: {
                                        text: Keys.t.goToResults,
                                        emits: 'goto-results'
                                    }
                                },
                            });
                            CalculateService.setCalculationProgress(0);
                            MemoryService.saveTimestepMemory();
                        }
                    } else {
                        FilteringService.setTokenCancelled('');
                        CalculateService.setCalculationProgress(0);
                    }
                })
                .catch(error => {
                    CalculateService.setEnableOptResults(false);
                    const message = UtilsService.prepareErrorMessage(error);
                    PopupService.show({
                        component: 'PopupDialog',
                        type: 'error',
                        message,
                        actions: {
                            cancel: {
                                text: Keys.t.cancel,
                            },
                            submit: {
                                text: Keys.t.retryCalculation,
                                emits: 'retry-calculation'
                            }
                        },
                    });
                    SpinnerService.hide();
            })
    },

    cancelCalculation() {
        const configObj = {};
        const token = FilteringService.getToken();
        if (token && token !== '') {
            configObj.headers = {
                Token: token
            }
        }

        const url = CalculateService.getCancelCalculationtUrl();
        return axios(url, configObj)
            .then(() => {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'info',
                    message: Keys.t.calculationWasCanceled,
                });
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                FilteringService.setTokenCancelled(token);
                CalculateService.setEnableOptResults(false);
                SpinnerService.hide();
            })
    },

    runProgressBar() {
        PopupService.show({
            component: 'PopupProgressBar',
            wait: true,
        });
        setTimeout(() => {
            CalculateService.getCalculationResult();
        }, 100);
    },

    setEnableOptResults(value) {
        store.dispatch(CommonActions.setEnableOptResults, value);
    },

    calculateDataPoints() {
        const objects = ObjectsService.getObjectsSelected().length || ObjectsService.getFinalAsteroidsList().length;
        const timestep = (1 / TimeFrameService.getTimestep().converted).toFixed() * 1;
        const timeframe = TimeFrameService.getTimeFrame();
        const days = UtilsService.dateDifferenceInDays(new Date(timeframe.end), new Date(timeframe.start));
        return parseInt(objects * timestep * days);
    },

    calculateMaxPointsInInterval() {
        const timeframe = TimeFrameService.getTimeFrame();
        const minutesDifference = UtilsService.getMinutesDifference(new Date(timeframe.start), new Date(timeframe.end));        
        
        const timestep = TimeFrameService.getTimestep();                
        let minutesPerStep = timestep.step;
        switch (timestep.unit) {
            case 'd':
                minutesPerStep *=  (24 * 60);
                break;
            case 'h':
                minutesPerStep *=  60;
                break;
            default:
                break;          
        }
        const steps = Math.floor(minutesDifference/minutesPerStep) + 1;        
        return steps;           
    },          
    dataPointsLimitExceeded() {
        const dataPointsLimit = Config.calculation.dataPointsLimit;
        const dataPoints = CalculateService.calculateDataPoints();
        return dataPoints > dataPointsLimit;
    },

    dataPointsExceededNotification() {
        const message = `
            ${Keys.t.yourInputDataHasExceededMaximumDataPointsLimit}<br /><br />
            ${Keys.t.maximumDataPionts}: ${Config.calculation.dataPointsLimit}<br />
            ${Keys.t.currentDataPoints}: ${CalculateService.calculateDataPoints()}<br /><br />
            ${Keys.t.pleaseAdjustYourCalculationInputsBeforeProceeding}`;

        PopupService.show({
            component: 'PopupInfo',
            type: 'warning',
            message,
        });
    }
}

export default CalculateService;
