import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import UtilsService from '@/services/utils.service'
import VisualisationService from './visualisation.service'
import Config from '@/constants/config'
import moment from 'moment'

const TimeFrameService = {
    getTimeFrame() {
        return store.getters[CommonGetters.timeFrame];
    },

    getPrevDay(date) {
        return UtilsService.datePrevDay(date);
    },

    getNextDay(date) {
        return UtilsService.dateNextDay(date);
    },

    getNextStep(date) {
        return UtilsService.dateNext10Minutes(date);
    },

    getTimestep() {
        return store.getters[CommonGetters.timestep];
    },

    getSimulationTime() {
        return store.getters[CommonGetters.simulationTime];
    },

    setDate(start, end) {
        TimeFrameService.setTimeFrame(start, end);
    },

    isDatesValid() {
        const timeFrame = store.getters[CommonGetters.timeFrame];
        return UtilsService.isDateValid(timeFrame.start) && UtilsService.isDateValid(timeFrame.end);
    },

    setTimeFrame(start, end) {
        if (start > end) {
            end = TimeFrameService.getNextDay(start);
        }
        
        const timeFrame = {
            start,
            end,
            outOfShortTerm: TimeFrameService.isOutOfShorTerm(start, end),
            dateTime: {
                start: start + 'T00:00',
                end: end + 'T00:00',
            },
            converted: {
                start: UtilsService.dateToJulian(start),
                end: UtilsService.dateToJulian(end)
            }
        }

        store.dispatch(CommonActions.setTimeFrame, timeFrame);
    },

    setDateTime(start, end) {
        if (TimeFrameService.getNextStep(start) >= end) {
            end = TimeFrameService.getNextStep(start);
        }

        const timeFrame = {
            start: start.split('T')[0],
            end: end.split('T')[0],
            outOfShortTerm: TimeFrameService.isOutOfShorTerm(start, end),
            dateTime: {
                start,
                end,
            },
            converted: {
                start: UtilsService.dateToJulian(start),
                end: UtilsService.dateToJulian(end)
            }
        }

        store.dispatch(CommonActions.setTimeFrame, timeFrame);
    },

    setTimeFrameConverted(timeFrame) {
        store.dispatch(CommonActions.setTimeFrame, {...timeFrame});
    },

    setCurrentDate() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        TimeFrameService.setTimeFrame(
            UtilsService.dateToStringTechnical(new Date()),
            UtilsService.dateToStringTechnical(tomorrow)
        );
    },

    isOutOfShorTerm(start, end) {
        const diffThreshhold = Config.timeFrame.shortTermThreshold;
        const diffStartDays = UtilsService.dateDifferenceInDays(new Date(start), new Date());
        const diffEndDays = UtilsService.dateDifferenceInDays(new Date(end), new Date());

        if (diffStartDays < 0 || diffEndDays+1 > diffThreshhold) {
            return true;
        }
        return false;
    },

    convertTimestep(step, unit) {
        let convertedValue = step;
        switch (unit) {
            case 'm':
                convertedValue = (step / (24 * 60)).toFixed(5);
                break;
            case 'h':
                convertedValue = (step / 24).toFixed(5);
                break;
            default:
                break;
        }
        return convertedValue;
    },

    setDefaultTimestep() {
        const step = Config.timeFrame.step;
        const unit = Config.timeFrame.unit;
        TimeFrameService.setTimestep(step, unit);
    },

    setTimestep(step, unit) {
        const timestep = {
            step,
            unit,
            converted: TimeFrameService.convertTimestep(step, unit),
        }
        store.dispatch(CommonActions.setTimestep, timestep);
    },

    getJulianDates() {
        const timeframe = TimeFrameService.getTimeFrame();
        const julianDateStart = parseFloat(timeframe.converted.start);
        const julianDateEnd = parseFloat(timeframe.converted.end);

        return {
            julianDateStart,
            julianDateEnd
        }
    },

    getUtcDates() {
        const timeframe = TimeFrameService.getTimeFrame();
        const utcStart = timeframe.dateTime.start;
        const utcEnd = timeframe.dateTime.end;

        return {
            utcStart: utcStart + 'Z',
            utcEnd: utcEnd + 'Z'
        }
    },

    getTimezone() {
        return store.getters[CommonGetters.timezone];
    },

    setTimezone(timezone) {
        store.dispatch(CommonActions.setTimezone, timezone);
    },

    getTimezoneShift(date = null) {
        const timezone = TimeFrameService.getTimezone();
        let timezoneHours = parseInt(timezone.replace('GMT ', ''));
        if (date && moment(new Date(date)).isDST() && (timezoneHours === 0 || timezoneHours === 1)) {
            timezoneHours += 1;
        }
        return timezoneHours;
    },

    setFlybyTimeframe(from, to) {
        const prevTimeFrame = TimeFrameService.getFlybyTimeframe();
        if (prevTimeFrame) {
            const {start: prevStartDate, end: prevEndDate} = prevTimeFrame;
            const currStartDate = UtilsService.dateToJulian(from);
            const currEndDate = UtilsService.dateToJulian(to);
            if (prevStartDate !== currStartDate || prevEndDate !== currEndDate) {
                VisualisationService.setIsNoApproachFoundMsgVisible(false);
            }
        }
        const flybyTimeframe = {
            start: UtilsService.dateToJulian(from),
            end: UtilsService.dateToJulian(to)
        }
        store.dispatch(CommonActions.setFlybyTimeframe, flybyTimeframe);
    },

    getFlybyTimeframe() {
        return store.getters[CommonGetters.flybyTimeframe];
    },

    getRestoreFlybyTime() {
        return store.getters[CommonGetters.restoreFlybyTime];
    },

    setRestoreFlybyTime(flybyTime) {
        store.dispatch(CommonActions.setRestoreFlybyTime, flybyTime);
    }
}

export default TimeFrameService;