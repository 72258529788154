<template>
    <div class="opt-filtering">
        <div class="objects">
            <ObjectsHeader />
            <ObjectsTable />
        </div>
    </div>
</template>

<script>
import ObjectsHeader from '@/components/opt/filtering/ObjectsHeader'
import ObjectsTable from '@/components/opt/filtering/ObjectsTable'

export default {
    name: 'ComponentsFiltering',
    components: {
        ObjectsHeader,
        ObjectsTable
    }
}
</script>

<style lang="scss">
@import "@/styles/opt/filtering.scss";
</style>