import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'

const PopupService = {
    getPopupShow() {
        return store.getters[CommonGetters.popupShow];
    },
    getPopupConfig() {
        return store.getters[CommonGetters.popupConfig];
    },
    setPopupShow(show, config = {}) {
        store.dispatch(CommonActions.setPopupShow, {show, config});
    },
    show(config) {
        if (config && config.wait) {
            setTimeout(() => {
                PopupService.setPopupShow(true, config);
            }, 100);
        } else {
            PopupService.setPopupShow(true, config);
        }
    },
    hide() {
        PopupService.setPopupShow(false);
    },
}

export default PopupService;