<template>
    <div class="popup-progress-bar">
        <div class="popup-header">
            {{ $t.calculationInProgress }}
        </div>

        <div class="popup-content">

            <div class="progress-bar__bar">
                <div class="progress-bar__fill" :class="{run: calculatedObjects}" :style="`width: ${(calculatedObjects / selectedObjects) * 100}%`"/>
            </div>

            <div class="progress-bar__info">
                <span v-if="calculatedObjects < 1">{{ $t.objectsQueue }}</span>
                <template v-else>
                    <span>{{ calculatedObjects }} / {{ selectedObjects }} {{ $t.processedObjects }},&nbsp;</span>
                    <span v-if="calculateRemainingTime() > 0">{{ $t.estimatedTimeToFinish }} {{ calculateRemainingTime() }} {{ calculateRemainingTime() > 1 ? $t.seconds : $t.second }}</span>
                    <span v-else>{{ $t.itTakesLongerThanExpected }}...</span>
                </template>
            </div>

            <div class="popup-footer">
                <button class="cancel" @click="stop()">{{ $t.stop }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import PopupService from '@/services/popup.service'
import CalculateService from '@/services/calculate.service'
import ObjectsService from '@/services/objects.service'

export default {
    name: 'PopupProgressBar',
    props: {
        config: {}
    },
    computed: {
        estimatedTime() {
            return CalculateService.getCalculationEstimatedTime();
        },
        progress() {
            return CalculateService.getCalculationProgress();
        },
        selectedObjects() {
            return ObjectsService.getObjectsSelected().length;
        }, 
        calculatedObjects() {
            return CalculateService.getCalculatedObjects();
        },
    },
    methods: {
        calculateRemainingTime() {
            return Math.floor(this.estimatedTime - (this.progress * this.estimatedTime));
        },
        stop() {
            PopupService.show({
                component: 'PopupDialog',
                type: 'error',
                message: this.$t.doYouWantToStopCalculation,
                actions: {
                    close: {
                        emits: 'resume-calculation'
                    },
                    cancel: {
                        text: this.$t.no,
                        emits: 'resume-calculation'
                    },
                    submit: {
                        text: this.$t.yes,
                        emits: 'cancel-calculation'
                    }
                },
                wait: true
            });
        }
    },
}
</script>

<style>

</style>