<template>
    <span>{{ $t.ephemeridesConfiguration }}:</span>
    <button class="link" @click="setDefault()">{{ $t.default }}</button>
    <button class="link" @click="setAll()">{{ $t.all }}</button>
    <button class="link" @click="setUserDefined()">{{ $t.userDefined }}</button>
</template>

<script>
import EphemeridesService from '@/services/ephemerides.service'
import PopupService from '@/services/popup.service'

export default {
    name: 'EphemeridesConfig',
    methods: {
        setDefault() {
            EphemeridesService.setEphemeridesDefaultColumns();
        },
        setAll() {
            EphemeridesService.setEphemeridesAllColumns();
        },
        setUserDefined() {
            PopupService.show({
                component: 'EphemeridesConfigPopup',
            });
        }
    }
}
</script>

<style>

</style>