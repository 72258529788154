<template>
    <div class="longterm-plots__slider" :class="{[`color-${plot}`]: true, disabled: !object.plots[plot].enable}">
        <span class="longterm-plots__slider--label">{{ rounding(slider.min) }}</span>
        <div class="longterm-plots__slider--content">
            <div class="longterm-plots__slider--title" v-html="displayTitle()"/>
            <Slider v-if="slider.min !== slider.max" :id="`slider-for-${plot}`" :tooltips="false" :step="step()" v-model="slider.value" :min="slider.min" :max="slider.max" @update="$emit('update', $event)" />
            <div v-else :id="`slider-for-${plot}`" class="slider-target slider-ltr slider-horizontal slider-txt-dir-ltr slider-no-range">
                <div class="slider-base">
                    <div class="slider-connects">
                        <span class="slider-constant">{{ $t.constant }}</span>
                    </div>
                </div>
            </div>
            <div class="longterm-plots__slider--title">&nbsp;</div>
        </div>
        <span class="longterm-plots__slider--label">{{ rounding(slider.max) }}</span>
    </div>
</template>

<script>
import LongtermService from '@/services/longterm.service'
import Slider from '@vueform/slider'

export default {
    name: 'ResultsLongTermSlider',
    components: {
        Slider
    },
    props: {
        plot: String,
        object: Object
    },
    data() {
        return {
            slider: {
                min: this.object.plots[this.plot].min * 10,
                max: this.object.plots[this.plot].max * 10,
                value: this.object.plots[this.plot].value * 10,
            }
        }
    },
    computed: {
        longtermDefaultPlots() {
            return LongtermService.getDefaultLongtermPlots();
        },
    },
    watch: {
        object: {
            deep: true,
            handler() {
                this.slider.value = this.object.plots[this.plot].value * 10;
            }
        },
    },
    methods: {
        displayTitle() {
            const title = this.$keys.titles[this.plot];
            const unit = this.$t[this.longtermDefaultPlots[this.plot]];
            const operatorTxt = LongtermService.getPlotsOperators()[this.plot];
            const value = this.rounding(this.slider.value);

            let operator = '=';
            if (operatorTxt) {
                if (operatorTxt === 'gt') {
                    operator = '≥';
                } else {
                    operator = '≤';
                }
            }

            return `${title} ${operator} ${value} <span class="longterm-plots__slider--units">${unit}</span>`;
        },
        step() {
            if (this.plot === 'SKY_MOV') {
                return 0.001;
            } else {
                return 1;
            }
        },
        rounding(value) {
            if (this.plot === 'SKY_MOV') {
                return (value / 10).toFixed(3);
            } else {
                return (value / 10).toFixed(1);
            }
        }
    },
}
</script>

<style>

</style>