import store from '@/store'
import { toRaw } from 'vue'
import axios from 'axios'
import Config from '@/constants/config'
import PopupService from '@/services/popup.service'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import ObservatoryService from '@/services/observatory.service'
import VisualisationService from '@/services/visualisation.service'
import TimeFrameService from '@/services/timeframe.service'
import ObjectsService from '@/services/objects.service'
import Keys from '@/constants/keys'
import { VueCookieNext } from 'vue-cookie-next'
import SpinnerService from '@/services/spinner.service'
import UtilsService from './utils.service'
import router from '@/router'
import SynodicCalculationService from "@/services/synodic-calculation.service";

const MemoryService = {
    getLoadToolStateUrl() {
        return Config.api.restUrl + Config.api.loadToolStateUrl;
    },

    getSaveToolStateUrl() {
        return Config.api.restUrl + Config.api.saveToolStateUrl;
    },

    getSetupMemory() {
        return store.getters[CommonGetters.setupMemory];
    },

    getToolState() {
        return store.getters[CommonGetters.toolState];
    },

    encrypt(string) {
        return btoa(string);
    },

    decrypt(string) {
        return atob(string);
    },

    getCurrentSetup() {
        const selectedObservatory = ObservatoryService.getSelectedObservatory();
        const selectedTimeFrame = TimeFrameService.getTimeFrame();
        const userDefinedObjects = ObjectsService.getUserDefinedObjects();
        const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
        return {
            selectedObservatory,
            selectedTimeFrame,
            userDefinedObjects,
            finalAsteroidsList,
        }
    },

    saveSetupMemory() {
        const selectedObservatory = {...ObservatoryService.getSelectedObservatory()};
        const selectedTimeFrame = {...TimeFrameService.getTimeFrame()};
        const userDefinedObjects = ObjectsService.getUserDefinedObjects();
        const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
        const memory = {
            selectedObservatory,
            selectedTimeFrame,
            userDefinedObjects,
            finalAsteroidsList,
        }
        MemoryService.setSetupMemory(memory);
    },

    restoreSetupMemory() {
        const memory = MemoryService.getSetupMemory();
        const selectedObservatory = {...memory.selectedObservatory};
        const selectedTimeFrame = {...memory.selectedTimeFrame};
        const userDefinedObjects = memory.userDefinedObjects;
        const finalAsteroidsList = memory.finalAsteroidsList;

        ObservatoryService.setSelectedObservatory(selectedObservatory);
        TimeFrameService.setTimeFrameConverted(selectedTimeFrame);
        ObjectsService.setUserDefinedObjects(userDefinedObjects);
        ObjectsService.setFinalAsteroidsList(finalAsteroidsList);
        MemoryService.saveSetupMemory();
    },

    setSetupMemory(value) {
        store.dispatch(CommonActions.setSetupMemory, value);
    },

    stateFromCookiesAvailable() {
        return VueCookieNext.getCookie('SAVED_TOOL_STATE');
    },

    loadToolState(toolStateId = null, pathname = null) {
        let decryptedStateId = '';
        if (toolStateId && toolStateId !== '') {
            if (isNaN(toolStateId)) {
                try {
                    decryptedStateId = MemoryService.decrypt(toolStateId);
                } catch {
                    decryptedStateId = '';
                }
            } else {
                decryptedStateId = toolStateId;
            }
        } else if (VueCookieNext.getCookie('SAVED_TOOL_STATE')) {
            decryptedStateId = MemoryService.decrypt(VueCookieNext.getCookie('SAVED_TOOL_STATE'));
        }

        if (decryptedStateId === '') {
            PopupService.show({
                component: 'PopupInfo',
                type: 'error',
                message: Keys.t.invalidToolStateId,
                wait: true,
            });
            return;
        }

        const url = MemoryService.getLoadToolStateUrl() + '?toolStateId=' + decryptedStateId;
        SpinnerService.show();
        return axios['get'](url)
            .then(response => {
                if (response.data !== '') {
                    const loadedState = JSON.parse(response.data.toolState);
            
                    if (pathname) {
                        router.push({
                            path: pathname
                        })
                    } else {
                        router.push({
                            path: '/'+loadedState.tool
                        })
                    }

                    if (loadedState.tool === 'ovt' || loadedState.tool === 'fvt') {

                        VisualisationService.setPlaybackSpeed(loadedState.speed);
                        VisualisationService.setPlaybackSpeedFVT(loadedState.speedFVT);
                        VisualisationService.setStartDate(loadedState.startDate);
                        VisualisationService.setCurrentDate(loadedState.activeCurrentDate);
                        VisualisationService.setEndDate(loadedState.endDate);
                        TimeFrameService.setFlybyTimeframe(loadedState.dateFrom, loadedState.dateTo);
                        VisualisationService.setSelectedObjectName(loadedState.selectedObjectName);

                    }

                    const isSovtTool = loadedState.tool === 'sovt';
                    let isSovtTimeframeChange = false;
                    if (isSovtTool) {
                        const {timeFrame: {start: startUpdated, end: endUpdated}} = loadedState;
                        const { start, end } = TimeFrameService.getTimeFrame();
                        isSovtTimeframeChange = start !== startUpdated || end !== endUpdated;
                    }

                    store.dispatch(CommonActions.setToolState, loadedState);

                    setTimeout(() => {

                        if (loadedState.tool === 'fvt') {
                            VisualisationService.setZoom(loadedState.activeZoomFVT);
                            if (loadedState.selectedCloseApproach && !loadedState.orbitActiveObject) {
                                //VisualisationService.setSelectedCloseApproach(loadedState.selectedCloseApproach);
                            }
                        }

                        if (loadedState.tool === 'ovt') {
                            VisualisationService.setZoom(loadedState.activeZoomOVT);
                            if (loadedState.finalAsteroidsList.length) {
                                for (const item of loadedState.finalAsteroidsList) {
                                    const designator = item.number ? item.number : item.name;
                                    if (VisualisationService.getIsChecked().indexOf(designator+'') > -1) {
                                        VisualisationService.checkObject(designator+'', item.perturbedOn, true);
                                    }
                                }
                            }
                        }

                        if (isSovtTool) {
                            const {sovtFocusedObject} = loadedState;
                            !isSovtTimeframeChange && SynodicCalculationService.adjustSovtToolState(sovtFocusedObject);
                            if (isSovtTimeframeChange) {
                                const isFocusedObject = sovtFocusedObject && sovtFocusedObject !== "sun";
                                isFocusedObject && VisualisationService.setSovtFocusedObject(sovtFocusedObject);
                            }
                        }
                    }, 500);
                    
                    VisualisationService.setOrientationFocus();
                    

                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'success',
                        message: Keys.t.dataLoadedSuccessfully,
                    });
                } else {
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message: Keys.t.invalidToolStateId,
                    });
                }
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: message + '<br /><br />' + Keys.t.invalidToolStateId,
                });
            })
            .finally(() => {
                SpinnerService.hide();
            });
    },
    
    saveStateOnSwitch(stateObj){
         
        if (stateObj.tool === 'ovt'){
            const vt = window['vt'];
            const camera = vt.getViewer().camera;

            stateObj.OVTcamera = new Object;
            stateObj.OVTcamera.pos = camera.position;
            stateObj.OVTcamera.rot = camera.rotation;
            stateObj.OVTcamera.quat = camera.quaternion;
            stateObj.activeZoomOVT = VisualisationService.getZoom();

            stateObj.speed = VisualisationService.getPlaybackSpeed();
            stateObj.dateFrom = TimeFrameService.getTimeFrame().start;
            stateObj.dateTo = TimeFrameService.getTimeFrame().end;
            stateObj.focusOVT = VisualisationService.getOrbitViewerFocusedOnObject();

            store.dispatch(CommonActions.setToolState, stateObj);
        } else if (stateObj.tool === 'fvt'){
            const vt = window['vt'];
            const camera = vt.getViewer().camera;

            stateObj.FVTcamera = new Object;
            stateObj.FVTcamera.pos = camera.position;
            stateObj.FVTcamera.rot = camera.rotation;
            stateObj.FVTcamera.quat = camera.quaternion;
            stateObj.focusFVT = VisualisationService.getOrbitViewerFocusedOnObject();

            stateObj.activeZoomFVT = VisualisationService.getZoom();
            stateObj.speedFVT = VisualisationService.getPlaybackSpeedFVT();
            stateObj.dateFrom = TimeFrameService.getFlybyTimeframe().start;
            stateObj.dateTo = TimeFrameService.getFlybyTimeframe().end;

            store.dispatch(CommonActions.setToolState, stateObj);
        } else if (stateObj.tool === 'scd'){
            const vt = window['scd'];
            const camera = vt.camera;
            stateObj.SCDcamera = new Object;
            stateObj.SCDcamera.pos = camera.position;
            stateObj.SCDcamera.rot = camera.rotation;
            stateObj.SCDcamera.quat = camera.quaternion;
            stateObj.activeZoomSCD = VisualisationService.getZoom();
            store.dispatch(CommonActions.setToolState, stateObj);
        }

        if (stateObj.tool === 'ovt' || stateObj.tool === 'fvt') {
            stateObj.activeCurrentDate = VisualisationService.getCurrentDate();
            stateObj.startDate = VisualisationService.getStartDate();
            stateObj.endDate = VisualisationService.getEndDate();
        }
        
    },

    saveToolState(share = false) {
        const stateObj = {...MemoryService.getToolState()};
        stateObj.orbitActiveObject = null;
        stateObj.synodicActiveObject = null;

        if (stateObj.tool === 'ovt' || stateObj.tool === 'fvt') {
            const vt = window['vt'];
            const camera = vt.getViewer().camera;

            const activeObject = VisualisationService.getOrbitActiveObject();

            if (activeObject && activeObject.distance.date)
                stateObj.activeCurrentDate = activeObject.distance.date;
            else
                stateObj.activeCurrentDate = VisualisationService.getCurrentDate();

            stateObj.startDate = VisualisationService.getStartDate();
            stateObj.endDate = VisualisationService.getEndDate();

            if (stateObj.tool === 'fvt'){
                stateObj.speedFVT = VisualisationService.getPlaybackSpeedFVT();
                stateObj.dateFrom = TimeFrameService.getFlybyTimeframe().start;
                stateObj.dateTo = TimeFrameService.getFlybyTimeframe().end;
                stateObj.focusFVT = VisualisationService.getOrbitViewerFocusedOnObject();
                stateObj.activeZoomFVT = VisualisationService.getZoom();

                stateObj.FVTcamera = new Object;
                stateObj.FVTcamera.pos = camera.position;
                stateObj.FVTcamera.rot = camera.rotation;
                stateObj.FVTcamera.quat = camera.quaternion;

            } else if (stateObj.tool === 'ovt'){
                stateObj.speed = VisualisationService.getPlaybackSpeed();
                stateObj.dateFrom = TimeFrameService.getTimeFrame().start;
                stateObj.dateTo = TimeFrameService.getTimeFrame().end;
                stateObj.focusOVT = VisualisationService.getOrbitViewerFocusedOnObject();
                stateObj.activeZoomOVT = VisualisationService.getZoom();

                stateObj.OVTcamera = new Object;
                stateObj.OVTcamera.pos = camera.position;
                stateObj.OVTcamera.rot = camera.rotation;
                stateObj.OVTcamera.quat = camera.quaternion;

            }
        }

        const state = JSON.stringify(toRaw(stateObj));
        const url = MemoryService.getSaveToolStateUrl();
        const data = {
            toolState: state
        }

        SpinnerService.show();
        return axios['post'](url, data)
            .then(response => {
                const encryptedToolStateId = MemoryService.encrypt(response.data.toolStateId+'');
                const shareLink = window.location.origin + window.location.pathname + '?c=' + encryptedToolStateId;
                let actions = {};

                if (!share) {
                    VueCookieNext.setCookie('SAVED_TOOL_STATE', encryptedToolStateId);
                } else {
                    actions = {
                        option: {
                            text: Keys.t.copyLinkToClipboard,
                            emits: 'copy-share-link',
                        }
                    }
                }

                PopupService.show({
                    component: 'PopupInfo',
                    type: 'success',
                    message: MemoryService.generateSaveMessage(share, shareLink),
                    actions,
                });
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                SpinnerService.hide();
            });
    },

    shareToolState() {
        MemoryService.saveToolState(true);
    },

    generateSaveMessage(share, shareLink) {
        if (share) {
            return `
                ${Keys.t.yourDataHasBeenSuccessfullySaved}<br /><br />
                ${Keys.t.linkToShare}: <a id="share-link" href="${shareLink}" title="${shareLink}">${shareLink}</a><br /><br />
                ${Keys.t.pleaseNoteThatYourData}`;
        } else {
            return `
                ${Keys.t.yourDataHasBeenSuccessfullySaved}<br />
                ${Keys.t.nowYouCanUseItToRestore}<br /><br />
                ${Keys.t.pleaseNoteThatYourData}`;
        }
    },

    getTimestepMemory() {
        return store.getters[CommonGetters.timestepMemory];
    },

    setTimestepMemory(object) {
        store.dispatch(CommonActions.setTimestepMemory, object);
    },

    saveTimestepMemory() {
        const timestep = TimeFrameService.getTimestep();
        MemoryService.setTimestepMemory(timestep);
    },

    copyLinkToClipboard(link) {
        UtilsService.copyToClipboard(link);
        PopupService.show({
            component: 'PopupInfo',
            type: 'success',
            message: Keys.t.linkWasSuccessfullyCopiedToClipboard,
            wait: true,
        });
    },

    getCookieAgreement() {
        return VueCookieNext.getCookie('COOKIE_AGREEMENT');
    },

    setCookieAgreement() {
        VueCookieNext.setCookie('COOKIE_AGREEMENT', true);
    },

    setSessionStorageEntry(key, value) {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch(error) {
            console.warn(error);
        }
    },

    getSessionStorageEntry(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }
}

export default MemoryService;