<template>
    <!--<OLD>-->
   <!--<input v-if="tool === 'scd' && timeFrame.dateTime" type="datetime-local" step="600" v-model="timeFrame.dateTime[type]" @input="setDateTime()" />-->
   <!--<input v-else type="date" v-model="timeFrame[type]" @change="setDate()" :min="getMinValue()" />-->
   <!--<OLD>-->
   
   <VueDatePicker v-if="tool === 'scd'  && timeFrame.dateTime" v-model="timeFrame.dateTime[type]" @closed="setDateTime()" format="dd/MM/yyyy HH:mm" :clearable="false" :year-range="[1900,2470]" auto-apply text-input  @blur="setDateTime" />
   <VueDatePicker v-else v-model="timeFrame[type]" @closed="setDate()" :min-date="getMinValue()" :enable-time-picker="false" format="dd/MM/yyyy" :clearable="false" :year-range="[1900,2470]" auto-apply  text-input @blur="setDate" />
   
</template>

<script> 
import TimeFrameService from '@/services/timeframe.service'

import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment';

export default {
   name: 'DateInput',
   props: {
       type: String,
       tool: String,
   },
   components: { VueDatePicker },
   data() {
       return {
           timeFrame: {},
       }
   },
   computed: {
       selectedTimeFrame() {
           return TimeFrameService.getTimeFrame();
       }
   },
   watch: {
       selectedTimeFrame: {
           deep: true,
           handler(newVal) {
               this.timeFrame.start = newVal.start;
               this.timeFrame.end = newVal.end;
               this.timeFrame.outOfShortTerm = !!newVal.outOfShortTerm;
               this.timeFrame.dateTime.start = newVal.dateTime.start ? newVal.dateTime.start : newVal.start + 'T00:00';
               this.timeFrame.dateTime.end = newVal.dateTime.end ? newVal.dateTime.end : newVal.end + 'T00:00';
           }
       }
   },
   methods: {
       setDate() {
           if(this.isDate(this.timeFrame.start)){
               this.timeFrame.start = moment(this.timeFrame.start ).format('YYYY-MM-DD');
               this.timeFrame.end = moment(new Date(this.timeFrame.end)).format('YYYY-MM-DD');
           }
           else{
               this.timeFrame.end = moment(this.timeFrame.end ).format('YYYY-MM-DD');
               this.timeFrame.start = moment(new Date(this.timeFrame.start)).format('YYYY-MM-DD');
           }
           TimeFrameService.setDate(this.timeFrame.start, this.timeFrame.end);
       },
       setDateTime() {
           if(this.isDate(this.timeFrame.dateTime.start)){
               this.timeFrame.dateTime.start = moment(this.timeFrame.dateTime.start ).format('YYYY-MM-DDTHH:mm');
               this.timeFrame.dateTime.end = moment(new Date(this.timeFrame.dateTime.end)).format('YYYY-MM-DDTHH:mm');
           }
           else{
               this.timeFrame.dateTime.end = moment(this.timeFrame.dateTime.end ).format('YYYY-MM-DDTHH:mm');
               this.timeFrame.dateTime.start = moment(new Date(this.timeFrame.dateTime.start)).format('YYYY-MM-DDTHH:mm');
           }
           TimeFrameService.setDateTime(this.timeFrame.dateTime.start, this.timeFrame.dateTime.end);
       },
       getMinValue() {
           if (this.type === 'end') {
               return TimeFrameService.getNextDay(this.timeFrame.start);        
           } else {
               return "01/01/1900";
           }
       },
       isDate(variable){
           return variable instanceof Date && !isNaN(variable);
       }
   },
   mounted() {
       this.timeFrame = this.selectedTimeFrame;
       if (!this.timeFrame.start && !this.timeFrame.end) {
           TimeFrameService.setCurrentDate();
       }
       else{
           if (this.tool === 'opt') {
               if(this.isDate(this.timeFrame.start)){
                   this.timeFrame.start = moment.utc(this.timeFrame.start ).format('YYYY-MM-DD');
               }
               else{
                   this.timeFrame.start = moment.utc(new Date(this.timeFrame.start)).format('YYYY-MM-DD');
               }
               if(this.isDate(this.timeFrame.end)){
                   this.timeFrame.end = moment.utc(this.timeFrame.end ).format('YYYY-MM-DD');
               }
               else{
                   this.timeFrame.end = moment.utc(new Date(this.timeFrame.end)).format('YYYY-MM-DD');
               }
           }
           else{
               if(this.isDate(this.timeFrame.dateTime.start)){
                   this.timeFrame.dateTime.start = moment(this.timeFrame.dateTime.start ).format('YYYY-MM-DDTHH:mm');
               }
               else{
                   //this.timeFrame.dateTime.start = this.timeFrame.dateTime.start.replace(/T00:00/, "");
                   this.timeFrame.dateTime.start = moment(new Date(this.timeFrame.dateTime.start)).format('YYYY-MM-DDTHH:mm');
               }
               if(this.isDate(this.timeFrame.dateTime.end)){
                   this.timeFrame.dateTime.end = moment(this.timeFrame.dateTime.end ).format('YYYY-MM-DDTHH:mm');
               }
               else{
                   //this.timeFrame.dateTime.end = this.timeFrame.dateTime.end.replace(/T00:00/, "");
                   this.timeFrame.dateTime.end = moment(new Date(this.timeFrame.dateTime.end)).format('YYYY-MM-DDTHH:mm');
               }
           }
       }
       if (this.tool === 'opt' && this.timeFrame.start) {
           TimeFrameService.setTimeFrame(this.timeFrame.start, this.timeFrame.end);
       }
   }
}
</script>

<style>

</style>