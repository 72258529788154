<template>
    <div
        v-if="
            objectDetails && selectedCalculatedObject && activeObject && !onAir
        "
        class="ovt__legend details"
        :class="{ 'with-config': configPanelOpen }"
    >
        <div class="ovt__legend--label">{{ $t.objectDetails }}</div>
        <div class="d-table">
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.designator }}:</div>
                <div class="d-table-cell">{{ activeObject._options.name }}</div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.diameter }}:</div>
                <div class="d-table-cell">
                    {{ activeObject._options.diam ? activeObject._options.diam.toFixed(1) : "-" }} <template v-if="activeObject._options.diam">m</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.absoluteMagnitude }}:</div>
                <div class="d-table-cell">
                    {{ activeObject._options.mag ? activeObject._options.mag.toFixed(1) : "-" }} <template v-if="activeObject._options.mag">{{ $t.mag }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.slope }}:</div>
                <div class="d-table-cell">
                    {{
                        slopeValue
                    }}
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.orbitPeriod }}:</div>
                <div class="d-table-cell">
                    {{
                        orbitPeriod !== null ? orbitPeriod.toFixed(3) : '-'
                    }} <template v-if="orbitPeriod !== null">{{ $t.years }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.synodicOrbitPeriod }}:</div>
                <div class="d-table-cell">
                    {{
                        synodicOrbitPeriod !== null ? synodicOrbitPeriod.toFixed(3) : '-'
                    }} <template v-if="synodicOrbitPeriod !== null">{{ $t.years }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.systemTimeMJD }}:</div>
                <div class="d-table-cell">
                    {{ simulationTimeInfo.jd }}
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.systemTime }}:</div>
                <div class="d-table-cell">
                    {{ simulationTimeInfo.date }}
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.distanceToSun }}:</div>
                <div class="d-table-cell">
                    {{ objectDistances.center }} <template v-if="objectDistances.center !== '-'">{{ $t.au }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.distanceToMainBodyCentre.replace('{mainBody}', closestMainBody) }}:</div>
                <div class="d-table-cell">
                    {{ objectDistances.earth }} <template v-if="objectDistances.earth !== '-'">{{ $t.au }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.solarElongation }}:</div>
                <div class="d-table-cell">
                    {{ 
                        elongationAngle !== null && phaseAngle !== null ? elongationAngle.toFixed(1) : '-' 
                    }} <template v-if="elongationAngle !== null && phaseAngle !== null">{{ $t.degrees }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.phaseAngle }}:</div>
                <div class="d-table-cell">
                    {{ 
                        phaseAngle !== null ? phaseAngle.toFixed(1) : '-' 
                    }} <template v-if="phaseAngle !== null">{{ $t.degrees }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.visualMagnitude }}:</div>
                <div class="d-table-cell">
                    {{ 
                        visualMagnitude !== null ? visualMagnitude.toFixed(1) : '-' 
                    }} <template v-if="visualMagnitude !== null">{{ $t.mag }}</template>
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.orbitPropagation }}:</div>
                <div class="d-table-cell">{{ activeOrbitPropagation }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitObjectDetails",
    data() {
        return {
            closestMainBody: 'Earth'
        }
    },
    computed: {
        selectedCalculatedObject() {
            return SynodicCalculationService.getSelectedCalculatedObject();
        },

        activeObject() {
            return SynodicCalculationService.getSynodicActiveObject();
        },

        simulationTimeInfo() {
            if (!this.activeObject || !this.selectedCalculatedObject) {
                return { jd: "-", date: "-" };
            }
            return {
                jd: parseInt(
                    UtilsService.dateToJulian(this.simulationTime) - 2400000.5
                ),
                date: moment(
                    UtilsService.dateToStringTechnical(this.simulationTime)
                ).format("DD-MM-YYYY"),
            };
        },

        objectDistances() {
            if (!this.activeObject || !this.selectedCalculatedObject || this.phaseAngle === null) {
                return { earth: "-", center: "-" };
            }
            return SynodicCalculationService.calculateObjectDistances(
                this.activeObject
            );
        },

        settings() {
            return VisualisationService.getSettings();
        },

        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },

        objectDetails() {
            return (
                this.settings &&
                this.settings.objectsSettings.objectDetails.value
            );
        },

        simulationTime() {
            return VisualisationService.getSimulationTime();
        },

        activeOrbitPropagation() {
            return this.isPerturbedOrbit ? this.$t.accurate : this.$t.keplerian;
        },

        isPerturbedOrbit() {
            return this.activeObject._id.endsWith(
                SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX_PERTURBED
            );
        },

        slopeValue() {
            if (!this.activeObject || !this.selectedCalculatedObject) {
                return '-';
            }
            return this.selectedCalculatedObject.objectData.g.toFixed(2);
        },

        orbitPeriod() {
            return SynodicCalculationService.calculateOrbitPeriod();
        },

        synodicOrbitPeriod() {
            return SynodicCalculationService.calculateSynodicOrbitPeriod();
        },

        elongationAngle() {
            return SynodicCalculationService.calculateElongationAngle();
        },

        phaseAngle() {
            return SynodicCalculationService.getCurrentChartValue('phaseAngle');
        },

        visualMagnitude() {
            return SynodicCalculationService.getCurrentChartValue('apparentMagOriginalValues');
        },

        orbitLegend() {
            return (
                this.settings && this.settings.objectsSettings.orbitLegend.value
            );
        },

        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        },
    },
};
</script>

<style></style>
