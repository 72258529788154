<template>        
    <div v-for="option of displaySettingsOptions" :key="option" class="field">
        <template v-if="typeOf(option) == 'checkbox'">            
            <input class="toggle-switch" :id="option" type="checkbox" :checked="isChecked(option)" @change="onChange(option, 'value', $event.target.checked)" />
            <label class="toggle-switch__button" :for="option"></label>                                            
            <span class="label">
                <span @click="onChange(option, 'value', !isChecked(option))">{{ $t[option] }}</span>                                                      
                <div v-if= "isChecked(option) && hasColor(option)" class="color-input-wrapper">
                    <input class="color" type="color" :id="option + '_color'" :name="option + '_color'" :value="color(option)" @input="onChange(option, 'color', $event.target.value)">
                </div>                 
            </span>         
        </template>          
        <template v-if="typeOf(option) == 'number'">   
            <div v-if="show(option)">                     
                <div v-if= "hasLabel(option)">
                    <label :for="option">{{ $t[option] }}:</label>
                </div>  
                <div class="input-wrapper"> 
                    <input type="number" :value="value(option)" :id="option" :name="option" @change="onChange(option, 'value', $event.target.value)" :min="min(option)" :max="max(option)"/>                     
                </div>            
            </div>            
        </template> 
        <template v-if="typeOf(option) == 'slider'">  
            <div v-if="show(option)">                               
                <PanelSlider :option="option" :settings="this.settings[this.type][option]" :onChange="onChange" :min="min(option)" :max="max(option)" />                        
            </div>                
        </template>          
        <template v-if="typeOf(option) == 'radio'">
            <div class="radio-btns">
                <div v-if= "hasLabel(option)" class="field">
                    <span class="label">
                        {{ $t[option] }}:                                                        
                        <div v-if= "hasColor(option)" class="color-input-wrapper">
                            <input class="color" type="color" :id="option + '_color'" :name="option + '_color'" :value="color(option)" @input="onChange(option, 'color', $event.target.value)">
                        </div>                 
                    </span>                
                </div>                             
                <div v-for="value of values(option)" :key="value" class="field">  
                    <div>                    
                        <input type="radio" :id="value" :value="value"  :name="option" :checked="isSelected(option, value)" @change="onChange(option, 'value', $event.target.value)">
                        <label :for="value">{{ $t[value] }}</label>     
                    </div>                
                </div>    
             </div>                                                              
        </template>   
        <template v-if="typeOf(option) == 'label'">                                
                <div class="field">
                    <span class="label">
                        {{ $t[option] }}:                                                        
                        <div v-if= "hasColor(option)" class="color-input-wrapper">
                            <input class="color" type="color" :id="option + '_color'" :name="option + '_color'" :value="color(option)" @input="onChange(option, 'color', $event.target.value)">
                        </div>                 
                    </span>                
                </div>            
        </template>
         <template v-if="typeOf(option) == 'range'">
            <div v-if="show(option)">
                <label :for="type + '_' + option">{{ displayName(option) }}:</label>
                <div class="input-wrapper">
                    <input type="number" :step="step(option)" :min="min(option)" :max="max(option) - 1" :value="value(option)[0]" :id="type + '_' + option" :name="type" @change="onChange(option, 'value', [$event.target.value,value(option)[1]])" />
                    <span class="separator">-</span>
                    <input type="number" :step="step(option)" :min="min(option) + 1" :max="max(option)" :value="value(option)[1]" :id="type + '_' + option" :name="type" @change="onChange(option, 'value', [value(option)[0], $event.target.value])" />
                    <span class="units">{{ units(option) }}</span>
                </div>
            </div>
        </template>                
    </div>      
</template>

<script>
import SkychartService from '@/services/skychart.service'
import CalculateService from '@/services/calculate.service'
import PanelSlider from '@/components/common/PanelSlider'
import UtilsService from '@/services/utils.service'

export default {
    name: 'SkyChartDisplaySettings',
    props: {        
        type: String, // displaySettings
        settings: Object,
    },
    emits: [
        'change'
    ],
    data() {
      return {    
        tool: 'scd',                
      }
    },
    components: {            
        PanelSlider,         
    },
    computed: {
        displaySettingsOptions() {
            return SkychartService.getSettingsOptions(this.type);
        },                
        numberOfPoints() {
            return CalculateService.calculateMaxPointsInInterval();            
        }
    },
    methods: {
        numberOfJds(option) {
            return this.numberOfPoints ? this.numberOfPoints : this.settings[this.type][option].value;
        },    
        onChange(option, property, value) { 
            // Range
            if (Array.isArray(value)) { // range           
                const valueMin = UtilsService.clamp(value[0],this.min(option), this.max(option));
                const valueMax = UtilsService.clamp(value[1],this.min(option), this.max(option));
                value = [valueMin, valueMax];
            } else {
                value = UtilsService.clamp(value,this.min(option), this.max(option));
            }                   
            // Launch event            
            SkychartService.updateSetting({
                group: this.type,
                change: {
                    option: option,
                    property: property,
                    value: value,
                }
            });                     
        },                                
        typeOf(option) {                       
            return this.settings[this.type][option].type;
        },                               
        isChecked(option) {            
            return (this.typeOf(option) !='checkbox') || this.settings[this.type][option].value;
        },
        isSelected(option, value) {                        
            return (value === this.settings[this.type][option].value);
        },
        hasColor(option) {                        
            return this.settings[this.type][option].color;
        },
        hasLabel(option) {                        
            return this.settings[this.type][option].label;
        },        
        hasParent(option) {                        
            return this.settings[this.type][option].parent;
        },        
        color(option) {
            return this.settings[this.type][option].color;
        }, 
        value(option) {
            return this.settings[this.type][option].value;  
        },
        values(option) {            
            return this.settings[this.type][option].values;
        },    
        min(option) {            
            return this.settings[this.type][option].min;            
        },        
        max(option) {            
            let maxValue = this.settings[this.type][option].max;            
            // the value is set with a function                        
            if(typeof maxValue === 'string') {  
                maxValue = this[maxValue](option);                
            }
            return maxValue;            
        },   
        step(option) {            
            return this.settings[this.type][option].step;            
        },
        units(option) {            
            return this.settings[this.type][option].units;            
        },         
        parent(option) {                        
            return this.settings[this.type][option].parent;
        },   
        show(option) {
            return !this.hasParent(option) || 
                    (this.hasParent(option) && (this.isChecked(this.parent(option)) || this.typeOf(this.parent(option)) !='checkbox'));
        }  
    }, 
    mounted() {       
    }   
    
}
</script>

<style>

</style>
