<template>
    <Multiselect
        v-model="selected[select.label]"
        mode="tags"
        :searchable="true"
        :createTag="false"
        :options="options"
        @select="onSelect($event)"
        @deselect="onSelect($event)"
        @clear="onClear($event)">
        <template v-slot:tag="{ option, handleTagRemove }">
            <span class="multiselect-tag">                
                <label :for="option.label">
                    {{ option.label }}
                    <div class="color-input-wrapper">
                        <input class="color" type="color" :id="option.label" :name="option.label" :value="changeColors(`#${option.color}`)" @input="updateColor($event.target.value, option)">
                    </div>  
                </label>                                  
                <span class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                    <span class="multiselect-tag-remove-icon"></span>
                </span>
            </span>
        </template>
    </Multiselect>
</template>


<script>
import VisualisationService from '@/services/visualisation.service'
import SpacekitService from '@/services/spacekit.service'
import Multiselect from '@vueform/multiselect'

export default {
    name: "OrbitMultiselect",
    components: {
        Multiselect,
    },
    props: {
        select: Object
    },
    computed: {
        invertColors() {
            return VisualisationService.getInvertColors();
        },
    },
    data() {
        return {
            options: this.select.options ? VisualisationService.formatSelectOptions(this.select.options) : [],
            selected: {},
        };
    },
    methods: {
        onSelect(option) {
            const color = this.options.filter(e => e.value == option)[0].color;
            VisualisationService.selectOption(option, this.select.id, color);
            const multiselectOptions = VisualisationService.getMultiselectOptions();
            multiselectOptions[this.select.id] = [];
            multiselectOptions[this.select.id].push(this.options);
            VisualisationService.setMultiselectOptions(multiselectOptions);
        },
        onClear() {
            VisualisationService.clearSelectedOptions(this.select.id);
        },
        updateColor(color, option) {
            option.color = color.replace('#', '');
            let groupObjects = SpacekitService.getSmallObjectsGroup(this.select.id, option.value);
            VisualisationService.updateColorOption(this.select.id, option.value, option.color);

            const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('');
            
            if (VisualisationService.getInvertColors()){
                color = inv(color);
                option.color = color.replace('#', '');
                groupObjects = SpacekitService.getSmallObjectsGroup(this.select.id, option.value);
                VisualisationService.updateColorOption(this.select.id, option.value, option.color);
            }

            if (groupObjects) {
                SpacekitService.updateGroupColor(groupObjects, color);
            }                        
        },   
        changeColors(hex){
            const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('')          
            
            if (VisualisationService.getInvertColors()){
                return inv(hex);
            }else {
                return hex;
            }
        },    
    },
    mounted() {   
        if (VisualisationService.getOrbitVisualisationActiveSelects()){
            const selects = VisualisationService.getOrbitVisualisationSelects();
            this.selected[this.select.label] = [];
            for (const select in selects) {
                for (let i = 0; i < selects[select].length; i++){
                    let type, phrase, color;
                    type = select;
                    phrase = selects[select][i].option;
                    color = selects[select][i].color;
                    if (this.select.id === type || (this.select.id === 'priority_list' && type === 'plrisk') ){
                        if (VisualisationService.getMultiselectOptions() && VisualisationService.getMultiselectOptions()[this.select.id])
                            this.options = VisualisationService.getMultiselectOptions()[this.select.id][0];
                        this.selected[this.select.label].push(phrase);
                    } 

                    if (type && phrase && color) {
                        const selectType = type === 'priority_list' ? 'plrisk' : type;
                        VisualisationService.getSmallobjectsData(selectType, phrase).then(smallObjects => SpacekitService.addSmallObjects(selectType, phrase, smallObjects, color));
                    }
                }
            }
        }
        if (!this.select.options) {            
            VisualisationService.getSmallobjectsData(this.select.id).then(options => this.options = options);            
        }
    }
};

</script>

<style lang="scss">
@import '@/styles/common/custom-select.scss';
</style>
