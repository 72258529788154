<template>
    <div class="scd__datapoints">
        <div class="objects__title details__datapoints--info">
            {{ $t.dataPointsSelection }}<Tooltip position="right" :tooltipText="`${$t.selectTimestampToVisualiseExplanation}`"><i class="icon-info" /></Tooltip>
        </div>
        <div style="display: none;" class="details__datapoints--add">
            <button class="link" @click="addTimestamps()">
                <i class="icon-plus"></i><span>{{ $t.addMoreTimestepsAfterSelectedTimestamp }}</span>
            </button>
        </div>
        <div class="details__datapoints--format">
            <SkychartDatapointsSwitches />
        </div>
        <div class="details__datapoints--table-wrapper">
            <div class="details__datapoints--table d-table">
                <div class="d-table-row table-header">
                    <div class="d-table-cell">
                        {{ $t.dateAndTimeUTC }}
                    </div>
                    <div class="d-table-cell">
                        <Tooltip :position="'top'" :tooltipText="$keys.tooltips.AST_RA">
                            <span class="column-title">
                                {{ $t.ra }}
                            </span>
                        </Tooltip>
                    </div>
                    <div class="d-table-cell">
                        <Tooltip :position="'top'" :tooltipText="$keys.tooltips.AST_DEC">
                            <span class="column-title">
                                {{ $t.dec }}
                            </span>
                        </Tooltip>
                    </div>
                    <div class="d-table-cell">
                        <Tooltip :position="'left'" :tooltipText="$keys.tooltips.positionAffectedByStarlight">
                            {{ $t.starlightAffected }}
                        </Tooltip>
                    </div>
                </div>
                <div v-for="item of objectData" :key="item" class="d-table-row" @click="setTraceDate(item[0])" :class="{active: item[0] === traceDate}">
                    <div class="d-table-cell">
                        {{ julianToDate(item[0]) }}
                    </div>
                    <div class="d-table-cell">
                        {{ format(item[1], 'ra') }}
                    </div>
                    <div class="d-table-cell">
                        {{ format(item[3], 'dec') }}
                    </div>
                    <div class="d-table-cell">
                        <Tooltip v-if="starlightAffected(item)" :position="'left'" :tooltipText="$keys.tooltips.positionAffectedByStarlight">
                            <i class="icon-star" />
                        </Tooltip>  
                        <span v-else>-</span>
                    </div>
                </div>
                <div v-if="!objectData.length" class="d-table-row">
                    <div class="d-table-cell">
                        <span class="units">{{ $t.noData }}</span>
                    </div>
                    <div class="d-table-cell"></div>
                    <div class="d-table-cell"></div>
                    <div class="d-table-cell"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/common/Tooltip'
import SkychartService from '@/services/skychart.service'
import UtilsService from '@/services/utils.service'
import SkychartDatapointsSwitches from '@/components/scd/SkychartDatapointsSwitches'

export default {
    name: 'SkyChartDatapointsTable',
    props: {
        name: String,
    },
    components: {
        Tooltip,
        SkychartDatapointsSwitches
    },
    computed: {
        objectData() {
            return SkychartService.getTraceObjects()[this.name].data[0].data;
        },
        traceDate() {
            return SkychartService.getTraceDate();
        },
        objectTracking() {
            return SkychartService.getObjectTracking();
        },
        dmsFormat() {
            return SkychartService.getDmsFormat();
        }
    },
    methods: {
        julianToDate(julianDate) {
            const timestamp = UtilsService.julianToTimestamp(julianDate * 1);
            return UtilsService.momentUtcDate(timestamp, true) || '-';
        },
        addTimestamps() {
            console.log('add');
        },
        setTraceDate(julianDate) {
            SkychartService.setTraceDate(julianDate);
        },
        format(item, raOrDec) {
            return UtilsService.formatDegrees(item, this.dmsFormat, raOrDec);
        },
        bindKeyboardEvents(e) {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                const currentTimestampIndex = this.objectData.findIndex(e => e[0] === this.traceDate);
                if (e.key === 'ArrowUp') {
                    if (currentTimestampIndex > 0) {
                        this.setTraceDate(this.objectData[currentTimestampIndex-1][0]);
                    }
                } else {
                    if (currentTimestampIndex < this.objectData.length - 1) {
                        this.setTraceDate(this.objectData[currentTimestampIndex+1][0]);
                    }
                }
            }
        },
        starlightAffected(item) {
            return SkychartService.getStarlightAffected(this.name, item[0]);
        }
    },
    mounted() {
        window.addEventListener('keydown', this.bindKeyboardEvents, true);
        const traceDate = SkychartService.getTraceDate();
        setTimeout(() => {
            this.traceDate = traceDate;
            this.setTraceDate(traceDate);
        }, 100);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.bindKeyboardEvents, true);
    }
}
</script>

<style>

</style>