/**
 * Custom shader
 */

 const InvertColorsShader = {

    uniforms: {

        'tDiffuse': { value: null },

    },

    vertexShader: /* glsl */`

        varying vec2 vUv;

        void main() {

            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

        }`,

    fragmentShader: /* glsl */`

        uniform float amount;

        uniform sampler2D tDiffuse;

        varying vec2 vUv;

        void main() {

            vec4 color = texture2D( tDiffuse, vUv );

            gl_FragColor = vec4(1.0 - color.rgb, 1.0);

        }`

};

export { InvertColorsShader };
