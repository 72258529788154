<template>
    <div class="ovt__objects-configuration" :class="{hidden: !show}">
      <div class="ovt__objects-configuration__title">{{ $t.selectObjects }}</div>
      <div class="ovt__objects-configuration__title-vertical">{{ $t.selectObjects }}</div>
      <div class="ovt__objects-configuration__subtitle">{{ $t.databaseObjects }}</div>
      <div class="ovt__objects-configuration__close" :class="{open: !show}" @click="toggleSelectObjectsPanel()"></div>
      <OrbitObjectsTable :tool="tool" />
      <div class="ovt__select-wrapper">
        <div class="ovt__select" v-for="select in displaySelects" :key="select">
          <div class="ovt__select-label">
            <span class="ovt__select-label-flex">
              {{ $t[select.label] }}
              <span v-if="select.label === 'neoccObservationalPriorityList'" class="ovt__select-label-tooltip">
                <Tooltip position="left" :tooltipText="$keys.tooltips.neoccObservationalPriorityListObjects"><i class="icon-info" /></Tooltip>
              </span>
            </span>
          </div>
          <OrbitMultiselect :select="select" />
        </div>
      </div>
      <OrbitRiskList />
      <OrbitParticlesSize />
      <SyntheticObjects :tool="tool"/>
      <FocusSelector tool="ovt"/>
    </div>
  </template>
  

<script>
import VisualisationService from '@/services/visualisation.service'
import OrbitObjectsTable from '@/components/ovt/OrbitObjectsTable'
import OrbitMultiselect from '@/components/ovt/OrbitMultiselect'
import OrbitRiskList from '@/components/ovt/OrbitRiskList'
import OrbitParticlesSize from '@/components/ovt/OrbitParticlesSize'
import SyntheticObjects from '@/components/common/SyntheticObjects'
import Tooltip from '@/components/common/Tooltip'
import FocusSelector from "@/components/common/FocusOnSelector"

export default {
    name: 'OrbitObjectsConfiguration',
    components: {
        OrbitMultiselect,
        OrbitObjectsTable,
        OrbitRiskList,
        OrbitParticlesSize,
        SyntheticObjects,
        Tooltip,
        FocusSelector
    },
    props: {
        tool: String
    },
    computed: {
        show() {
            return VisualisationService.getSelectObjectsShow();
        },
        displaySelects() {
            return VisualisationService.getSelects();
        }
    },
    methods: {
        toggleSelectObjectsPanel() {
            VisualisationService.setSelectObjectsShow(!this.show);
        }
    },
}
</script>

<style>
    .ovt__select-label-flex {
        display: flex;
        align-items: right;
    }
    
    .ovt__select-label-tooltip {
        margin-left: 10px;
    }
</style>