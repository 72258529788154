<template>
    <div v-if="object.plots[longtermCurrentPlot] && chartAdvancedFeatures" class="longterm-plots__chart--y-axis" :class="{active: longtermCurrentPlot !== '', ['color-'+longtermCurrentPlot]: true}">
        <div v-if="showMinMax()" class="longterm-plots__chart--y-axis-max">{{ rounding(object.plots[longtermCurrentPlot].max) }}</div>
        <div class="longterm-plots__chart--y-axis-value" :style="style()" >{{ rounding(object.plots[longtermCurrentPlot].value) }}</div>
        <div v-if="showMinMax()" class="longterm-plots__chart--y-axis-min">{{ rounding(object.plots[longtermCurrentPlot].min) }}</div>
    </div>
</template>

<script>
import LongtermService from '@/services/longterm.service'
import UtilsService from '@/services/utils.service'

export default {
    name: 'ResultsLongTermDynamicAxis',
    props: {
        object: Object,
    },
    computed: {
        longtermCurrentPlot() {
            return LongtermService.getLongtermCurrentPlot();
        },
        chartAdvancedFeatures() {
            return LongtermService.chartAdvancedFeatures();
        },
    },
    methods: {
        style() {
            const min = this.object.plots[this.longtermCurrentPlot].min;
            const max = this.object.plots[this.longtermCurrentPlot].max;
            const value = this.object.plots[this.longtermCurrentPlot].value;
            const top = 100 - (UtilsService.normalize(min, max)(value) * 100);
            return `top: ${top}%;`;
        },
        rounding(value) {
            if (this.longtermCurrentPlot === 'SKY_MOV') {
                return value.toFixed(3);
            } else {
                return value.toFixed(1);
            }
        },
        showMinMax() {
            const min = this.object.plots[this.longtermCurrentPlot].min;
            const max = this.object.plots[this.longtermCurrentPlot].max;
            return min !== max;
        }
    }
}
</script>

<style>

</style>