<template>
    <div v-if="showRecorder" class="ovt__legend recorder" :class="{'with-config': configPanelOpen}">
        <span v-if="!onAir" class="icon-cross" @click="closeRecorder()"></span>
        <button v-if="showRecorder" class="recorder-button" @click="toggleRecording()">
            <template v-if="!onAir">
                <i class="record-icon" /> <span>{{ $t.startRecording }}</span>
            </template>
            <template v-else>
                <i class="record-icon stop" /> <span>{{ $t.stopRecording }}</span>
            </template>
        </button>
        <span v-if="onAir" class="timer">{{ timeFormatted() }}</span>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'VisualisationRecorder',
    data() {
        return {
            canvas: null,
            stream: null,
            mediaSource: null,
            mediaRecorder: null,
            recordedBlobs: null,
            sourceBuffer: null,
            time: 0,
            timer: null,
        }
    },
    computed: {
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        showRecorder() {
            return VisualisationService.getOrbitRecorderShow();
        },
        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        }
    },
    methods: {
        closeRecorder() {
            VisualisationService.setSettingsPanelName('');
            return VisualisationService.toggleRecorderShow();
        },
        toggleRecording() {
            console.log('fn: toggleRecording', this.onAir);
            if (!this.onAir) {
                this.startRecording();
            } else {
                this.stopRecording();
            }
            VisualisationService.setOrbitRecorderOnAir(!this.onAir);
        },
        timeFormatted() {
            const hours = Math.floor(this.time / 3600);
            const minutes = Math.floor(this.time / 60);
            const seconds = this.time % 60;
            return `${hours > 9 ? hours : '0'+hours}:${minutes > 9 ? minutes : '0'+minutes}:${seconds > 9 ? seconds : '0'+seconds}`;
        },
        startRecording() {
            console.log('fn: startRecording', 'init');
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const options = { mimeType: isSafari ? 'video/mp4' : 'video/webm', videoBitsPerSecond: 25600000 };
            this.recordedBlobs = [];
            this.mediaRecorder = new MediaRecorder(this.stream, options);
            this.mediaRecorder.ondataavailable = this.handleDataAvailable;
            this.mediaRecorder.start(100);
            console.log('fn: startRecording', this.mediaRecorder);
            this.timer = setInterval(() => {
                this.time += 1;
            }, 1000);
        },
        handleDataAvailable(event) {
            console.log('fn: handleDataAvailable', event.data);
            if (event.data && event.data.size > 0) {
                this.recordedBlobs.push(event.data);
            }
        },
        stopRecording() {
            console.log('fn: stopRecording', 'init');
            this.mediaRecorder.stop();
            this.time = 0;
            clearInterval(this.timer);
            console.log('fn: stopRecording', 'complete', this.mediaRecorder, this.time, this.timer);
            this.download();
        },
        download() {
            console.log('fn: download', 'init');
            const tool = VisualisationService.getTool();
            const toolsTitles = {
                ovt: 'OrbitVisualisationTool',
                fvt: 'FlybyVisualisationTool',
                sovt: 'SynodicOrbitVisualisationTool'
            }
            const prefix = toolsTitles[tool];
            const blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
            console.log('fn: download', 'blob', blob);
            const url = window.URL.createObjectURL(blob);
            console.log('fn: download', 'url', url);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${prefix}_Record_${new Date().toISOString()}.webm`;
            document.body.appendChild(a);
            console.log('fn: download', 'a', a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                window.dispatchEvent(new Event('resize'));
                console.log('fn: download', 'complete');
            }, 100);
        },
        handleSourceOpen() {
            console.log('fn: handleSourceOpen', this.mediaSource);
            this.sourceBuffer = this.mediaSource.addSourceBuffer('video/webm; codecs="vp9"');
        },
    },
    mounted() {
        this.mediaSource = new MediaSource();
        this.mediaSource.addEventListener('sourceopen', this.handleSourceOpen, false);
        this.canvas = document.querySelector('.vt__simulation canvas');
        if (this.canvas){
            this.stream = this.canvas.captureStream();
        }
    }
}
</script>

<style>

</style>
