<template>
    <Navigation />
    <div class="header">
        <div class="header-left">
            <div class="tool-options">
                <button class="button-icon" @click="showNavigation()">
                    <i class="icon-menu" />
                </button>
                <button class="button-icon" @click="load()" :class="{disabled: !loadAvailable}">
                    <Tooltip v-if="!loadAvailable" position="bottom" :tooltipText="`${$t.thereIsNoSavedResults}`"><i class="icon-folder" /></Tooltip>
                    <i v-else class="icon-folder" />
                </button>
                <button class="button-icon" @click="save()">
                    <i class="icon-save" />
                </button>
                <button class="button-icon" @click="share()">
                    <i class="icon-share-2" />
                </button>
            </div>
            <div class="tool-title">
                {{ toolTitle }}
            </div>
        </div>
        <div class="header-right">
            <a class="tool-title" :href="$keys.urls.neoToolkit" :title="$keys.titles.neo">
                {{ $keys.titles.neo }}
            </a>
            <a class="logo-link" :href="$keys.urls.esa" :title="$keys.titles.esa" target="_blank">
                <img class="logo-img" src="/static/images/ESA_Logo_white.svg" :alt="$keys.titles.esa" />
            </a>
        </div>
    </div>
</template>

<script>
import Navigation from '@/components/common/Navigation'
import CommonActions from '@/store/common/common-actions'
import CommonGetters from '@/store/common/common-getters'
import MemoryService from '@/services/memory.service'
import PopupService from '@/services/popup.service'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'CommonHeader',
    components: {
        Navigation,
        Tooltip,
    },
    data() {
        return {
            dataSaved: false,
        }
    },
    computed: {
        toolTitle() {
            return this.$store.getters[CommonGetters.toolTitle];
        },
        loadAvailable() {
            return MemoryService.stateFromCookiesAvailable() || this.dataSaved;
        }
    },
    methods: {
        showNavigation() {
            this.$store.dispatch(CommonActions.setNavigationVisible, true);
        },
        load() {
            if (!this.loadAvailable) return;
            PopupService.show({
                component: 'PopupDialog',
                type: 'info',
                message: this.$t.doYouWantToLoadPreviouslySavedData,
                actions: {
                    cancel: {
                        text: this.$t.no
                    },
                    submit: {
                        text: this.$t.load,
                        emits: 'load-state-from-cookies'
                    }
                }
            });
        },
        save() {
            setTimeout(() => {
                this.dataSaved = true;
            }, 1000);
            MemoryService.saveToolState();
        },
        share() {
            MemoryService.shareToolState();
        },
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/common/header';
</style>