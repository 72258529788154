<template>
    <div class="scd">
        <ul class="tab-list">
            <li class="tab-item">
                <router-link class="tab-link t-center" :to="`${$keys.routes.scd}/${$keys.routes.setup}`" :key="`${$keys.routes.scd}/${$keys.routes.setup}`">{{ $t.setup }}</router-link>
            </li>
            <li class="tab-item">
                <router-link class="tab-link t-center" :class="{disabled: (!skychartEnableVisualisation && !isSyntheticObject) || !isUserDefinedObjectCustomObservatoryEnabled}" :to="`${$keys.routes.scd}/${$keys.routes.scdVisualisation}`" :key="$route.path">{{ $t.visualisation }}</router-link>
            </li>
        </ul>
        <div class="tab-content">
            <router-view />
        </div>
    </div>
</template>

<script>
import SyntheticObjectsService from '@/services/synthetic-objects.service';
import CommonActions from '@/store/common/common-actions'
import CommonGetters from '@/store/common/common-getters'

export default {
    name: 'SkyChartDisplayTool',
    computed: {
        skychartEnableVisualisation() {
            return this.$store.getters[CommonGetters.skychartEnableVisualisation];
        },
        isUserDefinedObjectCustomObservatoryEnabled() {
            return SyntheticObjectsService.isUserDefinedObjectCustomObservatoryEnabled();
        },
        isSyntheticObject() {
            return !!SyntheticObjectsService.getSyntheticDefaultObjectsList().length;
        }
    },
    mounted() {
        this.$store.dispatch(CommonActions.setTool, 'scd');
        this.$store.dispatch(CommonActions.setToolTitle, this.$keys.titles.scd);
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/scd/general.scss";
@import "@/styles/common/tabs.scss";
</style>