<template>
    <div class="shortterm-table d-table">
        <div class="d-table-row table-header">
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.object }}</span></div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.previousObservation }}</span></div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.database }}</span></div>
            </div>
            <div v-for="(field, index) of ephemeridesShortterm.fields" :key="index" class="d-table-cell fixed-width">
                <EphemeridesTableHeader :columnId="field" :columnUnits="ephemeridesUnits(index)" />
            </div>
            <div class="d-table-cell plots">
                <ShorttermPlotsHeader />
            </div>
        </div>

        <div v-for="(object, index) of objects" :key="index" class="d-table-row table-contents">
            <div class="d-table-cell">
                {{ displayName(object) }}
            </div>
            <div class="d-table-cell">
                <a :href="getPreviousObservation(object)" :title="object.name" target="_blank">
                    <i class="icon-external-link" />
                </a>
            </div>
            <div class="d-table-cell">
                {{ object.sourceType ? ( object.sourceType.name === $t.neocc ? $t.pdo : object.sourceType.name ) : $t.nA }}
            </div>
            <div v-for="(field, fieldIndex) of ephemeridesShortterm.fields" :key="fieldIndex" class="d-table-cell">
                <span v-html="ephemeridesValue(object.name, 0, fieldIndex, field, false)" />
            </div>
            <div class="d-table-cell plots">
                <ShorttermPlotsContent :name="object.name" />
            </div>
        </div>
    </div>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import EphemeridesService from '@/services/ephemerides.service'
import ShorttermService from '@/services/shortterm.service'
import FilteringService from '@/services/filtering.service'
import EphemeridesTableHeader from '@/components/opt/results/EphemeridesTableHeader'
import ShorttermPlotsHeader from '@/components/opt/results/ShorttermPlotsHeader'
import ShorttermPlotsContent from '@/components/opt/results/ShorttermPlotsContent'
import UtilsService from '@/services/utils.service'

export default {
    name: 'ResultsShortTermTable',
    components: {
        EphemeridesTableHeader,
        ShorttermPlotsHeader,
        ShorttermPlotsContent,
    },
    data() {
        return {
            shorttermColumns: ['SKY_MOV', 'MAG', 'ERR_A']
        }
    },
    computed: {
        objects() {
            return ObjectsService.getObjectsFilteredSelected();
        },
        ephemeridesShortterm() {
            return ShorttermService.getEphemeridesShortterm();
        },
    },
    methods: {
        getPreviousObservation(object) {
            const designator = (object.number && object.number !== '' && object.number !== '0') ? `${object.number} ${object.name}` : object.name;
            return FilteringService.getPreviousObservationUrl(object) + encodeURIComponent(designator);
        },
        ephemeridesUnits(index) {
            return this.ephemeridesShortterm.units[index];
        },
        ephemeridesValue(name, row, index, field) {
            if (!this.ephemeridesShortterm.objects || !this.ephemeridesShortterm.objects[name] || !this.ephemeridesShortterm.objects[name].length) return;
            if (field === 'JD_UTC') {
                return UtilsService.julianToDate(this.ephemeridesShortterm.objects[name][row][index], false) || '-';
            } else {
                return (this.ephemeridesShortterm.objects[name][row][index]*1).toFixed(1) || '-';
            }
        },
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },
        displayTitle(field, index) {
            const unit = this.ephemeridesUnits(index);
            const unitShort = this.$t[unit] || unit;
            const fieldLong = this.$keys.titles[field] || field;
            if (unitShort && unitShort !== '') {
                return `${fieldLong} (${unitShort})`;
            } else {
                return fieldLong;
            }
        },
    },
    mounted() {
        EphemeridesService.setEphemeridesFiltered(this.shorttermColumns);
    }
}
</script>

<style>

</style>