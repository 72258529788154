<template>
    <label for="type-topocentric">
        <input type="radio" id="type-topocentric" name="type" :checked="!isGeocentric" value="topocentric" @change="typeChange($event)" />
        {{ $t.topocentric }}
    </label>
    <label for="type-geocentric">
        <input type="radio" id="type-geocentric" name="type" :checked="isGeocentric" value="geocentric" @change="typeChange($event)" />
        {{ $t.geocentric }}
    </label>
</template>

<script>
import ObservatoryService from '@/services/observatory.service'

export default {
    name: 'ObservatoryTypeSwitch',
    computed: {
        isGeocentric() {
            return ObservatoryService.isGeocentric();
        }
    },
    methods: {
        typeChange($event) {
            if ($event.target.value === 'geocentric') {
                ObservatoryService.setGeocentricObservatory();
            } else {
                ObservatoryService.setRecentlySelectedObservatory();
            }
        }
    },
}
</script>

<style>

</style>