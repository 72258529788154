<template>
    <div v-if="show" class="cookie-notification" :class="{close: close}">
        <p v-html="$t.cookieNotificationText" />
        <button class="button primary" @click="agree()">{{ $t.iAgree }}</button>
    </div>
</template>

<script>
import MemoryService from '@/services/memory.service'

export default {
    name: 'CookieNotification',
    data() {
        return {
            show: false,
            close: false,
        }
    },
    methods: {
        agree() {
            this.close = true;
            MemoryService.setCookieAgreement();
        }
    },
    mounted() {
        this.show = MemoryService.getCookieAgreement() ? false : true;
    }
}
</script>

<style lang="scss">
@import "@/styles/cookie-notification.scss";
</style>