<template>
    <input class="toggle-switch" id="visualise-on-chart" type="checkbox" v-model="longtermChartSwitch"/>
    <label class="toggle-switch__button" for="visualise-on-chart"></label>
    <span class="toggle-switch__label" @click="toggleChart()">{{ $t.visualiseOnChart }}</span>
</template>

<script>
import LongtermService from '@/services/longterm.service'

export default {
    name: 'LongTermChartSwitch',
    data() {
        return {
            longtermChartSwitch: null,
        }
    },
    computed: {
        visualiseOnChart() {
            return LongtermService.getLongtermVisualiseOnChart();
        }
    },
    methods: {
        toggleChart() {
            this.longtermChartSwitch = !this.longtermChartSwitch;
        }
    },
    watch: {
        longtermChartSwitch(newVal) {
            LongtermService.setLongtermVisualiseOnChart(newVal);
        }
    },
    mounted() {
        this.longtermChartSwitch = this.visualiseOnChart;
    }
}
</script>

<style>

</style>