<template>
    <div class="plots-timeline">
        <Tooltip :tooltipText="$keys.tooltips.shorttermTimelineUTC">
            <i class="icon-info" />
        </Tooltip>
        <div class="plots-timeline__wrapper">
            <div class="plots-timeline__labels">
                <span v-for="(hour, index) of hours" :key="index">
                    {{ hour }}
                    <span v-if="isDusk(hour, hours[index + 1] || hours[0])" class="dusk" :style="twilightPosition(dusk)"></span>
                    <span v-if="isDawn(hour, hours[index + 1] || hours[0])" class="dawn" :style="twilightPosition(dawn)"></span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/common/Tooltip'
import ObjectsService from '@/services/objects.service'
import ShorttermService from '@/services/shortterm.service'
import TimeFrameService from '@/services/timeframe.service'

export default {
    name: 'ShortTermTimeline',
    components: {
        Tooltip,
    },
    data() {
        return {
            hours: ['12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00'],
            dusk: '',
            dawn: '',
        }
    },
    computed: {
        shorttermData() {
            return ShorttermService.getShorttermData(this.name, this.shorttermDate);
        },
        shorttermDate() {
            return ShorttermService.getShorttermDate();
        },
        name() {
            return ObjectsService.getObjectsFilteredSelected()[0].name;
        },
        sunAltLimit() {
            return ShorttermService.shortTermSunAltLimit();
        },
        timezoneShift() {
            return TimeFrameService.getTimezoneShift(this.shorttermDate);
        },
    },
    watch: {
        shorttermDate(newVal) {
            this.dusk = ShorttermService.getAstronomicalDusk(newVal).utc;
            this.dawn = ShorttermService.getAstronomicalDawn(newVal).utc;
        }
    },
    methods: {
        hasTwilight(twilight, hour, nHour) {
            let nextHour = nHour || '12:00';
            if (nextHour === '00:00') nextHour = '24:00';
            return nHour && twilight >= hour && twilight < nextHour;
        },
        isDusk(hour, nHour) {
            return this.hasTwilight(this.dusk, hour, nHour);
        },
        isDawn(hour, nHour) {
            return this.hasTwilight(this.dawn, hour, nHour);
        },
        twilightPosition(twilight) {
            const minutes = parseInt(twilight.split(':')[1]);
            let visibility = '';
            if (this.dawn === this.dusk) {
                visibility = 'visibility: hidden;';
            }
            return `left: ${(minutes / 60) * 100}%; ${visibility}`;
        },
        calculateTimezoneShift() {
            if (this.timezoneShift > 0) {
                for (let i = 0; i < this.timezoneShift; i++) {
                    this.hours.push(this.hours[i]);
                }
                for (let i = 0; i < this.timezoneShift; i++) {
                    this.hours.splice(0, 1);
                }
            } else if (this.timezoneShift < 0) {
                const timezoneShift = this.timezoneShift * -1;
                for (let i = 0; i < timezoneShift; i++) {
                    this.hours.unshift(this.hours[this.hours.length - 1 - i]);
                }
                for (let i = 0; i < timezoneShift; i++) {
                    this.hours.splice(-1, 1);
                }
            }
        }
    },
    mounted() {
        this.calculateTimezoneShift();
        this.dusk = ShorttermService.getAstronomicalDusk(this.shorttermDate).utc;
        this.dawn = ShorttermService.getAstronomicalDawn(this.shorttermDate).utc;
    }
}
</script>

<style>

</style>