<template>
    <div v-if="settings.displaySettings.telescopeParameters.value" class="scd__legend view" :class="{'with-panel': withPanel, 'with-details': !hide}">
        <div class="scd__legend--label">{{ $t.telescopeParameters }}</div>
        <div class="d-table">
            <div v-for="(item, key) in legend" :key="key" class="d-table-row">
                <template v-if="key !== 'date'">
                    <div class="d-table-cell">{{ $t[key] }}:</div>
                    <div class="d-table-cell">{{ format(item, key) }}</div>
                </template>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.observatoryAltitude }}:</div>
                <div class="d-table-cell">{{ altitude }} m</div>
            </div>
            <div v-for="(item, key) in legend" :key="key" class="d-table-row">
                <template v-if="key === 'date'">
                    <div class="d-table-cell">{{ $t[key] }}:</div>
                    <div class="d-table-cell">{{ format(item, key) }}</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import SkychartService from '@/services/skychart.service'
import UtilsService from '@/services/utils.service'
import ObservatoryService from '@/services/observatory.service'
import Config from '@/constants/config'

export default {
    name: 'SkyChartViewLegend',
    props: {
        legend: Object,
        withPanel: Boolean
    },
    data() {
        return {
            altitude: ObservatoryService.getSelectedObservatory().alt || parseInt(ObservatoryService.getObservatoryElevation()).toFixed(0)
        }
    },
    computed: {
        dmsFormat() {
            return SkychartService.getDmsFormat();
        },
        hide() {
            return SkychartService.getObjectsDetailsHide();
        },
        settings() {
            return SkychartService.getSettings();
        }
    },
    methods: {
        format(item, key) {
            if (key === 'date') {
                const timestamp = UtilsService.julianToTimestamp(item * 1);
                return UtilsService.momentUtcDate(timestamp, true) || '-';
            } else if (key === 'fov') {
                return item;
            } else if (key === 'ra') {
                return UtilsService.formatDegrees(item, (this.dmsFormat && key !== 'fov'), 'ra');
            } else if (key === 'dec') {
                return UtilsService.formatDegrees(item, (this.dmsFormat && key !== 'fov'), 'dec');
            } else {
                return UtilsService.formatDegrees(item, (this.dmsFormat && key !== 'fov'));
            }
        }
    },
    mounted() {
        if (this.dmsFormat === null) {
            SkychartService.setDmsFormat(Config.visualisation.dmsFormat);
        }
    }
}
</script>

<style>

</style>