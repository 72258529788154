<template>
    <div class="map" id="map" :class="{disabled: isGeocentric}"></div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Modify from 'ol/interaction/Modify';
import Config from '@/constants/config'
import ObservatoryService from '@/services/observatory.service'

export default {
    name: 'MapView',
    data() {
        return {
            map: null,
            view: null,
            iconFeature: null,
            config: Config.map,
        }
    },
    computed: {
        selectedObservatory() {
            return ObservatoryService.getSelectedObservatory();
        },
        isGeocentric() {
            return ObservatoryService.isGeocentric();
        },
    },
    mounted() {
        const coordinates = fromLonLat([
            this.selectedObservatory.longitude,
            this.selectedObservatory.latitude
        ]);

        this.iconFeature = new Feature({
            geometry: new Point(coordinates)
        });

        const iconStyle = new Style({
            image: new Icon(this.config.iconSettings)
        })
        
        this.iconFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
            features: [this.iconFeature],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        const rasterLayer = new TileLayer({
            source: new OSM(),
        });

        const target = document.getElementById('map');

        this.view = new View({
            center: coordinates,
            zoom: this.config.zoom
        })

        this.map = new Map({
            target,
            layers: [rasterLayer, vectorLayer],
            view: this.view,
        });

        const markerDrag = new Modify({
            hitDetection: vectorLayer,
            source: vectorSource,
        });

        markerDrag.on(['modifystart', 'modifyend'], event => {
            target.style.cursor = event.type === 'modifystart' ? 'grabbing' : 'pointer';
            if (event.type === 'modifyend') {
                const newCoordinates = toLonLat(event.features.array_[0].getGeometry().getCoordinates());
                const newLongitude = newCoordinates[0].toFixed(5);
                const newLatitude = newCoordinates[1].toFixed(5);
                ObservatoryService.setUserDefinedObservatory(newLatitude, newLongitude);
            }
        });

        this.map.addInteraction(markerDrag);
    },
    watch: {
        selectedObservatory(newVal) {
            if (this.isGeocentric || isNaN(newVal.longitude) || isNaN(newVal.latitude)) return;
            const coordinates = fromLonLat([newVal.longitude, newVal.latitude]);
            this.iconFeature.setGeometry(new Point(coordinates));
            this.view.animate({
                center: coordinates,
                duration: this.config.animationDuration,
            });
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/opt/map.scss";
</style>