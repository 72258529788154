<template>
    <button class="link" @click="clearAll()" :class="{disabled: !filters.length}">
        <i class="icon-filter"><i class="icon-x-circle" /></i> <span>{{ $t.clearAllFilters }}</span>
    </button>
</template>

<script>
import PopupService from '@/services/popup.service'
import FilteringService from '@/services/filtering.service'
import Config from '@/constants/config'

export default {
    name: 'ClearAllFilters',
    computed: {
        filters() {   
            var filteredKeys = Object.keys(FilteringService.getFilters());
            const filters = FilteringService.getFilters();  
            for (const filterId in Config.filters) {
                const filter = Config.filters[filterId];            
                if (filters[filterId] && filter.type === 'checkbox') { 
                    const size= (typeof (filters[filterId]) === "string") ? filters[filterId].split(',').length : filters[filterId].length;                                     
                    if (size === filter.options.split(',').length) {
                        filteredKeys = filteredKeys.filter(val => val !== filterId);                                                
                    }                                     
                }    
            }                    
            return filteredKeys;                        
        } 
    },
    methods: {
        clearAll() {
            PopupService.show({
                component: 'PopupDialog',
                type: 'info',
                message: this.$t.confirmClearAllFilters,
                actions: {
                    submit: {
                        emits: 'clear-all-filters'
                    }
                }
            });
        }
    }
}
</script>

<style>

</style>
