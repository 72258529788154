<template>
    <nav class="navigation" :class="{show: navigationVisible}">
        <div class="nav-header">
            <span>{{ $keys.titles.neo }}</span>
            <button class="button-icon" @click="hideNavigation()">
                <i class="icon-x" />
            </button>
        </div>
        <ul class="nav-list">
            <li class="nav-item" v-for="tool of tools" :key="tool" @click="hideNavigation()">
                <!--router-link class="nav-link" :to="$keys.routes[tool]" :class="{disabled: !toolsEnabled.includes(tool) }" :key="$route.path">{{ $keys.titles[tool] }}</router-link-->
                <router-link class="nav-link" :to="returnKeyRoutes(tool)" :class="{disabled: !toolsEnabled.includes(tool) }" :key="returnNavPath()">{{ $keys.titles[tool] }}</router-link>
                <Tooltip v-if="!toolsEnabled.includes(tool)" position="right" :tooltipText="`${$keys.titles[tool]} ${$t.isCommingSoon}`"><i class="icon-clock" /></Tooltip>
            </li>
        </ul>
    </nav>
</template>

<script>
import Config from '@/constants/config'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Tooltip from '@/components/common/Tooltip'
import ObjectsService from '@/services/objects.service'
import SkychartService from '@/services/skychart.service'
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'CommonNavigation',
    components: {
        Tooltip
    },
    data() {
        return {
            tools: Config.tools,
            toolsEnabled: Config.toolsEnabled,
        }
    },
    computed: {
        navigationVisible() {
            return this.$store.getters[CommonGetters.navigationVisible];
        }
    },
    methods: {
        hideNavigation() {
            this.$store.dispatch(CommonActions.setNavigationVisible, false);
        },
        returnNavPath(){
            const tool = VisualisationService.getTool();
            if ((tool === 'opt') && (this.$route.path.includes(tool)) &&(this.$route.path !== ObjectsService.getOptRoute())){
                if (this.$route.path === '/opt/setup'){
                    return ObjectsService.getOptRoute();
                } else {
                    ObjectsService.setOptRoute(this.$route.path);
                    return this.$route.path;
                }              
            } else if ((tool === 'scd') && (this.$route.path.includes(tool)) && (this.$route.path !== SkychartService.getScdRoute())){
                if (this.$route.path === '/scd/setup'){
                    return SkychartService.getScdRoute();
                } else {
                    SkychartService.setScdRoute(this.$route.path);
                    return this.$route.path;
                }
            } else 
                return this.$route.path;
        },
        returnKeyRoutes(tool){
            if ((tool === 'opt') && (this.$route.path.includes(tool)) && (this.$route.path !== ObjectsService.getOptRoute())){
                if (this.$route.path === '/opt/setup'){
                    ObjectsService.setOptRoute(this.$route.path);
                    return ObjectsService.getOptRoute();
                } else {
                    ObjectsService.setOptRoute(this.$route.path);
                    return this.$route.path;
                }              
            } else if ((tool === 'scd') && (this.$route.path.includes(tool)) && (this.$route.path !== SkychartService.getScdRoute())){
                if (this.$route.path === '/scd/setup'){
                    SkychartService.setScdRoute(this.$route.path);
                    return SkychartService.getScdRoute();
                } else {
                    SkychartService.setScdRoute(this.$route.path);
                    return this.$route.path;
                }
            } else {
                if (tool === 'opt'){
                    return ObjectsService.getOptRoute();
                }else if (tool === 'scd') {
                    return SkychartService.getScdRoute();
                }else
                    return this.$keys.routes[tool];
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common/navigation';
</style>