<template>
    <div v-if="object && Object.keys(object).length" class="scd__objects--details" :class="{active: activeObjectName !== '', hide}">
        <div class="scd__objects--details-wrapper">
            <div class="objects__title">
                {{ $t.details }}
            </div>
            <div class="details__close" @click="hideObjectsDetails()"></div>
            <div v-if="object" class="objects__list--item">
                <img class="asteroid-image" src="/static/images/asteroid_default.png" :alt="displayName(object)" />
                <span class="asteroid-description">
                    <span class="asteroid-name">{{ displayName(object) }}</span>
                    <span class="asteroid-type">{{ displayObjectType(object) }}</span>
                </span>
            </div>
            <div v-if="object" class="d-table details__table">
                <div class="d-table-row details__table--row" v-for="property of filteredTableProperties(object)" :key="property">
                    <div v-if="propertyName(property) === 'PHA'" class="d-table-cell details__table--cell">
                        <Tooltip position="right" :tooltipText="`${$keys.tooltips.pha}`">{{ propertyName(property) }}</Tooltip>
                    </div>
                    <div v-else class="d-table-cell details__table--cell">{{ propertyName(property) }}</div>
                    <div class="d-table-cell details__table--cell">{{ propertyValue(object, property) === $t.neocc ? $t.pdo : propertyValue(object, property)}}</div>
                </div>
            </div>
            <div class="details__datapoints">
                <SkychartDatapointsTable v-if="activeObjectName && !objectLoading(object.designator || object.name)" :name="activeObjectName" />
            </div>
        </div>
    </div>
</template>

<script>
import SkychartService from '@/services/skychart.service'
import ObjectsService from '@/services/objects.service'
import SyntheticObjectsService from '@/services/synthetic-objects.service'
import SkychartDatapointsTable from '@/components/scd/SkychartDatapointsTable'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'SkyChartObjectDetails',
    components: {
        SkychartDatapointsTable,
        Tooltip,
    },
    computed: {
        activeObjectName() {
            return SkychartService.getActiveObjectName();
        },
        object() {
            const finalAsteroidList = ObjectsService.getFinalAsteroidsList();
            const syntheticObjectsList = SyntheticObjectsService.getSyntheticDefaultObjectsList();
            const objects = finalAsteroidList.concat(syntheticObjectsList);
            return objects.filter(e => (e.designator || e.name) === this.activeObjectName)[0];
        },
        loadedObjects() {
            return SkychartService.getLoadedObjects();
        },
        hide() {
            return SkychartService.getObjectsDetailsHide();
        }
    },
    methods: {
        filteredTableProperties(object) {
            const restrictedKeys = ['id', 'number', 'code', 'filename'];
            if (!object.designator && ObjectsService.isCometWithoutObjectGroup(object)) {
                restrictedKeys.push('objectGroup');
            }
            if (object.designator) {
                restrictedKeys.push('objectName', 'designator');
            }
            return Object.keys(object).filter(e => !restrictedKeys.includes(e));
        },
        displayName(item) {
            return item.objectName || ObjectsService.objectNumberAndName(item);
        },
        propertyName(property) {
            return this.$t[property] ? this.$t[property] : property
        },
        propertyValue(object, property) {
            const value = object[property];
            if (!value) {
                return '-';
            }

            let displayValue = '';

            if (typeof value == 'object' && value.name) {
                displayValue = value.name;
            } else {
                displayValue = value;
            }

            if (object.objectName) {
                const parametersUnitLabelMap = SyntheticObjectsService.getObjectParametersUnitLabelMap();
                typeof displayValue === 'number' && (displayValue = parseFloat(displayValue.toFixed(5)));
                displayValue = `${displayValue}${parametersUnitLabelMap[property] ? (' ' + this.$t[parametersUnitLabelMap[property]]) : ''}`
            }

            switch (displayValue) {
                case true:
                    displayValue = this.$t.yes;
                    break;
                case false:
                    displayValue = this.$t.no;
                    break;
                case 'UR':
                    displayValue = this.$t.UR;
                    break;
                case 'NE':
                    displayValue = this.$t.NE;
                    break;
                case 'US':
                    displayValue = this.$t.US;
                    break;
                case 'LP':
                    displayValue = this.$t.LP;
                    break;
                case '0':
                    displayValue = this.$t.none;
                    break;
                case 'JPL':
                    displayValue = this.$t.jplNecs;
                    break;
                case 'N/A':
                    displayValue = this.$t.nonNeo;
                    break;
                default:
                    break;
            }

            return displayValue;
        },
        objectLoading(name) {
            return !this.loadedObjects.includes(name);
        },
        displayObjectType(object) {
            return object.objectName ? this.$t.userDefinedAsteroid : ObjectsService.displayObjectType(object);
        },
        hideObjectsDetails() {
            SkychartService.setObjectsDetailsHide(true);
        },
    }
}
</script>

<style>

</style>