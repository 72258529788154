export default {
    multiselectOptions: 'common/multiselectOptions',
    optRoute: 'common/optRoute',
    scdRoute: 'common/scdRoute',
    previousTimeOVT: 'common/previousTimeOVT',
    previousTimeFVT: 'common/previousTimeFVT',
    playbackSpeedFVT: 'common/playbackSpeedFVT',
    playbackSpeed: 'common/playbackSpeed',
    startDate: 'common/startDate',
    endDate: 'common/endDate',
    emptyFilter: 'common/emptyFilter',
    currentDate: 'common/currentDate',
    navigationVisible: 'common/navigationVisible',
    tool: 'common/tool',
    toolTitle: 'common/toolTitle',
    loadingObservatoryList: 'common/loadingObservatoryList',
    observatoryList: 'common/observatoryList',
    observatoryElevation: 'common/observatoryElevation',
    selectedObservatory: 'common/selectedObservatory',
    recentlySelectedObservatory: 'common/recentlySelectedObservatory',
    timeFrame: 'common/timeFrame',
    timestep: 'common/timestep',
    timestepMemory: 'common/timestepMemory',
    spinnerShow: 'common/spinnerShow',
    requestCancel: 'common/requestCancel',
    popupShow: 'common/popupShow',
    popupConfig: 'common/popupConfig',
    objects: 'common/objects',
    objectsSelected: 'common/objectsSelected',
    objectsInitialLoadPerformed: 'common/objectsInitialLoadPerformed',
    filters: 'common/filters',
    dropboxSetupId: 'common/dropboxSetupId',
    loadingAsteroidsList: 'common/loadingAsteroidsList',
    availableAsteroidsList: 'common/availableAsteroidsList',
    finalAsteroidsList: 'common/finalAsteroidsList',
    syntheticDefaultObjectsList: 'common/syntheticDefaultObjectsList',
    syntheticFvtObjectsList: 'common/syntheticFvtObjectsList',
    insertAsteroidsList: 'common/insertAsteroidsList',
    asteroidsNames: 'common/asteroidsNames',
    configurationPopupMode: 'common/configurationPopupMode',
    paginationFrom: 'common/paginationFrom',
    paginationLoading: 'common/paginationLoading',
    token: 'common/token',
    tokenCancelled: 'common/tokenCancelled',
    userDefinedObjects: 'common/userDefinedObjects',
    setupMemory: 'common/setupMemory',
    toolState: 'common/toolState',
    ephemerides: 'common/ephemerides',
    ephemeridesFiltered: 'common/ephemeridesFiltered',
    ephemeridesShortterm: 'common/ephemeridesShortterm',
    ephemeridesActiveRow: 'common/ephemeridesActiveRow',
    ephemeridesColumns: 'common/ephemeridesColumns',
    availableEphemeridesColumns: 'common/availableEphemeridesColumns',
    finalEphemeridesColumns: 'common/finalEphemeridesColumns',
    ephemeridesOnlyObservable: 'common/ephemeridesOnlyObservable',
    shorttermDate: 'common/shorttermDate',
    calculationProgress: 'common/calculationProgress',
    calculationEstimatedTime: 'common/calculationEstimatedTime',
    calculatedObjects: 'common/calculatedObjects',
    longtermObjectName: 'common/longtermObjectName',
    longtermActivePlots: 'common/longtermActivePlots',
    longtermObservablePlots: 'common/longtermObservablePlots',
    longtermEphemerides: 'common/longtermEphemerides',
    longtermVisualiseOnChart: 'common/longtermVisualiseOnChart',
    lastCalculatedEphemerides: 'common/lastCalculatedEphemerides',
    longtermCurrentPlot: 'common/longtermCurrentPlot',
    enableOptFiltering: 'common/enableOptFiltering',
    enableOptResults: 'common/enableOptResults',
    timezone: 'common/timezone',
    skychartEnableVisualisation: 'common/skychartEnableVisualisation',
    skychartObjectsShow: 'common/skychartObjectsShow',
    skychartMagNumber: 'common/skychartMagNumber',
    skychartActiveObjectName: 'common/skychartActiveObjectName',
    skychartSettingsPanelName: 'common/skychartSettingsPanelName',
    skychartSettings: 'common/skychartSettings',
    skychartLoadedObjects: 'common/skychartLoadedObjects',
    skychartTraceObjects: 'common/skychartTraceObjects',
    skychartExceedPathAngleObjects: 'common/skychartExceedPathAngleObjects',
    skychartTraceDate: 'common/skychartTraceDate',
    skychartObjectTracking: 'common/skychartObjectTracking',    
    skychartDmsFormat: 'common/skychartDmsFormat',
    skychartObjectsDetailsHide: 'common/skychartObjectsDetailsHide',
    orbitVisualisationObjectsShow: 'common/orbitVisualisationObjectsShow',
    isChecked: 'common/isChecked',
    isPerturbed: 'common/isPerturbed',
    selectedObjectName: 'common/selectedObjectName',
    loadingObjectsEphemerides: 'common/loadingObjectsEphemerides',
    riskListChecked: 'common/riskListChecked',
    orbitVisualisationSelects: 'common/orbitVisualisationSelects',
    orbitVisualisationActiveSelects: 'common/orbitVisualisationActiveSelects',
    orbitVisualisationNow: 'common/orbitVisualisationNow',
    simulationTime: 'common/simulationTime',
    orbitSettings: 'common/orbitSettings',
    orbitSettingsOVT: 'common/orbitSettingsOVT',
    orbitSettingsFVT: 'common/orbitSettingsFVT',
    orbitSettingsPanelName: 'common/orbitSettingsPanelName',
    keplerianData: 'common/keplerianData',
    perturbedData: 'common/perturbedData',
    orbitActiveObject: 'common/orbitActiveObject',
    orbitViewerFocusedOnObject: 'common/orbitViewerFocusedOnObject',
    orbitRecorderShow: 'common/orbitRecorderShow',
    orbitRecorderOnAir: 'common/orbitRecorderOnAir',
    mainBody: 'common/mainBody',
    syntheticMainBody: 'common/syntheticMainBody',
    closeApproachesRequest: 'common/closeApproachesRequest',
    closeApproaches: 'common/closeApproaches',
    closeApproachDetected:'common/closeApproachDetected',
    selectedCloseApproach: 'common/selectedCloseApproach',
    isNoApproachFoundMsgVisible: 'common/isNoApproachFoundMsgVisible',
    isSyntheticObjectFlybyAboveLdLimit: 'common/isSyntheticObjectFlybyAboveLdLimit',
    restoreFlybyTime: 'common/restoreFlybyTime',
    flybyTimeframe: 'common/flybyTimeframe',
    flybyClosestPoint: 'common/flybyClosestPoint',
    minCameraDistance: 'common/minCameraDistance',
    zoom: 'common/zoom',
    synodicOrbitChartSettings: 'common/synodicOrbitChartSettings',
    synodicOrbitChartsData: 'common/synodicOrbitChartsData',
    selectedSynodicObjectName: 'common/selectedSynodicObjectName',
    calculatedRealSynodicObjectsList: 'common/calculatedRealSynodicObjectsList',
    visibleRealSynodicObjectsList: 'common/visibleRealSynodicObjectsList',
    sovtFocusedObject: 'common/sovtFocusedObject',
    ovtFocusedObject: 'common/ovtFocusedObject',
    fvtFocusedObject: 'common/fvtFocusedObject',
    perturbedSynodicOrbitList: 'common/perturbedSynodicOrbitList',
    synodicActiveObject: 'common/synodicActiveObject',
    detectionPolarPoints: 'common/detectionPolarPoints',
    synodicSpeed: 'common/synodicSpeed',
    zoomOVT: 'common/zoomOVT',
    zoomFVT: 'common/zoomFVT',
    impactorsList: 'common/impactorsList',
}
