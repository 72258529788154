<template>
   <div class="sovt__input-row">
        <label class="sovt__input-label" :for="inputData.id">
            {{ $t[inputData.labelId] }} <span v-if="inputData.id === 'magnitudeLimit'" class="sovt__input-label--info">(V<sub>lim</sub>)</span>
            <template v-if="inputData.unitId">({{ $t[inputData.unitId] }})</template>
            <Tooltip v-if="inputData.tooltip" position="bottom" :class="{'tooltip--left': inputData.id === 'magnitudeLimit'}" :tooltipText="$keys.tooltips[inputData.tooltip]">
                <i class="icon-info" />
            </Tooltip>
        </label>
        <input
            v-if="inputData.type !== 'date'"
            class="sovt__input"
            :class="{
                'sovt__input--invalid':
                    show && inputData.emptyMsgId && !value && value !== 0 && selectedSynodicObjectName,
            }"
            :name="inputData.id"
            :id="inputData.id"
            :type="inputData.type"
            :step="setInputStep"
            :value="value"
            :min="setInputMin"
            :max="setInputMax"
            @input="handleInputChange"
        />
        <VueDatePicker
            v-else
            :model-value="value"
            @update:model-value="handleDateChange"
            :format="formatDate"
            :min-date="'1950-01-01'"
            :max-date="getMaxDate()"
            :clearable="false"
            :enable-time-picker="false"
            :year-range="[1950, getMaxDateYear()]"
            auto-apply
        />
        <span class="sovt__error-msg" v-if="inputData.emptyMsgId && !value && value !== 0 && selectedSynodicObjectName">
            {{ $t[inputData.emptyMsgId] }}
        </span>
    </div>
</template>

<script>
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import Tooltip from "@/components/common/Tooltip";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitObjectsCustomInput",
    components: {
        VueDatePicker,
        Tooltip,
    },
    props: {
        inputData: Object,
        value: [String, Number, Date],
    },
    computed: {
        isNumberInput() {
            return this.inputData.type === "number";
        },

        show() {
            return VisualisationService.getSelectObjectsShow();
        },

        selectedSynodicObjectName() {
            return VisualisationService.getSelectedSynodicObjectName();
        },

        setInputStep() {
            if (!this.isNumberInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { step } = validation;
            if (!step) {
                return 1;
            }
            return step;
        },

        setInputMin() {
            if (!this.isNumberInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { min } = validation;
            return min || min === 0 ? min : undefined;
        },

        setInputMax() {
            if (!this.isNumberInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { max } = validation;
            return max || max === 0 ? max : undefined;
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        getMaxDate() {
            const maxDate = UtilsService.createDateYearsFromNow(100);
            return UtilsService.dateToStringTechnical(maxDate);
        },
        getMaxDateYear() {
            const maxDate = new Date(this.getMaxDate());
            const maxYear = maxDate.getFullYear();
            return maxYear;
        },
        handleDateChange(value) {
            this.$emit("input", value);
        },
        validateNumber(value) {
            this.$emit("input", value);
        }
    },
};
</script>

<style lang="scss">
@import "@/styles/sovt/objects-custom-input.scss";
</style>
