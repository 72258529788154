<template>
    <div class="ephemerides-table d-table">
        <div class="d-table-row table-header">
            <div class="d-table-cell fixed-width no-print">
                <div class="column-title"><span>{{ $t.more }}</span></div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.object }}</span></div>
            </div>
            <div class="d-table-cell fixed-width no-print">
                <div class="column-title"><span>{{ $t.previousObservation }}</span></div>
            </div>
            <div class="d-table-cell fixed-width no-print">
                <div class="column-title"><span>{{ $t.database }}</span></div>
            </div>
            <!--  Configurable rest of the table: -->
            <div v-for="(field, index) of ephemeridesFiltered.fields" :key="index" class="d-table-cell" :data-print="displayTitle(field, index, false)">
                <EphemeridesTableHeader :columnId="field" :columnUnits="ephemeridesUnits(index)" />
            </div>
        </div>

        <div class="d-table-row table-contents print-only">
            <div class="d-table-cell no-print" />
            <div class="d-table-cell" :data-print="$t.designatorName"/>
            <div class="d-table-cell no-print" />
            <div class="d-table-cell no-print" />
            <div v-for="(field, index) of ephemeridesFiltered.fields" :key="index" class="d-table-cell" :data-print="displayTitle(field, index, true)" />
        </div>

        <template v-for="(object, index) of objects" :key="index">
            <div v-for="(datapoint, datapointIndex) of ephemeridesFiltered.objects[object.name]" :class="{lead: !datapointIndex}" :key="datapointIndex" class="d-table-row table-contents" @click="toggleEphemeridesActiveRow(object.name, datapointIndex)">
                <template v-if="isInTimeframe(datapoint)">
                    <div v-show="showRow(object.name, datapointIndex)" class="d-table-cell no-print">
                        <template v-if="datapointIndex === 0">
                            <i v-if="object.name === ephemeridesActiveRow" class="icon-minus-square" />
                            <i v-else class="icon-plus-square" />
                        </template>
                    </div>
                    <div v-show="showRow(object.name, datapointIndex)" class="d-table-cell" :data-print="displayName(object, true)">
                        <template v-if="datapointIndex === 0">
                            {{ displayName(object) }}
                        </template>
                    </div>
                    <div v-show="showRow(object.name, datapointIndex)" class="d-table-cell no-print">
                        <template v-if="datapointIndex === 0">
                            <a :href="getPreviousObservation(object)" :title="object.name" target="_blank">
                                <i class="icon-external-link" />
                            </a>
                        </template>
                    </div>
                    <div v-show="showRow(object.name, datapointIndex)" class="d-table-cell no-print">
                        <template v-if="datapointIndex === 0">
                            {{ object.sourceType ? ( object.sourceType.name === $t.neocc ? $t.pdo : object.sourceType.name ) : $t.nA }}
                        </template>
                    </div>
                    <!--  Configurable rest of the table: -->
                    <div v-show="showRow(object.name, datapointIndex)" v-for="(field, fieldIndex) of ephemeridesFiltered.fields" :key="fieldIndex" class="d-table-cell" :data-print="ephemeridesValue(object.name, datapointIndex, fieldIndex, field)">
                        <span v-html="ephemeridesValue(object.name, datapointIndex, fieldIndex, field)" />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import EphemeridesService from '@/services/ephemerides.service'
import FilteringService from '@/services/filtering.service'
import EphemeridesTableHeader from '@/components/opt/results/EphemeridesTableHeader'
import UtilsService from '@/services/utils.service'
import TimeFrameService from '@/services/timeframe.service'

export default {
    name: 'EphemeridesTable',
    components: {
        EphemeridesTableHeader,
    },
    computed: {
        objects() {
            return ObjectsService.getObjectsFilteredSelected();
        },
        ephemeridesFiltered() {
            return EphemeridesService.getEphemeridesFiltered();
        },
        ephemeridesActiveRow() {
            return EphemeridesService.getEphemeridesActiveRow();
        },
        ephemeridesColumns() {
            return EphemeridesService.getEphemeridesColumns();
        },
        ephemeridesUserDefinedColumns() {
            return EphemeridesService.getEphemeridesUserDefinedColumns();
        },
        filters() {
            return FilteringService.getFilters();
        },
        endJulianDate() {
           return TimeFrameService.getTimeFrame().converted.end; 
        }
    },
    methods: {
        isInTimeframe(datapoint) {
            return datapoint[0]*1 <= (this.endJulianDate + 1);
        },
        getPreviousObservation(object) {
            const designator = (object.number && object.number !== '' && object.number !== '0') ? `${object.number} ${object.name}` : object.name;
            return FilteringService.getPreviousObservationUrl(object) + encodeURIComponent(designator);
        },
        showRow(objectName, datapointIndex) {
            return datapointIndex === 0 || this.ephemeridesActiveRow === objectName;
        },
        toggleEphemeridesActiveRow(objectName, datapointIndex) {
            if (datapointIndex) return;
            if (this.ephemeridesActiveRow === objectName) {
                EphemeridesService.setEphemeridesActiveRow('');
            } else {
                EphemeridesService.setEphemeridesActiveRow(objectName);
            }
            
        },
        ephemeridesUnits(index) {
            return this.ephemeridesFiltered.units[index];
        },
        round(field, value) {
            if (!value) return false;
            let rounding = 1;
            if (field === 'AST_RA' || field === 'AST_HA' || field === 'AST_DEC' || field === 'DELTA' || field === 'R') {
                rounding = 6;
            } else if (field === 'APPMOT_RA' || field === 'APPMOT_DEC' || field === 'SKY_MOV') {
                rounding = 3;
            } else if (field === 'DAYS_UNOBSERVED' || field === 'ARC') {
                rounding = 0;
            }
            return (value*1).toFixed(rounding);
        },
        ephemeridesValue(name, row, index, field) {
            if (!this.ephemeridesFiltered.objects || !this.ephemeridesFiltered.objects[name]) return;
            if (field === 'JD_UTC') {
                const timestamp = UtilsService.julianToTimestamp(this.ephemeridesFiltered.objects[name][row][index]);
                return UtilsService.momentUtcDate(timestamp, true) || '-';
            } else {
                return this.round(field, this.ephemeridesFiltered.objects[name][row][index]) || '-';
            }
        },
        displayName(object, print) {
            let name = object.name;
            if (object.number) {    
                name = `(${object.number}) ${object.name}`;
            }
            if (print) {
                name = `"${name}"`;
            }
            return name;
        },
        displayTitle(field, index, native) {
            const unit = this.ephemeridesUnits(index);
            const unitShort = this.$t[unit] || unit;
            const fieldLong = native ? field : this.$keys.titles[field] || field;
            if (unitShort && unitShort !== '') {
                return `${fieldLong} (${unitShort})`;
            } else {
                return fieldLong;
            }
        },
    },
    mounted() {
        if (!EphemeridesService.getEphemeridesColumns().length) {
            EphemeridesService.setEphemeridesDefaultColumns();
        }
        EphemeridesService.setEphemeridesFiltered();
    }
}
</script>

<style>

</style>