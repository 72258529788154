<template>
    <div class="popup-overlay" :class="{show}">
        <div class="popup-wrapper" :class="{'popup-info': config.component === 'PopupInfo'}">
            <button class="button-icon close" @click="close()" v-if="config.component !== 'PopupObjectsConfiguration' && config.component !== 'PopupProgressBar' && config.component !== 'PopupSyntheticObjectConfiguration'">
                <i class="icon-x" />
            </button>
            <PopupInfo v-if="config.component === 'PopupInfo'" :config="config" @cancel="cancel($event)" @option="option($event)" />
            <PopupDialog v-if="config.component === 'PopupDialog'" :config="config" @cancel="cancel($event)" @submit="submit($event)" />
            <PopupObjectsConfiguration v-if="config.component === 'PopupObjectsConfiguration'" :config="config" @cancel="cancel($event)" @submit="submit($event)" />
            <PopupProgressBar v-if="config.component === 'PopupProgressBar'" :config="config" @cancel="cancel($event)" @submit="submit($event)" />
            <EphemeridesConfigPopup v-if="config.component === 'EphemeridesConfigPopup'" :config="config" @cancel="cancel($event)" @submit="submit($event)" />
            <PopupSyntheticObjectConfiguration v-if="config.component === 'PopupSyntheticObjectConfiguration'" :config="config" @cancel="cancel($event)" @submit="submit($event)"/>
            <PopupSyntheticObjectDelete v-if="config.component === 'PopupSyntheticObjectDelete'" :config="config" @cancel="cancel($event)" @submit="submit($event)"/>
        </div>
    </div>
</template>

<script>
import PopupService from '@/services/popup.service'
import PopupInfo from '@/components/common/PopupInfo'
import PopupDialog from '@/components/common/PopupDialog'
import PopupObjectsConfiguration from '@/components/common/PopupObjectsConfiguration'
import PopupProgressBar from '@/components/common/PopupProgressBar'
import EphemeridesConfigPopup from '@/components/opt/results/EphemeridesConfigPopup'
import PopupSyntheticObjectConfiguration from '@/components/common/PopupSyntheticObjectConfiguration'
import PopupSyntheticObjectDelete from '@/components/common/PopupSyntheticObjectDelete'

export default {
    name: 'CommonPopup',
    components: {
        PopupInfo,
        PopupDialog,
        PopupObjectsConfiguration,
        PopupSyntheticObjectConfiguration,
        PopupSyntheticObjectDelete,
        PopupProgressBar,
        EphemeridesConfigPopup,
    },
    computed: {
        show() {
            return PopupService.getPopupShow();
        },
        config() {
            return PopupService.getPopupConfig();
        }
    },
    methods: {
        close() {
            if (this.config.actions && this.config.actions.close && this.config.actions.close.emits) {
                this.$mitt.emit(this.config.actions.close.emits);
            }
            PopupService.hide();
        },
        cancel($event = null) {
            if ($event) {
                this.$mitt.emit($event);
            }
            PopupService.hide();
        },
        submit($event = null) {
            if ($event) {
                this.$mitt.emit($event);
            }
            PopupService.hide();
        },
        option($event = null) {
            if ($event) {
                this.$mitt.emit($event);
            }
            PopupService.hide();
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/common/popup.scss";
</style>