<template>
    <div class="plots-legend">
        <div class="plots-legend__twilight legend-element">
            <div class="legend-label wide-tooltip">
                <Tooltip position="bottom" :tooltipText="$keys.tooltips.astronomicalTwilight">
                    {{ $t.astronomicalTwilight }}
                </Tooltip>
            </div>
            <div class="altitude-box">
                <div class="altitude-box__value alt-0"></div>
            </div>
        </div>
        <div class="plots-legend__sunshine legend-element">
            <div class="legend-label">
                <Tooltip position="bottom" :tooltipText="$keys.tooltips.sunlightExplanation">
                    {{ $t.sunlight }}
                </Tooltip>
            </div>
            <div class="altitude-box">
                <div class="altitude-box__value alt-0 sunshine"></div>
            </div>
        </div>
        <div class="plots-legend__altitude legend-element">
            <div class="legend-label">
                <Tooltip position="bottom" :tooltipText="$keys.tooltips.objectAltitudeExplanation">
                    {{ $t.objectAltitude }}
                </Tooltip>
            </div>
            <div class="altitude-box with-label" v-for="box of boxes" :key="box.label">
                <div class="altitude-box__value" :class="box.class"></div>
                <div class="altitude-box__label">{{ box.label }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'ShortTermLegend',
    components: {
        Tooltip,
    },
    data() {
        return {
            boxes: [
                {class: 'alt-0', label: '<0°'},
                {class: 'alt-1', label: '0-5°'},
                {class: 'alt-2', label: '5-10°'},
                {class: 'alt-3', label: '10-15°'},
                {class: 'alt-4', label: '15-20°'},
                {class: 'alt-5', label: '20-30°'},
                {class: 'alt-6', label: '30-45°'},
                {class: 'alt-7', label: '45-60°'},
                {class: 'alt-8', label: '60-90°'},
            ]
        }
    }
}
</script>

<style>

</style>