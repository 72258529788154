<template>
    <canvas id="longtermChart" />
</template>

<script>
import * as ChartJs from 'chart.js'
import LongtermService from '@/services/longterm.service'
import UtilsService from '@/services/utils.service'

export default {
    name: 'LongTermPlotsChart',
    props: {
        object: Object
    },
    data() {
        return {
            chart: null,
            config: {
                type: 'line',
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    horizontalLine: [],
                    animation: {
                        duration: 500,
                    },
                    title: {
                        display: false,
                        text: 'World population per region (in millions)'
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            grid: {

                            },
                            ticks: {
                                callback: function() {
                                    return '';
                                }
                            },
                            min: 0,
                            max: 1,
                        },           
                        x: {
                            beginAtZero: true,
                            grid: {
                                color: '#222A32',
                            },
                            ticks: {
                                callback: function() {
                                    return '';
                                }
                            },
                            offset: true
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(ctx) {
                                    const index = ctx.dataIndex || 0;
                                    return ` ${ctx.dataset.label}: ${ctx.dataset.values[index]} ${ctx.dataset.units}`
                                }
                            },
                            padding: 10,
                            backgroundColor: 'rgba(61, 70, 89, 0.9)',
                            cornerRadius: 2,
                            titleFont: {
                                family: '"NotesEsaReg", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
                                style: 'normal',
                                size: 14,
                            },
                            bodyFont: {
                                family: '"NotesEsaReg", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
                                size: 14,
                            },
                            multiKeyBackground: 'rgba(61, 70, 89, 0.9)',
                            boxWidth: 10,
                            boxHeight: 1,
                        }
                    }
                },
            },
            colors: {},
            horizonalLinePlugin: null,
        }
    },
    computed: {
        longtermObjectName() {
            return LongtermService.getLongtermObjectName();
        },
        chartAdvancedFeatures() {
            return LongtermService.chartAdvancedFeatures();
        },
    },
    methods: {
        updateChart() {
            window['longtermChart'].update('resize');
        },
        prepareChartData() {
            this.config.data.datasets = [];
            this.config.options.horizontalLine = [];

            if (!this.horizonalLinePlugin) {
                this.horizonalLinePlugin = {
                    id: 'horizontalLine',
                    afterDatasetsDraw: chartInstance => {
                        const yScale = chartInstance.scales['y'];
                        const canvas = window['longtermChartCanvas'];
                        const ctx = window['longtermChartCtx'];
                        if (chartInstance.options.horizontalLine) {
                            for (let index = 0; index < chartInstance.options.horizontalLine.length; index++) {
                                const line = chartInstance.options.horizontalLine[index];
                                const color = line.color;
                                const yValue = yScale.getPixelForValue(line.value);
                                if (yValue) {
                                    ctx.setLineDash([10, 5]);
                                    ctx.beginPath();
                                    ctx.moveTo(0, yValue);
                                    ctx.lineTo(canvas.width, yValue);
                                    ctx.strokeStyle = color;
                                    ctx.stroke();
                                }
                                ctx.setLineDash([]);
                            }
                            return;
                        }
                    },
                }
            }

            if (!this.config.data.labels.length) {
                const timestampsReduced = UtilsService.reduceDensity(this.object.timestamps);
                for (const julianDate of timestampsReduced) {
                    const timestamp = UtilsService.julianToTimestamp(julianDate);
                    const date = UtilsService.momentUtcDate(timestamp, true);
                    this.config.data.labels.push(date);
                }
            }

            for (const plot in this.object.plots) {
                if (!this.object.plots[plot].enable) continue;
                const label = this.$keys.titles[plot];
                const color = this.colors[plot] || this.colors.default;
                const value = UtilsService.normalize(this.object.plots[plot].min, this.object.plots[plot].max)(this.object.plots[plot].value);
                const plotReducedValues = UtilsService.reduceDensity(this.object.plots[plot].values);
                const rounding = plot === 'SKY_MOV' ? 3 : 1;
                const valueRounded = plotReducedValues.map(e => e.toFixed(rounding));
                const values = [...valueRounded];
                const units = this.object.plots[plot].units === 'degrees' ? 'deg' : this.object.plots[plot].units;

                const data = values.map(UtilsService.normalize(this.object.plots[plot].min, this.object.plots[plot].max))

                let fill = false;

                if (this.chartAdvancedFeatures) {
                    const ctx = window['longtermChartCtx'];
                    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    fill = {
                        target: {
                            value,
                        },
                    }

                    if (this.object.plots[plot].operator === 'gt') {
                        gradient.addColorStop(0, UtilsService.hexToRgbA(color, 0.3));   
                        gradient.addColorStop(1, UtilsService.hexToRgbA(color, 0.1));
                        fill.above = gradient;
                        fill.below = 'transparent';
                    } else {
                        gradient.addColorStop(0, UtilsService.hexToRgbA(color, 0.1));   
                        gradient.addColorStop(1, UtilsService.hexToRgbA(color, 0.3));
                        fill.above = 'transparent';
                        fill.below = gradient;
                    }
                }

                this.config.options.horizontalLine.push({
                    value,
                    color,
                });

                this.config.data.datasets.push({
                    value,
                    values,
                    units,
                    label,
                    data,
                    borderColor: color,
                    fill,
                    tension: 0,
                });
            }
        }
    },
    watch: {
        object: {
            deep: true,
            handler() {
                this.prepareChartData();
                window['longtermChart'].data.datasets = this.config.data.datasets;
                this.updateChart();
            }
        }
    },
    mounted() {
        window['longtermChartCanvas'] = document.querySelector('#longtermChart');
        window['longtermChartCtx'] = window['longtermChartCanvas'].getContext('2d');

        this.colors = LongtermService.getLongtermColors();
        this.prepareChartData(true);
        
        ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));
        ChartJs.Chart.defaults.plugins.legend = false;
        ChartJs.Chart.register(this.horizonalLinePlugin);
    
        window['longtermChart'] = new ChartJs.Chart(window['longtermChartCanvas'], this.config);
    }
}
</script>

<style>

</style>
