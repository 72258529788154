<template>
    <button class="link" @click="resetAll()">
        <i class="icon-rotate-ccw" /><span>{{ $t.resetAll }}</span>
    </button>
</template>

<script>
import PopupService from '@/services/popup.service'

export default {
    name: 'ResetAll',
    methods: {
        resetAll() {
            PopupService.show({
                component: 'PopupDialog',
                type: 'info',
                message: this.$t.confirmReset,
                actions: {
                    submit: {
                        emits: 'reset-all'
                    }
                }
            });
        }
    }
}
</script>

<style>

</style>