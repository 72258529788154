<template>
    <div class="opt-results">
        <ResultsNavigation />
        <router-view />
    </div>
</template>

<script>
import ResultsNavigation from '@/components/opt/results/ResultsNavigation'

export default {
    name: 'ComponentsResults',
    components: {
        ResultsNavigation,
    }
}
</script>

<style lang="scss">
@import "@/styles/opt/results.scss";
</style>