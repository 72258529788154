<template>
    <div class="plots-header__wrapper">
        <div class="plots-header__up">
            <ShorttermDate />
            <ShorttermLegend />
        </div>
        <div class="plots-header__down">
            <ShorttermTimeline />
        </div>
    </div>
</template>

<script>
import ShorttermDate from '@/components/opt/results/ShorttermDate'
import ShorttermLegend from '@/components/opt/results/ShorttermLegend'
import ShorttermTimeline from '@/components/opt/results/ShorttermTimeline'

export default {
    name: 'ShortTermPlotsHeader',
    components: {
        ShorttermDate,
        ShorttermLegend,
        ShorttermTimeline,
    }
}
</script>

<style>

</style>