<template>
    <div class="scd__skychart" id="skychart" :class="{'with-panel': withPanel, 'inverted': settings.invertColors}">
        <slot />
    </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, watch, reactive, nextTick } from 'vue'
import SkychartService from '@/services/skychart.service'
import UtilsService from '@/services/utils.service'
import HUDService from '@/services/hud.service'
import MemoryService from '@/services/memory.service'

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'

export default defineComponent({
    name: 'SkyChartDisplay',
    emits: ['object-select', 'fov-change', 'print-request', 'view-legend'],
    props: {
        settings: Object,
        objects: Object,
        traceDate: String,
        activeObjectName: String,
        withPanel: Boolean,
    },
    setup(props, { emit }) {
        let container = null;
        let scene = new THREE.Scene();
        let camera = new THREE.PerspectiveCamera(props.settings.fov.value, window.innerWidth / window.innerHeight, 1, 2000);
        let sceneOrtho = new THREE.Scene();
        let cameraOrtho = new THREE.OrthographicCamera( window.innerWidth / -2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / -2, -1000, 1000 );
        let raycaster = new THREE.Raycaster();
        let mouse = new THREE.Vector2();
        let renderer = new THREE.WebGLRenderer({ antialias: true });
        let labelRenderer = new CSS2DRenderer();
        let controls = new OrbitControls(camera, renderer.domElement);
        let animationId = null;
        let printRequest = false;
        let activeObject = null;  /* eslint-disable-line */
        let viewLegend = reactive({
            fov: '0° x 0°',
            ra: 0,
            dec: 0,
            azimuth: 0,
            altitude: 0,
        })

        function init() {
            window['scd'] = {
                scene,
                sceneOrtho,
                camera,
                cameraOrtho,
                renderer,
                controls,
                spaceObjects: [],
                zoomObjects: [],
            }
            let zoomDelay;
            
            container = document.querySelector('#skychart');
            const width = container.clientWidth;
            const height = container.clientHeight;

            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        
            cameraOrtho.left = width / -2;
            cameraOrtho.right = width / 2;
            cameraOrtho.top = height / 2;
            cameraOrtho.bottom = height / -2;
            cameraOrtho.updateProjectionMatrix();

			renderer.setSize(width, height);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.toneMappingExposure = 0.5;
            renderer.autoClear = false;
			container.prepend(renderer.domElement);

            labelRenderer.setSize(width, height);
            labelRenderer.domElement.style.position = 'absolute';
            labelRenderer.domElement.style.top = '0px';
            labelRenderer.domElement.style.pointerEvents = 'none';
            SkychartService.crosshairToggle(labelRenderer.domElement);
            labelRenderer.domElement.style.zIndex = 0;
            renderer.domElement.parentNode.insertBefore(labelRenderer.domElement, renderer.domElement.nextSibling);

            camera.position.set(0, 10, 50);
            controls.rotateSpeed = -0.25;
            controls.enableZoom = false;
            controls.enablePan = false;
            controls.target = new THREE.Vector3(0, 0, 0);

            SkychartService.setCameraTarget(controls, 10, 0);
    
            renderer.render(scene, camera);
            renderer.render(sceneOrtho, cameraOrtho);
            
            SkychartService.initMilkyWay(scene, props.settings.displaySettings.milkyWayBackground.value);
            const { sunAlt } = SkychartService.initAtmosphere(scene, props.settings.displaySettings.atmosphere.value);
            SkychartService.initLandscape(scene, props.settings.displaySettings.landscape.value, sunAlt);
            SkychartService.initCompass(scene, props.settings.displaySettings.compass.value);
            SkychartService.initGrids(scene, props.settings.displaySettings);
            SkychartService.initBackgroundObjects(scene, props.settings.mag.value);
            SkychartService.initTraceObjects(scene, props.objects, camera);
            SkychartService.initConstellations(scene, props.settings.displaySettings.constellations.value);
            SkychartService.initRegions(scene, props.settings.displaySettings.regions.value);
            SkychartService.initDsos(scene, props.settings.displaySettings.dsos.value);            
           
            HUDService.initWatermarks(sceneOrtho);
            toggleLabelsVisibility();
            
            animate();

            container.addEventListener('click', onClick, false);
            container.addEventListener('mousemove', onMouseMove, false);
            container.addEventListener('dblclick', onDblClick, false);
            
            controls.addEventListener('change', () => {
                const heightHalf = Math.PI / 2;
                const angle = controls.getPolarAngle();
                if (scene.getObjectByName('Landscape')) {
                    const landscape = scene.getObjectByName('Landscape');
                    if (angle < heightHalf) {
                        const ratio = angle / heightHalf;
                        const opacity = Math.pow(ratio, 3);
                        landscape.material.opacity = opacity;
                    } else {
                        landscape.material.opacity = 1;
                    }
                }

                clearTimeout(zoomDelay);
                zoomDelay = setTimeout(() => {
                    SkychartService.initZoomObjects(scene, camera.fov, controls);
                }, 100);


                const width = container.clientWidth;
                const height = container.clientHeight;
                const aspect = width / height;
                const horizontalFov = (camera.getEffectiveFOV() * aspect).toFixed(1);
                const verticalFov = camera.getEffectiveFOV().toFixed(1);

                viewLegend.date = props.traceDate;
                viewLegend.fov = `${horizontalFov}° x ${verticalFov}°`;
                
                const [ra, dec, az, alt] = SkychartService.zoomRaDec(controls);
                viewLegend.ra = ra;
                viewLegend.dec = dec;
                viewLegend.azimuth = az;
                viewLegend.altitude = alt;
                
                const displaySettings = props.settings.displaySettings;
                const gridNames = ["horizontalGrid", "equatorialGrid", "eclipticGrid"];

                for (const idx in gridNames) {
                    const gridName = gridNames[idx];
                    if (displaySettings[`${gridName}`].value){  
                        const grid = scene.getObjectByName(gridName);
                        SkychartService.findGridScreenIntersections(grid);
                    }
                }
                SkychartService.updateGrids(scene, displaySettings,"");

            }, false);

            window['scd'].controls = controls;
            const displaySettings = props.settings.displaySettings;

            watch(() =>SkychartService.getTraceDate(), () => {                  
                SkychartService.updateGrids(scene, displaySettings, "", false, true);
                }, { deep: true }
            );

            watch(() => props.settings.displaySettings, (displaySettings) => {
                    if (scene.getObjectByName('Landscape')) {
                        scene.getObjectByName('Landscape').visible = displaySettings.landscape.value;
                    }
                    if (scene.getObjectByName('horizontalGrid')) {
                        scene.getObjectByName('horizontalGrid').visible = displaySettings.horizontalGrid.value;
                        SkychartService.updateGrids(scene, displaySettings,'horizontalGrid', true);
                    }
                    if (scene.getObjectByName('equatorialGrid')) {
                        scene.getObjectByName('equatorialGrid').visible = displaySettings.equatorialGrid.value;
                        SkychartService.updateGrids(scene, displaySettings,'equatorialGrid', true);
                    }
                    if (scene.getObjectByName('eclipticGrid')) {
                        scene.getObjectByName('eclipticGrid').visible = displaySettings.eclipticGrid.value;
                        SkychartService.updateGrids(scene, displaySettings,'eclipticGrid', true);
                    }
                    if (scene.getObjectByName('Atmosphere')) {
                        scene.getObjectByName('Atmosphere').visible = displaySettings.atmosphere.value;
                    }
                    if (scene.getObjectByName('Compass')) {
                        scene.getObjectByName('Compass').visible = displaySettings.compass.value;
                    }
                }, { deep: true }
            );           

            watch(() => props.settings.displaySettings.milkyWayBackground.value, visible => {                    
                    if (scene.getObjectByName('MilkyWay')) {
                        scene.getObjectByName('MilkyWay').visible = visible;
                    }
                }, { deep: true }
            );

            watch(() => props.settings.displaySettings.constellations.value, visible => {                    
                    if (scene.getObjectByName('Constellations')) {
                        scene.getObjectByName('Constellations').visible = visible;
                        visible ? camera.layers.enable(3) : camera.layers.disable(3);
                    }
                }, { deep: true }
            );

            watch(() => props.settings.displaySettings.regions.value, visible => {
                    if (scene.getObjectByName('Regions')) {
                        scene.getObjectByName('Regions').visible = visible;
                    }
                }, { deep: true }
            );

            watch(() => props.settings.displaySettings.dsos.value, visible => {
                    if (scene.getObjectByName('Dsos')) {
                        scene.getObjectByName('Dsos').visible = visible;
                        visible ? camera.layers.enable(4) : camera.layers.disable(4);
                    }
                }, { deep: true }
            );

            watch(() => props.settings.printRequest, (value) => {
                    printRequest = value;
                }
            );

            watch(() => props.settings.fov.value, (fov) => {
                    camera.fov = fov;
                    controls.rotateSpeed = -0.5 / (90 / fov);
                    SkychartService.scaleTrailPoints(scene, props.objects, fov);
                    camera.updateProjectionMatrix();
                }
            );

            watch(() => props.traceDate, () => {
                    SkychartService.moveObjects(scene);
                    activeObject = getActiveObject(props.activeObjectName);
                    const labels = labelRenderer.domElement.querySelectorAll('.skychart-label__time-value');
                    const traceDateTimestamp = UtilsService.julianToTimestamp(props.traceDate * 1);
                    const traceDateJulian = UtilsService.momentUtcDate(traceDateTimestamp, true);
                    for (const label of labels) {
                        if (label.innerText === traceDateJulian) {
                            label.classList.add('active');
                        } else {
                            label.classList.remove('active')
                        }
                    }
                }
            );

            watch(() => props.activeObjectName, activeObjectName => {
                    activeObject = getActiveObject(activeObjectName, true);
                }
            );

            watch(() => props.settings.displaySettings.crosshair.value, () => {
                    SkychartService.crosshairToggle(labelRenderer.domElement);
                }
            );

            watch(() => props.settings.invertColors, () => {
                    SkychartService.crosshairToggle(labelRenderer.domElement);
                }
            );

            watch(
                () => [
                    props.settings.displaySettings.starlight.value,                    
                    props.settings.displaySettings.objectTrajectories.color,                    
                    props.settings.displaySettings.trailPointSymbol.color,
                    props.settings.displaySettings.trailPointSymbol.value,
                    props.settings.displaySettings.trailPointSize.value
                ],
                () => {
                    const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {
                        SkychartService.buildTraceObject(scene, traceObjects[name], name, camera.fov);
                    }
                    SkychartService.moveObjects(scene);  
                }
            );

            watch(() => props.settings.displaySettings.objectNames.color, value => {                                        
                    const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {             
                         SkychartService.updateObjectNamesColor(scene, name, value);                    
                    }
                },
            );

           watch(() => props.settings.displaySettings.objectNamesSize.value, value => {                 
                    const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {             
                         SkychartService.updateObjectNamesSize(scene, name, value);                    
                    }                    
                }
            );

            watch(() => props.settings.displaySettings.objectNames.value, visible => {
                    visible ? camera.layers.enable(1) : camera.layers.disable(1);
                }, { deep: true },
            );

            watch(() => props.settings.displaySettings.trackDescription.value, visible => {
                    visible ? camera.layers.enable(2) : camera.layers.disable(2);
                },
            );

            watch(() => props.settings.displaySettings.uncertaintyRegion.value, visible => {     
                    const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {               
                        SkychartService.toggleUncertaintyRegion(scene, name, visible);                    
                    }                     
                },
            );
            watch(() => props.settings.displaySettings.uncertaintyRegion.color, value => {                                        
                    const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {             
                         SkychartService.updateUncertaintyColor(scene, name, value);                    
                    }
                },
            );            
            watch(() => props.settings.displaySettings.uncertaintyDensity.value, value => {                   
                const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {                             
                        SkychartService.updateUncertaintyDensity(scene, name, value);                    
                    }
                },
            );
            watch(() => props.settings.displaySettings.uncertaintyFactor.value, value => {                                        
                    const traceObjects = SkychartService.getTraceObjects();
                    for (const name in traceObjects) {             
                         SkychartService.updateUncertaintyFactor(scene, name, value);                    
                    }
                },
            );


            function toggleLabelsVisibility() {
                props.settings.displaySettings.objectNames.value ? camera.layers.enable(1) : camera.layers.disable(1);
                props.settings.displaySettings.trackDescription.value ? camera.layers.enable(2) : camera.layers.disable(2);
                props.settings.displaySettings.constellations.value ? camera.layers.enable(3) : camera.layers.disable(3);
                props.settings.displaySettings.dsos.value ? camera.layers.enable(4) : camera.layers.disable(4);
            }

            function updateSkychartLabels() {
                scene.traverse(child => {
                    if (child.name.indexOf('SkychartLabel_') === 0) {
                        child.lookAt(camera.position);
                        child.fontSize = child.defaultFontSize * camera.fov * 0.02;
                        if (child.name === 'SkychartLabel_trackDescription') {
                            const deg2rad = Math.PI/180;
                            child.rotateZ(45 * deg2rad);
                        }
                    }
                });
            }

            function getActiveObject(activeObjectName, forceShow = false) {
                const COLOR_SELECTED = '0x009fff'; //blue (selected color)
                const pointColor = '0x' + props.settings.displaySettings.trailPointSymbol.color.slice(1);                

                if (activeObjectName !== '' && scene.getObjectByName(activeObjectName)) {

                    let object = null;
                    scene.traverse(function(child) {
                        if (child.name === activeObjectName) {
                            //child.material.color.setHex('0xFFFFFF'); // white
                            child.material.color.setHex(pointColor); 
                            if (child.objectInfo[4] === props.traceDate) {
                                object = child;
                                return;
                            }
                        }
                    });

                    if (object) {
                        object.material.color.setHex(COLOR_SELECTED); 
                    }                    

                    const objectTracking = SkychartService.getObjectTracking();

                    if ((!object || !objectTracking) && !forceShow) return object;

                    SkychartService.setCameraTarget(controls, object.skyCoordinates.alt, object.skyCoordinates.az, object.skyCoordinates.ra, object.skyCoordinates.dec);
                    return object;
                } else {
                    return null;
                }
            }

            function render() {
                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(scene.children, true);
                for (const intersected of intersects) {
                    if (intersected.object.name === 'EquatorialGrid') {
                        break;
                    }
                }
                
            }

            function animate() {
                if (renderer) {
                    renderer.clear();
                    requestAnimationFrame(render);
                }
                if (animate) {
                    animationId = requestAnimationFrame(animate);
                }

                HUDService.updateWatermarks(sceneOrtho, container);

                updateSkychartLabels();

                renderer.render(scene, camera);
                renderer.render(sceneOrtho, cameraOrtho);
                labelRenderer.render(scene, camera);

                const width = container.clientWidth;
                const height = container.clientHeight;
                const aspect = width / height;
                const horizontalFov = (camera.getEffectiveFOV() * aspect).toFixed(1);
                const verticalFov = camera.getEffectiveFOV().toFixed(1);

                viewLegend.date = props.traceDate;
                viewLegend.fov = `${horizontalFov}° x ${verticalFov}°`;
                
                const [ra, dec, az, alt] = SkychartService.zoomRaDec(controls);
                viewLegend.ra = ra;
                viewLegend.dec = dec;
                viewLegend.azimuth = az;
                viewLegend.altitude = alt;

                emit('view-legend', viewLegend);

                if (printRequest) {
                    SkychartService.export(printRequest, props.settings.invertColors)
                    emit('print-request', false);
                }
            }

            function zoom(e) {
                e.preventDefault();
                let fov = props.settings.fov.value;
                let diff = 3;
                let newFov = fov;
                if (fov <= 2) {
                    diff = 0.1;
                } else if (fov <= 3) {
                    diff = 0.5;
                } else if (fov <= 5) {
                    diff = 1;
                }
                
                if (e.deltaY < 0) {
                    if (props.settings.fov.value >= props.settings.fov.min) {
                        newFov = fov - diff;
                        emit('fov-change', newFov);
                    }
                } else {
                    if (props.settings.fov.value <= props.settings.fov.max) {
                        newFov = fov + diff;
                        emit('fov-change', newFov);
                    }
                }
                if (newFov && newFov !== fov) {
                    clearTimeout(zoomDelay);
                    zoomDelay = setTimeout(() => {
                        SkychartService.initZoomObjects(scene, newFov, controls);
                    }, 100);
                }
            }

            container.querySelector('canvas').onwheel = zoom;

            function onMouseMove(event) {
                const panelOffset = props.withPanel ? 300 : 0;
                mouse.x = ((event.clientX - panelOffset) / (window.innerWidth - panelOffset)) * 2 - 1;
                mouse.y = -((event.clientY - 96) / (window.innerHeight - 120)) * 2 + 1;
            }

            function onClick(event) {
                const panelOffset = props.withPanel ? 260 : 0;
                mouse.x = ((event.clientX - 40 - panelOffset) / (window.innerWidth - 40 - panelOffset - 75)) * 2 - 1;
                mouse.y = -((event.clientY - 96) / (window.innerHeight - 120)) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(scene.children, true);
                let objectSelected = false;
                for (const intersected of intersects) {
                    if (intersected.object.objectInfo) {
                        emit('object-select', intersected.object);
                        objectSelected = true;
                        break;
                    }
                }
                if (!objectSelected) {
                    emit('object-select', null);
                }
            }

            function onDblClick() {
                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(scene.children, true);
                const {x, y, z} = intersects[0].point;
                const pointerSphericalCoordinates = new THREE.Spherical(0, 0, 0).setFromCartesianCoords(x, y, z);
                const alt = 90 - THREE.MathUtils.radToDeg(pointerSphericalCoordinates.phi);
                const az = - THREE.MathUtils.radToDeg(pointerSphericalCoordinates.theta);
                SkychartService.setCameraTarget(controls, alt, az);
            }

            function onWindowResize(){  
                const width = container.clientWidth;
                const height = container.clientHeight;

                camera.aspect = width / height;
                camera.updateProjectionMatrix();

                cameraOrtho.left = width / -2;
                cameraOrtho.right = width / 2;
                cameraOrtho.top = height / 2;
                cameraOrtho.bottom = height / -2;
                cameraOrtho.updateProjectionMatrix();

                renderer.setSize(width, height);
                labelRenderer.setSize(width, height);
            }

            window.addEventListener('resize', onWindowResize, false);
		}

        onBeforeUnmount(() => {
            const stateObj = {...MemoryService.getToolState()};
            MemoryService.saveStateOnSwitch(stateObj);
            cancelAnimationFrame(animationId);
            while (container.lastChild)
                container.removeChild(container.lastChild);
        });

        onMounted(async () => {
            await nextTick();
            const loadedState = {...MemoryService.getToolState()};  

            setTimeout(() => {
                
                init();
                const vt = window['scd'];
                let camera = vt.camera;

                if (loadedState && loadedState.SCDcamera && loadedState.tool === 'scd'){

                    camera.position.x = loadedState.SCDcamera.pos.x;
                    camera.position.y = loadedState.SCDcamera.pos.y;
                    camera.position.z = loadedState.SCDcamera.pos.z;

                    camera.rotation.x = loadedState.SCDcamera.rot.x;
                    camera.rotation.y = loadedState.SCDcamera.rot.y;
                    camera.rotation.z = loadedState.SCDcamera.rot.z;

                    camera.quaternion.w = loadedState.SCDcamera.quat.w;
                    camera.quaternion.x = loadedState.SCDcamera.quat.x;
                    camera.quaternion.y = loadedState.SCDcamera.quat.y;
                    camera.quaternion.z = loadedState.SCDcamera.quat.z;

                }
            }, 100);
        });

        return {
            viewLegend
        }
    },
});
</script>

<style>

</style>
