<template>
    <span v-if="!outOfShortTerm" class="info-label">{{ $t.preliminaryData }} <Tooltip position="right" :tooltipText="$t.preliminaryDataTooltip"><i class="icon-info" /></Tooltip></span>
    <span class="info-label">{{ $t.totalFound }}: {{ objectsTotal }}<span v-if="objectsTotal == 1000">+</span></span>
    <span class="info-label">{{ $t.totalSelected }}: {{ objectsSelected }}</span>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import Tooltip from '@/components/common/Tooltip'
import TimeFrameService from '@/services/timeframe.service'
import PopupService from '@/services/popup.service'
import FilteringService from '@/services/filtering.service'

export default {
    name: 'ObjectsSummary',
    components: {
        Tooltip
    },
    computed: {
        objectsTotal() {
            return ObjectsService.getObjects().total || 0;
        },
        objectsSelected() {
            const maxObjectLimit = FilteringService.getInitialLoadSize(); 
            if (ObjectsService.getObjectsSelected().length > maxObjectLimit) {
                const message = "Too many elements, only the first " + maxObjectLimit.toString() + " will be considered.";
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'warning',
                    message,
                });
            }
            return ObjectsService.getObjectsSelected().length || 0;
        },
        outOfShortTerm() {
            return TimeFrameService.getTimeFrame().outOfShortTerm;
        },
    }
}
</script>

<style>

</style>