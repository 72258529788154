<template>
    <div class="longterm-table d-table">
        <div class="d-table-row table-header">
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.object }}</span></div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.previousObservation }}</span></div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.database }}</span></div>
            </div>
        </div>

        <div v-for="(object, index) of objects" :key="index" class="d-table-row table-contents" :class="{active: longtermObjectName === object.name}" @click="setLongtermObject(object)"> 
            <div class="d-table-cell">
                {{ displayName(object) }}
            </div>
            <div class="d-table-cell">
                <a :href="getPreviousObservation(object)" :title="object.name" target="_blank">
                    <i class="icon-external-link" />
                </a>
            </div>
            <div class="d-table-cell">
                {{ object.sourceType ? ( object.sourceType.name === $t.neocc ? $t.pdo : object.sourceType.name ) : $t.nA }}
            </div>
        </div>
    </div>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import FilteringService from '@/services/filtering.service'
import LongtermService from '@/services/longterm.service'
import PopupService from '@/services/popup.service'

export default {
    name: 'ResultsLongTermTable',
    computed: {
        objects() {
            return ObjectsService.getObjectsFilteredSelected();
        },
        longtermObjectName() {
            return LongtermService.getLongtermObjectName();
        },
    },
    methods: {
        getPreviousObservation(object) {
            const designator = (object.number && object.number !== '' && object.number !== '0') ? `${object.number} ${object.name}` : object.name;
            return FilteringService.getPreviousObservationUrl(object) + encodeURIComponent(designator);
        },
        displayName(object) {
            let name = object.name;
            if (object.number) {    
                name = `(${object.number}) ${object.name}`;
            }
            return name;
        },
        setLongtermObject(object) {
            LongtermService.setLongtermObjectName(object.name);
            this.impactorMessage(object);
        },
        impactorMessage(object) {
            if (object.impacted) {
                let message = `Object ${object.name} impacted on ${object.impact_date}.`;
                if (object.error) {
                    message = object.error;
                }

                PopupService.show({
                    component: 'PopupInfo',
                    type: 'warning',
                    message
                });
            }
        }
    },
    mounted() {
        if (this.longtermObjectName === '' || !this.objects.filter(e => e.name === this.longtermObjectName).length) {
            LongtermService.setLongtermObjectName(this.objects[0].name);
            this.impactorMessage(this.objects[0]);
        }
    }
}
</script>

<style>

</style>