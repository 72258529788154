<template>
    <div class="popup-content">
        <i class="icon-alert-triangle popup-icon warning" />

        <div v-html="config.message" />

        <div class="popup-footer">
            <button class="cancel" @click="cancel()">{{ getCancelTxt() }}</button>
            <button class="primary" @click="submit()">{{ getSubmitTxt() }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupDialog',
    props: {
        config: {}
    },
    data() {
        return {
            cancelAction: this.config.actions && this.config.actions.cancel ? this.config.actions.cancel : null,
            submitAction: this.config.actions && this.config.actions.submit ? this.config.actions.submit : null,
        }
    },
    methods: {
        getCancelTxt() {
            if (this.cancelAction && this.cancelAction.text) {
                return this.cancelAction.text;
            } else {
                return this.$t.cancel;
            }
        },
        getSubmitTxt() {
            if (this.submitAction && this.submitAction.text) {
                return this.submitAction.text;
            } else {
                return this.$t.ok;
            }
        },
        cancel() {
            if (this.cancelAction && this.cancelAction.emits) {
                this.$emit('cancel', this.cancelAction.emits);
            } else {
                this.$emit('cancel');
            }
        },
        submit() {
            if (this.submitAction && this.submitAction.emits) {
                this.$emit('submit', this.submitAction.emits);
            } else {
                this.$emit('submit');
            }
        }
    }
}
</script>

<style>

</style>