import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Config from '@/constants/config'
import EphemeridesService from '@/services/ephemerides.service'
import TimeFrameService from '@/services/timeframe.service'
import UtilsService from '@/services/utils.service'

const LongtermService = {
    longTermUrl() {
        return Config.api.restUrl + Config.api.longTermUrl;
    },

    getDefaultLongtermPlots() {
        return Config.longtermPlots.defaultPlots;
    },

    getLongtermColors() {
        return Config.longtermPlots.colors;
    },

    getDefaultLongtermPlotsKeys() {
        return Object.keys(Config.longtermPlots.defaultPlots);
    },

    getTimestampDensity() {
        return Config.longtermPlots.timestampDensity;
    },

    getPlotsOperators() {
        return Config.longtermPlots.operators;
    },

    chartAdvancedFeatures() {
        return Config.longtermPlots.chartAdvancedFeatures;
    },

    getLongtermObjectName() {
        return store.getters[CommonGetters.longtermObjectName];
    },

    setLongtermObjectName(name) {
        store.dispatch(CommonActions.setLongtermObjectName, name);
    },

    getLongtermActivePlots() {
        return store.getters[CommonGetters.longtermActivePlots];
    },

    setLongtermActivePlots(array) {
        store.dispatch(CommonActions.setLongtermActivePlots, array);
    },

    getLongtermObservablePlots() {
        return store.getters[CommonGetters.longtermObservablePlots];
    },

    setLongtermObservablePlots(array) {
        store.dispatch(CommonActions.setLongtermObservablePlots, array);
    },

    getLongtermVisualiseOnChart() {
        return store.getters[CommonGetters.longtermVisualiseOnChart];
    },

    setLongtermVisualiseOnChart(boolean) {
        store.dispatch(CommonActions.setLongtermVisualiseOnChart, boolean);
    },

    getLongtermCurrentPlot() {
        return store.getters[CommonGetters.longtermCurrentPlot];
    },

    setLongtermCurrentPlot(value) {
        store.dispatch(CommonActions.setLongtermCurrentPlot, value);
    },

    addActivePlot(plot) {
        const plots = [...LongtermService.getLongtermActivePlots()];
        if (!plots.includes(plot)) {
            plots.push(plot);
        }
        LongtermService.setLongtermActivePlots(plots);
    },

    removeActivePlot(plot) {
        let plots = [...LongtermService.getLongtermActivePlots()];
        if (plots.includes(plot)) {
            plots = plots.filter(e => e !== plot);
        }
        LongtermService.setLongtermActivePlots(plots);
    },

    getLongtermEphemerides(name = null) {
        if (name) {
            return store.getters[CommonGetters.longtermEphemerides][name];
        } else {
            return store.getters[CommonGetters.longtermEphemerides];
        }
    },

    setLongtermEphemerides(object) {
        store.dispatch(CommonActions.setLongtermEphemerides, object);
    },

    buildLongtermEphemerides() {
        const ephemerides = EphemeridesService.getEphemerides();
        const endJulianDate = TimeFrameService.getTimeFrame().converted.end + 1;

        if (ephemerides && ephemerides.objects && ephemerides.fields) {
            const ephemeridesObject = {};
            const plots = LongtermService.getDefaultLongtermPlotsKeys() || [];

            for (const name in ephemerides.objects) {
                ephemeridesObject[name] = {};
                ephemeridesObject[name].plots = {};

                for (const plot of plots) {
                    const rounding = plot === 'SKY_MOV' ? 3 : 1;
                    const plotIndex = ephemerides.fields.indexOf(plot);
                    const values = [];
                    const timestamps = [];
                    
                    for (const timestamp of ephemerides.objects[name]) {
                        if (timestamp[0]*1 < endJulianDate) {
                            values.push(timestamp[plotIndex]);
                            timestamps.push(timestamp[0]);
                        }
                    }

                    const x = values.filter(e => e !== null);

                    let min = (Math.min.apply(Math, x)).toFixed(rounding)*1;
                    let max = (Math.max.apply(Math, x)).toFixed(rounding)*1;

                    if (min === Infinity || max === -Infinity) {
                        min = 0;
                        max = 0;
                    }

                    const value = (min + ((max - min) / 2)).toFixed(rounding)*1;
                    const units = ephemerides.units[plotIndex];

                    ephemeridesObject[name].timestamps = timestamps;
                    ephemeridesObject[name].observableAll = [];
                    ephemeridesObject[name].plots[plot] = {
                        enable: true,
                        min,
                        max,
                        value,
                        values,
                        operator: LongtermService.getPlotsOperators()[plot],
                        observable: [],
                        units,
                    };
                }
            }

            LongtermService.setLongtermEphemerides(ephemeridesObject);
        }
    },

    updateObservable(object, currentPlot) {
        let plots = [];
        if (currentPlot) {
            plots = [currentPlot];
        } else {
            plots = Object.keys(object.plots);
        }

        for (const plot of plots) {
            const rounding = plot === 'SKY_MOV' ? 3 : 1;
            const operator = object.plots[plot].operator;
            const currentValue = object.plots[plot].value.toFixed(rounding)*1;
            const observable = [];
            const valuesReduced = UtilsService.reduceDensity(object.plots[plot].values);

            for (const [index, val] of valuesReduced.entries()) {
                const value = val.toFixed(rounding)*1;
                if (operator === 'gt') {
                    if (value >= currentValue) {
                        if (observable.indexOf(index) === -1) observable.push(index);
                    } else {
                        if (observable.indexOf(index) > -1) observable.splice(observable.indexOf(index), 1);
                    }
                } else if (operator === 'lt') {
                    if (value <= currentValue) {
                        if (observable.indexOf(index) === -1) observable.push(index);
                    } else {
                        if (observable.indexOf(index) > -1) observable.splice(observable.indexOf(index), 1);
                    }
                } else if (!operator) {
                    if (value === currentValue) {
                        if (observable.indexOf(index) === -1) observable.push(index);
                    } else {
                        if (observable.indexOf(index) > -1) observable.splice(observable.indexOf(index), 1);
                    }
                }
            }

            object.plots[plot].observable = observable;
        }

        LongtermService.updateObservableAll(object);
    },

    updateObservableAll(object) {
        for (const plot in object.plots) {
            const enabledPlots = Object.keys(object.plots).map(e => object.plots[e].enable).filter(e => e === true).length;
            const observableAll = [];

            if (enabledPlots) {
                if (!object.plots[plot].enable) continue;
                const observableFirstRow = object.plots[plot].observable;
                for (const observableAllIndex of observableFirstRow) {
                    let observableInColumn = true;
                    if (enabledPlots === 1) {
                        observableAll.push(observableAllIndex);
                    } else {
                        for (const plotOther in object.plots) {
                            if (plotOther === plot || !object.plots[plotOther].enable) continue;
                            const observableOther = object.plots[plotOther].observable;
                            
                            if (observableOther.indexOf(observableAllIndex) === -1) {
                                observableInColumn = false;
                            }

                            if (observableInColumn) {
                                if (observableAll.indexOf(observableAllIndex) === -1) observableAll.push(observableAllIndex);
                            } else {
                                if (observableAll.indexOf(observableAllIndex) > -1) observableAll.splice(observableAll.indexOf(observableAllIndex), 1);
                            }
                        }
                    }
                }
            }

            object.observableAll = observableAll;
            break;
        }
    },
}

export default LongtermService;