<template>
    <slot />
</template>

<script>
import FilteringService from '@/services/filtering.service'
import ObjectsService from '@/services/objects.service'
import MemoryService from '@/services/memory.service'
import CalculateService from '@/services/calculate.service'
import router from '@/router'
import EphemeridesService from '@/services/ephemerides.service'
import Config from '@/constants/config'

export default {
    name: 'EventBus',
    methods: {
        init() {
            const href = window.location.href;
            const url = new URL(href);
            const toolStateId = url.searchParams.get('c');
            if (toolStateId) {
                MemoryService.loadToolState(toolStateId, url.pathname);
            }
        }
    },
    created() {
        this.init();

        this.$mitt.on('reset-all', () => {
            FilteringService.setFilters({});
            ObjectsService.setObjects([]);
            ObjectsService.selectNone();
            ObjectsService.setObjectsInitialLoadPerformed(false);
            FilteringService.getInitialObjectsList();
        });

        this.$mitt.on('clear-all-filters', () => {
            const filters = {};     
            //const filters = FilteringService.getFilters();       
            for (const filterId in Config.filters) {
                const filter = Config.filters[filterId];            
                if (filter.type === 'checkbox') {                                        
                    filters[filterId] = filter.options;                                      
                } 
            } 

            //FilteringService.setFilters({});
            FilteringService.setEmptyFilter(true);
            FilteringService.setFilters(filters);
            FilteringService.runFilteringRequest();
        });

        this.$mitt.on('confirm-setup-change', () => {
            MemoryService.saveSetupMemory();
            FilteringService.setFilters({});
            ObjectsService.setObjects([]);
            ObjectsService.setObjectsInitialLoadPerformed(false);
            EphemeridesService.setEphemerides({
                objects: {},
                fields: [],
                units: [],
            });
            EphemeridesService.setEphemeridesFiltered();
            router.push({
                name: 'opt-filtering'
            })
        });

        this.$mitt.on('cancel-setup-change', () => {
            MemoryService.restoreSetupMemory();
        });

        this.$mitt.on('load-state-from-cookies', () => {
            MemoryService.loadToolState();
        });

        this.$mitt.on('cancel-calculation', () => {
            CalculateService.cancelCalculation();
        });

        this.$mitt.on('resume-calculation', () => {
            CalculateService.runProgressBar();
        });

        this.$mitt.on('retry-calculation', () => {
            CalculateService.initCalculations();
        });

        this.$mitt.on('goto-results', () => {
            router.push({
                name: 'opt-results'
            })
        });

        this.$mitt.on('ignore-filtering-change', () => {
            router.push({
                name: 'opt-results',
                query: { ifc: 'true' }
            })
        });

        this.$mitt.on('confirm-filtering-change', () => {
            CalculateService.initCalculations();
        });

        this.$mitt.on('copy-share-link', () => {
            const link = document.querySelector('#share-link');
            if (link) {
                MemoryService.copyLinkToClipboard(link.getAttribute('href'));
            }
        });
    }
}
</script>

<style>

</style>
