<template>
    <div v-if="axisGuide" class="ovt__legend axes-viewer" :class="{'with-display-settings': settingsPanelName === 'objectsSettings',  inverted: invertColors}">
        <div class="axis x">
            <button @click="viewAxes('x')">x</button>
        </div>
        <div class="axis y">
            <button @click="viewAxes('y')">y</button>
        </div>
        <div class="axis z">
            <button @click="viewAxes('z')">z</button>
        </div>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'VisualisationAxesViewer',
    computed: {
        settings() {
            return VisualisationService.getSettings();
        },
        axisGuide() {
            return this.settings && this.settings.objectsSettings.axisGuide.value;
        },
        settingsPanelName() {
            return VisualisationService.getSettingsPanelName();
        },
        invertColors() {
            return VisualisationService.getInvertColors();
        },
    },
    methods: {
        viewAxes(view) {
            VisualisationService.viewAxes(view);
        }
    }
}
</script>

<style>

</style>
