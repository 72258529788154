<template>
    <div class="opt-results-longterm">
        <div class="opt-results-longterm__left">
            <LongtermTable />
        </div>
        <div class="opt-results-longterm__right">
            <LongtermPlots />
        </div>
    </div>
</template>

<script>
import LongtermTable from '@/components/opt/results/LongtermTable'
import LongtermPlots from '@/components/opt/results/LongtermPlots'

export default {
    name: 'ResultsLongTermPlots',
    components: {
        LongtermTable,
        LongtermPlots,
    }
}
</script>

<style lang="scss">
@import "@/styles/opt/results-longterm.scss";
</style>