<template>
    <div class="dropbox" :class="addClass()">
        <div class="dropbox-header">
            {{ title }}
            <button class="button-icon close" @click="close()">
                <i class="icon-x" />
            </button>
        </div>
        <div class="dropbox-content">
            <slot />
        </div>
        <div class="dropbox-footer" v-if="showButtons()">
            <button class="cancel" @click="close()">{{ $t.cancel }}</button>
            <button class="primary" @click="submit()">{{ $t.submit }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommonDropbox',
    emits: ['close', 'submit'],
    props: {
        title: String,
        config: {},
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.$emit('submit');
        },
        addClass() {
            return this.config && this.config.class ? this.config.class : '';
        },
        showButtons() {
            return !(this.config && this.config.hideButtons);
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/common/dropbox.scss";
</style>                           