<template>
    <div class="scd__legend" :class="{'with-panel': withPanel, 'with-details': !hide, 'without-telescope': !settings.displaySettings.telescopeParameters.value}">
        <div class="scd__legend--label">{{ $t.objectDetails }}</div>
        <div class="d-table">
            <div v-for="[index, value] of legend.entries()" :key="value" class="d-table-row">
                <div class="d-table-cell">{{ legendLabel(index) }}:</div>
                <div class="d-table-cell">{{ legendValue(index, value) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import UtilsService from '@/services/utils.service'
import SkychartService from '@/services/skychart.service';

export default {
    name: 'SkyChartLegend',
    props: {
        legend: Array,
        withPanel: Boolean,
    },
    computed: {
        hide() {
            return SkychartService.getObjectsDetailsHide();
        },
        settings() {
            return SkychartService.getSettings();
        },
    },
    methods: {
        legendLabel(index) {
            switch (index) {
                case 0:
                    return this.$t.name;
                case 1:
                    return this.$t.ra;
                case 2:
                    return this.$t.dec;
                case 3:
                    return this.$t.magLabel;
                case 4:
                    return this.$t.date;
                default:
                    break;
            }
        },
        legendValue(index, value) {
            if (index === 4) {
                const timestamp = UtilsService.julianToTimestamp(value * 1);
                return UtilsService.momentUtcDate(timestamp, true) || '-';
            } else {
                return value || '-';
            }
        }
    }
}
</script>

<style>

</style>