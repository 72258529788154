<template>
    <i class="icon-calendar" />
    <b>{{ start }} — {{ end }}</b>
</template>

<script>
import TimeFrameService from '@/services/timeframe.service'
import UtilsService from '@/services/utils.service'

export default {
    name: 'TimeFrameDisplay',
    data() {
        return {
            timeFrame: {},
            start: '',
            end: '',
        }
    },
    computed: {
        selectedTimeFrame() {
            return TimeFrameService.getTimeFrame();
        }
    },
    mounted() {
        this.timeFrame = this.selectedTimeFrame;
        if (!this.timeFrame.start && !this.timeFrame.end) {
            TimeFrameService.setCurrentDate();
        }
        this.start = UtilsService.dateToString(this.timeFrame.start);
        this.end = UtilsService.dateToString(this.timeFrame.end);
    }
}
</script>

<style>

</style>