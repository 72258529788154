<template>
    <ul class="opt-results-navigation">
        <li>
            <router-link  class="opt-results-navigation-item" :to="`${$keys.routes.opt}/${$keys.routes.optResults}/${$keys.routes.optLongTerm}`" :key="$route.path">{{ $t.longTermPlots }}</router-link>
        </li>
        <li>
            <router-link  class="opt-results-navigation-item" :to="`${$keys.routes.opt}/${$keys.routes.optResults}/${$keys.routes.optShortTerm}`" :key="$route.path">{{ $t.shortTermPlots }}</router-link>
        </li>
        <li>
            <router-link  class="opt-results-navigation-item" :to="`${$keys.routes.opt}/${$keys.routes.optResults}/${$keys.routes.optEphemerides}`" :key="$route.path">{{ $t.ephemerides }}</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'ResultsNavigation'
}
</script>

<style>

</style>