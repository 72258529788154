<template>
    <div class="scd__settings">
        <div class="scd__settings--section buttons">
            <SkychartSettingsButton v-for="option of buttonOptions" :key="option" :type="option" :settings="settings" @change="onChange($event)" @print="onPrint($event)" />
        </div>
        <div class="scd__settings--section magnitude">
            <span class="slider__title">
                {{ $t.mag }}
            </span>
            <span v-if="settings" class="magnitude__info" v-html="magValue" />
        </div>
        <div v-for="option of sliderOptions" :key="option" class="scd__settings--section slider">
            <SkychartSettingsSlider v-if="settings" :type="option" :settings="settings" />
        </div>
    </div>
</template>

<script>
import SkychartService from '@/services/skychart.service'
import SkychartSettingsButton from '@/components/scd/SkychartSettingsButton'
import SkychartSettingsSlider from '@/components/scd/SkychartSettingsSlider'

export default {
    name: 'SkyChartSettings',
    components: {
        SkychartSettingsButton,
        SkychartSettingsSlider,
    },
    emits: [
        'print-request'
    ],
    computed: {
        settings() {
            return SkychartService.getSettings();
        },
        buttonOptions() {
            return SkychartService.getSettingsOptions('button');
        },
        sliderOptions() {
            return SkychartService.getSettingsOptions('slider');
        },
        magValue() {
            return this.settings ? this.settings.mag.value : 0;
        }
    },
    methods: {
        onChange(event) {
            SkychartService.updateSetting(event);
        },
        onPrint(type) {
            this.$emit('print-request', type);
        }
    },
    mounted() {
        if (!this.settings) {
            SkychartService.setSettingsDefaults();
        }
    }
}
</script>

<style>

</style>