<template>
    <div
        v-if="orbitLegend && !onAir"
        class="ovt__legend perturbed sovt__orbit-legend"
        :class="{
            'with-config': configPanelOpen,
            'with-details': objectDetails && selectedCalculatedObject && activeObject,
        }"
    >
        <div class="ovt__legend--label">{{ $t.orbitLegend }}</div>
        <div class="d-table">
            <div class="d-table-row">
                <div class="d-table-cell">
                    <span
                        class="ovt_legend orbit-marker-sovt orbit-marker-sovt--selected-keplerian"
                        :style="activeKeplerianOrbitColor"
                    ></span>
                    <span
                        class="ovt_legend orbit-marker-sovt orbit-marker-sovt--keplerian"
                        :style="keplerianOrbitColor"
                    ></span>
                </div>
                <div class="d-table-cell">{{ $t.keplerian }} {{ $t.orbits }}</div>
                <Tooltip position="top" :tooltipText="$keys.tooltips.keplerianOrbit"><i class="icon-info" /></Tooltip>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">
                    <span
                        class="ovt_legend orbit-marker-sovt orbit-marker-sovt--selected-perturbed"
                        :style="activePerturbedOrbitColor"
                    ></span>
                    <span
                        class="ovt_legend orbit-marker-sovt orbit-marker-sovt--perturbed"
                        :style="perturbedOrbitColor"
                    ></span>
                </div>
                <div class="d-table-cell">{{ $t.perturbed }} {{ $t.orbits }}</div>
                <Tooltip position="top" :tooltipText="$keys.tooltips.perturbedOrbit"><i class="icon-info" /></Tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitLegend",
    components: {
        Tooltip,
    },
    computed: {
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },

        settings() {
            return VisualisationService.getSettings();
        },

        orbitLegend() {
            return this.settings && this.settings.objectsSettings.orbitLegend.value;
        },

        objectDetails() {
            return this.settings && this.settings.objectsSettings.objectDetails.value;
        },

        selectedCalculatedObject() {
            return SynodicCalculationService.getSelectedCalculatedObject();
        },

        activeObject() {
            return SynodicCalculationService.getSynodicActiveObject();
        },

        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        },

        activeKeplerianOrbitColor() {
            return {
                backgroundColor: this.settings.objectsSettings.keplerianOrbits.color,
            };
        },

        keplerianOrbitColor() {
            return {
                backgroundColor: UtilsService.applyOpacityToHexColor(
                    this.settings.objectsSettings.keplerianOrbits.color,
                    0.4
                ),
            };
        },

        activePerturbedOrbitColor() {
            return {
                backgroundColor: this.settings.objectsSettings.perturbedOrbits.color,
            };
        },

        perturbedOrbitColor() {
            return {
                backgroundColor: UtilsService.applyOpacityToHexColor(
                    this.settings.objectsSettings.perturbedOrbits.color,
                    0.4
                ),
            };
        },
    },
};
</script>

<style></style>
