<template>
    <div class="altitude-wrapper">
        <div class="sunshine for-dusk" :style="getDuskStyle()"></div>
        <div class="sunshine for-dawn" :style="getDawnStyle()"></div>
        <div class="altitude-box" v-for="(altitude, index) of shorttermData.objectAltitudes" :key="index" :style="`max-width: ${timestep.converted * 100}%;`">
            <div class="altitude-box__value" :class="objectAltitudeClass(altitude, index)" :data-altitude="altitude"></div>
        </div>
    </div>
    <div class="altitude-wrapper tooltips">
        <div class="altitude-box" v-for="(altitude, index) of shorttermData.objectAltitudes" :key="index" :style="`max-width: ${timestep.converted * 100}%;`">
            <Tooltip position="left" :tooltipText="tooltipText(altitude, index)">
                <div class="altitude-box__value transparent" :data-altitude="altitude"></div>
            </Tooltip>
        </div>
    </div>
</template>

<script>
import ShorttermService from '@/services/shortterm.service'
import Tooltip from '@/components/common/Tooltip'
import UtilsService from '@/services/utils.service'
import TimeFrameService from '@/services/timeframe.service'

export default {
    name: 'ShortTermPlotContent',
    components: {
        Tooltip,
    },
    props: {
        name: String,
    },
    data() {
        return {
            duskOffset: 0,
            dawnOffset: 0,
            wrapperOffset: 0,
        }
    },
    computed: {
        shorttermData() {
            return ShorttermService.getShorttermData(this.name, this.shorttermDate);
        },
        shorttermDate() {
            return ShorttermService.getShorttermDate();
        },
        sunAltLimit() {
            return ShorttermService.shortTermSunAltLimit();
        },
        timestep() {
            return TimeFrameService.getTimestep();
        },
    },
    methods: {
        getDuskStyle() {
            if (!this.duskOffset) {
                return 'display: none';
            }
            if (this.duskOffset > this.dawnOffset) {
                return `left: ${this.dawnOffset - this.wrapperOffset}px; right: 25px; width: ${this.duskOffset - this.dawnOffset}px;`;
            } else {
                return `width: ${this.duskOffset - this.wrapperOffset - 25}px;`;
            }
        },
        getDawnStyle() {
            if (!this.dawnOffset) {
                return 'display: none';
            }
            if (this.duskOffset > this.dawnOffset) {
                return 'display: none';
            } else {
                return `left: ${this.dawnOffset - this.wrapperOffset}px; right: 25px; width: auto;`;
            }
        },
        formatDate(julianDate, local) {
            const timestamp = UtilsService.julianToTimestamp(julianDate);
            if (local) {
                return UtilsService.momentInLocalTimeDate(timestamp, true);
            } else {
                return UtilsService.momentUtcDate(timestamp, true);
            }
        },
        objectAltitudeClass(altitudeValue) {
            const value = parseFloat(altitudeValue);
            let altitudeClass = 0;
            if (value <= 0) {
                altitudeClass = 0;
            } else if (value > 0 && value <= 5) {
                altitudeClass = 1;
            } else if (value > 5 && value <= 10) {
                altitudeClass = 2;
            } else if (value > 10 && value <= 15) {
                altitudeClass = 3;
            } else if (value > 15 && value <= 20) {
                altitudeClass = 4;
            } else if (value > 20 && value <= 30) {
                altitudeClass = 5;
            } else if (value > 30 && value <= 45) {
                altitudeClass = 6;
            } else if (value > 45 && value <= 60) {
                altitudeClass = 7;
            } else if (value > 60 && value <= 90) {
                altitudeClass = 8;
            } else {
                altitudeClass = 9;
            }
            return 'alt-' + altitudeClass;
        },
        tooltipText(altitude, index) {
            return `
                ${this.$t.utcTime}: ${this.formatDate(this.shorttermData.altitudeDates[index], false)}<br />
                ${this.$t.localTime}: ${this.formatDate(this.shorttermData.altitudeDates[index], true)}<br />
                ${this.$t.altitude}: ${altitude ? altitude.toFixed(1) + ' deg'  : '-'}
            `;
        },
        setOffsets() {
            setTimeout(() => {
                this.duskOffset = UtilsService.getElementOffset(document.querySelector('.dusk')).left;
                this.dawnOffset = UtilsService.getElementOffset(document.querySelector('.dawn')).left;
                this.wrapperOffset = UtilsService.getElementOffset(document.querySelector('.altitude-wrapper')).left;
            }, 50);
        }
    },
    watch: {
        shorttermDate() {
            this.setOffsets();
        }
    },
    mounted() {
        setTimeout(() => {
            this.setOffsets();
        }, 100);
        window.addEventListener('resize', this.setOffsets);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.setOffsets);
    }
}
</script>

<style>

</style>