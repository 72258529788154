<template>
    <div class="opt">
        <ul class="tab-list">
            <li class="tab-item">
                <router-link class="tab-link t-center" :to="`${$keys.routes.opt}/${$keys.routes.setup}`" :key="`${$keys.routes.opt}/${$keys.routes.setup}`">{{ $t.setup }}</router-link>
            </li>
            <li class="tab-item">
                <router-link class="tab-link t-center" :class="{disabled: !enableOptFiltering}" :to="`${$keys.routes.opt}/${$keys.routes.optFiltering}`" :key="$route.path">{{ $t.filtering }}</router-link>
            </li>
            <li class="tab-item">
                <router-link class="tab-link t-center" :class="{disabled: !(enableOptResults && objectsSelected)}" :to="`${$keys.routes.opt}/${$keys.routes.optResults}`" :key="$route.path">{{ $t.results }}</router-link>
            </li>
        </ul>
        <div class="tab-content">
            <router-view />
        </div>
    </div>
</template>

<script>
import CommonActions from '@/store/common/common-actions'
import CommonGetters from '@/store/common/common-getters'
import ObjectsService from '@/services/objects.service'

export default {
    name: 'ObservationPlanningTool',
    computed: {
        enableOptFiltering() {
            return this.$store.getters[CommonGetters.enableOptFiltering];
        },
        enableOptResults() {
            return this.$store.getters[CommonGetters.enableOptResults];
        },
        objectsSelected() {
            return ObjectsService.getObjectsSelected().length || 0;
        },
    },
    mounted() {
        this.$store.dispatch(CommonActions.setTool, 'opt');
        this.$store.dispatch(CommonActions.setToolTitle, this.$keys.titles.opt);
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/opt/general.scss";
@import "@/styles/common/tabs.scss";
</style>
