<template>
    <div class="settings-button" @click="setSettingsPanelName(type)" :class="{active: settingsPanelName === type}">
        <i v-if="type === 'displaySettings'" class="icon-settings"></i>
        <i v-if="type === 'invertColors'" class="icon-repeat"></i>        
        <i v-if="type === 'export'" class="icon-log-out"></i>        
        <span>{{ $t[type] }}</span>
        <SkychartSettingsPanel v-if="settings && settingsPanelName === type" @click.stop>
            <SkychartDisplaySettings v-if="settingsPanelName === 'displaySettings'" :type="settingsPanelName" :settings="settings" @change="onChange(settingsPanelName, $event)" />            
            <SkychartPrint v-if="settingsPanelName === 'export'" @print="onPrint($event)" />
        </SkychartSettingsPanel>
    </div>
</template>

<script>
import SkychartService from '@/services/skychart.service'
import SkychartSettingsPanel from '@/components/scd/SkychartSettingsPanel'
import SkychartDisplaySettings from '@/components/scd/SkychartDisplaySettings'
import SkychartPrint from '@/components/scd/SkychartPrint'

export default {
    name: 'SkyChartSettingsButton',
    props: {
        type: String,
        settings: Object,
    },
    emits: [
        'change',
        'print',
    ],
    components: {
        SkychartSettingsPanel,
        SkychartDisplaySettings,        
        SkychartPrint,
    },
    computed: {
        settingsPanelName() {
            return SkychartService.getSettingsPanelName();
        },
        invertColors() {
            return SkychartService.getInvertColors();
        }
    },
    methods: {
        setSettingsPanelName(name) {            
            if (name !== 'invertColors') {
                if (name === this.settingsPanelName) {
                    SkychartService.setSettingsPanelName('');
                } else {
                    SkychartService.setSettingsPanelName(name);
                }
            } else {
                SkychartService.setInvertColors(!this.invertColors);
            }
        },
        onChange(group, change) {
            this.$emit('change', {group, change});
        },
        onPrint(type) {
            this.$emit('print', type);
        }
    }
}
</script>

<style>

</style>