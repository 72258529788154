<template>
    <div class="welcome">
        <h1>{{ $keys.titles.neo }}</h1>
        <ul class="nav-list">
            <li class="nav-item" v-for="tool of tools" :key="tool">
                <router-link class="nav-link" :to="$keys.routes[tool]" :class="{disabled: !toolsEnabled.includes(tool) }" :key="$route.path">{{ $keys.titles[tool] }}</router-link>
                <Tooltip v-if="!toolsEnabled.includes(tool)" position="top" :tooltipText="`${$keys.titles[tool]} ${$t.isCommingSoon}`"><i class="icon-clock" /></Tooltip>
            </li>
        </ul>
    </div>
</template>

<script>
import Config from '@/constants/config'
import Tooltip from '@/components/common/Tooltip'
import CommonActions from '@/store/common/common-actions'

export default {
    name: 'WelcomePage',
    components: {
        Tooltip
    },
    data() {
        return {
            tools: Config.tools,
            toolsEnabled: Config.toolsEnabled,
        }
    },
    mounted() {
        this.$store.dispatch(CommonActions.setTool, '');
        this.$store.dispatch(CommonActions.setToolTitle, '');
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/welcome.scss";
</style>
