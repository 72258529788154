<template>
    <div class="longterm-plots__switch">
        <input class="toggle-switch" :id="`switch-${plot}`" type="checkbox" v-model="plotSwitch" @change="$emit('switch', $event)"/>
        <label class="toggle-switch__button" :for="`switch-${plot}`"></label>
    </div>
</template>

<script>
export default {
    name: 'ResultsLongTermSwitch',
    props: {
        plot: String,
        object: Object
    },
    data() {
        return {
            plotSwitch: this.object.plots[this.plot].enable,
        }
    },
}
</script>

<style>

</style>