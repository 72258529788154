<template>
    <button class="link" @click="getCurrentLocation()">
        <i class="icon-compass" /><span>{{ $t.useMyCurrentLocation }}</span>
    </button>
</template>

<script>
import ObservatoryService from '@/services/observatory.service'
import PopupService from '@/services/popup.service'

export default {
    name: 'UserLocation',
    methods: {
        getCurrentLocation() {
            if(!navigator.geolocation) {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: this.$t.geolocationNotSupported
                });
            } else {
                navigator.geolocation.getCurrentPosition(this.geolocationSuccess, this.geolocationError);
            }
        },
        geolocationSuccess(position) {
            ObservatoryService.setUserDefinedObservatory(position.coords.latitude, position.coords.longitude);
        },
        geolocationError() {
            PopupService.show({
                component: 'PopupInfo',
                type: 'warning',
                message: this.$t.geolocationNotSupported
            });
        },
    }
}
</script>

<style>

</style>