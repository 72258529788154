import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Config from '@/constants/config'
import axios from 'axios'
import PopupService from '@/services/popup.service'
import SpinnerService from '@/services/spinner.service'
import Keys from '@/constants/keys'
import SpacekitService from '@/services/spacekit.service'
import ObjectsService from '@/services/objects.service'
import TimeFrameService from '@/services/timeframe.service'
import UtilsService from '@/services/utils.service'
import HUDService from '@/services/hud.service'
import html2canvas from 'html2canvas'
import * as THREE from 'three'
import MemoryService from '@/services/memory.service'
import SynodicCalculationService from '@/services/synodic-calculation.service'
import SyntheticObjectsService from './synthetic-objects.service'

const VisualisationService = {
    getSmallobjectsApiUrl() {
        return Config.api.restUrl + Config.api.getSmallobjects;
    },
    getEphemeridesApiUrl() {
        return Config.api.restUrl + Config.api.getEphemerides;
    },
    getMainBodiesApiUrl() {
        return Config.api.restUrl + Config.api.mainBodies;
    },
    getCloseApproachesApiUrl() {
        return Config.api.restUrl + Config.api.closeApproaches;
    },
    getFlybyApiUrl() {
        return Config.api.restUrl + Config.api.flyby;
    },
    getShapeUrl() {
        return Config.api.restUrl + Config.api.shape;
    },
    getTool() {
        return store.getters[CommonGetters.tool];
    },
    getPreviousTimeOVT() {
        return store.getters[CommonGetters.previousTimeOVT];
    },
    setMultiselectOptions(value) {
        store.dispatch(CommonActions.setMultiselectOptions, value);
    },
    getMultiselectOptions() {
        return store.getters[CommonGetters.multiselectOptions];
    },
    setPreviousTimeOVT(value) {
        store.dispatch(CommonActions.setPreviousTimeOVT, value);
    },
    getPreviousTimeFVT() {
        return store.getters[CommonGetters.previousTimeFVT];
    },
    setPreviousTimeFVT(value) {
        store.dispatch(CommonActions.setPreviousTimeFVT, value);
    },
    getPlaybackSpeedFVT() {
        return store.getters[CommonGetters.playbackSpeedFVT];
    },
    setPlaybackSpeedFVT(value) {
        store.dispatch(CommonActions.setPlaybackSpeedFVT, value);
    },
    getPlaybackSpeed() {
        return store.getters[CommonGetters.playbackSpeed];
    },
    setPlaybackSpeed(value) {
        store.dispatch(CommonActions.setPlaybackSpeed, value);
    },
    getCurrentDate() {
        return store.getters[CommonGetters.currentDate];
    },
    setCurrentDate(value) {
        store.dispatch(CommonActions.setCurrentDate, value);
    },
    getStartDate() {
        return store.getters[CommonGetters.startDate];
    },
    setStartDate(value) {
        store.dispatch(CommonActions.setStartDate, value);
    },
    getEndDate() {
        return store.getters[CommonGetters.endDate];
    },
    setEndDate(value) {
        store.dispatch(CommonActions.setEndDate, value);
    },
    getIsChecked() {
        return store.getters[CommonGetters.isChecked];
    },
    setIsChecked(value) {
        store.dispatch(CommonActions.setIsChecked, value);
    },
    setIsPerturbed(value) {
        store.dispatch(CommonActions.setIsPerturbed, value);
    },
    getIsPerturbed() {
        return store.getters[CommonGetters.isPerturbed];
    },
    getSelectedObjectName() {
        return store.getters[CommonGetters.selectedObjectName];
    },
    setSelectedObjectName(value) {
        return store.dispatch(CommonActions.setSelectedObjectName, value);
    },
    getRiskListChecked() {
        return store.getters[CommonGetters.riskListChecked];
    },
    setRiskListChecked(value) {
        store.dispatch(CommonActions.setRiskListChecked, value);
    },
    getOrbitRecorderShow() {
        return store.getters[CommonGetters.orbitRecorderShow];
    },
    setOrbitRecorderShow(boolean) {
        store.dispatch(CommonActions.setOrbitRecorderShow, boolean);
    },
    getOrbitRecorderOnAir() {
        return store.getters[CommonGetters.orbitRecorderOnAir];
    },
    setOrbitRecorderOnAir(boolean) {
        store.dispatch(CommonActions.setOrbitRecorderOnAir, boolean);
    },
    getSelectObjectsShow() {
        return store.getters[CommonGetters.orbitVisualisationObjectsShow];
    },
    setSelectObjectsShow(boolean) {
        store.dispatch(CommonActions.setOrbitVisualisationObjectsShow, boolean);
    },
    getKeplerianData(){
        return store.getters[CommonGetters.keplerianData];
    },
    setKeplerianData(object){
        store.dispatch(CommonActions.setKeplerianData, object);
    },
    getPerturbedData(){
        return store.getters[CommonGetters.perturbedData];
    },
    setPerturbedData(object){
        store.dispatch(CommonActions.setPerturbedData, object);
    },
    getOrbitActiveObject() {
        return store.getters[CommonGetters.orbitActiveObject];
    },
    setOrbitActiveObject(object) {
        const now = VisualisationService.getNow();
        const tool = VisualisationService.getTool();
        if (tool === 'ovt') {
            const currentDate = VisualisationService.getCurrentDate();
            VisualisationService.updateDistances(currentDate, object);
        }
        else{
            VisualisationService.updateDistances(now, object);
        }
        store.dispatch(CommonActions.setOrbitActiveObject, object);
    },
    getSimulationTime() {
        return store.getters[CommonGetters.simulationTime];
    },
    setSimulationTime(date) {
        store.dispatch(CommonActions.setSimulationTime, date);
    },
    loadingObjectsEphemerides() {
        return store.getters[CommonGetters.loadingObjectsEphemerides];
    },
    setLoadingObjectsEphemerides(array) {
        store.dispatch(CommonActions.setLoadingObjectsEphemerides, array);
    },
    getNow() {
        return store.getters[CommonGetters.orbitVisualisationNow];
    },
    setNow(date) {
        store.dispatch(CommonActions.setOrbitVisualisationNow, date);
        VisualisationService.updateDistances(date);
    },
    getSettings() {
        return store.getters[CommonGetters.orbitSettings];
    },
    setSettings(object) {
        store.dispatch(CommonActions.setOrbitSettings, object);
    },
    getSettingsOptions(type) {
        const tool = VisualisationService.getTool();
        if (tool && tool !== '' && tool!=='opt' && tool!=='scd') {
            return Config.visualisation.options[tool][type].split(',');
        } else {
            return [];
        }
    },
    getSettingsDefaults() {
        return Config.visualisation.settings;
    },
    setSettingsDefaults() {
        const defaults = VisualisationService.getSettingsDefaults();
        VisualisationService.setSettings(defaults);
    },
    getSettingsPanelName() {
        return store.getters[CommonGetters.orbitSettingsPanelName];
    },
    setSettingsPanelName(string) {
        store.dispatch(CommonActions.setOrbitSettingsPanelName, string);
    },
    getInvertColors() {
        const settings = VisualisationService.getSettings();
        return settings && settings.invertColors.all;
    },
    setInvertColors(boolean) {
        const settings = VisualisationService.getSettings();
        settings.invertColors.all = boolean;
        VisualisationService.setSettings(settings);
    },
    isAnyInvertColorsActive() {
        const settings = VisualisationService.getSettings();
        return Object.values(settings.invertColors).some(value => !!value);
    },
    setParticleSize(size) {
        const settings = VisualisationService.getSettings();
        settings.particleSize = size;
        VisualisationService.setSettings(settings);
    },
    getIsSynodicOrbitChartActive() {
        const settings = VisualisationService.getSettings();
        return settings && settings.synodicOrbitCharts;
    },
    setSynodicOrbitCharts(isChartActive) {
        const settings = VisualisationService.getSettings();
        settings.synodicOrbitCharts = isChartActive;
        VisualisationService.setSettings(settings);
    },
    reloadTool(){
        const asteroids = ObjectsService.getFinalAsteroidsList();
        const syntheticObjects = SyntheticObjectsService.getSyntheticObjectsList();
        const activeObjects = asteroids.concat(syntheticObjects);
        const tool = VisualisationService.getTool();
        const checked = VisualisationService.getIsChecked();
        const perturbedob = VisualisationService.getIsPerturbed();

        const settings = VisualisationService.getSettings();
        const labelsVisibility = settings.objectsSettings.objectNames.value;
        if (activeObjects.length == 0)
            VisualisationService.setOrbitActiveObject(null);
        for (const activeObject of activeObjects) {
            const designator = activeObject.designator ? activeObject.designator : activeObject.number ? activeObject.number : activeObject.name;
            if (activeObject && checked.includes(designator.toString()) && tool && tool === 'ovt'){
                const perturbed = perturbedob.includes(designator.toString());
                //VisualisationService.addObject(designator, perturbed);
                VisualisationService.addOrbitData(designator, perturbed);
                const currentSelectedName = VisualisationService.getSelectedObjectName();
                if (designator.toString() === currentSelectedName) {
                    VisualisationService.highlightObject(designator.toString());
                }
                SpacekitService.toggleObjectsNames(labelsVisibility);
            }
            else if (activeObject && tool && tool === 'fvt'){
                VisualisationService.removeObject(designator);
            }
        }

        if (tool && (tool === 'ovt' || tool === 'fvt'))
            VisualisationService.setOrientationFocus();

        if (tool && tool === 'fvt'){
            const selCloseApproach = VisualisationService.getSelectedCloseApproach();
            VisualisationService.setOrbitActiveObject(null);
            VisualisationService.setSelectedCloseApproach(null);
            if (selCloseApproach && !VisualisationService.getOrbitActiveObject()) {
                VisualisationService.setSelectedCloseApproach(selCloseApproach, true);
            }
            setTimeout(() => {     
                SpacekitService.updateAsteroidsSizeFactor(VisualisationService.getSettings().objectsSettings.asteroidsSizeFactor.value);
            }, 3000);
        }
    },
    setOrientationFocus(reloaded = false){
        const loadedState = {...MemoryService.getToolState()};
        const tool = VisualisationService.getTool();
        const setZoom = reloaded ? true : false;

        if (tool === 'fvt' && loadedState.fvtFocusedObject && loadedState.fvtFocusedObject !== 'mainbody') {
            VisualisationService.focusOnSelectedObject(true,setZoom);
            setTimeout(() => {
                SpacekitService.setOrientation();  
            }, 3000);
        } else if (tool === 'fvt' && (!loadedState.fvtFocusedObject || loadedState.fvtFocusedObject === 'mainbody')) {
            VisualisationService.focusOnCenter(true,setZoom);
            SpacekitService.setOrientation();
        }

        setTimeout(() => {
            if (!tool) {
                return;
            }
            const focusedOvtObject = loadedState.ovtFocusedObject;
            if (tool === 'ovt') {
                focusedOvtObject === 'sun' ? VisualisationService.focusOnCenter(false, setZoom) : SpacekitService.focusOnObject(focusedOvtObject, false, setZoom);
            }
            if (tool === 'ovt' && loadedState.focusOVT){
                //VisualisationService.focusOnSelectedObject(false,setZoom);
                SpacekitService.setOrientation();  
            } else if (tool === 'ovt' && !loadedState.focusOVT) {
                //VisualisationService.focusOnCenter(false,setZoom);
                SpacekitService.setOrientation();
            }
            if (tool && (tool === 'ovt' || tool === 'fvt' || tool === 'sovt'))
                VisualisationService.resize();
        }, 3000);
    },
    resize(){
        const tool = VisualisationService.getTool();
        if (tool !== 'opt' && tool !== 'scd'){
            const vt = window['vt'];
            const container = vt.getSimulationElement();
            const camera = vt.getViewer().camera;
            camera.aspect = container.clientWidth / container.clientHeight;
            camera.updateProjectionMatrix();
            const cameraOrtho = vt.cameraOrtho;
            cameraOrtho.left = container.clientWidth / -2;
            cameraOrtho.right = container.clientWidth / 2;
            cameraOrtho.top = container.clientHeight / 2;
            cameraOrtho.bottom = container.clientHeight / -2;
            cameraOrtho.updateProjectionMatrix();

            const renderer = vt.getRenderer();
            renderer.setSize(container.clientWidth, container.clientHeight);

            vt.staticForcedUpdate();
        }
    },
    selectObject(designator, perturbed) {
        const checked = VisualisationService.getIsChecked();
        const perturbedob = VisualisationService.getIsPerturbed();

        if (perturbed && !perturbedob.includes(designator)){
            perturbedob.push(designator);
            VisualisationService.setIsPerturbed(perturbedob);
        } else if (!perturbed && perturbedob.includes(designator)) {
            perturbedob.splice(perturbedob.indexOf(designator), 1);
        }
        if (!checked.includes(designator)) {
            //VisualisationService.addObject(designator, perturbed);
            VisualisationService.addOrbitData(designator, perturbed);
            checked.push(designator);
            VisualisationService.setIsChecked(checked);
        }
        const currentSelectedName = VisualisationService.getSelectedObjectName();
        if (designator === currentSelectedName) {
            VisualisationService.setSelectedObjectName(null);
            VisualisationService.deHighlightObject(designator);
            //VisualisationService.focusOnCenter();
        } else {
            VisualisationService.setSelectedObjectName(designator);
            VisualisationService.highlightObject(designator);
        }
    },

    selectSynodicObject(designator) {
        SpacekitService.removeDetectionPolarObjects();
        SpacekitService.removeOrbitRangeObject();
        const selectedObjectName = VisualisationService.getSelectedSynodicObjectName() === designator ? '' : designator;
        VisualisationService.setSelectedSynodicObjectName(selectedObjectName);
    },

    handleFocusOnChange(focusedObject, tool) {
        if (!focusedObject) {
            SpacekitService.setMinCameraDistance(0);
            return;
        }
        if (focusedObject === 'sun') {
            VisualisationService.focusOnSun();
            return;
        }
        tool === 'sovt' && SpacekitService.focusOnSovtObject(focusedObject);
        tool === 'ovt' && SpacekitService.focusOnObject(focusedObject);
    },

    focusOnSun() {
        SpacekitService.setMinCameraDistance(0);
        SpacekitService.focusOnCenter();
    },
   
    checkObject(designator, perturbed, reload = false) {
        const checked = VisualisationService.getIsChecked();
        const selected = VisualisationService.getSelectedObjectName();
        const perturbedob = VisualisationService.getIsPerturbed();

        if (perturbed && !perturbedob.includes(designator)){
            perturbedob.push(designator);
            VisualisationService.setIsPerturbed(perturbedob);
        } else if (!perturbed && perturbedob.includes(designator)) {
            perturbedob.splice(perturbedob.indexOf(designator), 1);
        }
        if (checked.indexOf(designator) > -1) {
            //checked.splice(checked.indexOf(designator), 1);
            if (selected === designator && !reload) {
                VisualisationService.setSelectedObjectName(null);
                VisualisationService.setOrbitActiveObject(null);
                //VisualisationService.focusOnCenter();
            }
            if (reload) {
                //VisualisationService.addObject(designator, perturbed);
                VisualisationService.addOrbitData(designator, perturbed);
            } else {
                const focusedObject = VisualisationService.getOvtFocusedObject();
                const isFocusedDeselected = focusedObject === designator;
                isFocusedDeselected && VisualisationService.setOvtFocusedObject('');
                VisualisationService.removeObject(designator);
                VisualisationService.removeObject(designator + '_perturbed');
                checked.splice(checked.indexOf(designator), 1);
            }
        } else {
            //VisualisationService.addObject(designator, perturbed);
            VisualisationService.addOrbitData(designator, perturbed);
            checked.push(designator);
            VisualisationService.setIsChecked(checked);
        }
    },
    getSelects() {
        return Config.orbitVisualisation.selects;
    },
    getSynodicObjectsInputsList() {
        return Config.synodicOrbitVisualisation.parameterInputs;
    },
    getOrbitVisualisationSelects() {
        return store.getters[CommonGetters.orbitVisualisationSelects];
    },
    getOrbitVisualisationActiveSelects() {
        return store.getters[CommonGetters.orbitVisualisationActiveSelects];
    },
    setOrbitVisualisationActiveSelects() {
        store.dispatch(CommonActions.setOrbitVisualisationActiveSelects);
    },
    selectOption(option, group, color) {
        const selects = VisualisationService.getOrbitVisualisationSelects();        
        const selectGroup = group === 'priority_list' ? 'plrisk' : group;
        if (!selects[selectGroup]) {
            selects[selectGroup] = [];
        }    
        if (selects[selectGroup].filter(e => e.option === option).length) {
            selects[selectGroup] = selects[selectGroup].filter(e => e.option != option);
            SpacekitService.removeSmallObjects(selectGroup, option);
            this.deselectOption(option, selectGroup);
        } else {
            selects[selectGroup].push({ option, color });
        }
    },
    deselectOption(option, group){
        const selects = VisualisationService.getOrbitVisualisationSelects();        
        selects[group] = selects[group].filter(e => e.option != option);
    },
    updateColorOption(group,option,color) {
        const selects = VisualisationService.getOrbitVisualisationSelects();            
        const selectGroup = group === 'priority_list' ? 'plrisk' : group;
        selects[selectGroup] = selects[selectGroup].filter(e => e.option != option); 
        selects[selectGroup].push({ option, color });             
    },
    clearSelectedOptions(group) {        
        const selectedOptions = VisualisationService.getOrbitVisualisationSelects();        
        const selectGroup = group === 'priority_list' ? 'plrisk' : group;
        const currentSelectedOptions = selectedOptions[selectGroup];
        currentSelectedOptions.length = 0;
        SpacekitService.removeSmallObjects(selectGroup);
    },
    formatSelectOptions(optionsArray) {
        if (!optionsArray.length) return optionsArray;
        const options = [];
        for (const option of optionsArray) {
            const color = UtilsService.randomHexColor(false);
            const value = option.value || option.id;
            let label = option.label || option.name;

            if (!label && Keys.t[value]) {
                label = Keys.t[value];
            }

            options.push({
                value,
                label,
                color
            })
        }

        return options;
    },
    formatSelectValues(optionsArray) {
        if (optionsArray.list && optionsArray.list.data) {
            return optionsArray.list.data;
        } else {
            return optionsArray;
        }
        
    },
    getSmallobjectsData(type, phrase = null) {
        let details = '';
        if (type === 'plrisk') {
            details = phrase ? '?list=' + phrase : '';
        } else {
            details = phrase ? '//' + phrase : '/';
        }
        const url = VisualisationService.getSmallobjectsApiUrl() + type + details;
        const loadingObjects = VisualisationService.loadingObjectsEphemerides();

        if (type && phrase) {    
            loadingObjects.push(type + '_' + phrase);
        }
        
        return axios(url)
            .then(response => {
                let formattedArray;
                if (!phrase) {
                    formattedArray = VisualisationService.formatSelectOptions(response.data);
                } else {
                    formattedArray = VisualisationService.formatSelectValues(response.data);
                    loadingObjects.splice(loadingObjects.indexOf(type + '_' + phrase), 1);
                }
                return formattedArray;
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
                if (type && phrase) {
                    loadingObjects.splice(loadingObjects.indexOf(type + '_' + phrase), 1);
                }
                return [];
            })
    },
    getSelectedOptionValue() {
        let type, phrase, color;
        const selects = VisualisationService.getOrbitVisualisationSelects();
        const activeSelects = VisualisationService.getOrbitVisualisationActiveSelects();

        for (const select in selects) {
            if (!activeSelects[select]) {
                activeSelects[select] = [];
            }

            const exclude = (arr1, arr2) => arr1.filter(o1 => arr2.map(o2 => o2.option).indexOf(o1.option) === -1);
            const newOption = exclude(selects[select], activeSelects[select])[0];

            if (newOption) {
                type = select;
                phrase = newOption.option;
                color = newOption.color;
            }
            activeSelects[select] = [...selects[select]];
        }
    
        return {type, phrase, color};
    },

    getOrbitData(designator, perturbed, kepForPert = false) {
        if (!designator) {
            return;
        }

        let url = '';
        if (perturbed) {
            url = VisualisationService.getEphemeridesApiUrl() + 'perturbed?name=' + designator;
        } else {
            url = VisualisationService.getEphemeridesApiUrl() + 'keplerian?names=' + designator;
        }

        const loadingObjects = VisualisationService.loadingObjectsEphemerides();
        loadingObjects.push(designator);

        return axios(url)
            .then(response => {
                const data = response.data.ephemerides.data;
                const error = response.data.ephemerides.errors ? response.data.ephemerides.errors[0] : null;
                if (data.length) {
                    if (perturbed) {
                        VisualisationService.removeObject(designator + '_perturbed');
                        SpacekitService.addPerturbedOrbit(data, designator);
                        if (designator == VisualisationService.getSelectedObjectName()) {
                            VisualisationService.highlightObject(designator);
                        }
                    } else {
                        VisualisationService.removeObject(designator);
                        if (!data[0].catalogueNumber) {
                            data[0].catalogueNumber = data[0].designator;
                        }
                        data[0].catalogueNumber = data[0].catalogueNumber+'';
                        if (kepForPert){
                            const keplerianData = VisualisationService.getKeplerianData();
                            keplerianData.push(data[0]);
                            VisualisationService.setKeplerianData(keplerianData);
                        } else {
                            SpacekitService.createObject(data[0]);
                            if (data[0].catalogueNumber === VisualisationService.getSelectedObjectName()) {
                                VisualisationService.highlightObject(data[0].catalogueNumber);
                            }
                        }
                    }
                    loadingObjects.splice(loadingObjects.indexOf(designator), 1);
                } else if (error) {
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message: error.message + ' ' + error.name,
                    });
                    loadingObjects.splice(loadingObjects.indexOf(error.name), 1);
                }
                const vt = window['vt'];
                vt.staticForcedUpdate();
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                console.warn(message);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'warning',
                    message: Keys.t.perturbedOrbitForObject + ' ' + designator + ' ' + Keys.t.doesNotExist + '.',
                });
                loadingObjects.splice(loadingObjects.indexOf(designator), 1);

                const finalAsteroidList = ObjectsService.getFinalAsteroidsList();
                if (finalAsteroidList) {
                    const spaceObject = finalAsteroidList.filter(e => e.number+'' === designator || e.name === designator)[0];
                    if (spaceObject) {
                        spaceObject.keplerianOnly = true;
                        spaceObject.perturbedOn = false;
                    }
                }
            })
    },
    
    getPerturbedOrbit(designator, kepForPert = false) {
        return VisualisationService.getOrbitData(designator, true, kepForPert);
    },

    getKeplerianOrbit(designator, kepForPert = false) {
        return VisualisationService.getOrbitData(designator, false, kepForPert);
    },

    async addObject(designator, perturbed) {
        const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(designator);
        if (perturbed) {
            !isSyntheticObject ? this.getKeplerianOrbit(designator, true).then(this.getPerturbedOrbit(designator)) : await this.addSyntheticPerturbedOrbit(designator);
        } else {
            !isSyntheticObject ? this.getKeplerianOrbit(designator) : this.addSyntheticKeplerianOrbit(designator);
        }
    },

    addSyntheticKeplerianOrbit(designator) {
        if (!designator) {
            return;
        }
        const syntheticObjectData =
            SyntheticObjectsService.getSyntheticObjectParameters(
                designator
            );
        VisualisationService.removeObject(syntheticObjectData.designator + '_perturbed');
        syntheticObjectData.catalogueNumber = syntheticObjectData.designator;
        SpacekitService.createObject(syntheticObjectData);
        if (
            syntheticObjectData.catalogueNumber ===
            VisualisationService.getSelectedObjectName()
        ) {
            VisualisationService.highlightObject(
                syntheticObjectData.catalogueNumber
            );
        }
        VisualisationService.checkIsFocusOnObject(designator);
    },

    async addSyntheticPerturbedOrbit(designator) {
        if (!designator) {
            return;
        }
        const loadingObjects = VisualisationService.loadingObjectsEphemerides();
        loadingObjects.push(designator);
        const syntheticObjectData =
            SyntheticObjectsService.getSyntheticObjectParameters(
                designator
            );
        const objectApiParameters = SyntheticObjectsService.getSyntheticObjectApiParameters(syntheticObjectData);
        const apiUrl = SyntheticObjectsService.getOvtSyntheticObjectPerturbedOrbitApiUrl() + UtilsService.parseParams(objectApiParameters);
        try {
            const response = await axios(apiUrl)
            const data = response.data.ephemerides.ephemerides.data
            const error = response.data.ephemerides.ephemerides.errors ? response.data.ephemerides.ephemerides.errors[0] : null
            if (data.length) {
                VisualisationService.removeObject(designator)
                SpacekitService.addSyntheticPerturbedOrbit(data, syntheticObjectData)
                if (designator == VisualisationService.getSelectedObjectName()) {
                    VisualisationService.highlightObject(designator)
                }
            } else if (error) {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: `${error.message} ${error.name}`,
                })
            } else {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: Keys.t.ephemeridesForObjectNotAvailable.replace('${designator}', designator)
                })
            }
            loadingObjects.splice(loadingObjects.indexOf(designator), 1);
            VisualisationService.checkIsFocusOnObject(designator);
            window['vt'].staticForcedUpdate();
        } catch (error) {
            const message = UtilsService.prepareErrorMessage(error)
            console.warn(message)
            PopupService.show({
                component: 'PopupInfo',
                type: 'warning',
                message: `${Keys.t.perturbedOrbitForObject} ${syntheticObjectData.objectName} ${Keys.t.doesNotExist}.`
            })
            loadingObjects.splice(loadingObjects.indexOf(designator), 1)
            const syntheticObjectList = SyntheticObjectsService.getSyntheticObjectsList()
            if (syntheticObjectList && syntheticObjectList.length) {
                const spaceObject = syntheticObjectList.filter(object => object.name === designator)[0]
                if (spaceObject) {
                    spaceObject.keplerianOnly = true
                    spaceObject.perturbedOn = false
                }
            }
        }
    },

    removeObject(designator) {
        if (!designator) {
            return;
        } else {
            SpacekitService.removeObject(designator);
        }
    },

    drawOrbitData(designator, perturbed) {
        const kepObjects = VisualisationService.getKeplerianData();
        const pertObjects = VisualisationService.getPerturbedData();

        let kepObject, pertObject;

        for (const kepObj of kepObjects) {
            if (kepObj.catalogueNumber === designator.toString()){
                kepObject = kepObj;
            }
        }
        
        for (const pertObj of pertObjects) {
            if (pertObj.designator === designator.toString()){
                pertObject = pertObj;
            }
        }      
        
        if (pertObject && perturbed) {
            VisualisationService.removeObject(designator + '_perturbed');
            SpacekitService.addPerturbedOrbit(pertObject.data, designator);
            if (designator === VisualisationService.getSelectedObjectName()) {
                VisualisationService.highlightObject(designator);
            }
        }
        else if (kepObject && !perturbed) {
            VisualisationService.removeObject(designator);    
            SpacekitService.createObject(kepObject);
            if (kepObject.catalogueNumber === VisualisationService.getSelectedObjectName()) {
                VisualisationService.highlightObject(kepObject.catalogueNumber);
            }           
        } else {           
            PopupService.show({
                component: 'PopupInfo',
                type: 'warning',
                message: 'Orbit does not exist',
            });            
        }            
        VisualisationService.checkIsFocusOnObject(designator);    
        const vt = window['vt'];
        vt.staticForcedUpdate();
    },
    requestOrbitServer(designator, perturbed, kepForPert = false) {        
        // check designator
        if (!designator) {            
            PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: 'Invalid designator ' + designator
            });
            return;
        }        

        // url
        const urlType = perturbed ? 'perturbed?name=': 'keplerian?names=';
        let url = VisualisationService.getEphemeridesApiUrl() + urlType + designator;
        
        // loadingObjects
        const loadingObjects = VisualisationService.loadingObjectsEphemerides();
        loadingObjects.push(designator);              
        
        return axios(url)
            .then(response => {
                const dataRaw = response.data;
                const responseData = typeof dataRaw === 'string' ? JSON.parse(dataRaw) : dataRaw;
                const data = responseData.ephemerides.data;
                const error = responseData.ephemerides.errors ? responseData.ephemerides.errors[0] : null;

                if (error) {
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message: error.message + ' ' + error.name,
                    });
                    loadingObjects.splice(loadingObjects.indexOf(error.name), 1);
                }

                // For objects, without KPM data
                if (!data.length) {
                    data.push({
                        object: designator,
                        catalogueNumber: designator,
                        designator: designator,
                        epoch: 60000,
                        a: 0,
                        e: 0,
                        i: 0,
                        ma: 0,
                        om: 0,
                        aphelion: 0,
                        q:0,
                        w: 0,
                        orbitPeriod: 0,
                        tp: 0,
                        mag: 0,
                        g: 0,
                        diam: 0,
                        taxonomy: "-"
                    })
                }

                if (data.length) {
                    if (perturbed) {
                        VisualisationService.removeObject(designator + '_perturbed');
                        const perturbedData = VisualisationService.getPerturbedData();
                        const perturbedObject = {};
                        const maximumObjectNumber = VisualisationService.getSettings().maxSavedPerturbedObjects;
                        perturbedObject.designator = designator;
                        perturbedObject.data = data;

                        let isObjectFound = perturbedData.some(obj => {
                            return obj.designator === designator;
                        });

                        if (!isObjectFound)
                            perturbedData.push(perturbedObject);

                        if (perturbedData.length > maximumObjectNumber)
                            perturbedData.shift();

                        VisualisationService.setPerturbedData(perturbedData);
                        SpacekitService.addPerturbedOrbit(data, designator);
                        if (designator === VisualisationService.getSelectedObjectName()) {
                            VisualisationService.highlightObject(designator);
                        }
                    } else {
                        VisualisationService.removeObject(designator);
                        if (!data[0].catalogueNumber) {
                            data[0].catalogueNumber = data[0].designator;
                        }
                        data[0].catalogueNumber = data[0].catalogueNumber+'';

                        const keplerianData = VisualisationService.getKeplerianData();

                        let isObjectFound = keplerianData.some(obj => {
                            return obj.catalogueNumber === data[0].catalogueNumber;
                        });
                         
                        if (!isObjectFound)
                            keplerianData.push(data[0]);
                        VisualisationService.setKeplerianData(keplerianData);
                        
                        if (!kepForPert){
                            SpacekitService.createObject(data[0]);
                            if (data[0].catalogueNumber === VisualisationService.getSelectedObjectName()) {
                                VisualisationService.highlightObject(data[0].catalogueNumber);
                            }
                        }
                    }
                    loadingObjects.splice(loadingObjects.indexOf(designator), 1);
                }
                VisualisationService.checkIsFocusOnObject(designator);
                const vt = window['vt'];
                vt.staticForcedUpdate();
            })
            .catch(error => {
                setTimeout(() => {     
                    this.addOrbitData(designator, false);
                }, 1000);
                const message = UtilsService.prepareErrorMessage(error);
                console.warn(message);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'warning',
                    message: Keys.t.perturbedOrbitForObject + ' ' + designator + ' ' + Keys.t.doesNotExist + '.',
                });
                loadingObjects.splice(loadingObjects.indexOf(designator), 1);

                const finalAsteroidList = ObjectsService.getFinalAsteroidsList();
                if (finalAsteroidList) {
                    const spaceObject = finalAsteroidList.filter(e => e.number+'' === designator || e.name === designator)[0];
                    if (spaceObject) {
                        spaceObject.keplerianOnly = true;
                        spaceObject.perturbedOn = false;
                    }
                }                  
            })
    },
    async addOrbitData(designator, perturbed) {
        const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(designator);
        if (isSyntheticObject) {
            return perturbed ? await this.addSyntheticPerturbedOrbit(designator) : this.addSyntheticKeplerianOrbit(designator);
        }
        // check if already loaded                
        const kepObjects = VisualisationService.getKeplerianData();
        const pertObjects = VisualisationService.getPerturbedData();

        let kepObject, pertObject;

        for (const kepObj of kepObjects) {
            if (kepObj.catalogueNumber === designator.toString()){
                kepObject = kepObj;
            }
        }
        
        for (const pertObj of pertObjects) {
            if (pertObj.designator === designator.toString()){
                pertObject = pertObj;
            }
        }
                                           
        if ((kepObject && !perturbed) || (pertObject && perturbed)) {    
            this.drawOrbitData(designator, perturbed);           
        }          
        else if (!pertObject && perturbed) {
            this.requestOrbitServer(designator, false, true)
                .then(() => {
                    return this.requestOrbitServer(designator, true);
                })
                .catch((error) => {
                    console.error("Error requesting perturbed orbit:", error);
                });
        } else {
            this.requestOrbitServer(designator, false)
                .catch((error) => {
                    console.error("Error requesting regular orbit:", error);
                });
        }       
    },

    checkIsFocusOnObject(designator) {
        const isFocusOnObject = VisualisationService.getOvtFocusedObject() === designator;
        if (!isFocusOnObject) {
            return;
        }
        const vt = window['vt'];
        if (!vt) {
            return;
        }
        vt.getViewer().stopFollowingObject();
        SpacekitService.focusOnObject(designator);
    },

    focusOnSelectedObject(flyby = false, setZoom = false) {
        const designator = flyby ? VisualisationService.getFvtFocusedObject() : VisualisationService.getSelectedObjectName();
        if (designator || flyby) {
            SpacekitService.focusOnObject(designator, flyby, setZoom);
            VisualisationService.setOrbitViewerFocusedOnObject(true);
        }
    },

    focusOnCenter(flyby = false, setZoom = false) {
        flyby = VisualisationService.getTool() === 'fvt';
        SpacekitService.focusOnCenter(flyby, setZoom);
        VisualisationService.setOrbitViewerFocusedOnObject(false);
    },

    highlightObject(designator) {
        SpacekitService.highlightObject(designator, true);
    },

    deHighlightObject(designator) {
        SpacekitService.highlightObject(designator, false);
    },    
    updateSetting(event) {
        const settings = VisualisationService.getSettings();        
        const group = event.group;        
        const option = event.change.option;        
        const property = event.change.property;        
        const value = event.change.value;                        
        if (event.change.option)            
            settings[group][option][property] = value;
        else  
            settings[group][property] = value;

        VisualisationService.setSettings(settings);
    },

    updateChildSetting(childSettingData) {
        const {type, groupName, optionName, optionValue} = childSettingData;
        const settings = VisualisationService.getSettings();
        settings[type][groupName].children[optionName].value = optionValue;
        VisualisationService.setSettings(settings);
    },

    updateRotationAngle(jd0, jd1, initialRotation = false) {
        const vt = window['vt'];
        const mainBody = vt.flybyScene.filter(e => e._id === 'mainbody')[0];
        const asteroid = vt.flybyScene.filter(e => e._id === 'asteroid')[0];
        if ((mainBody) && (initialRotation || mainBody.initialRotationPerformed)) {
            const period = mainBody._options.period;
            const angle = ((2 * Math.PI) / period) * (jd1 - jd0);
            mainBody.get3jsObjects()[0].rotateZ(angle);
            mainBody.initialRotationPerformed = true;

            if (asteroid){
                const e = asteroid._options.rotation;
                var t = Math.PI;
                var r = e.period;
                var a = e.yorp || 0;
                var o = THREE.MathUtils.degToRad(e.phi0 || 0);
                var l = jd1;
                var c = jd0;
                var u = o + 2*t/r*(l-c) + 1/2*a*Math.pow(l-c,2);
            
                asteroid.get3jsObjects()[0].rotateZ(u);
            }
            
        }
    },

    updateDistances(date, object = null) {
        //const nowJD = UtilsService.dateToJulian(date);
        const vt = window['vt'];
        //const settings = VisualisationService.getSettings();
        let nowJD = UtilsService.dateToJulian(date);       
        const tool = VisualisationService.getTool();

         if (!object) {
            object = VisualisationService.getOrbitActiveObject();
        }

        if (tool === 'ovt') {
            const perturbedObjects = vt.spaceObjects.filter(o => o._id.includes('_perturbed'));          
            for (const spaceObject of perturbedObjects) {
                const trailDurationYears = spaceObject._options.orbitPathSettings.trailDurationYears;
                const leadDurationYears = spaceObject._options.orbitPathSettings.leadDurationYears;
                const kepOrbitPeriod = spaceObject._options.keplerianOrbitPeriod;
                const partOrbit = kepOrbitPeriod >= 365.25 * (trailDurationYears + leadDurationYears);

                if (spaceObject._orbit && !spaceObject._options.hideOrbit && partOrbit) {
                    if (spaceObject._orbitPath ) {
                        spaceObject._simulation.getScene().remove(spaceObject._orbitPath);
                    }
                    spaceObject._orbitPath = spaceObject._orbit.getOrbitShape(nowJD, true);
                    spaceObject._simulation.getScene().add(spaceObject._orbitPath);
                }  
            }
        }

        if (object) {
            if (tool === 'ovt')
                if (object._options.ecliptic.displayLines) {
                    object._simulation.getScene().remove(object._eclipticLines);
                    object._eclipticLines = SpacekitService.getLinesToEcliptic(object);
                    object._simulation.getScene().add(object._eclipticLines);
                }
            if (tool === 'ovt' || tool === 'sovt') {
                const newDate = UtilsService.dateToStringTechnical(date);
                nowJD = UtilsService.dateToJulian(newDate);
                if (vt.planets){
                    const earth = vt.planets.filter(e => e._id === 'earth')[0];
                    if (earth) {
                        //const xyzObject = object.getPosition(nowJD);  - causes the bug on object update or orbit refreshing
                        const simObject = SpacekitService.getObject(object._id); // the bug is no more present
                        let xyzObject;

                        if (simObject)
                            xyzObject = simObject.getPosition(nowJD);
                        else 
                            xyzObject = object.getPosition(nowJD);

                        const xyzBody = earth.getPosition(nowJD);

                        object.distance = {
                            sun: VisualisationService.distanceToCenter(xyzObject),
                            earth: VisualisationService.distanceToBody(xyzObject, xyzBody),
                            date,
                            simulationTime: VisualisationService.getSimulationTime(),
                        }

                        if (object.recentPosition && object.recentPosition[0] === xyzObject[0] && object.recentPosition[1] === xyzObject[1] && object.recentPosition[2] === xyzObject[2]) {
                            object.isStopped = true;
                        } else {
                            object.isStopped = false;
                        }

                        object.recentPosition = xyzObject;
                    }
                }
            } else if (tool === 'fvt') {
                const sun = vt.flybyScene.filter(e => e._id === 'sun')[0];
                if (sun) {
                    const xyzObject = object.getPosition(nowJD);
                    const xyzBody = sun.getPosition(nowJD);
        
                    object.distance = {
                        sun: (VisualisationService.distanceToBody(xyzObject, xyzBody) / 10000).toFixed(6),
                        earth: (VisualisationService.distanceToCenter(xyzObject) / 10000).toFixed(6),
                        date
                    }
                }
                SpacekitService.updateArrows(nowJD);
            }
        }
    },

    distanceToCenter(xyzObject, rounding = 3) {
        const [x_obj, y_obj, z_obj] = xyzObject;
        if (isNaN(x_obj) || isNaN(y_obj) || isNaN(z_obj)) {
            return '-'
        }
        return Math.sqrt(Math.pow(x_obj, 2) + Math.pow(y_obj, 2) + Math.pow(z_obj, 2)).toFixed(rounding);
    },

    distanceToBody(xyzObject, xyzBody, rounding = 3) {
        const [x_obj, y_obj, z_obj] = xyzObject;
        const [x_earth, y_earth, z_earth] = xyzBody;
        if (isNaN(x_obj) || isNaN(y_obj) || isNaN(z_obj)) {
            return '-'
        }
        return Math.sqrt(Math.pow(x_obj - x_earth, 2) + Math.pow(y_obj - y_earth, 2) + Math.pow(z_obj - z_earth, 2)).toFixed(rounding);
    },

    exportImage(invertColors) {
        HUDService.toggleWatermarks(true);
        const settings = VisualisationService.getSettings();
        let backcol = settings.background; 

        if(settings.objectsSettings.milkyWayBackground.value){
            backcol = '#0a111b';
        }
        requestAnimationFrame(() => {
            html2canvas(document.querySelector('.vt__simulation'), { scale: 1, backgroundColor: backcol }).then(canvas => {         
                const imgData = canvas.toDataURL('image/png');
                if (invertColors) {
                    const invertImage = UtilsService.invertImage(imgData);
                    invertImage.then(invertedImgData => {
                        VisualisationService.downloadImage(invertedImgData);
                    });
                } else {
                    VisualisationService.downloadImage(imgData);
                }
            });
            HUDService.toggleWatermarks(false);
        }); 
    },

    downloadImage(imgData) {
        const tool = VisualisationService.getTool();
        const toolsTitles = {
            ovt: 'OrbitVisualisationTool',
            fvt: 'FlybyVisualisationTool',
            sovt: 'SynodicOrbitVisualisationTool'
        }
        const prefix = toolsTitles[tool];
        const link = document.createElement('a');
        link.download = `${prefix}_${new Date().toISOString()}.png`;
        link.href = imgData;
        link.click();
        link.remove();
    },

    getOrbitViewerFocusedOnObject() {
        return store.getters[CommonGetters.orbitViewerFocusedOnObject];
    },

    setOrbitViewerFocusedOnObject(boolean) {
        store.dispatch(CommonActions.setOrbitViewerFocusedOnObject, boolean);
    },

    viewAxes(view) {
        const tool = VisualisationService.getTool();
        const isSovtTool = tool === 'sovt';
        const isOvtTool = tool === 'ovt';
        const isFvtTool = tool === 'fvt';
        isSovtTool && VisualisationService.setSovtFocusedObject('sun');
        isOvtTool && VisualisationService.setOvtFocusedObject('sun');
        isFvtTool && VisualisationService.setFvtFocusedObject('mainbody');
        (!isSovtTool && !isOvtTool && !isFvtTool) && VisualisationService.focusOnCenter();
        switch (view) {
            case 'x':
                SpacekitService.setCameraPosition(5, 0, -0.0000001);
                break;

            case 'y':
                SpacekitService.setCameraPosition(0, 5, -0.0000001);
                break;

            case 'z':
                SpacekitService.setCameraPosition(0, 0, 5);                
                break;
        
            default:
                break;
        }
    },

    verifyFinalAsteroidList(list) {
        const vt = window['vt'];
        const toRemove = [];
        for (const spaceObject of vt.spaceObjects) {
            const designator = spaceObject._id.indexOf('_perturbed') > 0 ? spaceObject._id.replace('_perturbed', '') : spaceObject._id;
            const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(designator);
            const isOnAsteroidList = list.filter(e => e.number == designator || e.name == designator).length;
            if (!isOnAsteroidList && !isSyntheticObject) {
                toRemove.push(designator);
            }
        }
        for (const designator of toRemove) {
            VisualisationService.checkObject(designator, false);
        }
    },

    toggleRecorderShow() {
        const show = VisualisationService.getOrbitRecorderShow();
        VisualisationService.setOrbitRecorderShow(!show);
    },    
    tooglePerturbedPropagation(disabled) {
        const settings = VisualisationService.getSettings();         
        if (disabled) { 
            settings.objectsSettings.perturbedOrbitPropagation.value = false;                 
        }
        settings.objectsSettings.perturbedOrbitPropagation.disabled = disabled;                 
    },
    getMainBodies() {
        const url = VisualisationService.getMainBodiesApiUrl();
        return axios(url)
            .then(response => {
                let mainBodies = [];
                if (response && response.data && response.data.mainBodies) {
                    mainBodies = response.data.mainBodies;
                }
                return mainBodies;
            })
    },

    setMainBody(object) {
        store.dispatch(CommonActions.setMainBody, object);
    },

    getMainBody() {
        return store.getters[CommonGetters.mainBody];
    },

    getMainBodyName() {
        const tool = VisualisationService.getTool();
        if (tool === 'fvt') {
            return store.getters[CommonGetters.selectedCloseApproach] ? store.getters[CommonGetters.selectedCloseApproach].mainBody.name : 'Earth'; 
        }
        return 'Earth';
    },

    setCloseApproachesRequest(object) {
        store.dispatch(CommonActions.setCloseApproachesRequest, object);
    },

    getCloseApproachesRequest() {
        return store.getters[CommonGetters.closeApproachesRequest];
    },

    setIsNoApproachFoundMsgVisible(isVisible) {
        store.dispatch(CommonActions.setIsNoApproachFoundMsgVisible, isVisible)
    },

    getIsNoApproachFoundMsgVisible() {
        return store.getters[CommonGetters.isNoApproachFoundMsgVisible];
    },

    async searchCloseApproaches() {
        const timeframe = TimeFrameService.getFlybyTimeframe();
        const mainBody = VisualisationService.getMainBody();
        const objects = ObjectsService.getFinalAsteroidsList();

        const paramsObj = {
            startDate: timeframe.start - 2400000.5 + '',
            endDate: timeframe.end - 2400000.5 + ''
        }

        if (mainBody) {
            const mainBodies = mainBody.map(({id}) => id);
            paramsObj.mainBody = mainBodies.join(',');
        }

        if (objects.length) {
            const names = [];
            for (const object of objects) {
                names.push(object.number || object.name);
            }
            paramsObj.names = names.join();
        }

        const url = VisualisationService.getCloseApproachesApiUrl() + UtilsService.parseParams(paramsObj);
        SpinnerService.show();
        VisualisationService.setIsNoApproachFoundMsgVisible(false);
        await axios(url)
            .then(response => {
                let closeApproaches = [];
                if (response && response.data && response.data.closeApproaches) {
                    closeApproaches = response.data.closeApproaches;
                }
                if (!closeApproaches.length) {
                    VisualisationService.setIsNoApproachFoundMsgVisible(true);
                    if(!VisualisationService.getSelectObjectsShow()){
                        PopupService.show({
                            component: 'PopupInfo',
                            type: 'info',
                            message: Keys.t.noCloseApproachesDetected
                        });
                    }
                }
                VisualisationService.setCloseApproaches(closeApproaches);
                VisualisationService.setSelectedCloseApproach(null);
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                SpinnerService.hide();
            });
    },

    setCloseApproaches(array) {
        store.dispatch(CommonActions.setCloseApproaches, array);
    },

    getCloseApproaches() {
        return store.getters[CommonGetters.closeApproaches];
    },

    setSelectedCloseApproach(object, reloaded = false) {
        if (!object) {
            return;
        }
        const isSyntheticObject = !!object.objectName;
        if (isSyntheticObject) {
            VisualisationService.computeUserDefinedCloseApproach(reloaded);
            return;
        }
        if (object) {
            const name = object.designator || object.name;
            const date = object.datetime.split('.')[0].replace(/\//g, '');
            const center = object.mainBody.id;
            const url = `${VisualisationService.getFlybyApiUrl()}?name=${name}&date=${date}&center=${center}`;
            let axiosSource = axios.CancelToken.source();
            VisualisationService.setCloseApproachesRequest(axiosSource);

            SpinnerService.show(true);
            axios(url, {
                cancelToken: axiosSource.token,
              })
                .then(response => {
                    const data = response.data;
                    if (!data || !data.asteph || !data.asteph.length) {
                        throw Keys.t.closeApproachDataNotFound;
                    } else {
                        SpacekitService.removeFlybyScene();
                        const jds = data.asteph.map(e => e.t);
                        object.jds = jds;
                        store.dispatch(CommonActions.setSelectedCloseApproach, object);
                        VisualisationService.getAsteroidShape(name).then(shape => {
                            const objectName = ObjectsService.objectNumberAndName(object);
                            const designator = object.number || object.designator || object.name;
                            SpacekitService.createFlybyScene(jds, center, data.suneph, data.mooneph, data.asteph, shape, objectName, designator, data.diam, data.mag, data.sundir, data['3d-ellsun']);
                            const vt = window['vt'];
                            const asteroid = vt.flybyScene.filter(e => e._id === 'asteroid')[0];
                            VisualisationService.setOrbitActiveObject(asteroid);
                            //VisualisationService.focusOnCenter();
                                         
                            VisualisationService.setOrientationFocus(reloaded);
                        });
                    }
                })
                .catch(error => {
                    const message = UtilsService.prepareErrorMessage(error);
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message
                    });
                })
                .finally(() => {
                    SpinnerService.hide();
                });
        }
    },

    async computeUserDefinedCloseApproach(reloaded = false) {
        store.dispatch(CommonActions.setSelectedCloseApproach, null);
        VisualisationService.setOrbitActiveObject(null);
        SpacekitService.removeFlybyScene();
        const isAboveLdLimit = SyntheticObjectsService.checkIsFlybyAboveLimit();
        SyntheticObjectsService.setIsSyntheticObjectFlybyAboveLdLimit(isAboveLdLimit);
        if (isAboveLdLimit) {
            return;
        }
        const apiParameters = SyntheticObjectsService.getSyntheticObjectFvtApiParameters();
        const apiUrl = SyntheticObjectsService.getFvtSyntheticObjectApiUrl() + UtilsService.parseParams(apiParameters);
        const axiosSource = axios.CancelToken.source();
        VisualisationService.setCloseApproachesRequest(axiosSource);
        SpinnerService.show(true);
        await axios(apiUrl, {cancelToken: axiosSource.token}).then(response => {
            const data = response.data;
            if (!data || !data.asteph || !data.asteph.length) {
                throw Keys.t.closeApproachDataNotFound;
            } else {
                const loadedState = {...MemoryService.getToolState()};
                const flybyTime = reloaded && loadedState.activeCurrentDate ? loadedState.activeCurrentDate : null; 
                const syntheticObjectData = UtilsService.deepCopy(SyntheticObjectsService.getSyntheticFvtObjectsList()[0]);
                SpacekitService.removeFlybyScene();
                const jds = data.asteph.map(e => e.t);
                syntheticObjectData.jds = jds;
                syntheticObjectData.mainBody = SyntheticObjectsService.getSyntheticMainBody();
                store.dispatch(CommonActions.setSelectedCloseApproach, syntheticObjectData);
                VisualisationService.getAsteroidShape(syntheticObjectData.objectName).then(shape => {
                    const objectName = syntheticObjectData.objectName;
                    const designator = syntheticObjectData.designator;
                    const center = SyntheticObjectsService.getSyntheticMainBody().id;
                    SpacekitService.createFlybyScene(jds, center, data.suneph, data.mooneph, data.asteph, shape, objectName, designator, data.diam, data.mag || syntheticObjectData.mag, data.sundir, data['3d-ellsun'], true, flybyTime);
                    const vt = window['vt'];
                    const asteroid = vt.flybyScene.filter(e => e._id === 'asteroid')[0];
                    VisualisationService.setOrbitActiveObject(asteroid);
                    VisualisationService.setOrientationFocus(reloaded);

                    if (reloaded) {
                        const focusedObject = loadedState.fvtFocusedObject;
                        flybyTime && TimeFrameService.setRestoreFlybyTime(flybyTime);
                        focusedObject && VisualisationService.setFvtFocusedObject(focusedObject);
                    }
                });
            }

        }).catch(error => {
            const message = UtilsService.prepareErrorMessage(error);
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message
                    });
        }).finally(() => SpinnerService.hide());
    },

    getAsteroidShape(name) {
        const url = `${VisualisationService.getShapeUrl()}?names=${name}`;
        return axios(url)
            .then(response => {
                return response.data;
            });
    },

    getSelectedCloseApproach() {
        return store.getters[CommonGetters.selectedCloseApproach];
    },

    getMainBodyParameters(id) {
        return Config.mainBodiesParameters[id];
    },

    getFlybyClosestPoint() {
        return store.getters[CommonGetters.flybyClosestPoint];
    },

    setFlybyClosestPoint(jd) {
        store.dispatch(CommonActions.setFlybyClosestPoint, jd);
    },

    getMinCameraDistance() {
        return store.getters[CommonGetters.minCameraDistance];
    },

    setMinCameraDistance(units) {
        store.dispatch(CommonActions.setMinCameraDistance, units);
    },

    getZoom() {
        return store.getters[CommonGetters.zoom];
    },

    setZoom(zoom) {
        store.dispatch(CommonActions.setZoom, zoom);
    },

    getZoomOVT() {
        return store.getters[CommonGetters.zoomOVT];
    },

    setZoomOVT(zoom) {
        store.dispatch(CommonActions.setZoomOVT, zoom);
    },

    getZoomFVT() {
        return store.getters[CommonGetters.zoomFVT];
    },

    setZoomFVT(zoom) {
        store.dispatch(CommonActions.setZoomFVT, zoom);
    },

    setInverseColourMarker(boolean){
        store.dispatch(CommonActions.setInverseColourMarker, boolean);
    },

    getInverseColourMarker(){
        return store.getters[CommonGetters.inverseColourMarker];
    },
    getInvertColorsOptionsList() {
        return Config.visualisation.settings.invertColors;
    },

    getDetectionPolarColor() {
        const settings = VisualisationService.getSettingsDefaults();
        const color = settings.objectsSettings.detectionPolarProperties.children.color.value;
        return UtilsService.convertHexColorToHexNumber(color);
    },

    getDetectionPolarTransparency() {
        const defaultSettings = VisualisationService.getSettingsDefaults();
        return defaultSettings.objectsSettings.detectionPolarProperties.children.transparency.value;
    },

    getOrbitRangeColor() {
        const color = SynodicCalculationService.ORBIT_RANGE_COLOR;
        return UtilsService.convertHexColorToHexNumber(color);
    },

    getSelectedSynodicObjectName() {
        return store.getters[CommonGetters.selectedSynodicObjectName];
    },

    setSelectedSynodicObjectName(value) {
        return store.dispatch(CommonActions.setSelectedSynodicObjectName, value);
    },

    getSovtFocusedObject() {
        return store.getters[CommonGetters.sovtFocusedObject];
    },

    setSovtFocusedObject(focusedObject = '') {
        store.dispatch(CommonActions.setSovtFocusedObject, focusedObject);
    },

    getOvtFocusedObject() {
        return store.getters[CommonGetters.ovtFocusedObject];
    },

    setOvtFocusedObject(focusedObject = '') {
        store.dispatch(CommonActions.setOvtFocusedObject, focusedObject);
    },

    getFvtFocusedObject() {
        return store.getters[CommonGetters.fvtFocusedObject];
    },

    setFvtFocusedObject(focusedObject = '') {
        store.dispatch(CommonActions.setFvtFocusedObject, focusedObject);
    },

    verifyVisiblePerturbedObjects(currentDate) {
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        const vt = window["vt"];
        const isCheckedList = VisualisationService.getIsChecked();
        const visibleRealSynodicObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        const visibleObjects = [...isCheckedList, ...visibleRealSynodicObjectsList];
        if (!visibleObjects.length) {
            return;
        }
        const perturbedOrbitList = SynodicCalculationService.getPerturbedSynodicOrbitList();

        visibleObjects.forEach((objectDesignator) => {
            let object = UtilsService.findItemInObjectList(
                "_id",
                SynodicCalculationService.getTransformedSpaceObjectId(objectDesignator, true),
                vt.spaceObjects
            )
            
            if (!object) {
                object = vt.spaceObjects.filter((obj) => obj._obj.name === objectDesignator)[0];
            }

            const perturbedObjectsList = VisualisationService.getIsPerturbed();
            const isPerturbedOrbit = perturbedOrbitList.includes(objectDesignator) || perturbedObjectsList.includes(objectDesignator);
            if (!isPerturbedOrbit) {
                return;
            }

            if (!object || !object.impactDate) {
                return;
            }

            const currentTimestamp = new Date(currentDate).getTime();
            const impactTimestamp = new Date(object.impactDate).getTime();
            const isAfterImpact = currentTimestamp > impactTimestamp;
            
            const isObjectVisible = object._object3js.visible;
            if (isAfterImpact && isObjectVisible) {
                object._object3js.visible = false;
                HUDService.removeLabel(object);
                return;
            }
            if (!isAfterImpact && !isObjectVisible) {
                object._object3js.visible = true;
                HUDService.createLabel(
                    object,
                    UtilsService.hexColor(objectsSettings.objectNames.color),
                    objectsSettings.objectNamesSize.value
                );
                return;
            }
        });
    },
}

export default VisualisationService;
