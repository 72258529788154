<template>
    <div class="longterm-plots">
        <div class="longterm-plots__controls">
            <div v-for="plot of longtermDefaultPlotsKeys" :key="plot" class="longterm-plots__handler">
                <LongtermSwitch v-if="Object.keys(object).length" :plot="plot" :object="object" :key="longtermObjectName" @switch="onSwitch(plot, $event.target.checked)" />
                <LongtermSlider v-if="Object.keys(object).length" :plot="plot" :object="object" :key="longtermObjectName" @update="onUpdate(plot, $event)" @mouseover="onMouseOver(plot)" @mouseleave="onMouseLeave(plot)"/>
            </div>
            <button class="longterm-plots__reset-values primary" @click="onReset()">Reset values</button>
        </div>
        <div class="longterm-plots__visuals" :class="{'with-chart': visualiseOnChart, 'with-y-axis': chartAdvancedFeatures && longtermCurrentPlot !== ''}">
            <div class="longterm-plots__chart">
                <div class="longterm-plots__chart--header">
                    <LongtermHeader v-if="Object.keys(object).length" :object="object" />
                </div>
                <div class="longterm-plots__chart--content">
                    <LongtermChartYAxis v-if="Object.keys(object).length" :object="object" />
                    <LongtermChart v-if="Object.keys(object).length" :object="object" />
                </div>
            </div>
            <div class="longterm-plots__bars">
                <div class="longterm-plots__bars--content">
                    <template v-for="plot of longtermDefaultPlotsKeys" :key="plot">
                        <LongtermBar v-if="Object.keys(object).length" :plot="plot" :object="object" />
                    </template>
                </div>
                <div class="longterm-plots__bars--footer">
                    <LongtermLegend v-if="Object.keys(object).length" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LongtermService from '@/services/longterm.service'
import LongtermSwitch from '@/components/opt/results/LongtermSwitch'
import LongtermSlider from '@/components/opt/results/LongtermSlider'
import LongtermLegend from '@/components/opt/results/LongtermLegend'
import LongtermBar from '@/components/opt/results/LongtermBar'
import LongtermHeader from '@/components/opt/results/LongtermHeader'
import LongtermChart from '@/components/opt/results/LongtermChart'
import LongtermChartYAxis from '@/components/opt/results/LongtermChartYAxis'

export default {
    name: 'ResultsLongTermPlots',
    components: {
        LongtermSwitch,
        LongtermSlider,
        LongtermLegend,
        LongtermBar,
        LongtermHeader,
        LongtermChart,
        LongtermChartYAxis,
    },
    data() {
        return {
            object: {},
            observable: false,
        }
    },
    computed: {
        longtermObjectName() {
            return LongtermService.getLongtermObjectName();
        },
        longtermDefaultPlots() {
            return LongtermService.getDefaultLongtermPlots();
        },
        longtermDefaultPlotsKeys() {
            return LongtermService.getDefaultLongtermPlotsKeys();
        },
        longtermEphemerides() {
            return LongtermService.getLongtermEphemerides();
        },
        visualiseOnChart() {
            return LongtermService.getLongtermVisualiseOnChart();
        },
        longtermCurrentPlot() {
            return LongtermService.getLongtermCurrentPlot();
        },
        chartAdvancedFeatures() {
            return LongtermService.chartAdvancedFeatures();
        },
    },
    methods: {
        onSwitch(plot, value) {
            this.object.plots[plot].enable = value;
            LongtermService.updateObservable(this.object, null);
        },
        onUpdate(plot, value) {
            this.object.plots[plot].value = value / 10;
            LongtermService.updateObservable(this.object, plot);
        },
        onReset() {
            for (const [key, plot] of Object.entries(this.object.plots)) {
                const rounding = key === 'SKY_MOV' ? 3 : 1;
                plot.value = (plot.min + ((plot.max - plot.min) / 2)).toFixed(rounding)*1;
                LongtermService.updateObservable(this.object, key);
            }
        },
        onMouseOver(plot) {
            if (plot !== this.longtermCurrentPlot) {
                LongtermService.setLongtermCurrentPlot(plot);
            }
        },
        onMouseLeave() {
            LongtermService.setLongtermCurrentPlot('');
        }
    },
    watch: {
        longtermEphemerides(newVal) {
            this.object = newVal[this.longtermObjectName];
        },
        longtermObjectName(newVal) {
            this.object = this.longtermEphemerides[newVal];
            LongtermService.updateObservable(this.object, null);
        }
    },
    mounted() {
        const activePlots = LongtermService.getLongtermActivePlots() || [];
        if (!activePlots.length) {
            LongtermService.setLongtermActivePlots(this.longtermDefaultPlotsKeys);
        }
        if (!LongtermService.getLongtermEphemerides(this.longtermObjectName)){
            LongtermService.buildLongtermEphemerides();
        }
        this.object = this.longtermEphemerides[this.longtermObjectName];
        LongtermService.updateObservable(this.object, null);
    },
}
</script>

<style>

</style>