<template>
    <div v-if="isVisible" class="focus-selector">
        <span class="focus-selector__label">{{ $t.focusOn }}</span>
        <Multiselect
            class="focus-selector__input"
            v-model="selectedObject"
            :searchable="true"
            :canClear="false"
            :canDeselect="false"
            :placeholder="'Please select'"
            :options="selectOptions"
            openDirection="top"
            @select="onSelect($event)"
        >
        </Multiselect>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import ObjectsService from "@/services/objects.service";
import SpacekitService from "@/services/spacekit.service";
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "FlybyFocusSelector",
    components: {
        Multiselect,
    },
    props: {
        type: String,
        selectedType: String,
    },
    data() {
        return {
            selectedObject: "",
            sunSelectOption: { label: "Sun", value: "sun" },
        };
    },
    computed: {
        isVisible() {
            let isVisible = false;
            if (this.type === "user-defined") {
                isVisible = SyntheticObjectsService.checkIsSyntheticObjectFlybyCalculated();
            }
            if (this.type === "database") {
                isVisible = this.selectedCloseApproach && this.isNonSyntheticObjectFlybyCalculated;
            }
            return isVisible;
        },

        selectedCloseApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },

        focusedObject() {
            return VisualisationService.getFvtFocusedObject();
        },

        isNonSyntheticObjectFlybyCalculated() {
            return SyntheticObjectsService.checkIsNonSyntheticObjectFlybyCalculated();
        },

        selectOptions() {
            const selectOptions = [];
            if (!this.selectedCloseApproach) {
                return selectOptions;
            }
            selectOptions.push({
                label:
                    this.type === "database"
                        ? ObjectsService.objectNumberAndName(this.selectedCloseApproach)
                        : this.selectedCloseApproach.objectName,
                value: "asteroid",
            });
            this.selectedCloseApproach.mainBody.id === "earth" && selectOptions.push({ label: "Moon", value: "moon" });
            selectOptions.push({ label: this.selectedCloseApproach.mainBody.name, value: "mainbody" });
            selectOptions.push(this.sunSelectOption);
            return selectOptions;
        },

        isCurrentTypeSelected() {
            return this.selectedType === this.type;
        },
    },
    methods: {
        onSelect(selectedObject) {
            if (!this.isCurrentTypeSelected) {
                return;
            }
            VisualisationService.setFvtFocusedObject(selectedObject);
        },

        setFvtFocusedObject(focusedObject) {
            if (!focusedObject) {
                return;
            }
            if (focusedObject === "mainbody") {
                VisualisationService.focusOnCenter(true);
                return;
            }
            SpacekitService.focusOnObject(focusedObject, true);
        },
    },
    watch: {
        focusedObject(objectName) {
            this.selectedObject = objectName;
            this.setFvtFocusedObject(objectName);
        },
    },
    mounted() {
        this.selectedObject = VisualisationService.getFvtFocusedObject();
    },
};
</script>

<style lang="scss">
@import "@/styles/common/focus-selector.scss";
</style>
