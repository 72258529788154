<template>
    <div class="sovt__charts-switcher switcher">
        <span class="switcher__label" :class="{ 'switcher__label--active': !isChecked() }">{{ $t.actualDates }}</span>
        <div class="switcher__wrapper">
            <input class="toggle-switch" id="sovtChartMode" type="checkbox" :checked="isChecked()"
                @change="onChange($event.target.checked)" />
            <label class="toggle-switch__button switcher__button" for="sovtChartMode"></label>
        </div>
        <span class="switcher__label" :class="{ 'switcher__label--active': isChecked() }">{{ $t.daysNumber }}</span>
    </div>
</template>

<script>
import SynodicChartService from "@/services/synodic-chart.service";

export default {
    name: "SynodicOrbitChartsSwitcher",
    props: {
        isParametersProvided: Boolean,
    },
    methods: {
        isChecked() {
            return SynodicChartService.getSynodicOrbitChartsSettings()
                .daysNumberMode;
        },
        onChange(isChecked) {
            SynodicChartService.setSynodicOrbitChartsSettings({
                daysNumberMode: isChecked,
            });
        },
    },
};
</script>

<style lang="scss">
@import "@/styles/sovt/charts-switcher.scss";
</style>
