<template>
    <input type="number" :readonly="coordinate[type] === '.'" v-model="coordinate[type]" :min="getBounds('min')" :max="getBounds('max')" @change="setCustomObservatory(type)" />
</template>

<script>
import ObservatoryService from '@/services/observatory.service'

export default {
    name: 'CustomCoordinate',
    props: {
        type: String
    },
    data() {
        return {
            coordinate: {
                latitude: 51.47793,
                longitude: 0,
                altitude: 2000,
            },
            bounds: {
                latitude: {
                    min: -90,
                    max: 90,
                },
                longitude: {
                    min: -180,
                    max: 180,
                },
                altitude: {
                    min: -500,
                    max: 1000000,
                }
            }
        }
    },
    computed: {
        selectedObservatory() {
            return ObservatoryService.getSelectedObservatory();
        },
        isGeocentric() {
            return ObservatoryService.isGeocentric();
        },
        observatoryElevation() {
            return ObservatoryService.getObservatoryElevation();
        }
    },
    watch: {
        selectedObservatory(newVal) {
            this.coordinate.latitude = this.isGeocentric ? '.' : newVal.latitude;
            this.coordinate.longitude = this.isGeocentric ? '.' : newVal.longitude;
            if (newVal.alt) {
                this.coordinate.altitude = newVal.alt;
            } else {
                this.coordinate.altitude = this.isGeocentric ? '.' : this.coordinate.altitude;
            }
        }
    },
    methods: {
        minMaxLimiter(type) {
            if (isNaN(this.coordinate[type]) || this.coordinate[type] === '') {
                this.coordinate[type] = 0;
            } else {
                if (this.coordinate[type] < this.bounds[type].min) {
                    this.coordinate[type] = this.bounds[type].min;
                }
                if (this.coordinate[type] > this.bounds[type].max) {
                    this.coordinate[type] = this.bounds[type].max;
                }
                if (type === 'altitude') {
                    this.coordinate[type] = this.coordinate[type].toFixed(0);
                } else {
                    this.coordinate[type] = this.coordinate[type].toFixed(5);
                }
            }
        },
        setCustomObservatory(type) { 
            this.minMaxLimiter(type);
            ObservatoryService.setUserDefinedObservatory(this.coordinate.latitude, this.coordinate.longitude);
            if (type === 'altitude') {
                ObservatoryService.setObservatoryElevation(this.coordinate.altitude);
            }
        },
        getBounds(bound) {
           return this.bounds[this.type][bound];
        }
    },
    mounted() {
        this.coordinate.latitude = this.isGeocentric ? '.' : this.selectedObservatory.latitude * 1;
        this.coordinate.longitude = this.isGeocentric ? '.' : this.selectedObservatory.longitude * 1;
        this.coordinate.altitude = this.isGeocentric ? '.' : this.observatoryElevation * 1;
    }
}
</script>

<style>

</style>