<template>
    <div>
        <input class="toggle-switch" id="object-tracking" type="checkbox" v-model="objectTrackingSwitch"/>
        <label class="toggle-switch__button" for="object-tracking"></label>
        <span class="toggle-switch__label" @click="toggleObjectTracking()">{{ $t.objectTracking }}</span>
    </div>
    <div>
        <input class="toggle-switch" id="dms-format" type="checkbox" v-model="dmsFormatSwitch"/>
        <label class="toggle-switch__button" for="dms-format"></label>
        <span class="toggle-switch__label" @click="toggleDmsFormat()">
            <Tooltip position="top" :tooltipText="`${$keys.tooltips.sexagesimalCoordinates}`">{{ $t.dmsFormat }}</Tooltip>
        </span>
    </div>
</template>

<script>
import SkychartService from '@/services/skychart.service'
import Config from '@/constants/config'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'SkyChartDatapointsSwitches',
    components: {
        Tooltip
    },
    data() {
        return {
            objectTrackingSwitch: true,
            dmsFormatSwitch: false,
        }
    },
    computed: {
        objectTracking() {
            return SkychartService.getObjectTracking();
        },
        dmsFormat() {
            return SkychartService.getDmsFormat();
        }
    },
    methods: {
        toggleObjectTracking() {
            this.objectTrackingSwitch = !this.objectTrackingSwitch;
        },
        toggleDmsFormat() {
            this.dmsFormatSwitch = !this.dmsFormatSwitch;
        }
    },
    watch: {
        objectTrackingSwitch(newVal) {
            SkychartService.setObjectTracking(newVal);
        },
        dmsFormatSwitch(newVal) {
            SkychartService.setDmsFormat(newVal);
        }
    },
    mounted() {
        if (this.objectTracking === null) {
            SkychartService.setObjectTracking(Config.visualisation.objectTracking);
        }
        if (this.dmsFormat === null) {
            SkychartService.setDmsFormat(Config.visualisation.dmsFormat);
        }
        this.objectTrackingSwitch = this.objectTracking;
        this.dmsFormatSwitch = this.dmsFormat;
    }
}
</script>

<style>

</style>