<template>
    <div
        v-if="orbitLegend && !onAir"
        class="ovt__legend perturbed"
        :class="{
            'with-config': configPanelOpen,
            'with-details': objectDetails && activeObject,
            'with-orbit-reliable': objectDetails && activeObject && activeObject._useEphemTable,
            'with-close-approach-detected': objectDetails && activeObject && activeObject._useEphemTable && closeApproachDetected(activeObject.distance.date)
        }">
        <div class="ovt__legend--label">{{ $t.orbitLegend }}</div>
        <div class="d-table">
            <div class="d-table-row">
                <div class="d-table-cell">
                    <span class="ovt_legend orbit-marker keplerian" :style="keplerianColor"></span>
                    <span class="ovt_legend orbit-marker keplerianlight" :style="keplerianLightColor"></span>
                    <!--span class="ovt_legend orbit-marker keplerianlight" style="display: block; border-bottom: 2px solid; border-color: #BCE7F1;"></span-->
                </div>
                <div class="d-table-cell">{{ $t.keplerian }} {{ $t.orbits }}</div>
                <Tooltip position="top" :tooltipText="$keys.tooltips.keplerianOrbit"><i class="icon-info" /></Tooltip>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">
                    <span class="ovt_legend orbit-marker perturbed" :style="perturbedColor"></span>
                    <span class="ovt_legend orbit-marker perturbedlight" :style="perturbedLightColor"></span>
                    <!--span class="ovt_legend orbit-marker perturbedlight" style="display: block; border-bottom: 2px solid; border-color: #FDCECF;"></span-->
                </div>
                <div class="d-table-cell">{{ $t.perturbed }} {{ $t.orbits }}</div>
                <Tooltip position="top" :tooltipText="$keys.tooltips.perturbedOrbit"><i class="icon-info" /></Tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'
import UtilsService from '@/services/utils.service'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'OrbitPerturbedLegend',
    computed: {
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        settings() {
            return VisualisationService.getSettings();
        },
        orbitLegend() {
            return this.settings && this.settings.objectsSettings.orbitLegend.value;
        },
        objectDetails() {
            return this.settings && this.settings.objectsSettings.objectDetails.value;
        },
        activeObject() {
            return VisualisationService.getOrbitActiveObject();
        },
        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        },
        keplerianColor() {            
            return {
                display: 'block',
                backgroundColor: this.settings.objectsSettings.keplerianOrbits.color
            };
        },        
        keplerianLightColor() {            
            return {
                display: 'block',
                borderBottom: '2px solid',
                borderColor: UtilsService.applyOpacityToHexColor(this.settings.objectsSettings.keplerianOrbits.color,0.4),
            };            
        }, 
        perturbedColor() {
            return {
                display: 'block',
                backgroundColor: this.settings.objectsSettings.perturbedOrbits.color
            };
        },
        perturbedLightColor() {
            return {
                display: 'block',
                borderBottom: '2px solid',
                borderColor: UtilsService.applyOpacityToHexColor(this.settings.objectsSettings.perturbedOrbits.color,0.4),
            };            
        },         
    },
    methods: {
        closeApproachDetected(date) {
            const mjd = parseInt(UtilsService.dateToJulian(date)) - 2400000;
            const closeApproaches = this.activeObject._options.closeApproachTable
            let closeApproachDetected = null;
            if (closeApproaches)
                closeApproachDetected = closeApproaches.indexOf(mjd) > -1;
            return closeApproachDetected;
        },
    },
    components: {
        Tooltip
    }
}
</script>

