<template>
    <div class="objects-header">
        <div class="objects-header__summary">
            <ObjectsSummary />
        </div>
        <div class="objects-header__timeframe">
            <TimeFrameDisplay />
        </div>
        <div class="objects-header__calculate">
            <div class="objects-header__calculate--clearing">
                <ResetAll />
                <ClearAllFilters />
            </div>
            <TimestepSetup :tool="'opt'" />
            <CalculateEphemerides />
        </div>
    </div>
</template>

<script>
import TimeFrameDisplay from '@/components/opt/filtering/TimeFrameDisplay'
import CalculateEphemerides from '@/components/opt/filtering/CalculateEphemerides'
import ObjectsSummary from '@/components/opt/filtering/ObjectsSummary'
import TimestepSetup from '@/components/common/setup/TimestepSetup'
import ResetAll from '@/components/opt/filtering/ResetAll'
import ClearAllFilters from '@/components/opt/filtering/ClearAllFilters'

export default {
    name: 'ObjectsHeader',
    components: {
        TimeFrameDisplay,
        TimestepSetup,
        CalculateEphemerides,
        ResetAll,
        ClearAllFilters,
        ObjectsSummary
    }
}
</script>

<style>

</style>