<template>
    <div class="plots-date">
        <i class="icon-calendar" />
        <span class="plots-date__handler" @click="prevDay()">
            <i class="icon-chevron-left" />
        </span>
        <span class="plots-date__display">
            {{ date }}
        </span>
        <span class="plots-date__handler" @click="nextDay()">
            <i class="icon-chevron-right" />
        </span>
        <div class="plots-date__time" v-if="showTwilightHours()">
            <Tooltip position="top" :tooltipText="`${$t.astronomicalNights}`" >
                <div>{{ dusk.local }} — {{ dawn.local }}</div>
            </Tooltip>
            <div>({{ timezoneFormatted() }})</div>
        </div>
    </div>
</template>

<script>
import ShorttermService from '@/services/shortterm.service'
import TimeFrameService from '@/services/timeframe.service'
import UtilsService from '@/services/utils.service'
import Tooltip from '@/components/common/Tooltip.vue'

export default {
    name: 'ShortTermDate',
    data() {
        return {
            dusk: '',
            dawn: '',
            date: '',
        }
    },
    components: {
        Tooltip
    },
    computed: {
        shorttermDate() {
            return ShorttermService.getShorttermDate();
        },
        startDay() {
            return TimeFrameService.getTimeFrame().start;
        },
        endDay() {
            return TimeFrameService.getTimeFrame().end;
        },
        timezoneShift() {
            return TimeFrameService.getTimezoneShift(this.shorttermDate);
        },
    },
    methods: {
        prevDay() {
            const prevDay = TimeFrameService.getPrevDay(this.shorttermDate);
            const timestampPrev = (new Date(prevDay)).getTime();
            const timestampStart = (new Date(this.startDay)).getTime();
            if (timestampPrev >= timestampStart) {
                ShorttermService.setShorttermDate(prevDay);
            }
        },
        nextDay() {
            const nextDay = TimeFrameService.getNextDay(this.shorttermDate);
            let lastDay = TimeFrameService.getPrevDay(this.endDay);
            lastDay = TimeFrameService.getNextDay(lastDay);
            const timestampNext = (new Date(nextDay)).getTime();
            const timestampEnd = (new Date(lastDay)).getTime();
            if (timestampNext <= timestampEnd) {
                ShorttermService.setShorttermDate(nextDay);
            }
        },
        timezoneFormatted() {
            const sign = this.timezoneShift < 0 ? '' : '+';
            const hours = (this.timezoneShift+'').length === 1 ? '0'+this.timezoneShift : this.timezoneShift;
            return `GMT${sign}${hours}:00`;
        },
        showTwilightHours() {
            return !(this.dusk === 'SUN_HIGH' || this.dawn === 'SUN_HIGH' || this.dusk.local === this.dawn.local);
        }
    },
    watch: {
        shorttermDate(newVal) {
            this.dusk = ShorttermService.getAstronomicalDusk(newVal);
            this.dawn = ShorttermService.getAstronomicalDawn(newVal);
            this.date = UtilsService.dateToString(newVal);
        }
    },
    mounted() {
        ShorttermService.setShorttermDate(this.startDay);
        this.dusk = ShorttermService.getAstronomicalDusk(this.shorttermDate);
        this.dawn = ShorttermService.getAstronomicalDawn(this.shorttermDate);
        this.date = UtilsService.dateToString(this.shorttermDate);
    }
}
</script>

<style>

</style>