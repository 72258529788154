<template>
    <div class="ephemerides-header">
        <div class="ephemerides-header__switch">
            <EphemeridesSwitch />
        </div>
        <div class="ephemerides-header__config">
            <EphemeridesConfig />
        </div>
        <div class="ephemerides-header__download">
            <EphemeridesDownload />
        </div>
    </div>
</template>

<script>
import EphemeridesSwitch from '@/components/opt/results/EphemeridesSwitch'
import EphemeridesConfig from '@/components/opt/results/EphemeridesConfig'
import EphemeridesDownload from '@/components/opt/results/EphemeridesDownload'

export default {
    name: 'EphemeridesHeader',
    components: {
        EphemeridesSwitch,
        EphemeridesConfig,
        EphemeridesDownload
    }
}
</script>

<style>

</style>