import axios from 'axios'
import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import SpinnerService from '@/services/spinner.service'
import TimeFrameService from '@/services/timeframe.service'
import ObservatoryService from '@/services/observatory.service'
import PopupService from '@/services/popup.service'
import ObjectsService from '@/services/objects.service'
import Config from '@/constants/config'
import CalculateService from '@/services/calculate.service'
import UtilsService from '@/services/utils.service'

const FilteringService = {
    getFilteringApiUrl() {
        return Config.api.restUrl + Config.api.filtering;
    },

    getPreviousObservationUrl(object) {
        if (object.sourceType && object.sourceType.name === 'NEOCP') {
            return Config.previousObservationUrl.NEOCP;
        } else if (object.sourceType && object.sourceType.name === 'JPL') {
            return object.bodyType === 'Comet' ?
            Config.previousObservationUrl.JPLComet :
            Config.previousObservationUrl.JPL;
        } else {
            return Config.previousObservationUrl.default;
        }
    },

    getFiltersEphemeridesMap() {
        return Config.filtersEphemeridesMap;
    },

    getFilters() {
        return store.getters[CommonGetters.filters];
    },

    getPaginationFrom() {
        return store.getters[CommonGetters.paginationFrom];
    },

    getPaginationSize() {
        return Config.pagination.size;
    },

    getInitialLoadSize() {
        return Config.pagination.initialLoadSize;
    },

    setPaginationFrom(from) {
        store.dispatch(CommonActions.setPaginationFrom, from);
    },

    getToken() {
        return store.getters[CommonGetters.token];
    },

    setToken(token) {
        store.dispatch(CommonActions.setToken, token);
    },

    getTokenCancelled() {
        return store.getters[CommonGetters.tokenCancelled];
    },

    setTokenCancelled(token) {
        store.dispatch(CommonActions.setTokenCancelled, token);
    },

    getPaginationLoading() {
        return store.getters[CommonGetters.paginationLoading];
    },

    setPaginationLoading(value) {
        store.dispatch(CommonActions.setPaginationLoading, value);
    },

    getLastCalculatedEphemerides() {
        return store.getters[CommonGetters.lastCalculatedEphemerides];
    },

    setLastCalculatedEphemerides(boolean) {
        store.dispatch(CommonActions.setLastCalculatedEphemerides, boolean);
    },

    setFilters(filters) {
        store.dispatch(CommonActions.setFilters, filters);
    },
getEmptyFilter() {
        return store.getters[CommonGetters.emptyFilter];
    },
    setEmptyFilter(value) {
        store.dispatch(CommonActions.setEmptyFilter, value);
    },
    setDefaultFilterValues() {
        const filters = FilteringService.getFilters();
        FilteringService.setEmptyFilter(true); 
        for (const filterId in Config.filters) {
            const filter = Config.filters[filterId];
            if (filterId !== 'densityOfStars' && filterId !== 'moonglow' && filterId !== 'skyUncertainty') {
                const checkedFields = document.querySelectorAll('[name="'+filterId+'"]:checked');
                const checkedArray = [];
                for (const checkedField of checkedFields) {
                    //checkedArray.push(encodeURIComponent(checkedField.value));
                    if (filter.type !== 'checkbox'){
                        if (checkedField.value === 'N/A'){
                            checkedArray.push(true);
                        }
                        else
                            checkedArray.push(false);
                    } else {
                        checkedArray.push(checkedField.value);
                    }
                }

                if ((!checkedFields||!checkedFields.length) && (filter.type !== 'checkbox')){
                    checkedArray.push(true);
                }
        
                if (filter.type === 'range') {                
                    if (filter.defaultOperator === 'lt') {
                        filters[filterId] = {
                            [filterId+'Min']: filter.min * 1,
                            [filterId+'Max']: filter.default * 1,
                            [filterId+'NA']: checkedArray,
                        }
                    } else {
                        filters[filterId] = {
                            [filterId+'Min']: filter.default * 1,
                            [filterId+'Max']: filter.max * 1,
                            [filterId+'NA']: checkedArray,
                        }
                    }
                }
                else if (filter.type === 'minValue') {
                    filters[filterId] = {
                        [filterId+'Min']: filter.min * 1,
                        [filterId+'Max']: null,
                        [filterId+'NA']: checkedArray,
                    }
                }
                else if (filter.type === 'checkbox') {                                        
                    filters[filterId] = filter.options;      
                }                               
            }
        }
        FilteringService.setFilters(filters);
    },

    getInitialObjectsList() {
        const asteroidsNames = ObjectsService.getAsteroidsNames();
        const userDefinedObjects = ObjectsService.getUserDefinedObjects();
        if (!userDefinedObjects || !asteroidsNames || asteroidsNames == '') {
            FilteringService.setDefaultFilterValues();
        }
        FilteringService.setLastCalculatedEphemerides(false);
        FilteringService.runFilteringRequest();
        ObjectsService.setObjectsInitialLoadPerformed(true);
        CalculateService.setEnableOptResults(false);
    },

    runFilteringRequest(requestConfig = {}, shouldMakeBackendRequest=true) {        
        const pagination = requestConfig.pagination || false;
        if (!pagination) {
            FilteringService.setPaginationFrom(0);
        }

        const configObj = {};
        const paramsObj = {
            header: true, // constant
            step: 1, // constant for prefiltering
            // from: 0,
            // size: 20,
            // julianDateStart: '',
            // julianDateEnd: '',
            // observatoryCode: 'C16',
            // observatoryElevation: 2000,
            // observatoryLatitude: '',
            // observatoryLongitude: '',
            // sources: '',
            // bodyTypes: '',
            // orbitTypes: '',
            // risk: '',
            // priority: '',
            // pha: '',
            // elevationMin: '',
            // elevationMax: '',
            // densityOfStarsMin: '',
            // densityOfStarsMax: '',
            // galaxyLatitudeMin: '',
            // galaxyLatitudeMax: '',
            // magMin: '',
            // magMax: '',
            // skyUncertaintyMin: '',
            // skyUncertaintyMax: '',
            // moonElongationMin: '',
            // moonElongationMax: '',
            // moonglowMin: '',
            // moonglowMax: '',
            // sunAltitudeMax: '',
            // sunAltitudeMin: '',
            // sunElongationMin: '',
            // sunElongacyMax: '',
            // skyMovementMin: '',
            // skyMovementMax: '',
            // nrOfDaysUnobservedMin: '',
            // nrOfDaysUnobservedMax: '',
            // observationArcMin: '',
            // observationArcMax: '',
            // names: '',
        };

        paramsObj.from = FilteringService.getPaginationFrom();

        if (!ObjectsService.initialLoadPerformed())
            paramsObj.size = FilteringService.getInitialLoadSize();
        else 
            paramsObj.size = FilteringService.getPaginationSize();

        const julianDates = TimeFrameService.getJulianDates();
        paramsObj.julianDateStart = julianDates['julianDateStart'];
        paramsObj.julianDateEnd = julianDates['julianDateEnd'] + 0.9999;
        //paramsObj.julianDateEnd = julianDates['julianDateEnd'];

        const utcDates = TimeFrameService.getUtcDates();
        paramsObj.utcStart = utcDates['utcStart'];
        paramsObj.utcEnd = utcDates['utcEnd'].replace('00:00', '23:59');

        const observatory = ObservatoryService.getSelectedObservatory();
        if (observatory.code) {
            paramsObj.observatoryCode = observatory.code;
        } else {
            paramsObj.observatoryElevation = ObservatoryService.getObservatoryElevation();
            paramsObj.observatoryLatitude = observatory.latitude;
            paramsObj.observatoryLongitude = observatory.longitude*1 < 0 ? observatory.longitude*1 + 360 : observatory.longitude;
        }

        const lastCalculatedEphemerides = FilteringService.getLastCalculatedEphemerides();
        if (lastCalculatedEphemerides) {
            paramsObj.lastCalculatedEphemerides = true;
        }

        FilteringService.prepareFilterParameters(paramsObj);

        const asteroidsNames = ObjectsService.getAsteroidsNames();
        const userDefinedObjects = ObjectsService.getUserDefinedObjects() && asteroidsNames && asteroidsNames !== '';
        if (userDefinedObjects) paramsObj.names = asteroidsNames;

        if (pagination || lastCalculatedEphemerides) {
            const token = FilteringService.getToken();
            if (token && token !== '') {
                configObj.headers = {
                    Token: token
                }
            }
        }

        if (pagination) {
            FilteringService.setPaginationLoading(true);
        } else {
            SpinnerService.show();
        }

        //paramsObj.size = 250;
        const url = FilteringService.getFilteringApiUrl() + UtilsService.parseParams(paramsObj);

        if (shouldMakeBackendRequest) {
            axios(url, configObj)
                .then(response => {                    
                    const mappedObjects = FilteringService.mapObjects(response.data);
                    FilteringService.setToken(mappedObjects.token);
                    if (pagination) {
                        ObjectsService.concatObjects(mappedObjects, userDefinedObjects);
                    } else if (lastCalculatedEphemerides) {
                        ObjectsService.setObjects(mappedObjects, true);
                    } else {
                        ObjectsService.setObjects(mappedObjects, userDefinedObjects);
                    }
                    ObjectsService.setObjectsInitialLoadPerformed(true);
                    //FilteringService.setPaginationLoading(false);
                })
                .catch(error => {
                    const message = UtilsService.prepareErrorMessage(error);
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message,
                    });
                })
                .finally(() => {
                    if (pagination) {
                        FilteringService.setPaginationLoading(false);
                    } else {
                        SpinnerService.hide();
                    }
                });
        } else {
            // If the condition is not met, return an empty response
            const emptyResponse = {
              data: null, // You can set this to an empty object or any desired value
              status: 200, // Set an appropriate status code (e.g., 200 for success)
              statusText: 'OK',
              headers: {}, // Optional: You can set headers if needed
              config: {}, // Optional: You can provide the Axios config if needed
            };
          
            // Create a promise that resolves with the empty response
            const emptyResponsePromise = Promise.resolve(emptyResponse);
          
            // Use the empty response promise
            emptyResponsePromise
              .then(() => {
                // Handle the empty response              
                const mappedObjects = FilteringService.mapObjectsForNullToken();                
                if (pagination) {
                    ObjectsService.concatObjects(mappedObjects, userDefinedObjects);
                } else if (lastCalculatedEphemerides) {
                    ObjectsService.setObjects(mappedObjects, true);
                } else {
                    ObjectsService.setObjects(mappedObjects, userDefinedObjects);
                }                
              })
              .catch(error => {
                // Handle errors from the empty response
                const message = UtilsService.prepareErrorMessage(error);
                    PopupService.show({
                        component: 'PopupInfo',
                        type: 'error',
                        message,
                    });
              })
              .finally(() => {
                if (pagination) {
                    FilteringService.setPaginationLoading(false);
                } else {
                    SpinnerService.hide();
                }
              });
        }

    },

    prepareFilterParameters(paramsObj) {
        const filters = FilteringService.getFilters();
        if (!filters || !Object.keys(filters).length || filters.constructor !== Object) return;

        for (const name in filters) {
            const filter = filters[name];
            if (Array.isArray(filter) && filter.length) {
                paramsObj[name] = filter.join();
            } else if (typeof filter === 'string' && filter !== '') {
                paramsObj[name] = filter;
            } else if (Object.keys(filter).length && filter.constructor === Object) {
                for (const subName in filter) {
                    const subValue = filter[subName];
                    if (subValue !== null) {
                        paramsObj[subName] = subValue;
                    }
                }
            }
        }
    },

    mapObjects(objectsObject) {
        const objects = {};
        objects.token = objectsObject.token || null;
        objects.total = objectsObject.totalResults || 0;
        objects.list = objectsObject.object.data || [];
        return objects;
    },
    mapObjectsForNullToken() {
        const objects = {};
        objects.token = null;
        objects.total =  0;
        objects.list = [];
        return objects;
    },
}

export default FilteringService;
