import moment from "moment";
import UtilsService from "@/services/utils.service";
import store from "@/store";
import CommonGetters from "@/store/common/common-getters";
import CommonActions from "@/store/common/common-actions";

const SynodicChartService = {
    APPARENT_MAG_LIMIT: 32,
    MONTH_NAMES_MAP: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
    TIME_DISPLAY_SETTINGS: {
        unit: "day",
        displayFormats: { day: "DD.MM.YYYY" },
    },
    DAY_TICKS_SETTINGS: {
        maxRotation: 0,
        minRotation: 0,
    },
    DATE_TICKS_SETTINGS: {
        maxRotation: 30,
        minRotation: 30,
    },
    FONT_FAMILY:
        '"NotesEsaReg", "Helvetica Neue", "Helvetica", "Arial", sans-serif',

    generateChartConfig(config) {
        return {
            type: "line",
            data: {
                datasets: [],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: !(config.y && config.y.min),
                        min: config.y && config.y.min ? config.y.min : 0,
                        type:
                            config.y && config.y.type
                                ? config.y.type
                                : "linear",
                        title: {
                            display: config.y && config.y.title,
                            text:
                                config.y && config.y.title
                                    ? config.y.title
                                    : "",
                            color:
                                config.y && config.y.titleColor
                                    ? config.y.titleColor
                                    : "#fff",
                            font: {
                                size: 12,
                                family: SynodicChartService.FONT_FAMILY,
                            },
                        },
                        grid: {
                            color:
                                config.grid && config.grid.color
                                    ? config.grid.color
                                    : "#636363",
                            drawTicks:
                                !!config.grid && !!config.grid.visibleTicks,
                        },
                        ticks: {
                            stepSize: config.y.step,
                            color:
                                config.grid && config.grid.ticksColor
                                    ? config.grid.ticksColor
                                    : "#b4b4b4",
                            sampleSize: 1,
                            maxRotation: 0,
                            minRotation: 0,
                            font: {
                                size: 11,
                                family: SynodicChartService.FONT_FAMILY,
                            },
                        },
                        afterFit: (scaleInstance) =>
                            (scaleInstance.width =
                                config.y && config.y.labelWidth
                                    ? config.y.labelWidth
                                    : 50),
                    },
                    x: {
                        beginAtZero: !!config.x && !!config.x.beginZero,
                        title: {
                            display: config.x && config.x.title,
                            text:
                                config.x && config.x.title
                                    ? config.x.title
                                    : "",
                            color:
                                config.x && config.x.titleColor
                                    ? config.x.titleColor
                                    : "#fff",
                            font: {
                                size: 12,
                                family: SynodicChartService.FONT_FAMILY,
                            },
                        },
                        grid: {
                            color:
                                config.grid && config.grid.color
                                    ? config.grid.color
                                    : "#636363",
                            drawTicks:
                                !!config.grid && !!config.gird.visibleTicks,
                        },
                        ticks: {
                            source: "auto",
                            stepSize: config.x.step,
                            color:
                                config.grid && config.grid.ticksColor
                                    ? config.grid.ticksColor
                                    : "#b4b4b4",
                            sampleSize: 2,
                            font: {
                                size: 11,
                                family: SynodicChartService.FONT_FAMILY,
                            },
                        },
                    },
                },
                elements: {
                    line: {
                        borderWidth:
                            config.line && config.line.width
                                ? config.line.width
                                : 1,
                        borderColor:
                            config.line && config.line.color
                                ? config.line.color
                                : "#0F84DD",
                    },
                    point: {
                        radius: 0,
                    },
                },
                plugins: {
                    legend: !!config.plugins && !!config.plugins.legendVisible,
                    tooltip: {
                        mode: "nearest",
                        intersect: false,
                        displayColors: false,
                        callbacks: {
                            title: () => "",
                            label: (ctx) => {
                                const timeLabel =
                                    ctx.chart.options.scales.x.title.text;
                                const timeValue = SynodicChartService.convertTooltipDate(
                                    ctx.label
                                );
                                const valueLabel =
                                    ctx.chart.options.scales.y.title.text;
                                const isMagnitudeChart = valueLabel.includes('magnitude');
                                const value = isMagnitudeChart ? ctx.dataset.originalData[ctx.dataIndex].toFixed(1) : ctx.parsed.y.toFixed(3);
                                return [
                                    `${timeLabel}: ${timeValue}`,
                                    `${valueLabel}: ${value}`,
                                ];
                            },
                        },
                        bodyFont: {
                            family: SynodicChartService.FONT_FAMILY,
                        },
                        padding: 8,
                    },
                    annotation: {
                        clip: false,
                        annotations: {
                            lineAnnotation: {
                                type: "line",
                                borderColor: "#B50000",
                                borderWidth: 1.25,
                                scaleID: "x",
                                value: 0,
                            },
                            pointAnnotation: {
                                type: "point",
                                backgroundColor: "white",
                                borderWidth: 0,
                                radius: 4,
                                xScaleID: "x",
                                xValue: 0,
                                yScaleID: "y",
                                yValue: 0,
                            },
                        },
                    },
                },
                normalized: true,
                spanGaps: true,
                parsing: false,
                animation: false,
                maintainAspectRatio: false,
            },
        };
    },

    setVerticalLinePlugin() {
        return {
            id: "verticalLine",
            afterDatasetsDraw: (chartInstance) => {
                if (chartInstance.options.verticalLine) {
                    const yScale = chartInstance.scales["y"];
                    const xScale = chartInstance.scales["x"];
                    const context = chartInstance.ctx;
                    const chartData = chartInstance.getDatasetMeta(0).data;

                    chartInstance.options.verticalLine.forEach(
                        ({ lineValue, color = "#B50000", bulletPoint }) => {
                            const verticalLineYValue = chartData.find(
                                (point) => point.parsed.x === lineValue
                            ).parsed.y;
                            const xValue = xScale.getPixelForValue(lineValue);
                            const yValue = yScale.getPixelForValue(
                                verticalLineYValue
                            );
                            context.beginPath();
                            context.strokeStyle = color;
                            context.moveTo(xValue, yScale.top);
                            context.lineTo(xValue, yScale.bottom);
                            context.stroke();

                            if (bulletPoint.visible) {
                                const {
                                    color: bulletColor = "white",
                                    radius: bulletRadius = 3,
                                } = bulletPoint;
                                context.beginPath();
                                context.arc(
                                    xValue,
                                    yValue,
                                    bulletRadius,
                                    0,
                                    2 * Math.PI,
                                    false
                                );
                                context.fillStyle = bulletColor;
                                context.fill();
                                context.strokeStyle = bulletColor;
                                context.stroke();
                            }
                        }
                    );
                }
            },
        };
    },

    setHorizontalLinePlugin() {
        return {
            id: "horizontalLine",
            afterDatasetsDraw: (chartInstance) => {
                if (chartInstance.options.horizontalLine) {
                    const yScale = chartInstance.scales["y"];
                    const xScale = chartInstance.scales["x"];
                    const context = chartInstance.ctx;

                    chartInstance.options.horizontalLine.forEach(
                        ({ lineValue, color = "#B50000" }) => {
                            const yValue = yScale.getPixelForValue(lineValue);
                            context.beginPath();
                            context.strokeStyle = color;
                            context.moveTo(xScale.left, yValue);
                            context.lineTo(xScale.right, yValue);
                            context.stroke();
                        }
                    );
                }
            },
        };
    },

    updateSynodicOrbitObjectChartsData(
        designator,
        startDateJulian,
        endDateJulian,
        pointsChartData,
        isPerturbed = false
    ) {
        const chartsData = UtilsService.deepCopy(
            SynodicChartService.getSynodicOrbitChartsData()
        );
        const objectDesignator = isPerturbed
            ? `${designator}_perturbed`
            : designator;
        const isRangeChanged =
            startDateJulian !== chartsData.startDateJulian ||
            endDateJulian !== chartsData.endDateJulian ||
            pointsChartData.length > chartsData.date.length;
        const isObjectChartDataChanged = chartsData.objects[objectDesignator]
            ? chartsData.objects[objectDesignator].startDateJulian !==
                  startDateJulian ||
              chartsData.objects[objectDesignator].endDateJulian !==
                  endDateJulian
            : true;
        if (!isObjectChartDataChanged && !isRangeChanged) {
            return;
        }
        const {
            date,
            earthDistance,
            phaseAngle,
            apparentMag,
        } = SynodicChartService.prepareChartData(
            pointsChartData,
            isRangeChanged,
            isObjectChartDataChanged
        );
        if (isRangeChanged) {
            chartsData.startDateJulian = startDateJulian;
            chartsData.endDateJulian = endDateJulian;
            chartsData.date = date;
        }
        if (isObjectChartDataChanged) {
            const newObjectChartData = {
                startDateJulian,
                endDateJulian,
                earthDistance,
                phaseAngle,
                apparentMag: apparentMag.map(mag => mag > SynodicChartService.APPARENT_MAG_LIMIT ? SynodicChartService.APPARENT_MAG_LIMIT : mag),
                apparentMagOriginalValues: apparentMag,
            };
            chartsData.objects[objectDesignator] = newObjectChartData;
        }
        SynodicChartService.setSynodicOrbitChartsData(chartsData);
    },

    removeSynodicOrbitObjectChartData(designator, shouldRemoveAll = true) {
        const chartsData = UtilsService.deepCopy(
            SynodicChartService.getSynodicOrbitChartsData()
        );
        if (shouldRemoveAll) {
            chartsData.objects[designator] &&
                delete chartsData.objects[designator];
        }
        chartsData.objects[`${designator}_perturbed`] &&
            delete chartsData.objects[`${designator}_perturbed`];
        if (!Object.keys(chartsData.objects).length) {
            chartsData.date = [];
            chartsData.startDateJulian = null;
            chartsData.endDateJulian = null;
            chartsData.objects = {};
        }
        SynodicChartService.setSynodicOrbitChartsData(chartsData);
    },

    prepareChartData(
        pointsChartData,
        isRangeChanged,
        isObjectChartDataChanged
    ) {
        const dateList = [];
        const earthDistanceList = [];
        const phaseAngleList = [];
        const apparentMagList = [];
        pointsChartData.forEach(
            ({ date, apparentMag, earthDistance, phaseAngle }) => {
                isRangeChanged &&
                    dateList.push(SynodicChartService.formatDate(date));
                if (isObjectChartDataChanged) {
                    apparentMagList.push(+apparentMag.toFixed(3));
                    earthDistanceList.push(+earthDistance.toFixed(3));
                    phaseAngleList.push(+phaseAngle.toFixed(3));
                }
            }
        );
        return {
            ...(isRangeChanged && { date: dateList }),
            ...(isObjectChartDataChanged && {
                earthDistance: earthDistanceList,
                phaseAngle: phaseAngleList,
                apparentMag: apparentMagList,
            }),
        };
    },

    setUpChartAxisValues(values, isDecimalAllowed, isOnlyOneValue) {
        if (isOnlyOneValue) {
            return { step: 1, startValue: Math.floor(Math.min(...values)) };
        }
        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);
        const valueRoundTo = SynodicChartService.calculateRoundToValue(
            minValue,
            maxValue,
            isDecimalAllowed
        );
        const {
            step,
            startValue,
        } = SynodicChartService.calculateAxisStepAndStart(
            minValue,
            maxValue,
            valueRoundTo
        );
        return { step, startValue };
    },

    calculateAxisStepAndStart(
        minValue,
        maxValue,
        roundTo = 5,
        numIntervals = 4
    ) {
        const range = maxValue - minValue;
        const generalStep = range / numIntervals;
        const step = Math.ceil(generalStep / roundTo) * roundTo;
        const startValue = Math.floor(minValue / step) * step;
        return { step, startValue };
    },

    calculateRoundToValue(minValue, maxValue, isDecimalAllowed) {
        const range = maxValue - minValue;
        let roundTo = 5;
        if (range < 20) {
            roundTo = 2;
        }
        if (range < 10) {
            roundTo = 1;
        }
        if (range < 5 && isDecimalAllowed) {
            roundTo = 0.5;
        }
        if (range < 2.5 && isDecimalAllowed) {
            roundTo = 0.2;
        }
        return roundTo;
    },

    getValueForCurrentDate(values, date) {
        const foundDate = values.find(({ x }) => x === date);
        return foundDate ? foundDate.y : null;
    },

    formatDate(dateTimeString) {
        const [month, day, year] = dateTimeString.split("/");
        const isoMonth = month.padStart(2, "0");
        const isoDay = day.padStart(2, "0");
        return moment(`${year}-${isoMonth}-${isoDay}`, "YYYYMMDD").valueOf();
    },

    convertTooltipDate(tooltipDate) {
        const convertedTooltipDate = tooltipDate.replaceAll(/[\s,:\s]+/g, "");
        if (!isNaN(+convertedTooltipDate)) {
            return tooltipDate;
        }
        const parsedDate = tooltipDate.split(/[\s,:\s]+/);
        const day = parsedDate[1].padStart(2, "0");
        const month = (
            SynodicChartService.MONTH_NAMES_MAP.indexOf(parsedDate[0]) + 1
        )
            .toString()
            .padStart(2, "0");
        const year = parsedDate[2];
        return `${day}.${month}.${year}`;
    },

    getSynodicOrbitChartsData() {
        return store.getters[CommonGetters.synodicOrbitChartsData];
    },

    setSynodicOrbitChartsData(chartsData) {
        return store.dispatch(
            CommonActions.setSynodicOrbitChartsData,
            chartsData
        );
    },

    getSynodicOrbitChartsSettings() {
        return store.getters[CommonGetters.synodicOrbitChartSettings];
    },

    setSynodicOrbitChartsSettings(value) {
        return store.dispatch(
            CommonActions.setSynodicOrbitChartSettings,
            value
        );
    },
};

export default SynodicChartService;
