<template>
    <div class="ovt__settings--panel" :class="{ 'sovt__settings--panel': tool === 'sovt' }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'VisualisationSettingsPanel',
    props: {
        tool: String,
    }
}
</script>

<style></style>