import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueClickAway from 'vue3-click-away';
import Keys from '@/constants/keys'
import mitt from 'mitt';
import { VueCookieNext } from 'vue-cookie-next'

const app = createApp(App);

app.config.globalProperties.$keys = Keys;
app.config.globalProperties.$t = Keys.t;
app.config.globalProperties.$mitt = mitt();

app.use(store)
    .use(router)
    .use(VueClickAway)
    .use(VueCookieNext)
    .mount('#app');

VueCookieNext.config({ expire: '30d' });