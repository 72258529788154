<template>
    <div class="custom-input">
        <label class="custom-input__label" :for="inputData.id"
            >{{ $t[inputData.labelId] }}
            <template v-if="inputData.unitId">({{ $t[inputData.unitId] }})</template>
            <Tooltip v-if="inputData.tooltip" position="bottom" :tooltipText="$keys.tooltips[inputData.tooltip]"
                ><i class="icon-info"
            /></Tooltip>
        </label>
        <input
            v-if="inputData.type !== 'date'"
            class="custom-input__field"
            :class="{
                'custom-input__field--invalid':
                    isError ||
                    (isPerihelionAphelionError &&
                        (inputData.id === 'perihelionDistance' || inputData.id === 'aphelionDistance')),
                loading: loading,
            }"
            :name="inputData.id"
            :id="inputData.id"
            :type="inputData.type"
            :step="setInputStep"
            :value="value"
            :disabled="loading"
            :ref="inputData.id"
            :min="setInputMin"
            :max="setInputMax"
            :maxlength="setTextInputMaxLength"
            @input="handleInputChange"
        />
        <VueDatePicker
            v-else
            :model-value="value"
            @update:model-value="handleDateChange"
            format="dd/MM/yyyy HH:mm"
            :min-date="'1950-01-01'"
            :max-date="getMaxDate()"
            :clearable="false"
            :enable-time-picker="true"
            :year-range="[1950, getMaxDateYear()]"
            auto-apply
            text-input
            utc="preserve"
            :class="{ loading: loading }"
        />
        <span class="custom-input__error" v-if="isError && inputData.errorMsgId">
            {{ setErrorMsg }}
        </span>
        <span
            class="custom-input__error"
            v-if="
                isPerihelionAphelionError &&
                (inputData.id === 'perihelionDistance' || inputData.id === 'aphelionDistance')
            "
        >
            {{ getPerihelionAphelionErrorMsg }}
        </span>
    </div>
</template>

<script>
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import Tooltip from "@/components/common/Tooltip";
import UtilsService from "@/services/utils.service";

export default {
    name: "CustomInput",
    components: {
        VueDatePicker,
        Tooltip,
    },
    props: {
        inputData: Object,
        value: [String, Number, Date, InputEvent],
        loading: Number,
        isError: [Boolean, undefined],
        isPerihelionAphelionError: [Boolean, undefined],
    },
    computed: {
        isNumberInput() {
            return this.inputData.type === "number";
        },

        isTextInput() {
            return this.inputData.type === "text";
        },

        setInputStep() {
            if (!this.isNumberInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { step } = validation;
            if (!step) {
                return 1;
            }
            return step;
        },

        setInputMin() {
            if (!this.isNumberInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { min } = validation;
            return min || min === 0 ? min : undefined;
        },

        setInputMax() {
            if (!this.isNumberInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { max } = validation;
            return max || max === 0 ? max : undefined;
        },

        setTextInputMaxLength() {
            if (!this.isTextInput) {
                return undefined;
            }
            const { validation } = this.inputData;
            if (!validation) {
                return undefined;
            }
            const { maxLength } = validation;
            return maxLength || undefined;
        },

        setErrorMsg() {
            if (this.inputData.errorMsgId !== "limitedNumber") {
                return this.$t[this.inputData.errorMsgId];
            }
            const { min, max } = this.inputData.validation;
            return this.$t[this.inputData.errorMsgId].replace("{min}", min).replace("{max}", max);
        },

        getPerihelionAphelionErrorMsg() {
            return this.$t[
                `${this.inputData.id === "perihelionDistance" ? "perihelionDistanceError" : "aphelionDistanceError"}`
            ];
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
        },
        getMaxDate() {
            const maxDate = UtilsService.createDateYearsFromNow(100);
            return UtilsService.dateToStringTechnical(maxDate);
        },
        getMaxDateYear() {
            const maxDate = new Date(this.getMaxDate());
            const maxYear = maxDate.getFullYear();
            return maxYear;
        },
        handleDateChange(value) {
            this.$emit("dateChange", value);
        },
        handleInputChange(value) {
            this.$emit("input", value);
        },
    },
};
</script>

<style lang="scss">
@import "@/styles/common/custom-input.scss";
</style>
