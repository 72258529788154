<template>
    <div class="opt-setup__wrapper">
        <div class="opt-setup">
            <div class="opt-setup__row opt-setup__row--settings">
                <div class="opt-setup__col">
                    <div class="select-time-frame">
                        <h3>1. {{ $t.selectTimeFrame}} <Tooltip v-if="tool !== 'scd'" position="bottom" :tooltipText="$t.selectedTimeFrameExplanation"><i class="icon-info" /></Tooltip></h3>
                        <div class="d-table-row">
                            <div class="d-table-cell label">{{ $t.startDate }}:</div>
                            <div class="d-table-cell">
                                <TimeFrameInput :tool="tool" type="start" />
                            </div>
                        </div>
                        <div class="d-table-row">
                            <div class="d-table-cell label">{{ $t.endDate }}:</div>
                            <div class="d-table-cell">
                                <TimeFrameInput :tool="tool" type="end" />
                            </div>
                        </div>
                        <div v-if="tool !== 'scd'" v-show="outOfShortTerm" class="t-center time-frame-error">
                            {{ $t.selectedTimeFrameIsOutOfShortTermAnalysis }}
                        </div>
                    </div>
                    <div v-if="tool === 'scd'" class="select-time-step">
                        <h3>2. {{ $t.setTimestep}} <Tooltip position="bottom" :tooltipText="$t.setTimestepExplanation"><i class="icon-info" /></Tooltip></h3>
                        <TimestepSetup :tool="tool" />
                    </div>
                </div>
                <div class="opt-setup__col">
                    <div class="select-observatory-point">
                        <h3>{{ tool === 'scd' ? 3 : 2 }}. {{ $t.selectObservatoryPoint }}</h3>
                        <div class="d-table">
                            <div class="d-table-row">
                                <div class="d-table-cell label">{{ $t.type }}:</div>
                                <div class="d-table-cell">
                                    <ObservatoryTypeSwitch />
                                </div>
                            </div>
                            <div class="d-table-row">
                                <div class="d-table-cell label">{{ $t.findObservatory }}:</div>
                                <div class="d-table-cell">
                                    <FindObservatory />
                                </div>
                            </div>
                            <div class="d-table-row">
                                <div class="d-table-cell label">{{ $t.latitude }}:<Tooltip position="top" :tooltipText="$t.latitudeDefinition"><i class="icon-info" /></Tooltip></div>
                                <div class="d-table-cell">
                                    <CustomCoordinate type="latitude" />
                                </div>
                            </div>
                            <div class="d-table-row">
                                <div class="d-table-cell label">{{ $t.longitude }}:<Tooltip position="top" :tooltipText="$t.longitudeDefinition"><i class="icon-info" /></Tooltip></div>
                                <div class="d-table-cell">
                                    <CustomCoordinate type="longitude" />
                                </div>
                            </div>
                            <div class="d-table-row">
                                <div class="d-table-cell label">{{ $t.altitude }}:<Tooltip position="top" :tooltipText="$t.altitudeDefinition"><i class="icon-info" /></Tooltip></div>
                                <div class="d-table-cell">
                                    <CustomCoordinate type="altitude" />
                                </div>
                            </div>
                        </div>
                        <p class="t-right">
                            <UserCurrentLocation />
                        </p>
                        <div>
                            <Map />
                            <MapTimezone />
                        </div>
                    </div>
                </div>
                
                <div class="opt-setup__col">
                    <div class="configure-objects">
                        <h3>{{ tool === 'scd' ? 4 : 3 }}. {{ $t.objects }} <Tooltip position="bottom" :tooltipText="$t.objectsConfigurationExplanationForSCD"><i class="icon-info" /></Tooltip></h3>
                        <ObjectConfiguration :tool="tool" />
                    </div>
                </div>
            </div>
            <div class="opt-setup__row opt-setup__row--submit">
                <router-link v-if="tool === 'opt'" class="button primary" :class="{disabled: disableSubmitSetup()}" to="filtering" :key="$route.path">{{ $t.goTo }} {{ $t.filtering }}</router-link>
                <router-link v-if="tool === 'scd'" class="button primary" :class="{disabled: disableScdSubmitSetup()}" to="visualisation" :key="$route.path">{{ $t.goTo }} {{ $t.visualisation }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import FindObservatory from '@/components/common/setup/FindObservatory'
import UserCurrentLocation from '@/components/common/setup/UserCurrentLocation'
import Map from '@/components/common/setup/Map'
import MapTimezone from '@/components/common/setup/MapTimezone'
import CustomCoordinate from '@/components/common/setup/CustomCoordinate'
import TimeFrameInput from '@/components/common/setup/TimeFrameInput'
import Tooltip from '@/components/common/Tooltip'
import ObservatoryTypeSwitch from '@/components/common/setup/ObservatoryTypeSwitch'
import ObjectConfiguration from '@/components/common/setup/ObjectConfiguration'
import TimeFrameService from '@/services/timeframe.service'
import ObjectsService from '@/services/objects.service'
import SyntheticObjectsService from '@/services/synthetic-objects.service'
import TimestepSetup from '@/components/common/setup/TimestepSetup'

export default {
    name: 'CommonSetup',
    props: {
        tool: String,
    },
    components: {
        FindObservatory,
        ObservatoryTypeSwitch,
        UserCurrentLocation,
        Map,
        MapTimezone,
        CustomCoordinate,
        TimeFrameInput,
        Tooltip,
        ObjectConfiguration,
        TimestepSetup
    },
    computed: {
        userDefinedObjects() {
            return ObjectsService.getUserDefinedObjects();
        },
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },
        syntheticObjectsList() {
            return SyntheticObjectsService.getSyntheticDefaultObjectsList();
        },
        outOfShortTerm() {
            return TimeFrameService.getTimeFrame().outOfShortTerm;
        },
        isDatesValid() {
            return TimeFrameService.isDatesValid();
        },
        timestep() {
            return TimeFrameService.getTimestep();
        },
        isUserDefinedObjectCustomObservatoryEnabled() {
            return SyntheticObjectsService.isUserDefinedObjectCustomObservatoryEnabled();
        }
    },
    methods: {
        disableSubmitSetup() {
            return this.userDefinedObjects && !this.finalAsteroidsList.length || !this.isDatesValid;
        },
        disableScdSubmitSetup() {
            return this.userDefinedObjects && !this.finalAsteroidsList.length && !this.syntheticObjectsList.length || !this.isDatesValid || !this.isUserDefinedObjectCustomObservatoryEnabled;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common/setup.scss";
</style>