<template>
    <div v-click-away="hideDropdown" class="find-observatory dropdown">
        <input type="text"
            v-model="name"
            :placeholder="$t.startTyping"
            :class="{loading: loadingObservatoryList}"
            @keyup="findObservatoryByPhrase($event)"
            @input="onObservatoryInput($event)"
            @focus="showDropdown()"
            @click="showDropdown()"
            :readonly="isGeocentric" />
        <ul v-show="observatoryList.length && dropdown" class="dropdown-list" >
            <li class="dropdown-list__item" v-for="(item, index) of observatoryList" :key="index" @click="onObservatorySelect(item)">
                {{ item.name }} ({{ item.code }})
            </li>
        </ul>
    </div>
</template>

<script>
import { directive } from 'vue3-click-away';
import ObservatoryService from '@/services/observatory.service'

export default {
    name: 'FindObservatory',
    data() {
        return {
            typingDelay: null,
            dropdown: false,
            name: '',
        }
    },
    directives: {
        ClickAway: directive
    },
    computed: {
        selectedObservatory() {
            return ObservatoryService.getSelectedObservatory();
        },
        observatoryList() {
            return ObservatoryService.getObservatoryList();
        },
        loadingObservatoryList() {
            return ObservatoryService.getLoadingObservatoryList();
        },
        isGeocentric() {
            return ObservatoryService.isGeocentric();
        }
    },
    methods: {
        findObservatoryByPhrase($event) {
            clearTimeout(this.typingDelay);
            this.typingDelay = setTimeout(function() {
                const phrase = $event.target.value
                ObservatoryService.findObservatoryByPhrase(phrase);
            }, 400);
        },
        onObservatoryInput($event) {
            this.showDropdown();
            const inputValue = $event.target.value.replace(/ *\([^)]*\) */g, "");
            const matchingObservatory = this.observatoryList.filter(observatory => observatory.name === inputValue);
            if (matchingObservatory.length) {
                ObservatoryService.setSelectedObservatory(matchingObservatory[0]);
            }
        },
        onObservatorySelect(observatory) {
            observatory.elevation = '.';
            ObservatoryService.setSelectedObservatory(observatory);
            this.hideDropdown();
        },
        showDropdown() {
            if (!this.isGeocentric) {
                this.dropdown = true;
            }
        },
        hideDropdown() {
            this.dropdown = false;
        },
        getObservatoryNameDisplay(observatory) {
            const observatoryCode = observatory.code && observatory.code !== '' ? ` (${observatory.code})` : '';
            return observatory.name + observatoryCode;
        }
    },
    watch: {
        selectedObservatory(newVal) {
            this.name = this.getObservatoryNameDisplay(newVal);
        }
    },
    mounted() {
        this.name = this.getObservatoryNameDisplay(this.selectedObservatory);
    }
}
</script>

<style>

</style>