<template>
    <input class="toggle-switch" id="only-observable" type="checkbox" v-model="onlyObservableSwitch"/>
    <label class="toggle-switch__button" for="only-observable"></label>
    <span class="toggle-switch__label" @click="toggleOnlyObservable()">
        {{ $t.onlyObservableTimestamps }}
    </span>
    <Tooltip :tooltipText="$keys.tooltips.onlyObservableTimestampsExplanation">
        <i class="icon-info" />
    </Tooltip>
</template>

<script>
import EphemeridesService from '@/services/ephemerides.service'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'EphemeridesSwitch',
    components: {
        Tooltip,
    },
    data() {
        return {
            onlyObservableSwitch: false,
        }
    },
    computed: {
        onlyObservable() {
            return EphemeridesService.ephemeridesOnlyObservable();
        }
    },
    methods: {
        toggleOnlyObservable() {
            this.onlyObservableSwitch = !this.onlyObservableSwitch;
        }
    },
    watch: {
        onlyObservableSwitch(newVal) {
            EphemeridesService.setEphemeridesOnlyObservable(newVal);
        }
    },
    mounted() {
        this.onlyObservableSwitch = this.onlyObservable;
    }
}
</script>

<style>

</style>