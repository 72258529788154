<template>
    <div v-if="tool === 'opt'" class="timestep-setup on-filtering">
        <span class="info-label">{{ $t.timestep }}:</span>
        <a class="set-timestep" @click="toggleSetup()">
            {{ timestepLabel() }} <i class="icon-edit-2" />
        </a>
        <Dropbox v-if="dropboxSetupId === 'timestep'" :title="$t.timestep" @close="cancelSetup()" @submit="submitSetup()">
            <div class="field">
                {{ $t.value }}: <input v-model="step" :min="minStep" :max="maxStep" type="number" @change="stepChange()" />
            </div>
            <div class="field">
                {{ $t.chooseUnits }}:
            </div>
            <label class="field" v-for="(stepUnit, index) of availableUnits" :key="index" :for="'stepUnit_'+stepUnit">
                <input type="radio" name="stepUnit" :id="'stepUnit_'+stepUnit" :checked="stepUnit === unit" :value="stepUnit" @change="stepUnitChange(stepUnit)" />
                {{ getStepUnitDisplay(null, stepUnit) }}
            </label>
        </Dropbox>
    </div>
    <div v-if="tool === 'scd'" class="timestep-setup on-setup d-table">
        <div class="d-table-row">
            <div class="d-table-cell label">{{ $t.value }}:</div>
            <div class="d-table-cell">
                <input v-model="step" :min="minStep" :max="maxStep" type="number" @change="stepChange(true)" />
            </div>
        </div>
        <div class="d-table-row">
            <div class="d-table-cell label">{{ $t.chooseUnits }}:</div>
            <div class="d-table-cell">
                <label class="field" v-for="(stepUnit, index) of availableUnits" :key="index" :for="'stepUnit_'+stepUnit">
                    <input type="radio" name="stepUnit" :id="'stepUnit_'+stepUnit" :checked="stepUnit === unit" :value="stepUnit" @change="stepUnitChange(stepUnit, true)" />
                    {{ getStepUnitDisplay(null, stepUnit) }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import Config from '@/constants/config'
import Dropbox from '@/components/common/Dropbox'
import TimeFrameService from '@/services/timeframe.service'
import UtilsService from '@/services/utils.service'
import PopupService from '@/services/popup.service'
import DropboxService from '@/services/dropbox.service'

export default {
    name: 'TimestepSetup',
    props: {
        tool: String,
    },
    components: {
        Dropbox
    },
    data() {
        return {
            timestep: {},
            step: Config.timeFrame.step,
            unit: Config.timeFrame.unit,
            minimumValues: Config.timeFrame.stepMinimum,
            maximumValues: {},
            minStep: '',
            maxStep: '',
            showTimestepSetup: false,
            availableUnits: ['m', 'h', 'd'],
        }
    },
    computed: {
        selectedTimestep() {
            return TimeFrameService.getTimestep();
        },
        dropboxSetupId() {
            return DropboxService.getDropboxSetupId();
        },
        timeFrame() {
            return TimeFrameService.getTimeFrame();
        }
    },
    methods: {
        timeUnit(unit) {
            return 'timeUnit_'+unit;
        },
        timestepLabel() {
            return this.timestep.step + ' ' + this.getStepUnitDisplay(this.timestep.step, this.timestep.unit);
        },
        getStepUnitDisplay(step, unit = this.unit) {
            if (!step) {
                step = this.step;
            }
            return step > 1 ? this.$t[this.timeUnit(unit)] + 's' : this.$t[this.timeUnit(unit)];
        },
        toggleSetup() {
            if (this.dropboxSetupId === 'timestep') {
                DropboxService.setDropboxSetupId('');
            } else {
                DropboxService.setDropboxSetupId('timestep');
            }
        },
        cancelSetup() {
            DropboxService.setDropboxSetupId('');
        },
        submitSetup() {
            TimeFrameService.setTimestep(this.step, this.unit);
            DropboxService.setDropboxSetupId('');
        },
        getMaximumValues() {
            let dayDifference = UtilsService.dateDifferenceInDays(new Date(this.timeFrame.end), new Date(this.timeFrame.start));
            if (!dayDifference) {
                dayDifference = 1;
            }
            this.maximumValues = {
                m: dayDifference * 24 * 60,
                h: dayDifference * 24,
                d: dayDifference,
            }
        },
        updateMinValue() {
            this.minStep = this.minimumValues[this.unit];
            if (this.step < this.minStep) {
                this.step = this.minStep;
                if (this.tool !== 'scd') {
                    const message = `${this.$t.minTimestep1} <b>${this.step} <span class="t-lowercase">${this.getStepUnitDisplay()}</span></b>.<br />${this.$t.minTimestep2} "<span class="t-lowercase">${this.getStepUnitDisplay()}</span>".`;
                    this.showLimitPopup(message);
                }
            }
        },
        updateMaxValue() {
            this.maxStep = this.maximumValues[this.unit];
            if (this.step > this.maxStep) {
                this.step = this.maxStep;
                if (this.tool !== 'scd') {
                    const message = `${this.$t.maxTimestep1} <b>${this.step} <span class="t-lowercase">${this.getStepUnitDisplay()}</span></b>.<br />${this.$t.maxTimestep2} "<span class="t-lowercase">${this.getStepUnitDisplay()}</span>".`;
                    this.showLimitPopup(message);
                }
            }
        },
        showLimitPopup(message) {
            PopupService.show({
                component: 'PopupInfo',
                type: 'info',
                message,
            });
        },
        updateUnitDisplay() {
            this.updateMinValue();
            this.updateMaxValue();
        },
        stepChange(immediate = false) {
            this.updateUnitDisplay();
            if (immediate) {
                TimeFrameService.setTimestep(this.step, this.unit);
            }
        },
        stepUnitChange(unit, immediate = false) {
            this.unit = unit;
            this.updateUnitDisplay();
            if (immediate) {
                TimeFrameService.setTimestep(this.step, this.unit);
            }
        },
        updateTimestep() {
            this.timestep = this.selectedTimestep;
            this.step = this.timestep.step;
            this.unit = this.timestep.unit;
        }
    },
    mounted() {
        this.updateUnitDisplay();
        this.getMaximumValues();
        this.stepChange();

        if (!this.selectedTimestep.step || !this.selectedTimestep.unit) {
            TimeFrameService.setDefaultTimestep();
        }

        this.updateTimestep();
    },
    watch: {
        selectedTimestep(newVal) {
            this.timestep = newVal;
            this.updateTimestep();
        },
        timeFrame() {
            this.getMaximumValues();
            this.stepChange();
        }
    },
}
</script>

<style lang="scss">
@import "@/styles/common/timestep-setup.scss";
</style>