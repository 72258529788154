<template>
    <div>
        <div v-show="tool === 'opt'" class="configure-objects__section">
            <label class="configure-objects__label" for="objects-filtering">
                <input type="radio" id="objects-filtering" name="choice" :checked="!userDefinedObjects" value="false"
                    @change="setUserDefinedObjects($event)" />
                {{ $t.findUsingFitlering }}
            </label>
            <label class="configure-objects__label" for="objects-user-defined">
                <input type="radio" id="objects-user-defined" name="choice" :checked="userDefinedObjects" value="true"
                    @change="setUserDefinedObjects($event)" />
                {{ $t.configureMyList }}
            </label>
        </div>
        <div class="configure-objects__section">
            <p v-if="isUserDefinedSectionAvilable" class="configure-objects__subtitle">{{ $t.databaseObjects }}</p>
            <button class="link" :class="{ disabled: !userDefinedObjects }" @click="configureObjects()">
                <i class="icon-settings" /><span>{{ $t.configureYourOwnListOfObjects }}</span>
            </button>
        </div>
        <div class="configure-objects__section objects-list" :class="{ disabled: !userDefinedObjects }">
            <div class="objects-list__inner" :class="{'objects-list__inner--limited': isUserDefinedSectionAvilable}">
                <div class="d-table-cell label" v-for="(item, index) of finalAsteroidsList" :style="{ color: getItemColor(item)}" :key="index">
                    {{ displayName(item) }}
                </div>
            </div>
        </div>
        <SyntheticObjects v-if="isUserDefinedSectionAvilable" :tool="tool"/>
    </div>
</template>

<script>
import SyntheticObjects from '@/components/common/SyntheticObjects'
import PopupService from '@/services/popup.service'
import ObjectsService from '@/services/objects.service'
import SyntheticObjectsService from '@/services/synthetic-objects.service'
import VisualisationService from '@/services/visualisation.service'
import config from '@/constants/config'

export default {
    name: 'ObjectConfiguration',
    components: {
        SyntheticObjects,
    },
    props: {
        tool: String,
    },
    computed: {
        userDefinedObjects() {
            return ObjectsService.getUserDefinedObjects();
        },
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },
        isUserDefinedSectionAvilable() {
            return SyntheticObjectsService.isToolWithSyntheticObjects();
        }
    },
    methods: {
        getItemColor(item) {
            const settings = config.visualisation.settings;
            if (item.code == "non-NEO") {
                return settings.nonNEAcolor;
            } else if (item.sourceType.name == "NEOCP"){
                return settings.NEOCPcolor;
            }
        },
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },
        setUserDefinedObjects($event) {
            const choiceValue = $event.target.value === "true" ? true : false;
            ObjectsService.setUserDefinedObjects(choiceValue);
        },
        configureObjects() {
            PopupService.show({
                component: 'PopupObjectsConfiguration',
            });
        },
        nonNea(item){
            if (item.filename == "non-NEA")
                return true;
            else 
                return false;
        }
    },
    mounted() {
        /**
         * ORIGINAL
         * 
        if (this.tool === 'scd' || this.tool === 'ovt') {
            ObjectsService.setUserDefinedObjects(true);
        }
         */
        if (this.tool === 'scd' || this.tool === 'ovt' || this.tool === 'opt') {
            if (this.tool === 'scd' || this.tool === 'ovt') {
                ObjectsService.setUserDefinedObjects(true);
            }

            if (this.tool === 'scd' || this.tool === 'opt') {
                const activeObject = VisualisationService.getOrbitActiveObject();
                if (activeObject) {
                    let name = activeObject._options.name;
                    if (name.includes(" ")) {
                        name = name.split(" ")[0];
                    }
                    //console.log("Object name: " + name);
                    //VisualisationService.checkObject(name, false);
                }
            }
        }
    }
}
</script>

<style></style>