<template>
    <div class="opt-results-shortterm shortterm">
        <ShorttermTable />
    </div>
</template>

<script>
import ShorttermTable from '@/components/opt/results/ShorttermTable'

export default {
    name: 'ResultsShortTermPlots',
    components: {
        ShorttermTable,
    }
}
</script>

<style lang="scss">
@import "@/styles/opt/results-shortterm.scss";
</style>