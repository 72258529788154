<template>
    <button v-for="option of printOptions" :key="option" class="primary field" @click="print(option)">
        {{ $t[option] }}
    </button>
    <img src="/static/images/ESA_Logo_black.svg"  class="logo-img pdf esa" />
</template>

<script>
import SkychartService from '@/services/skychart.service'

export default {
    name: 'SkyChartPrint',
    emits: [
        'print'
    ],
    computed: {
        printOptions() {
            return SkychartService.getSettingsOptions('export');
        }
    },
    methods: {
        print(type) {
            this.$emit('print', type);
        },
    }
}
</script>

<style>

</style>