<template>
    <div class="longterm-plots__header">
        <div class="longterm-plots__header--left">
            <LongtermChartSwitch />
        </div>
        <div class="longterm-plots__header--center" :class="{observable: observable}">
            <span v-if="observable">
                {{ $t.objectObservableUnderSetConditions }}
            </span>
            <span v-else>
                {{ $t.objectNotObservableUnderSetConditions }}
            </span>
        </div>
        <div class="longterm-plots__header--right">
            <LongtermDownload v-if="Object.keys(object).length" :object="object" />
        </div>
    </div>
</template>

<script>
import LongtermService from '@/services/longterm.service'
import LongtermChartSwitch from '@/components/opt/results/LongtermChartSwitch'
import LongtermDownload from '@/components/opt/results/LongtermDownload'

export default {
    name: 'ResultsLongTermHeader',
    props: {
        object: Object
    },
    components: {
        LongtermChartSwitch,
        LongtermDownload,
    },
    computed: {
        longtermObjectName() {
            return LongtermService.getLongtermObjectName();
        },
        observable() {
            return LongtermService.getLongtermEphemerides(this.longtermObjectName).observableAll ? LongtermService.getLongtermEphemerides(this.longtermObjectName).observableAll.length : 0;
        }
    }
}
</script>

<style>

</style>