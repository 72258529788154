<template>
    <div class="popup-content">
        <i v-if="config.type === 'success'" class="icon-check-circle popup-icon success" />
        <i v-if="config.type === 'info'" class="icon-info popup-icon info" />
        <i v-if="config.type === 'warning'" class="icon-alert-triangle popup-icon warning" />
        <i v-if="config.type === 'error'" class="icon-alert-octagon popup-icon error" />

        <div v-html="config.message" />

        <div class="popup-footer">
            <button v-if="optionAction" class="link" @click="option()">{{ getOpitonTxt() }}</button>
            <button class="primary" @click="cancel()">{{ getCancelTxt() }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupInfo',
    props: {
        config: {}
    },
    data() {
        return {
            optionAction: this.config.actions && this.config.actions.option ? this.config.actions.option : null,
            cancelAction: this.config.actions && this.config.actions.cancel ? this.config.actions.cancel : null,
        }
    },
    methods: {
        getOpitonTxt() {
            if (this.optionAction && this.optionAction.text) {
                return this.optionAction.text;
            }
        },
        getCancelTxt() {
            if (this.cancelAction && this.cancelAction.text) {
                return this.cancelAction.text;
            } else {
                return this.$t.ok;
            }
        },
        cancel() {
            if (this.cancelAction && this.cancelAction.emits) {
                this.$emit('cancel', this.cancelAction.emits);
            } else {
                this.$emit('cancel');
            }
        },
        option() {
            if (this.optionAction && this.optionAction.emits) {
                this.$emit('option', this.optionAction.emits);
            }
        }
    }
}
</script>

<style>

</style>