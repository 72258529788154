<template>
    <div class="objects-table d-table">
        <div class="d-table-row table-header">
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'database'}">
                <div class="column-title">
                    <SelectHandler />
                </div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.object }}</span></div>
            </div>
            <div class="d-table-cell fixed-width">
                <div class="column-title"><span>{{ $t.previousObservation }}</span></div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'database'}">
                <div class="column-title">
                    <FilterHandler :filterId="'database'" />
                </div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'bodyType'}">
                <div class="column-title">
                    <FilterHandler :filterId="'bodyType'" />
                </div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'orbitType'}">
                <div class="column-title">
                    <FilterHandler :filterId="'orbitType'" />
                </div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'risk'}">
                <div class="column-title">
                    <FilterHandler :filterId="'risk'" />
                </div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'priority'}">
                <div class="column-title">
                    <FilterHandler :filterId="'priority'" />
                </div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'pha'}">
                <div class="column-title">
                    <FilterHandler :filterId="'pha'" />
                </div>
            </div>
            <!--  -->
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'magnitude'}" >
                <div class="column-title">
                    <FilterHandler :filterId="'magnitude'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.mag }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'elevation'}">
                <div class="column-title">
                    <FilterHandler :filterId="'elevation'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.degrees }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'sunElongation'}">
                <div class="column-title">
                    <FilterHandler :filterId="'sunElongation'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.degrees }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'skyMovement'}">
                <div class="column-title">
                    <FilterHandler :filterId="'skyMovement'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.arcsecmin }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'skyUncertainty'}">
                <div class="column-title">
                    <FilterHandler :filterId="'skyUncertainty'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.arcsec }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'galaxyLatitude'}">
                <div class="column-title">
                    <FilterHandler :filterId="'galaxyLatitude'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.degrees }}</span></div>
            </div>
            <div class="d-table-cell fixed-width" :class="{active: dropboxSetupId === 'nrOfDaysUnobserved'}">
                <div class="column-title">
                    <FilterHandler :filterId="'nrOfDaysUnobserved'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.days }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'observationArc'}">
                <div class="column-title">
                    <FilterHandler :filterId="'observationArc'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.days }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'moonElongation'}">
                <div class="column-title">
                    <FilterHandler :filterId="'moonElongation'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.degrees }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'densityOfStars'}">
                <div class="column-title">
                    <FilterHandler :filterId="'densityOfStars'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.starsarcmin2 }}</span></div>
            </div>
            <div class="d-table-cell" :class="{active: dropboxSetupId === 'moonglow'}">
                <div class="column-title">
                    <FilterHandler :filterId="'moonglow'" />
                </div>
                <div class="column-units"><span>{{ $t.in }} {{ $t.magarcsec2 }}</span></div>
            </div>
            <div class="d-table-cell">
                <div class="column-title">{{ $t.impactCloseApproach }}</div>
                <div class="column-units capitalized"><span>{{ $t.date }}</span></div>
            </div>
        </div>

        <div v-for="(object, index) of objectsList" :key="index" class="d-table-row table-contents" :class="impactedOrCloseApproachClass(object)">
            <template v-if="!object.error">
                <div class="d-table-cell">
                    <button class="button-icon" @click="checkboxClick(object.name)">
                        <i :class="{'icon-square': !isSelected(object.name), 'icon-check': isSelected(object.name)}" />
                    </button>
                </div>
                <div class="d-table-cell">
                    <span v-if="object.number">({{ object.number }})</span> {{ object.name }}
                </div>
                <div class="d-table-cell">
                    <a :href="getPreviousObservation(object)" :title="object.name" target="_blank">
                        <i class="icon-external-link" />
                    </a>
                </div>
                <div class="d-table-cell">{{ object.sourceType ? ( object.sourceType.name === $t.neocc ? $t.pdo : object.sourceType.name ) : $t.nA }}</div>
                <div class="d-table-cell">{{ object.bodyType || $t.nA}}</div>
                <div class="d-table-cell">{{ object.orbitType || $t.nA}}</div>
                <div class="d-table-cell">{{ object.risk ? $t.yes : $t.no }}</div>
                <div class="d-table-cell">{{ object.priority || $t.nA }}</div>
                <div class="d-table-cell">{{ object.pha ? $t.yes : $t.no }}</div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'magnitude')"/></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'elevation')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'sunElongation')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'skyMovement')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'skyUncertainty')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'galaxyLatitude')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'nrOfDaysUnobserved')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'observationArc')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'moonElongation')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'densityOfStars')" /></div>
                <div class="d-table-cell"><span v-html="displayObjectsValue(object, 'moonglow')" /></div>
                <div class="d-table-cell date">
                    <Tooltip position="left" :tooltipText="displayImpactCloseApproachText(object)">
                        <span v-html="displayImpactCloseApproachDate(object)" style="white-space: nowrap;"></span>
                    </Tooltip>
                </div>
            </template>
            <template v-else>
                <div class="d-table-cell error">!</div>
                <div class="d-table-cell error"><span v-if="object.number">({{ object.number }})</span> {{ object.name }}</div>
                <div class="d-table-cell error"><span class="error-info">{{ object.error }}</span></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
                <div class="d-table-cell"></div>
            </template>
        </div>
        <Pagination />
    </div>
    <div v-if="!objectsList || !objectsList.length" class="objects-no-results">
        <i class="icon-list" />
        <span>{{ $t.thereAreNoObjectsFulfillingThisCriteria }}</span>
    </div>
</template>

<script>
import FilteringService from '@/services/filtering.service'
import DropboxService from '@/services/dropbox.service'
import ObjectsService from '@/services/objects.service'
import FilterHandler from '@/components/opt/filtering/FilterHandler'
import SelectHandler from '@/components/opt/filtering/SelectHandler'
import UtilsService from '@/services/utils.service'
import Pagination from '@/components/opt/filtering/Pagination'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'ObjectsTable',
    components: {
        FilterHandler,
        SelectHandler,
        Pagination,
        Tooltip,
    },
    data() {
        return {
            objectsList: [],
            objectsListSelected: [],
            fixedHeader: false,
        }
    },
    computed: {
        objects() {
            return ObjectsService.getObjects();
        },
        objectsSelected() {
            return ObjectsService.getObjectsSelected();
        },
        dropboxSetupId() {
            return DropboxService.getDropboxSetupId();
        },
    },
    methods: {
        objectImpactOrCloseApproach(object) {
            const impact = object.impacted;
            const closeApproach = object.closeApproach;
            if (impact && impact !== 'false') {
                return 1;
            } else if (closeApproach && closeApproach !== 'false') {
                return 2;
            } else {
                return 0;
            }
        },
        impactedOrCloseApproachClass(object) {
            const objectEvent = this.objectImpactOrCloseApproach(object);
            if (!objectEvent) return '';

            let objectClass = 'close-approach';
            if (objectEvent === 1) {
                objectClass = 'impacted';
            }
            return objectClass;
        },
        displayImpactCloseApproachDate(object) {
            const objectEvent = this.objectImpactOrCloseApproach(object);
            if (!objectEvent) return '-';

            let date = '<i class="icon-alert-triangle"></i>' + UtilsService.dateToString(new Date(object.closeApproach));
            if (objectEvent === 1) {
                date = '<i class="icon-x"></i>' + UtilsService.dateToString(new Date(object.impact_date));
            }
            return date;
        },
        displayImpactCloseApproachText(object) {
            const objectEvent = this.objectImpactOrCloseApproach(object);
            if (!objectEvent) return '';
            
            let text = `${this.$keys.tooltips.closeApproach} ${UtilsService.dateToString(new Date(object.closeApproach))}`;
            if (objectEvent === 1) {
                text = `${this.$keys.tooltips.impact} ${UtilsService.dateToString(new Date(object.impact_date))}`;
            }
            return text;
        },
        displayObjectsValue(object, filterName) {
            const filters = object.filters;
            if (!filters) return this.$t.nA;
            
            const filter = filters.find(filter => filter.name === filterName);

            if (!filter) return this.$t.nA;

            let filterMin = filter.minValue;
            let filterMax = filter.maxValue;

            if (isNaN(filterMin) || isNaN(filterMax)) return this.$t.nA;
            
            let rounding = 1;
            if (filterName === 'skyMovement') {
                rounding = 3;
            } else if (filterName === 'nrOfDaysUnobserved' || filterName === 'observationArc') {
                rounding = 0;
            }

            filterMin = (filterMin*1).toFixed(rounding);
            filterMax = (filterMax*1).toFixed(rounding);
            
            if (filterName === 'nrOfDaysUnobserved' || filterMin === filterMax) {
                return `${filterMax}`;
            }

            return `${filterMin} <span class="value-separator">-</span> ${filterMax}`;
        },
        checkboxClick(id) {
            ObjectsService.handleSelect(id);
        },
        isSelected(id) {
            return this.objectsListSelected.includes(id);
        },
        getPreviousObservation(object) {
            const clearName = object.name.includes('(') ? object.name.slice(0, object.name.indexOf('(') - 1) : object.name;
            const normalizedName = clearName.indexOf('/') > 1 ? clearName.slice(0, clearName.indexOf('/')) : clearName;
            const variant = object.name.slice(normalizedName.indexOf('-') -1)
            const variantName = (object.name.includes('-')) && (variant.includes('-')) ? normalizedName + variant : normalizedName;
            const objectName = object.bodyType === 'Comet' ? variantName : object.name;
            const designator = (object.number && object.number !== '' && object.number !== '0') ? `${object.number} ${objectName}` : objectName;
            
            return FilteringService.getPreviousObservationUrl(object) + encodeURIComponent(designator);
        },
    },
    watch: {
        objects: {
            deep: true,
            handler(newVal) {
                this.objectsList = newVal.list;
            }
        },
        objectsSelected(newVal) {
            this.objectsListSelected = newVal;
        }
    },
    mounted() {
        if (!ObjectsService.initialLoadPerformed()) {
            FilteringService.getInitialObjectsList();
        } else {
            this.objectsList = this.objects.list;
            this.objectsListSelected = this.objectsSelected;
        }
    },
}
</script>

<style>

</style>