<template>
    <div class="settings-button" @click="setSettingsPanelName(type)" :class="{active: (settingsPanelName === type || (type === 'invertColors' && invertColors))}">
        <i v-if="type === 'objectsSettings'" class="icon-settings"></i>        
        <i v-if="type === 'charts'" class="icon-bar-chart"></i>
        <i v-if="type === 'invertColors'" class="icon-repeat"></i>
        <i v-if="type === 'export'" class="icon-log-out"></i>
        <span v-if="type === 'objectsSettings'">{{ $t.displaySettings }}</span>
        <span v-else>{{ $t[type] }}</span>
        <SettingsPanel v-if="settings && settingsPanelName === type && type !== 'charts'" :tool="toolName" @click.stop>
            <ObjectsSettings v-if="settingsPanelName === 'objectsSettings'" :type="settingsPanelName" :settings="settings" @change="onChange(settingsPanelName, $event)" />            
            <Export v-if="settingsPanelName === 'export'" @print="onPrint($event)" />
            <!--<InvertColors v-if="settingsPanelName === 'invertColors'" :settings="settings"/>-->
        </SettingsPanel>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service';
import SettingsPanel from '@/components/common/visualisation/SettingsPanel';
import ObjectsSettings from '@/components/common/visualisation/ObjectsSettings';
import Export from '@/components/common/visualisation/Export';
//import InvertColors from '@/components/common/visualisation/InvertColors';

export default {
    name: 'VisualisationSettingsButton',
    props: {
        type: String,
        settings: Object,
    },
    emits: [
        'change',
        'export',
    ],
    components: {
        SettingsPanel,
        ObjectsSettings,        
        Export,
        //InvertColors,
    },
    computed: {
        settingsPanelName() {
            return VisualisationService.getSettingsPanelName();
        },
        invertColors() {
            return VisualisationService.isAnyInvertColorsActive();
        },
        isSynodicOrbitChartActive() {
            return VisualisationService.getIsSynodicOrbitChartActive();
        },
        toolName() {
            return VisualisationService.getTool();
        }
    },
    methods: {
        setSettingsPanelName(name) {
            if (name !== 'charts' && name !== 'invertColors' && this.isSynodicOrbitChartActive) {
                VisualisationService.setSynodicOrbitCharts(false);
            }
            if (name === 'charts') {
                VisualisationService.setSynodicOrbitCharts(
                    !this.isSynodicOrbitChartActive
                );
            }
            if (name !== 'invertColors') {
                VisualisationService.setSettingsPanelName(name === this.settingsPanelName ? "" : name);
                return;
            }
            VisualisationService.setInvertColors(!this.invertColors);
        },
        onChange(group, change) {
            this.$emit('change', {group, change});
        }, 
        onPrint(type) {
            this.$emit('export', type);
        }
    },
}
</script>

<style>

</style>