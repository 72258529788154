<template>
    <div v-if="show()" class="d-table-row table-contents pagination">
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell pagination-cell">
            <span class="pagination-info" :class="{loading}" @click="getAnotherPage()">
                <span class="pagination-info__text">
                    <i class="icon-maximize-2" />
                    <span>{{ $t.thereAreMoreObjectsFulfillingThisCriteria }}</span>
                </span>
                <span class="pagination-info__loading"></span>
            </span>
        </div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
        <div class="d-table-cell"></div>
    </div>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import FilteringService from '@/services/filtering.service'

export default {
    name: 'FilteringPagination',
    data() {
        return {
            scrollDelay: null,
            nextPageReady: false,
        }
    },
    computed: {
        objectsCount() {
            return ObjectsService.getObjects().list.length || 0;
        },
        total() {
            return ObjectsService.getObjects().total || 0;
        },
        from() {
            return FilteringService.getPaginationFrom();
        },
        size() {
            if (this.from === 0)
                return FilteringService.getInitialLoadSize();
            else 
                return FilteringService.getPaginationSize();
        },
        loading() {
            return FilteringService.getPaginationLoading();
        }
    },
    methods: {
        show() {
            return this.total > this.from + this.size;
        },
        getAnotherPage() {
            FilteringService.setPaginationFrom(this.from + this.size);
            FilteringService.runFilteringRequest({ pagination: true });
        },
        onWheelEvent(event) {
            if (this.nextPageReady) {
                if (event.deltaY > 0) {
                    FilteringService.setPaginationFrom(this.from + this.size);
                    FilteringService.runFilteringRequest({ pagination: true });
                }
                this.nextPageReady = false;
            }
        },
        onScrollEvent() {
            if (this.show()) {
                const contentHeight = document.querySelector('.content').offsetHeight;
                const contentScrollTop = document.querySelector('.content').scrollTop;
                const triggerOffsetTop = document.querySelector('.pagination-info').offsetParent.offsetTop;
                const headerOffset = 48;
                const footerOffset = 21*2 - 5;
                if (contentHeight + contentScrollTop + headerOffset >= triggerOffsetTop + footerOffset) {
                //if (contentHeight + contentScrollTop + headerOffset >= footerOffset) {
                    this.nextPageReady = true;
                    //this.nextPageReady = false;
                }
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.onScrollEvent, true);
        window.addEventListener('wheel', this.onWheelEvent, true);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.onScrollEvent, true);
        window.removeEventListener('wheel', this.onWheelEvent, true);
    }
}
</script>

<style>

</style>