<template>
    <div class="fvt__timeframe-selection">
        <div class="input start-date">
            <span class="input-label">{{ $t.startDate }}:</span>
            <VueDatePicker v-model="dateFrom" :alt-position="() => ({ top: 306, left: 0 })" :teleport="true" format="dd/MM/yyyy" :min-date="'1950-01-01'" :max-date="dateTo" :year-range="[1950,dateToToYear()]" :enable-time-picker="false" :clearable="false" auto-apply @blur="dateFromBlur" text-input/>
        </div>
        <div class="input end-date">
            <span class="input-label">{{ $t.endDate }}:</span>
            <VueDatePicker v-model="dateTo" :alt-position="() => ({ top: 306, left: 80 })" :teleport="true" format="dd/MM/yyyy" :min-date="dateFrom" :max-date="dateToMax()" :year-range="[dateFromToYear(),dateToMaxToYear()]" :enable-time-picker="false" :clearable="false" auto-apply @blur="dateToBlur" text-input />
        </div>
    </div>

</template>

<script>
import UtilsService from '@/services/utils.service'
import TimeFrameService from '@/services/timeframe.service'
import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import JulianDate from 'julian-date'

export default {
    name: 'FlybyTimeframeSelection',
    components: { VueDatePicker },
    data() {
        return {
            dateFrom: '',
            dateTo: '',
            now: new Date(),
        }
    },
    watch: {
         dateFrom(newVal) {
            const d = new Date(newVal);
            if (newVal !== '' && d instanceof Date && !isNaN(d)) {
                const date = d.getTime() >= new Date(this.to).getTime() ? new Date(this.to.getTime() - (1000*60*60*24)) : d;
                if (d > new Date(this.now)) {
                    this.now = date;
                }

                this.from = date;
                this.dateFrom = UtilsService.dateToStringTechnical(date);
                TimeFrameService.setFlybyTimeframe(this.dateFrom, this.dateTo);
            }
        },
        dateTo(newVal) {
            const d = new Date(newVal);
            if (newVal !== '' && d instanceof Date && !isNaN(d)) {
                const date = d.getTime() <= new Date(this.from).getTime() ? new Date(this.from.getTime() + (1000*60*60*24)) : d;
                this.to = date;
                TimeFrameService.setFlybyTimeframe(this.dateFrom, this.dateTo);
            }
        },
    },
    methods: {
        dateToMax() {
            const now = new Date(this.now);
            const year = now.getFullYear();
            const max = new Date(now.setYear(year + 100));
            return UtilsService.dateToStringTechnical(max);
        },
        dateToMaxToYear() {
            const dateToMax = new Date(this.dateToMax());
            const year = dateToMax.getFullYear();
            return year;
        },
        dateFromToYear() {
            const dateFrom = new Date(this.dateFrom);
            const year = dateFrom.getFullYear();
            return year;
        },
        dateToToYear() {
            const dateTo = new Date(this.to);
            const year = dateTo.getFullYear();
            return year;
        },
        dateFromBlur(value) {
            const d = new Date(value);
            const min = new Date('1950-01-01');
            if (d < min) {
                this.dateFrom = UtilsService.dateToStringTechnical(min);
            }
        },
        dateToBlur(value) {
            const d = new Date(value);
            const max = new Date(this.dateToMax());
            const date = d.getTime() <= new Date(this.from).getTime() ? new Date(this.from.getTime() + (1000*60*60*24)) : d;
            if (d > max) {
                this.dateTo = this.dateToMax();
            }
            if (d < new Date(this.now)) {
                this.now = date;
                this.dateTo = UtilsService.dateToStringTechnical(date);
            }
        },
        julianToDate(julian){
            const j = new JulianDate().julian(julian);
            return j.getDate();
        }
    },
    mounted() {
        const queryStart = this.$route.query.start;
        const queryEnd = this.$route.query.end;

        const prevStartDate = TimeFrameService.getFlybyTimeframe() && TimeFrameService.getFlybyTimeframe().start ? UtilsService.dateToStringTechnical(this.julianToDate(TimeFrameService.getFlybyTimeframe().start)) : null;
        const prevEndDate = TimeFrameService.getFlybyTimeframe() && TimeFrameService.getFlybyTimeframe().end ? UtilsService.dateToStringTechnical(this.julianToDate(TimeFrameService.getFlybyTimeframe().end)) : null;

        if (queryStart && queryEnd) {
            this.dateFrom = UtilsService.dateToStringTechnical(queryStart);
            this.dateTo = UtilsService.dateToStringTechnical(queryEnd);
        } else if ((TimeFrameService.getFlybyTimeframe() && TimeFrameService.getFlybyTimeframe().start && prevStartDate!==this.dateFrom) && (TimeFrameService.getFlybyTimeframe() && TimeFrameService.getFlybyTimeframe().end && prevEndDate!==this.dateTo)){
            this.dateFrom = prevStartDate;
            this.dateTo = prevEndDate;
        } else {
            this.dateFrom = UtilsService.dateToStringTechnical(this.now);
            this.dateTo = UtilsService.dateToStringTechnical(UtilsService.dateAddDays(this.now, 10));
        }
    }
}
</script>

<style>

</style>