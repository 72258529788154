<template>
    <div class="ephemerides-configuration">
        <div class="popup-header">
            {{ $t.listOfEphemeridesColumns }}
        </div>
        <div class="popup-content">
            <div class="select-list">
                <div class="select-list__column">
                    <span class="label">
                        {{ $t.possibleQuantities }} ({{ availableEphemeridesColumns.length }}):
                    </span>
                    <ul class="select-list__objects available">
                        <li class="columnname available" :class="{active: isAvailableSelected(item)}" v-for="(item, index) of availableEphemeridesColumns" :key="index" @click="onAvailableSelect(item)">
                            {{ $keys.titles[item] }} <small class="columnname-id">{{ item }}</small>
                        </li>
                    </ul>
                </div>
                <div class="select-list__column">
                    <span class="label"></span>
                    <div class="buttons">
                        <div>
                            <button class="secondary" :class="{disabled: !availableSelected.length}" @click="add()"><span>{{ $t.add }}</span><i class="icon-chevron-right" /></button>
                            <button class="secondary" :class="{disabled: !availableEphemeridesColumns.length}" @click="addAll()"><span>{{ $t.addAll }}</span><i class="icon-chevrons-right" /></button>
                        </div>
                        <div>
                            <button class="secondary" :class="{disabled: !finalSelected.length}" @click="remove()"><i class="icon-chevron-left" /><span>{{ $t.remove }}</span></button>
                            <button class="secondary" :class="{disabled: !finalEphemeridesColumns.length}" @click="removeAll()"><i class="icon-chevrons-left" /><span>{{ $t.removeAll }}</span></button>
                        </div>
                    </diV>
                </div>
                <div class="select-list__column">
                    <span class="label">
                        {{ $t.selectedQuantities }} ({{ finalEphemeridesColumns.length }}):
                    </span>
                    <ul class="select-list__objects final">
                        <li class="columnname final" :class="{active: isFinalSelected(item)}" v-for="(item, index) of finalEphemeridesColumns" :key="index" @click="onFinalSelect(item)">
                            {{ $keys.titles[item] }} <small class="columnname-id">{{ item }}</small>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="popup-footer">
            <button class="cancel" @click="cancel()">{{ $t.cancel }}</button>
            <button class="primary" @click="submit()" :class="{disabled: !finalEphemeridesColumns.length}">{{ $t.save }}</button>
        </div>
    </div>
</template>

<script>
import EphemeridesService from '@/services/ephemerides.service'

export default {
    name: 'EphemeridesConfiguration',
    props: {
        config: {}
    },
    data() {
        return {
            availableSelected: [],
            finalSelected: [],
        }
    },
    computed: {
        availableEphemeridesColumns() {
            return EphemeridesService.getAvailableEphemeridesColumns();
        },
        finalEphemeridesColumns() {
            return EphemeridesService.getFinalEphemeridesColumns();
        },
    },
    methods: {
        onAvailableSelect(item) {
            if (this.isAvailableSelected(item)) {
                this.availableSelected = this.availableSelected.filter(e => e !== item);
            } else {
                this.availableSelected.push(item);
            }
        },
        isAvailableSelected(item) {
            return this.availableSelected.filter(e => e === item).length;
        },
        onFinalSelect(item) {
            if (this.isFinalSelected(item)) {
                this.finalSelected = this.finalSelected.filter(e => e !== item);
            } else {
                this.finalSelected.push(item);
            }
        },
        isFinalSelected(item) { 
            return this.finalSelected.filter(e => e === item).length;
        },
        add() {
            this.availableSelected = EphemeridesService.ephemeridesConfigAdd(this.availableSelected);
        },
        addAll() {
            this.availableSelected = EphemeridesService.ephemeridesConfigAddAll();
        },
        remove() {
            this.finalSelected = EphemeridesService.ephemeridesConfigRemove(this.finalSelected);
        },
        removeAll() {
            this.finalSelected = EphemeridesService.ephemeridesConfigRemoveAll();
        },
        cancel() {
            this.$emit('cancel');
        },
        submit() {
            EphemeridesService.updateEphemeridesColumns();
            EphemeridesService.setEphemeridesColumns(this.finalEphemeridesColumns);
            this.$emit('submit');
        },
    },
    mounted() {
        if (!this.finalEphemeridesColumns.length) {
            const allFields = EphemeridesService.getEphemerides().fields;
            EphemeridesService.setAvailableEphemeridesColumns(allFields);
        }
    }
}
</script>

<style>

</style>