import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'

const DropboxService = {
    getDropboxSetupId() {
        return store.getters[CommonGetters.dropboxSetupId];
    },

    setDropboxSetupId(dropboxSetupId) {
        store.dispatch(CommonActions.setDropboxSetupId, dropboxSetupId);
    },
}

export default DropboxService;