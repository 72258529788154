import store from '@/store'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Config from '@/constants/config'
import EphemeridesService from '@/services/ephemerides.service'
import UtilsService from '@/services/utils.service'
import TimeFrameService from '@/services/timeframe.service'

const ShorttermService = {
    shortTermUrl() {
        return Config.api.restUrl + Config.api.shortTermUrl;
    },

    shortTermSunAltLimit() {
        return Config.shortTermPlots.sunAltLimit;
    },

    getEphemeridesShortterm() {
        return store.getters[CommonGetters.ephemeridesShortterm];
    },

    setEphemeridesShortterm(ephemerides) {
        store.dispatch(CommonActions.setEphemeridesShortterm, ephemerides);
    },

    getShorttermDate() {
        return store.getters[CommonGetters.shorttermDate];
    },

    setShorttermDate(value) {
        store.dispatch(CommonActions.setShorttermDate, value);
    },

    getTimeShift(date = null) {
        let shift = TimeFrameService.getTimezoneShift(date) + 12 + '';
        if (shift.length === 1) shift = '0' + shift;
        return shift + ':00';
    },

    getShorttermData(objectName, date) {
        const oneDayStep = TimeFrameService.getTimestep().converted === 1;
        const startDate = UtilsService.momentUtcDate(date + ' ' + ShorttermService.getTimeShift(date));
        const endDate = UtilsService.momentUtcDate(date + ' ' + ShorttermService.getTimeShift(date)).add(1, 'days');
        const ephemerides = EphemeridesService.getEphemerides();
        const objAltIndex = ephemerides.fields.indexOf('ELEV');
        const sunAltIndex = ephemerides.fields.indexOf('ELSUN');
        const objectAltitudes = [];
        const altitudeDates = [];
        const sunAltitudes = [];

        if (startDate) {
            for (const timeStamp of ephemerides.objects[objectName]) {
                const timestepFromJulian = UtilsService.julianToTimestamp(timeStamp[0]*1);
                const timestep = UtilsService.momentUtcDate(timestepFromJulian);
                if (oneDayStep) {
                    if (timestep >= startDate.subtract(12, 'hours') && timestep < endDate.subtract(12, 'hours')) {
                        objectAltitudes.push(timeStamp[objAltIndex]);
                        altitudeDates.push(timeStamp[0]);
                        sunAltitudes.push(timeStamp[sunAltIndex]);
                    }
                } else {
                    if (timestep >= startDate && timestep < endDate) {
                        objectAltitudes.push(timeStamp[objAltIndex]);
                        altitudeDates.push(timeStamp[0]);
                        sunAltitudes.push(timeStamp[sunAltIndex]);
                    }
                }
            }
        }
        
        return {
            objectAltitudes,
            sunAltitudes,
            altitudeDates,
        }
    },

    getAstronomicalDusk(date) {
        return UtilsService.astronomicalTwilight('Dusk', date);
    },

    getAstronomicalDawn(date) {
        const nextDay = UtilsService.dateNextDay(date);
        return UtilsService.astronomicalTwilight('Dawn', nextDay);
    }
}

export default ShorttermService;