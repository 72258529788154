<template>
    <div class="popup-content">
        <i class="icon-alert-triangle popup-icon warning" />
        <div>{{ getInfoMessage }}</div>
        <div class="popup-footer">
            <button class="cancel" @click="cancelObjectRemove()">
                {{ $t.cancel }}
            </button>
            <button class="primary" @click="acceptObjectRemove()">
                {{ $t.ok }}
            </button>
        </div>
    </div>
</template>

<script>
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import VisualisationService from "@/services/visualisation.service";
import UtilsService from "@/services/utils.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";

export default {
    name: "PopupSyntheticObjectDelete",
    components: {},
    props: {
        config: Object,
    },
    computed: {
        getInfoMessage() {
            const objectName = SyntheticObjectsService.getSyntheticObjectName(this.config.selectedObject);
            return this.$t.removeUserDefinedObject.replace("{objectName}", objectName);
        },
    },
    methods: {
        cancelObjectRemove() {
            this.$emit("cancel");
        },

        acceptObjectRemove() {
            const isDefaultList = SyntheticObjectsService.isDefaultSyntheticObjects();
            const objectName = SyntheticObjectsService.getSyntheticObjectName(this.config.selectedObject);
            isDefaultList ? this.removeDefaultObject(objectName) : this.removeFvtObject(objectName);
            this.$emit("submit");
        },

        removeDefaultObject(objectName) {
            const objectsList = UtilsService.deepCopy(SyntheticObjectsService.getSyntheticDefaultObjectsList());
            const objectIndex = UtilsService.findItemIndexInObjectList("objectName", objectName, objectsList);
            objectsList.splice(objectIndex, 1);
            SyntheticObjectsService.setSyntheticDefaultObjectsList(objectsList);
            const currentTool = VisualisationService.getTool();
            currentTool === "ovt" && this.adjustOvtToolFeatures();
            currentTool === "sovt" && this.adjustSovtToolFeatures();
        },

        adjustOvtToolFeatures() {
            const { designator } = this.config.selectedObject;
            const { checkedObjectsList, perturbedObjectsList, isObjectChecked, isObjectSelected, isObjectPerturbed } =
                SyntheticObjectsService.getOvtObjectsInformationsByDesignator(designator);
            isObjectSelected && this.adjustOvtSelectedObject();
            isObjectChecked && this.adjustOvtCheckedObjects(checkedObjectsList, designator, isObjectPerturbed);
            isObjectPerturbed && this.adjustOvtPerturbedObject(perturbedObjectsList, designator);
        },

        adjustOvtSelectedObject() {
            VisualisationService.setSelectedObjectName(null);
        },

        adjustOvtCheckedObjects(checkedObjectsList, designator, isObjectPerturbed) {
            const focusedObject = VisualisationService.getOvtFocusedObject();
            const isFocusedDeselected = focusedObject === designator;
            isFocusedDeselected && VisualisationService.setOvtFocusedObject('');
            checkedObjectsList.splice(checkedObjectsList.indexOf(designator), 1);
            VisualisationService.removeObject(`${designator}${isObjectPerturbed ? "_perturbed" : ""}`);
        },

        adjustOvtPerturbedObject(perturbedObjectsList, designator) {
            perturbedObjectsList.splice(perturbedObjectsList.indexOf(designator), 1);
        },

        adjustSovtToolFeatures() {
            const { designator } = this.config.selectedObject;
            const calculatedObjectList = UtilsService.deepCopy(
                SynodicCalculationService.getCalculatedRealSynodicObjectsList()
            );
            const calculatedObjectIndex = UtilsService.findItemIndexInObjectList(
                "designator",
                designator,
                calculatedObjectList
            );
            const { isObjectVisible } = SynodicCalculationService.getSynodicObjectVisibilityInfo(designator);
            if (calculatedObjectIndex === -1) {
                SynodicCalculationService.removePerturbedObjectOrbit(designator);
                return;
            }
            if (isObjectVisible) {
                SynodicCalculationService.removeVisibleCalculatedObject(designator);
            }
            SynodicCalculationService.removePerturbedObjectOrbit(designator);
            calculatedObjectList.splice(calculatedObjectIndex, 1);
            SynodicCalculationService.setCalculatedRealSynodicObjectsList(calculatedObjectList);
        },

        removeFvtObject(objectName) {
            const objectsList = UtilsService.deepCopy(SyntheticObjectsService.getSyntheticFvtObjectsList());
            const objectIndex = UtilsService.findItemIndexInObjectList("objectName", objectName, objectsList);
            objectsList.splice(objectIndex, 1);
            SyntheticObjectsService.setSyntheticFvtObjectsList(objectsList);
        },
    },
};
</script>

<style lang="scss"></style>
