<template>
    <div class="map map-timezone" id="map-timezone"></div>
</template>

<script>
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Config from '@/constants/config'
import KML from 'ol/format/KML';
import ObservatoryService from '@/services/observatory.service'
import TimeframeService from '@/services/timeframe.service'

export default {
    name: 'MapTimezone',
    data() {
        return {
            map: null,
            view: null,
            config: Config.map,
        }
    },
    computed: {
        selectedObservatory() {
            return ObservatoryService.getSelectedObservatory();
        },
        isGeocentric() {
            return ObservatoryService.isGeocentric();
        },
    },
    mounted() {
        const coordinates = fromLonLat([
            this.selectedObservatory.longitude,
            this.selectedObservatory.latitude
        ]);

        const timezonesKml = new VectorLayer({
            source: new VectorSource({
                url: '/static/kml/timezones.kml',
                format: new KML({
                    extractStyles: false,
                }),
            }),
        });

        const target = document.getElementById('map-timezone');

        this.view = new View({
            center: coordinates,
            zoom: 3,
            maxZoom: 3,
        })

        this.map = new Map({
            target,
            layers: [timezonesKml],
            view: this.view,
        });    
    },
    methods: {
        getTimezone() {
            const x = this.map.values_.size[0] / 2;
            const y = this.map.values_.size[1] / 2;
            let setOffset = true;
            setTimeout(() => {
                this.map.forEachFeatureAtPixel([x, y], feature => {
                    if (feature.get('name') && setOffset) {
                        const timezone = feature.get('name');
                        TimeframeService.setTimezone(timezone);
                        setOffset = false;
                    }
                });
            }, 250)
        }
    },
    watch: {
        selectedObservatory(newVal) {
            if (this.isGeocentric || isNaN(newVal.longitude) || isNaN(newVal.latitude)) {
                TimeframeService.setTimezone('GMT +00:00');
                return;
            }
            const coordinates = fromLonLat([newVal.longitude, newVal.latitude]);
            this.view.animate({
                center: coordinates,
                duration: this.config.animationDuration,
            }, this.getTimezone);
        }
    }
}
</script>

<style>

</style>