import { createRouter, createWebHistory } from 'vue-router'
import Guards from '@/router/guards'
import Keys from '@/constants/keys'
import Welcome from '@/views/Welcome'
import ObservationPlannigTool from '@/views/ObservationPlannigTool'
import SkyChartDisplayTool from '@/views/SkyChartDisplayTool'
import Setup from '@/components/common/Setup'
import OptFiltering from '@/components/opt/Filtering'
import OptResults from '@/components/opt/Results'
import ResultsLongTerm from '@/components/opt/ResultsLongTerm'
import ResultsShortTerm from '@/components/opt/ResultsShortTerm'
import ResultsEphemerides from '@/components/opt/ResultsEphemerides'
import Skychart from '@/components/scd/Skychart'
import OrbitVisualisationTool from '@/components/ovt/OrbitVisualisationTool'
import FlybyVisualisationTool from '@/components/fvt/FlybyVisualisationTool'
import SynodicOrbitVisualisationTool from '@/components/sovt/SynodicOrbitVisualisationTool'

const routes = [
    {
        name: 'welcome',
        path: '/',
        component: Welcome,
    },
    {
        name: 'opt',
        path: Keys.routes.opt,
        component: ObservationPlannigTool,
        children: [
            {
                path: Keys.routes.opt,
                redirect: `${Keys.routes.opt}/${Keys.routes.setup}`
            },
            {
                name: 'opt-setup',
                path: `${Keys.routes.opt}/${Keys.routes.setup}`,
                props: { tool: 'opt' },
                component: Setup
            },
            {
                name: 'opt-filtering',
                path: Keys.routes.optFiltering,
                component: OptFiltering,
                beforeEnter: (to, from, next) => {
                    if (Guards.optFilteringAvailable()) next()
                    else next({ name: 'opt-setup' })
                }
            },
            {
                name: 'opt-results',
                path: Keys.routes.optResults,
                component: OptResults,
                redirect: `${Keys.routes.opt}/${Keys.routes.optResults}/${Keys.routes.optLongTerm}`,
                beforeEnter: (to, from, next) => {
                    if (Guards.optResultsAvailable(to.query.ifc)) next()
                    else next({ name: 'opt-filtering' })
                },
                children: [
                    {
                        name: 'opt-results-longterm',
                        path: Keys.routes.optLongTerm,
                        component: ResultsLongTerm
                    },
                    {
                        name: 'opt-results-shortterm',
                        path: Keys.routes.optShortTerm,
                        component: ResultsShortTerm
                    },
                    {
                        name: 'opt-results-ephemerides',
                        path: Keys.routes.optEphemerides,
                        component: ResultsEphemerides
                    },
                ]
            }
        ]
    },
    {
        name: 'scd',
        path: Keys.routes.scd,
        component: SkyChartDisplayTool,
        children: [
            {
                path: Keys.routes.scd,
                redirect: `${Keys.routes.scd}/${Keys.routes.setup}`
            },
            {
                name: 'scd-setup',
                path: `${Keys.routes.scd}/${Keys.routes.setup}`,
                props: { tool: 'scd' },
                component: Setup
            },
            {
                name: 'scd-visualisation',
                path: Keys.routes.scdVisualisation,
                component: Skychart,
                beforeEnter: (to, from, next) => {
                    if (Guards.scdVisualisationAvailable()) next()
                    else next({ name: 'scd-setup' })
                }
            },
        ]
    },
    {
        name: 'ovt',
        path: Keys.routes.ovt,
        component: OrbitVisualisationTool,
        props: { tool: 'ovt' }
    },
    {
        name: 'fvt',
        path: Keys.routes.fvt,
        component: FlybyVisualisationTool,
        props: { tool: 'fvt' }
    },
    {
        name: 'sovt',
        path: Keys.routes.sovt,
        component: SynodicOrbitVisualisationTool,
        props: { tool: 'sovt' }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
