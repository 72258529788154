<template>
    <div class="fvt">
        <VisualisationSimulation :tool="tool">
            <FlybyArrowLegend />
            <VisualisationObjectLegend />
        </VisualisationSimulation>
        <VisualisationAxesViewer />
        <FlybyTimeline />
        <VisualisationRecorder />
        <VisualisationSettings :tool="tool" />
        <FlybyObjectsConfiguration :tool="tool" />
    </div>
</template>

<script>
import FlybyObjectsConfiguration from '@/components/fvt/FlybyObjectsConfiguration'
import VisualisationSimulation from '@/components/common/visualisation/Simulation'
import VisualisationRecorder from '@/components/common/visualisation/Recorder'
import VisualisationObjectLegend from '@/components/common/visualisation/ObjectLegend'
import VisualisationAxesViewer from '@/components/common/visualisation/AxesViewer'
import VisualisationSettings from '@/components/common/visualisation/Settings'
import FlybyTimeline from '@/components/fvt/FlybyTimeline'
import CommonActions from '@/store/common/common-actions'
import FlybyArrowLegend from './FlybyArrowLegend.vue'

export default {
    name: 'FlybyVisualisationTool',
    components: {
    FlybyObjectsConfiguration,
    VisualisationSimulation,
    VisualisationRecorder,
    VisualisationObjectLegend,
    VisualisationAxesViewer,
    VisualisationSettings,
    FlybyTimeline,
    FlybyArrowLegend
},
    props: {
        tool: String,
    },                     
    mounted() {        
        this.$store.dispatch(CommonActions.setTool, 'fvt');
        this.$store.dispatch(CommonActions.setToolTitle, this.$keys.titles.fvt);
    }
}
</script>

<style lang="scss">
// @import "@/styles/common/visualisation.scss"; duplicated in OVT
@import "@/styles/fvt/general.scss";
</style>
