<template>
    <span :tooltip="tooltipText" :position="position"><span class="after" v-html="tooltipText" /><span class="before" /><slot /></span>
</template>

<script>
export default {
    name: "CommonTooltip",
    props: {
        tooltipText: {
            type: String,
            default: "Tooltip text",
        },
        position: {
            default: "top",
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common/tooltip.scss";
</style>