<template>
    <div class="spinner-overlay" :class="{show}">
        <div class="spinner-chase">
            <div class="spinner-chase-dot"></div>
            <div class="spinner-chase-dot"></div>
            <div class="spinner-chase-dot"></div>
            <div class="spinner-chase-dot"></div>
            <div class="spinner-chase-dot"></div>
            <div class="spinner-chase-dot"></div>
        </div>
        <button v-if="token && withCancel" @click="cancel()" class="primary spinner-abort">{{$t.stopCalculations}}</button>
    </div>
</template>

<script>
import SpinnerService from '@/services/spinner.service'


export default {
    name: 'CommonSpinner',
    computed: {
        show() {
            return SpinnerService.getSpinnerShow();
        },
        withCancel() {
            return SpinnerService.getRequestCancel();
        },
        token() {
            return SpinnerService.getToken();
        }
    },
    methods: {
        cancel() {
            this.token && this.token.cancel();
            return SpinnerService.resetToken();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common/spinner.scss";
</style>