<template>
    <button v-for="option of printOptions" :key="option" class="primary field" @click="runExport(option)">
        {{ $t[option] }}
    </button>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'VisualisationExport',
    emits: [
        'print'
    ],
    computed: {
        printOptions() {
            return VisualisationService.getSettingsOptions('export');
        },
        invertColors() {
            return VisualisationService.getInvertColors();
        },
    },
    methods: {
        runExport(type) {
            if (type === 'img') {
                VisualisationService.exportImage(this.invertColors);
            } else if (type === 'webm') {
                VisualisationService.toggleRecorderShow();
            }
        }
    }
}

</script>

<style>

</style>