<template>
    <div class="column-title">
        <Tooltip :position="'bottom'" :tooltipText="$keys.tooltips[columnId]">
            <span class="with-filter">
               {{ $keys.titles[columnId] }}
            </span>
        </Tooltip>
    </div>
    <div class="column-units"><span><span v-if="columnUnits !== ''">{{ $t.in }}&nbsp;</span>{{ $t[columnUnits] || columnUnits }}</span></div>
</template>

<script>
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'EphemeridesTableHeader',
    props: {
        columnId: String,
        columnUnits: String,
    },
    components: {
        Tooltip
    }
}
</script>

<style>

</style>