<template>
    <div class="fvt__main-bodies">
        <span class="input-label">{{ $t.mainBody }}:</span>
        <Multiselect
            v-if="this.type === 'database'"
            v-model="selectedMainBodies"
            mode="tags"
            :searchable="true"
            :options="mainBodiesSelectList"
            @select="onSelect($event)"
            @deselect="onDeselect($event)"
            @clear="onClear($event)"
        />
        <select v-if="this.type === 'user-defined'" @change="onChange($event.target.value)">
            <option
                v-for="mainBody of mainBodies"
                :key="mainBody.id"
                :value="mainBody.id"
                :selected="selectedSyntheticMainBody && mainBody.id === selectedSyntheticMainBody.id"
            >
                {{ mainBody.name }}
            </option>
        </select>
    </div>
</template>

<script>
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import VisualisationService from "@/services/visualisation.service";
import Multiselect from "@vueform/multiselect";
import Keys from "@/constants/keys";

export default {
    name: "FlybyMainBodySelector",
    components: {
        Multiselect,
    },
    props: {
        type: String,
    },
    data() {
        return {
            mainBodies: [],
            mainBodiesSelectList: [],
            selectedMainBodies: [],
            selectedSyntheticMainBody: "",
        };
    },
    computed: {
        syntheticMainBody() {
            return SyntheticObjectsService.getSyntheticMainBody();
        },
    },
    methods: {
        onChange(id) {
            SyntheticObjectsService.setIsSyntheticObjectFlybyAboveLdLimit(false);
            const mainBody = this.mainBodies.filter((e) => e.id === id)[0];
            SyntheticObjectsService.setSyntheticMainBody(mainBody);
        },

        onSelect(option) {
            VisualisationService.setIsNoApproachFoundMsgVisible(false);
            if (option === "all") {
                this.selectedMainBodies = ["all"];
                const selectedOptions = this.mainBodies;
                VisualisationService.setMainBody(selectedOptions);
                return;
            }
            if (option !== "all" && this.selectedMainBodies.includes("all")) {
                this.selectedMainBodies = this.selectedMainBodies.filter((mainBody) => mainBody !== "all");
            }
            const selectedOptions = this.mainBodies.filter((mainBody) => this.selectedMainBodies.includes(mainBody.id));
            VisualisationService.setMainBody(selectedOptions);
        },

        onDeselect() {
            VisualisationService.setIsNoApproachFoundMsgVisible(false);
            const selectedOptions = this.mainBodies.filter((mainBody) => this.selectedMainBodies.includes(mainBody.id));
            VisualisationService.setMainBody(selectedOptions);
        },

        onClear() {
            VisualisationService.setIsNoApproachFoundMsgVisible(false);
            VisualisationService.setMainBody([]);
        },
    },
    mounted() {
        VisualisationService.getMainBodies().then((mainBodies) => {
            this.mainBodies = mainBodies;
            const selectList = [{ name: Keys.t.allMainBodies, id: "all" }].concat(mainBodies);
            this.mainBodiesSelectList = selectList.map((mainBody) => ({ label: mainBody.name, value: mainBody.id }));
            const mainBody = VisualisationService.getMainBody();
            if (!mainBody.length) {
                this.selectedMainBodies = [];
            } else if (mainBody.length === this.mainBodies.length) {
                this.selectedMainBodies = ["all"];
            } else {
                mainBody.forEach((mainBody) => {
                    const { id } = mainBody;
                    this.selectedMainBodies.push(id);
                });
            }
            this.selectedSyntheticMainBody = SyntheticObjectsService.getSyntheticMainBody() || {
                id: "earth",
                name: "Earth",
            };
            const syntheticMainBody = this.mainBodies.filter((e) => e.id === this.selectedSyntheticMainBody.id)[0];
            SyntheticObjectsService.setSyntheticMainBody(syntheticMainBody);

            const queryMainBody = this.$route.query.mainbody;
            if (queryMainBody) {
                this.selectedMainBodies.push(queryMainBody);
                this.onSelect(queryMainBody);
            }
        });
    },
};
</script>

<style></style>
