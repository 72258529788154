<template>
    <div class="opt-results-ephemerides">
        <EphemeridesHeader />
        <EphemeridesTable />
    </div>
</template>

<script>
import EphemeridesHeader from '@/components/opt/results/EphemeridesHeader'
import EphemeridesTable from '@/components/opt/results/EphemeridesTable'

export default {
    name: 'ResultsEphemerides',
    components: {
        EphemeridesHeader,
        EphemeridesTable
    },
}
</script>

<style lang="scss">
@import "@/styles/opt/results-ephemerides.scss";
</style>