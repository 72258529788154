<template>
    <div class="slider__title">{{ $t[type] }}</div>
    <div class="slider__minmax"><i class="icon-plus" /></div>
    <Slider :lazy='false' :tooltips="false" orientation="vertical" direction="ltr" v-model="value" :step="min" :min="min" :max="max" />
    <div class="slider__minmax"><i class="icon-minus" /></div>
</template>

<script>
import Slider from '@vueform/slider'
import SpacekitService from '@/services/spacekit.service'
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'VisualisationSettingsSlider',
    components: {
        Slider,
    },
    props: {
        type: String,
        settings: Object,
    },
    emits: [
        'change',
    ],
    data() {
        return this.settings[this.type];
    },
    computed: {
        currentZoom(){
            const tool = VisualisationService.getTool();
            //let zoom = VisualisationService.getZoom();
            if (tool === 'ovt')
                return VisualisationService.getZoomOVT();
            else if (tool === 'fvt')
                return VisualisationService.getZoomFVT();
            else return VisualisationService.getZoom();
        },
        minCameraDistance() {
            return VisualisationService.getMinCameraDistance();
        }
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                SpacekitService.setZoom(Math.pow(newVal, 2));
            }
        },
        minCameraDistance(newVal) {
            if (newVal) {
                let zoom = VisualisationService.getZoom();
                const tool = VisualisationService.getTool();
                if (tool === 'ovt'){
                    zoom = VisualisationService.getZoomOVT();
                    this.$emit('change', {group: 'zoom', change: {property: 'value', value: Math.sqrt(zoom)}});
                }
                else if (tool === 'fvt'){
                    zoom = VisualisationService.getZoomFVT();
                    this.$emit('change', {group: 'zoom', change: {property: 'value', value: Math.sqrt(zoom)}});
                }
            }
        },
        currentZoom(newVal){
            if (newVal){
                this.value = Math.sqrt(newVal);
            }
        }
    },
    methods: {
        onMouseDown(event) {
             if (event.button === 2) {
                const tool = VisualisationService.getTool();
                const isSovtTool = tool === 'sovt';
                const isOvtTool = tool === 'ovt';
                const isFvtTool = tool === 'fvt';
                const vt = window['vt'];
                (isSovtTool || isOvtTool) && SpacekitService.setMinCameraDistance(0);
                isFvtTool && SpacekitService.setMinCameraDistance(0.15);
                vt.getViewer().stopFollowingObject();
                vt.staticForcedUpdate();
                VisualisationService.setOrbitViewerFocusedOnObject(false);
                isSovtTool && VisualisationService.setSovtFocusedObject('');
                isOvtTool && VisualisationService.setOvtFocusedObject('');
                isFvtTool && VisualisationService.setFvtFocusedObject('');
            }
        },
        onMouseWheel(event) {
            const zoomFactor = 0.01;
            if (event.deltaY > 0) {
                this.value += zoomFactor;
                if (this.value > this.max) {
                    this.value = this.max;
                }
            } else {
                this.value -= zoomFactor;
                if (this.value < this.min) {
                    this.value = this.min;
                }
            }
            this.$emit('change', {group: 'zoom', change: {property: 'value', value: this.value}});
        }
    },
    mounted() {        
        document.querySelector('.vt__simulation').addEventListener('mousedown', this.onMouseDown);
        if (document.querySelector('.vt__simulation canvas')){
            document.querySelector('.vt__simulation canvas').addEventListener('wheel',this.onMouseWheel);
        }
        
        setTimeout(() => {
            const tool = VisualisationService.getTool();
            if (tool === 'ovt'){
                if (!VisualisationService.getZoomOVT()){
                    SpacekitService.setZoom(Math.pow(this.value, 2));
                } else {
                    this.value = Math.sqrt(VisualisationService.getZoomOVT());
                    SpacekitService.setZoom(VisualisationService.getZoomOVT());
                }
            } else if (tool === 'fvt'){
                if (!VisualisationService.getZoomFVT()){
                    SpacekitService.setZoom(Math.pow(this.value, 2));
                } else {
                    this.value = Math.sqrt(VisualisationService.getZoomFVT());
                    SpacekitService.setZoom(VisualisationService.getZoomFVT());
                }
            }
        }, 1000);

    },
    beforeUnmount() {
        document.querySelector('.vt__simulation').removeEventListener('mousedown', this.onMouseDown);
        if (document.querySelector('.vt__simulation canvas')){
            document.querySelector('.vt__simulation canvas').removeEventListener('wheel', this.onMouseWheel);
        }
    }
}
</script>

<style>

</style>